import {CrudService, DomainDefinition, Entity, PropertyDefinition} from '@ic/ng-crud-client';
import {InjectionToken, Injector} from '@angular/core';

export interface Country extends Entity {
  countryCode: string;
  label: string;
}
export const COUNTRY_SERVICE = new InjectionToken<CrudService<Country>>('COUNTRY_SERVICE');

export const countryDefinition: DomainDefinition<Country> = {
  path: 'countries',
  service: COUNTRY_SERVICE,
  properties: new Map<keyof Country, PropertyDefinition>([
    // TODO: should this remain void
    ],
  ),
}
export function countryCrudServiceFactory (httpClient, injector: Injector) {
  return new CrudService(countryDefinition, httpClient, injector);
}
