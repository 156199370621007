import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'pool-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
