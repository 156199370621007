import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ListPage, Notification, USER_NOTIFICATION_CONFIGURATION, UserNotificationConfiguration} from './definitions';
import {HttpClient} from '@angular/common/http';
import {LazyLoadEvent} from 'primeng/api';
import {map} from "rxjs/operators";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";


@Injectable({
  providedIn: 'root'
})
export class UserNotificationService {

  constructor(private httpClient: HttpClient,
              @Inject(USER_NOTIFICATION_CONFIGURATION) private configuration: UserNotificationConfiguration,
              private sanitizer: DomSanitizer) {
  }

  sendTestMail(receiver: string): Observable<{ mailSessionOutput: string }> {
    const url = this.configuration.backendBaseURL + 'testMail/sendTestMail';
    return this.httpClient.post<{ mailSessionOutput: string }>(url, {testMailTarget: receiver});
  }

  list(filter: LazyLoadEvent): Observable<ListPage> {
    const params: any = {};
    for (const p in filter.filters) {
      if (!filter.filters.hasOwnProperty(p)) {
        continue;
      }
      params[p] = filter.filters[p].value;
    }
    if (params.sentDateTime) {
      const sentDate = params.sentDateTime;
      delete params.sentDateTime;
      if (sentDate.length === 2 && sentDate[0] instanceof Date && sentDate[1] instanceof Date) {
        params['sentDateTime:gt'] = sentDate[0].toISOString();
        params['sentDateTime:lt'] = sentDate[1].toISOString();
      } else if(sentDate.length === 1 && sentDate[0] instanceof Date) {
        params['sentDateTime:gt'] = sentDate[0].toISOString();
      }
    }

    params.offset = filter.first;
    params.max = filter.rows;
    if (filter.sortField) {
      params.sort = filter.sortField;
    }
    if (filter.sortOrder) {
      params.order = filter.sortOrder > 0 ? 'ASC' : 'DESC';
    }

    const url = this.configuration.backendBaseURL + 'notification';
    return this.httpClient.get<ListPage>(url, {params: params});
  }

  get(id: Number): Observable<Notification & { mailContentURL: SafeResourceUrl }> {
    const url = this.configuration.backendBaseURL + 'notification/show/' + id;
    return this.httpClient.get<Notification>(url).pipe(
      map(notification => {
        const url = this.configuration.backendBaseURL + 'notification/mailContent/' + notification.id;
        return {
          ...notification,
          mailContentURL: this.sanitizer.bypassSecurityTrustResourceUrl(url),
        };
      }),
    );
  }
}
