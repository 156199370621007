import {CrudService, ListPage} from '@ic/ng-crud-client';
import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {Assignment, assignmentDefinition} from '../employee/employee.definition';
import {AuthService, SecurityContext} from '@ic/auth';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {OrganisationUnit} from '../organisation-unit/organisation-unit.definition';
import {Role} from '../../role';


export class AssignmentContext {
  private readonly _canPlan: Set<number>;

  constructor(assignments: Assignment[]) {
    this._canPlan = new Set<number>(assignments.filter(it => it.canPlan).map(it => it.organisationUnit.id));
  }

  get plannableOuIds(): number[] {
    return [...this._canPlan];
  }

  canPlan(ou: OrganisationUnit): boolean {
    return this._canPlan.has(ou.id);
  }
}

@Injectable({
  providedIn: 'root',
})
export class AssignmentService extends CrudService<Assignment> {
  readonly context$: Observable<AssignmentContext>;

  constructor(http: HttpClient, injector: Injector,
              private authService: AuthService,
  ) {
    super(assignmentDefinition, http, injector);
    if(authService.securityContextSnapshot.hasAnyRole(
      [
        Role.ROLE_READ_AVAILABILITY_POOL,
        Role.ROLE_READ_AVAILABILITY_OU,
        Role.ROLE_READ_ACTIVEBOOKING_POOL,
        Role.ROLE_READ_ACTIVEBOOKING_OU,
        Role.ROLE_READ_STINT_POOL,
        Role.ROLE_READ_STINT_OU,
        Role.ROLE_READ_BLOCKEDSTINT_POOL,
        Role.ROLE_READ_BLOCKEDSTINT_OU,
      ],
    )) {
      const payload = {
        filters: {
          employee: { value: [{ id: this.employeeId(this.authService.securityContextSnapshot) }], matchMode: 'in' },
          canPlan: { value: [true], matchMode: 'in' },
        },
      };
      this.context$ = (this.list(payload) as Observable<ListPage<Assignment>>).pipe(
        map((page) => {
          return new AssignmentContext(page.records);
        }),
        shareReplay(1),
      );
    }
  }

  saveList(assignments: Assignment[]): Observable<void> {
    return this.http.post<void>(environment.serverURL + 'assignment/save', assignments);
  }

  save(entity: Assignment, format?: string): Observable<Assignment> {
    throw new Error('the corresponding API endpoint is not implemented');
  }

  canPlan(): Observable<ListPage<Assignment>> {
    const payload = {
      filters: {
        employee: {value: [{id: this.employeeId(this.authService.securityContextSnapshot)}], matchMode: 'in'},
        canPlan: {value: [true], matchMode: 'in'},
      },
    };
    return this.list(payload).pipe(
      catchError(error => {
        throw new Error('No assignment data');
      }),
    ) as Observable<ListPage<Assignment>>;
  }

  private employeeId(securityContext: SecurityContext) {
    return securityContext.customClaims.get('employee_id');
  }
}
