import {Availability} from '../pool-crud/availability/availability.definition';
import {Booking} from '../pool-crud/booking/booking.definition';
import {PoolShift} from '../pool-crud/pool-shift/pool-shift.definition';
import {Shift} from '../pool-crud/shift/shift.definition';
import {LocalDate} from '@js-joda/core';
import {StintValue} from '../pool-crud/stint/stint.definition';
import {Appointment, AppointmentValue} from '../pool-crud/appointment/appointment.definition';
import {MessageType} from '../definitions';
import {ResourceType} from '../pool-crud/employee/employee.definition';
import {WorkType} from '../pool-crud/booking/work-type.definition';

export enum EntryType {
  AVAILABILITY = 'AVAILABILITY',
  BOOKING = 'BOOKING',
  ABSENCE = 'ABSENCE',
  STINT = 'STINT',  // formerly known as BookingRequest/Vacancy ('Offener Dienst')
  MEETING = 'MEETING',
  TRAINING = 'TRAINING',
  INTERVIEW = 'INTERVIEW',
  STINT_OFFER = 'STINT_OFFER',
  BROADCAST = 'BROADCAST',
  LABOR_LAW_VIOLATION = 'LABOR_LAW_VIOLATION',
  QUOTA_INCREASE_REQUEST = 'QUOTA_INCREASE_REQUEST',
  MESSAGE = 'MESSAGE',
}

export interface EmployeeDay {
  availabilities: Availability[];
  bookings: Booking[];
}

export enum PlanningRowType {
  GROUP = 'GROUP', ORGANISATION_UNIT = 'ORGANISATION_UNIT', RESOURCE = 'RESOURCE',
}

export interface PlanningCell {
  title: string;
  subtitle: string;
  tooltip: { text: string, css?: string }[];
  css: string;
  hasComment: boolean;
}

export interface PlanningCellCommand {
  rowType: PlanningRowType;
  cells: { rowId: number, date: LocalDate }[];
}

export interface PlanningRow {
  type: PlanningRowType;
  id?: number;
  key?: string;
  title: string;
  subtitle: string;
  css?: string;
  cells?: (key: string) => PlanningCell;
  balance: string[];
  email?: string; // This property is only set for rows of type RESOURCE
  nComments: number;
}

export interface PlanningRowBatch {
  total: number;
  rows: PlanningRow[];
  first?: number;
  isUpdate?: boolean;
}

export enum Tab {
  CREATE, UPDATE, DELETE, CHOOSE,
}

export enum ChangeType {
  CREATE = 'CREATE', UPDATE = 'UPDATE', DELETE = 'DELETE', ACCEPT = 'ACCEPT' // TODO: check with David
}

export interface ChangeDetail {
  property: string;
  oldValue: string;
  newValue: string;
}

export interface Change {
  changeType?: ChangeType;
  entryType: EntryType;
  entry: any;
  remark?: string;
  severity?: MessageType;
  inferences?: Change[];
  metaData?: any;
  details?: ChangeDetail[];
}

/*export interface Change {
  changeType: ChangeType
}

export interface BookingChange extends Change {
  inferences: StintChange // TODO: use reference to changes.stints[i]
  booking: Booking
}

export interface StintChange extends Change {
  stint: Stint
}

export interface Changes {
  bookings: BookingChange[]
  stints: StintChange[]
  absences
  meetings
  trainings
}*/


export enum ChangeState {
  DRAFT = 'DRAFT', VALIDATED = 'VALIDATED', APPLIED = 'APPLIED',
}

export interface ChangeCommand {
  changes: Change[];
  state?: ChangeState;
}

export enum CancellationReason {
  ILLNESS = 'ILLNESS',
  ILLNESS_CHILD = 'ILLNESS_CHILD',
  ACCIDENT = 'ACCIDENT',
  ORGANISATION_UNIT = 'ORGANISATION_UNIT',
  COMPENSATION = 'COMPENSATION',
  LOCATION_TIME_INACCEPTABLE = 'LOCATION_TIME_INACCEPTABLE',
  OTHER = 'OTHER',
}

export interface CancelBookingCommand {
  booking: Booking;
  cancelledByResource: boolean;
  cancellationReason: CancellationReason;
  reason: string;
}

export type EntrySelection = {
  [key in keyof typeof EntryType]:
  number[]
};

export enum ResourceGroupKey {
  PENSUM = 'PENSUM',
  QUALIFICATION = 'QUALIFICATION',
  SEX = 'SEX',
  RESOURCE_TYPE = 'RESOURCE_TYPE',
  NONE = 'NONE',
}

export interface Scrolling {
  index: number;
  diff: number;
}

export interface TooltipDTO {
  text: string;
  css?: string;
}

export interface PlanningFetchSettings {
  showAbsences?: boolean;
  showBlocked?: boolean;
  showOU?: boolean;
  showResWithoutAvailability?: boolean;
  showAllStints?: boolean;
  showAllBookings?: boolean;
  showEmptyOURows?: boolean;
  showEmptyResourceRows?: boolean;
  showOnlyFutureOpenStints?: boolean;
}

export interface PlanningFetchCommand extends PlanningFetchSettings {
  groupBy?: ResourceGroupKey;
  qualification?: number[];
  ou?: number[];
  hi?: number[];
  ouFilterType?: OUFilterType;
  sex?: string;
  term?: string;
  name?: string;
  start?: LocalDate;
  end?: LocalDate;
  first?: number;
  max?: number;
  closed?: Set<string>;
  resourceTypes?: ResourceType[];
  workType?: WorkType[];
  agency?: number[];
}

// NOTE: deprecated properties should finally be moved to the dialog state. We no longer want to keep them in the planning board store
export interface ImmutablePlanningState {
  maxRows: number;
  fetchCommand: PlanningFetchCommand;
  daysWithAppointments: Map<string, TooltipDTO[]>;
  planningRows: PlanningRow[];
  rowScrolling: Scrolling;
  /**
   * @deprecated
   */
  poolShifts: Map<number, PoolShift>;
  loading: boolean;
  /**
   * @deprecated
   */
  dialogLoading: boolean;
  /**
   * @deprecated
   */
  dialog: ImmutableDialogState;
  /**
   * @deprecated
   */
  edit: ImmutablePlanningEditState;
  appointment: ImmutableAppointmentState;
  error?: any;
}

export interface ImmutableDialogState {
  changeCommand: ChangeCommand;
  changeSelection: string[];
  currentTab: Tab;
  currentPage: number;
  formValid: boolean;
}

export interface CellSelection {
  day: LocalDate;
  row: PlanningRow;
  event: MouseEvent;
}

export interface ImmutablePlanningEditState {
  cellSelection: CellSelection[];
  affectedEntries: Change[];
  entrySelection: EntrySelection;
  cellOptionSelection: number[];
  entryTypeSelection: EntryType;
  availabilityForm: Partial<Shift>;
  bookingForm: Partial<StintValue>;
  cancelForm: Partial<CancelBookingCommand>;
}

export interface ImmutableAppointmentState {
  day: LocalDate;
  appointmentsOfDay: Appointment[];
  selectedAppointment: Appointment;  // entryType < entry.workType
  appointmentForm: AppointmentValue;
}

export enum OUFilterType {
  CAN_WORK_ON = 'CAN_WORK_ON', HAS_WORKED_ON = 'HAS_WORKED_ON', BOOKING_ON = 'BOOKING_ON',  // CAN_PLAN_ON, IS_BASE_ORGANISATION
}

