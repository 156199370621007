import {
  CachedCrudService,
  DomainDefinition,
  Entity,
  FilterType,
  PropertyDefinition,
} from '@ic/ng-crud-client';
import { AvailabilityType, TimeSpan } from '../../definitions';
import { Employee, RESOURCE_SERVICE } from '../employee/employee.definition';
import { InjectionToken } from '@angular/core';
import { LocalDateTime } from '@js-joda/core';


export interface Absence extends Entity, TimeSpan {

  availabilityType?: AvailabilityType;
  // todo: this value is never set and therefore null, not sure if this is rather a screen than an availability property
  //       as planners won't see resources they cannot plan at all. However a booking on another organisation unit
  //       should be disabled

  resource?: Partial<Employee>;
  start: LocalDateTime;
  end: LocalDateTime;
  comment: string;

}
export const ABSENCE_SERVICE = new InjectionToken<CachedCrudService<Absence>>('ABSENCE_SERVICE')
export const absenceDefinition: DomainDefinition<Absence> = {
  path: 'absence',
  service: ABSENCE_SERVICE,
  properties: new Map<keyof Absence, PropertyDefinition>([
    ['resource', {
      type: 'belongs-to',
      service: RESOURCE_SERVICE,
      filter: FilterType.IN,
      sortable: true,
      sortProperty: 'resourceName',
    }],
    ['start', {
      type: 'local-date-time',
      nullable: true,
      filter: FilterType.GREATER_OR_EQUALS,
      sortable: true,
    }],
    ['end', {
      type: 'local-date-time',
      nullable: true,
      filter: FilterType.BETWEEN,
      sortable: true,
    }],
    ['comment', {
      type: 'text',
      nullable: true,
      filter: FilterType.CONTAINS,
      sortable: false,
    }],
    ['availabilityType', {
      type: 'enum',
      filter: FilterType.IN,
      cls: AvailabilityType,
      prefix: 'AvailabilityType',
      sortable: true,
    }],
  ]),
  formDefinitions: {
    default: {
      fields: ['resource', 'start', 'end', 'comment','availabilityType'],
    },
  },
  tableDefinitions: {
    default: {
      columns: ['resource', 'start', 'end', 'comment', 'availabilityType'],
      inlineEditable: false,
      immutable: true,
      disableDetail: true,
      sortField: 'start',
      sortOrder: 1,
      filters: {
        start: {value: new Date().toISOString(), matchMode: 'gte'},
      },
      filterStorable: true,
    },
  },
};

