import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {ApplicationStatusService} from './application-status.service';
import {first} from 'rxjs/operators';
import {ServerStatus} from './definitions';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStatusResolver  {
  constructor(protected serverStatusService: ApplicationStatusService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ServerStatus> {
    return this.serverStatusService.status$.pipe(first());
  }
}
