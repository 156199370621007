import {Injectable, Injector} from '@angular/core';
import {CrudService} from '@ic/ng-crud-client';
import {HttpClient} from '@angular/common/http';
import {Specialty, specialtyDefinition} from './specialty.definition';
import {Observable} from 'rxjs/internal/Observable';


@Injectable({
  providedIn: 'root',
})
export class SpecialtyService extends CrudService<Specialty> {

  constructor(http: HttpClient, injector: Injector) {
    super(specialtyDefinition, http, injector)
  }
  delete(model: any): Observable<unknown> {
    return this.http.delete(this.apiBaseUrl + `specialty/delete/${model.id}`);
  }
}
