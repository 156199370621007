import {Injectable, OnDestroy} from '@angular/core';
import {LocalDate} from '@js-joda/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CellSelection, PlanningRow, PlanningRowType} from '../planning.definitions';
import {AuthService, SecurityContext} from '@ic/auth';
import {Role} from '../../role';

/**
 * This CellSelectionService is responsible for communicating the current cell selection between interested components.
 * It provides methods to select and deselect cells as well as an observable for listening to selection changes.
 *
 * Currently the class' primary use is to communicate the cell selection between cells and and the dialog.
 */
@Injectable()
export class CellSelectionService implements OnDestroy {

  constructor(private authService: AuthService) {
    this.securityContext = authService.securityContextSnapshot
  }

  private _cellSelection$: BehaviorSubject<CellSelection[]> = new BehaviorSubject([])
  private _hover: CellSelection;
  private securityContext: SecurityContext

  private static cellSelectionEqual(a: CellSelection, b: CellSelection): boolean {
    return a.row.id === b.row.id && a.row.type === b.row.type && a.day.equals(b.day)
  }

  ngOnDestroy(): void {
    // complete the observable in order to avoid resource leaks with subscribers, that did not unsubscribe.
    this._cellSelection$.complete();
  }

  /**
   * Get a list of currently selected cells
   */
  get cellSelectionSnapshot(): CellSelection[] {
    return this._cellSelection$.value;
  }

  /**
   * Replace the current selection with the given list.
   */
  setSelection(cells: CellSelection[]): void {
    const newSelection = cells.reduce((selectableCells: CellSelection[], cell: CellSelection) => {
      if (this.isCellSelectable(cell, selectableCells)) {
        selectableCells.push(cell);
      }
      return selectableCells;
    }, []);
    this._cellSelection$.next(newSelection);
  }

  /**
   * Add the given cells to the current selection
   */
  addSelection(cells: CellSelection[]): void {
    const newSelection = cells.reduce((selectableCells: CellSelection[], cell: CellSelection) => {
      if (this.isCellSelectable(cell, selectableCells)) {
        selectableCells.push(cell);
      }
      return selectableCells;
    }, this._cellSelection$.value);
    this._cellSelection$.next(newSelection);
  }

  /**
   * Each of the given cells array is selected if not previously selected and deselected otherwise.
   */
  toggleSelection(cells: CellSelection[]): void {
    const newSelection: CellSelection[] = this._cellSelection$.value.concat();
    for (const cell of cells) {
      const idx = newSelection.findIndex(c => CellSelectionService.cellSelectionEqual(c, cell))
      if (idx >= 0) {
        newSelection.splice(idx, 1);
      } else {
        newSelection.push(cell)
      }
    }
    this._cellSelection$.next(newSelection);
  }

  /**
   * Add a selection range to the current selection. Usually this is done by pressing the shift button while selecting cells.
   * Currently this method only selects elements in a single row. If start and end cell do not belong to the same row, then only cells in
   * the start row between start date and end date are selected.
   */
  addSelectionRange(start: CellSelection, end: CellSelection): void {
    // select all cells between previously selected cell and currently clicked cell
    // The current implementation only selects the cells in the previously selected row.
    // const start = currentSelection[currentSelection.length - 1];
    let startDate: LocalDate;
    let endDate: LocalDate;
    // find minimum date
    if (start.day < end.day) {
      startDate = start.day.plusDays(1);
      endDate = end.day;
    } else {
      startDate = end.day;
      endDate = start.day;
    }
    // fill days in between
    const cells = [];
    while (startDate <= endDate) {
      cells.push({
        event: end.event,
        day: startDate,
        row: start.row,
      });
      startDate = startDate.plusDays(1);
    }
    this.addSelection(cells);
  }

  /**
   * deselect all cells and start again with an empty selection
   */
  deselectCells() {
    this._cellSelection$.next([]);
  }

  /**
   * The returned Observable fires whenever the CellSelection changes
   */
  getCellsObservable(): Observable<CellSelection[]> {
    return this._cellSelection$;
  }

  /**
   *
   */
  isCellSelected(row: PlanningRow, date: LocalDate): boolean {
    return this._cellSelection$.value.some(cell => cell.row === row && cell.day.equals(date))
  }

  private isCellSelectable(cell: CellSelection, currentSelection: CellSelection[]): boolean {
    if (cell.row.type === PlanningRowType.GROUP) {
      console.warn('invalid cell selection: cells in group header rows can not be selected');
      return false
    }
    if (currentSelection.length > 0 && currentSelection[0].row.type !== cell.row.type) {
      console.warn('invalid cell selection: cell has a different row type');
      return false;
    }
    if (currentSelection.some(existingCell => CellSelectionService.cellSelectionEqual(cell, existingCell))) {
      console.warn('invalid cell selection: cell is already selected');
      return false;
    }
    if(cell.row.css.indexOf('disabled') > -1) {
      return false;
    }
    // TODO: check permissions for this cell. Can the user do any action on this cell?
    // Note currently we can not check the roles in a detailed way because the entries are not available, when selecting a cell, e.g.
    // if a user only has DELETE permissions, he should not be allowed to open the dialog on an empty cell, however because entries of a
    // cell are unknown, this can not be determined.
    switch (cell.row.type) {
      case PlanningRowType.RESOURCE:
        return this.securityContext.hasAnyRole([
          Role.ROLE_CREATE_AVAILABILITY_OU,
          Role.ROLE_CREATE_AVAILABILITY_POOL,
          Role.ROLE_DELETE_AVAILABILITY_OU,
          Role.ROLE_DELETE_AVAILABILITY_POOL,
          Role.ROLE_CREATE_UNCONFIRMEDBOOKING_OU,
          Role.ROLE_CREATE_LOWBOOKING_OU,
          Role.ROLE_CREATE_NIGHTBOOKING_OU,
          Role.ROLE_CREATE_NIGHTLOWBOOKING_OU,
          Role.ROLE_CREATE_UNCONFIRMEDBOOKING_POOL,
          Role.ROLE_DELETE_BOOKING_OU,
          Role.ROLE_DELETE_BOOKING_POOL,

        ]);
      case PlanningRowType.ORGANISATION_UNIT:
        return this.securityContext.hasAnyRole([

          Role.ROLE_CREATE_STINT_OU,
          Role.ROLE_CREATE_STINT_OPEN_POOL,
          Role.ROLE_DELETE_STINT_OU,
          Role.ROLE_DELETE_STINT_POOL,
        ]);
    }
    return false;
  }

  // ------------------------------------------------------------------------------------------------------------------------
  // hover highlighting
  // ------------------------------------------------------------------------------------------------------------------------
  /**
   * Set the cell over which the mouse is currently hovering
   */
  setHover(cell: CellSelection): void {
    this._hover = cell;
  }

  /**
   * Is the mouse hovering over a column with the given date?
   */
  isHoverDate(date: LocalDate): boolean {
    return this._hover && date.isEqual(this._hover.day);
  }

  /**
   * Is the mouse hovering over a cell of the given row?
   */
  isHoverRow(row: PlanningRow): boolean {
    return this._hover && row.id === this._hover.row.id && row.type === this._hover.row.type;
  }
}
