import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {PoolConfigurationService} from './pool-configuration.service';
import {PoolConfiguration} from './pool-configuration.definition';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class PoolConfigurationResolverService  {
  constructor(private poolConfigurationService: PoolConfigurationService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PoolConfiguration> {
    return this.poolConfigurationService.get();
  }
}
