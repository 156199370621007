import {InjectionToken, Injector} from '@angular/core';
import {CrudService, DomainDefinition, Entity, FilterType, PropertyDefinition} from '@ic/ng-crud-client';
import {PoolShift} from '../pool-shift/pool-shift.definition';
import {WorkType} from '../booking/work-type.definition';

export interface Qualification extends Entity {
  sortIndex: number;
  pool: Entity;
  qualification: string;
  bookable: boolean;
  deleted: boolean;
  isLow: boolean;
  defaultWorkType: WorkType;
}

export const QUALIFICATION_SERVICE = new InjectionToken<CrudService<PoolShift>>('QUALIFICATION_SERVICE');

export const qualificationDefinition: DomainDefinition<Qualification> = {
  path: 'qualification',
  service: QUALIFICATION_SERVICE,
  properties: new Map<keyof Qualification, PropertyDefinition>([
    ['qualification', {
      type: 'string',
      filter: FilterType.CONTAINS,
      sortable: false,
    }],
    ['defaultWorkType', {
      type: 'enum',
      filter: FilterType.IN,
      sortable: false,
      prefix: 'WorkType',
      cls: WorkType,
    }],
    ['isLow', {
      type: 'boolean',
      filter: FilterType.IN,
    }],
    ['bookable', {
      type: 'boolean',
      filter: FilterType.IN,
    }],
  ]),
  formDefinitions: {
    qualification: {
      fields: ['qualification', 'isLow'],
    },
  },
  tableDefinitions: {
    default: {
      columns: ['qualification', 'isLow', 'defaultWorkType',],
      inlineEditable: true,
      immutable: false,
      disableDetail: true,
      sortField: 'sortIndex',
      sortOrder: 1,
      filterStorable: true,
      filters: {},
    },
  },
};


