import {CrudService, ListPage} from '@ic/ng-crud-client';
import {StintOffer, stintOfferDefinition} from './stint-offer.definition';
import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {Sex} from '../../definitions';
import {StintService} from '../stint/stint.service';
import {Stint} from '../stint/stint.definition';

@Injectable({
  providedIn: 'root',
})
export class StintOfferService extends CrudService<StintOffer> {

  constructor(http: HttpClient, injector: Injector, private stintService: StintService) {
    super(stintOfferDefinition, http, injector);
  }

  list(body?: object, params?: any): Observable<ListPage<StintOffer> | StintOffer[]> {
    return super.list(body, params).pipe(
      map((it) => {
        const listPage = it as ListPage<StintOffer>;
        if (listPage) {
          listPage.records = this.mapOffers(listPage.records);
          return listPage;
        } else {
          const arr = it as StintOffer[];
          return this.mapOffers(arr);
        }
      }),
    );
  }

  mapOffers(offers: StintOffer[]): StintOffer[] {
    return offers.map(offer => {
      const stint = offer.stint as Stint;
      this.stintService.mapDeepValues([stint])
      this.stintService.convertStringsToDate([stint]);
      const sex = stint.sex || Sex.NONE;
      return {
        ...offer,
        sex,
        stint: {
          ...offer.stint,
          sex,
        },
      }
    });
  }
}
