import {DateTimeFormatter, DayOfWeek, LocalDate, LocalDateTime, LocalTime} from '@js-joda/core';
import {OperatorFunction} from 'rxjs';
import {map} from 'rxjs/operators';
import {Entity, MinimalList} from '@ic/ng-crud-client';
import {SelectItem} from 'primeng/api';
import {LOCAL_DATE_FORMATTER} from '@ic/ng-crud-client';
import {MultiSelect} from 'primeng/multiselect';
import {QueryList} from '@angular/core';

export const DATE_DE = DateTimeFormatter.ofPattern('dd.MM.yyyy');
export const DATE_TIME_DE = DateTimeFormatter.ofPattern('dd.MM.yyyy HH:mm');
export const DATE_ISO = DateTimeFormatter.ofPattern('yyyy-MM-dd');
export const TIME_DE = DateTimeFormatter.ofPattern('HH.mm');
export const TIME_ISO = DateTimeFormatter.ofPattern('HH:mm');

export function formatTime(time: LocalTime) {
  return `${String(time.hour()).padStart(2, '0')}:${String(time.minute()).padStart(2, '0')}`;
}

export function formatDateTime(dateTime: LocalDateTime) {
  return `${String(dateTime.toLocalDate().format(LOCAL_DATE_FORMATTER) + ' ' + dateTime.hour()).padStart(2, '0')}:${String(dateTime.minute()).padStart(2, '0')}`;
}

export function isWeekend(date: LocalDate) {
  return [DayOfWeek.SATURDAY, DayOfWeek.SUNDAY].indexOf(date.dayOfWeek()) > -1;
}


export function toSelectItems<R extends Entity>(): OperatorFunction<MinimalList<R>, SelectItem[]> {
  return input$ => input$.pipe(
    map((list) => list.map((item) => ({value: item, label: item.label}))),
  );
}

export function shiftTimesToDateTime(start: LocalTime, end: LocalTime, startBreak: LocalTime, endBreak: LocalTime) {
  const startDateTime = LocalDateTime.of(LocalDate.of(1970, 1, 1), start)
  let endDateTime;
  let startBreakDateTime;
  let endBreakDateTime;
  if (end.isBefore(start)) {
    endDateTime = LocalDateTime.of(LocalDate.of(1970, 1, 2), end)
  } else {
    endDateTime = LocalDateTime.of(LocalDate.of(1970, 1, 1), end)
  }
  if (startBreak) {
    if (startBreak.isBefore(start)) {
      startBreakDateTime = LocalDateTime.of(LocalDate.of(1970, 1, 2), startBreak)
    } else {
      startBreakDateTime = LocalDateTime.of(LocalDate.of(1970, 1, 1), startBreak)
    }
  }
  if (endBreak) {
    if (endBreak.isBefore(start)) {
      endBreakDateTime = LocalDateTime.of(LocalDate.of(1970, 1, 2), endBreak)
    } else {
      endBreakDateTime = LocalDateTime.of(LocalDate.of(1970, 1, 1), endBreak)
    }
  }
  const dateTimeMap = new Map();
  dateTimeMap.set('start', startDateTime);
  dateTimeMap.set('end', endDateTime);
  dateTimeMap.set('startBreak', startBreakDateTime);
  dateTimeMap.set('endBreak', endBreakDateTime);
  return dateTimeMap;
}


export function toHttpParams(obj: object): { [p: string]: string | string[] } {
  const params = {}
  Object.keys(obj)
    .filter((key) => obj[key])
    .forEach((key) => params[key] = obj[key].toString());
  return params;
}

export function closeAllMultiSelects(multiSelects: QueryList<MultiSelect>): void {
  multiSelects.forEach((multiSelect) => {
    if (multiSelect.overlayVisible) {
      multiSelect.hide();
      multiSelect.cd.detectChanges();
    }
  });
}
