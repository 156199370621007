import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {fromEvent, Observable} from 'rxjs';
import {finalize, map, take} from 'rxjs/operators';
import {ImagePropertyDefinition} from '../definitions';
import {TranslateService} from '@ngx-translate/core';

export function ImageSizeValidator(propertyDef: ImagePropertyDefinition, translate: TranslateService): AsyncValidatorFn {
    return (ctrl: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        const img = new Image();
        const load$ = fromEvent(img, 'load').pipe(
            take(1),
            map(event => {
                let errors = null;
                if (propertyDef.hasOwnProperty('height') && propertyDef.height !== img.height) {
                    const message = translate.instant('error.validation.image.exactHeight',
                        { exact: propertyDef.height, current: img.height });
                    errors = Object.assign(errors || {}, {exactHeight: message});
                }
                if (propertyDef.hasOwnProperty('width') && propertyDef.width !== img.width) {
                    const message = translate.instant('error.validation.image.exactWidth',
                        { exact: propertyDef.width, current: img.width });
                    errors = Object.assign(errors || {}, {exactWidth: message});
                }
                const fileSize = ctrl.value.substring(22).length * 3 / 4 - 1;
                // https://softwareengineering.stackexchange.com/questions/288670/know-file-size-with-a-base64-string#answer-368406
                if (propertyDef.hasOwnProperty('fileSize') && propertyDef.fileSize < fileSize) {
                  const message = translate.instant('error.validation.image.maxFileSize', {max: propertyDef.fileSize, current: fileSize});
                  errors = Object.assign(errors || {}, {maxFileSize: message});
                }
                return errors;
            }),
        );
        img.src = ctrl.value;
        return load$;
    };
}
