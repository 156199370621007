import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {ListboxModule} from 'primeng/listbox';
import {ButtonModule} from 'primeng/button';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ListDialogFrameComponent} from './list-dialog-frame/list-dialog-frame.component';
import {ListDialogContentComponent} from './list-dialog-content/list-dialog-content.component';
import {SharedModule} from '../../shared/shared.module';
import {StintAgencyAssignmentComponent} from './pages/stint-agency-assignment/stint-agency-assignment.component';
import {BookingFormModule} from '../../planning/dialog/pages/booking-form/booking-form.module';
import {MessageFormModule} from '../../planning/dialog/pages/message-form/message-form.module';
import {PlanningDialogStateService} from '../../planning/dialog/services/planning-dialog-state.service';
import {ListDialogStateService} from './services/list-dialog-state.service';
import {DialogSummaryModule} from '../../planning/dialog/pages/dialog-summary/dialog-summary.module';
import {BookingCancelFormModule} from '../../planning/dialog/pages/booking-cancel-form/booking-cancel-form.module';
import {DialogStateService} from '../../planning/dialog/services/dialog-state.service';
import {StintDeleteFormModule} from '../../planning/dialog/pages/stint-delete-form/stint-delete-form.module';
import {StintBroadcastFormModule} from '../../planning/dialog/pages/stint-broadcast-form/stint-broadcast-form.module';
import {ReassignBookingFormModule} from '../../planning/dialog/pages/reassign-booking/reassign-booking-form.module';
import {AcceptStintFormModule} from '../../planning/dialog/pages/accept-stint/accept-stint-form.module';
import {StintFormModule} from '../../planning/dialog/pages/stint-form/stint-form.module';
import {AppointmentDialogStateService} from '../../planning/dialog/services/appointment-dialog-state.service';
import {ListAppointmentDialogStateService} from './services/list-appointment-dialog-state.service';
import {CheckboxModule} from 'primeng/checkbox';
import {TooltipModule} from 'primeng/tooltip';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

@NgModule({
  declarations: [
    ListDialogFrameComponent,
    ListDialogContentComponent,
    StintAgencyAssignmentComponent,
  ],
  providers: [
    // TODO: check this
    {provide: PlanningDialogStateService, useExisting: ListDialogStateService},
    {provide: DialogStateService, useExisting: ListDialogStateService},
    {provide: AppointmentDialogStateService, useExisting: ListAppointmentDialogStateService},
  ],
  exports: [
    ListDialogFrameComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    DropdownModule,
    ListboxModule,
    ButtonModule,
    ScrollPanelModule,
    SharedModule,
    BookingCancelFormModule,
    BookingFormModule,
    MessageFormModule,
    DialogSummaryModule,
    StintFormModule,
    StintDeleteFormModule,
    StintBroadcastFormModule,
    ReassignBookingFormModule,
    AcceptStintFormModule,
    CheckboxModule,
    TooltipModule,
    ProgressSpinnerModule,
  ],
})
export class ListDialogModule {
}
