import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AcceptStintComponent} from './accept-stint.component';
import {SharedModule} from '../../../../shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {CheckboxModule} from 'primeng/checkbox';
import {AuthCoreModule} from '@ic/auth';


@NgModule({
  declarations: [AcceptStintComponent],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    CheckboxModule,
    AuthCoreModule,
  ],
})
export class AcceptStintFormModule {
}
