import {Injectable, Injector} from '@angular/core';
import {CrudService} from '@ic/ng-crud-client';
import {HttpClient} from '@angular/common/http';
import {Function, functionDefinition} from './function.definition';
import {Observable} from 'rxjs/internal/Observable';


@Injectable({
  providedIn: 'root',
})
export class FunctionService extends CrudService<Function> {

  constructor(http: HttpClient, injector: Injector) {
    super(functionDefinition, http, injector)
  }
  delete(model: any): Observable<unknown> {
    return this.http.delete(this.apiBaseUrl + `function/delete/${model.id}`);
  }
}
