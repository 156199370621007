import {Injectable} from '@angular/core';
import {Change, ChangeCommand, EntryType} from '../../planning.definitions';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {delay, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {Sex} from '../../../definitions';
import {AppointmentService} from '../../../pool-crud/appointment/appointment.service';
import {LocalDate, LocalDateTime} from '@js-joda/core';
import {Appointment} from '../../../pool-crud/appointment/appointment.definition';
import {AppointmentDialogContext} from '../../dialog/definitions';
import {DialogDataService} from './dialog-data.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentDialogDataService extends DialogDataService {
  private _request$ = new Subject<LocalDate>();

  constructor(http: HttpClient, private appointmentService: AppointmentService) {
    super(http)
  }

  private _appointmentDialogContext$: Observable<AppointmentDialogContext>;

  get appointmentDialogContext$(): Observable<AppointmentDialogContext> {
    if (!this._appointmentDialogContext$) {
      this._appointmentDialogContext$ = this._request$.pipe(
        tap(() => this.loadingSubject.next(true)),
        switchMap((date: LocalDate) => date != null ? this._loadAppointmentsOfDay(date) : of(null)),
        delay(this.delayTime),
        tap(() => {
          this.loadingSubject.next(false);
        }),
      );
    }
    return this._appointmentDialogContext$;
  }


  loadAppointments(date: LocalDate): void {
    this._request$.next(date);
  }


  /**
   * Loads the entries affected by the provided date
   */
  private _loadAppointmentsOfDay(day: LocalDate): Observable<AppointmentDialogContext> {
    const params = {day: day.toString()};
    return this.http.get<any[]>(environment.serverURL + 'planning/appointmentsOfDay', {params}).pipe(
      map((data: any[]) => {
        const appointments: Appointment[] = data.map((obj) => ({
          ...obj,
          start: LocalDateTime.parse(obj.start),
          end: LocalDateTime.parse(obj.end),
        }));
        return {day: day, affectedEntries: appointments.map(
          (appointment) => ({ entryType: appointment.workType, entry: appointment }))}; // tood: fix entryType
      }),
      shareReplay(1),
    );
  }


  protected changeFromJson(change: Change) {
    return {...change, entry: this.appointmentService.convertStringsToDate([change.entry])[0]};
  }
}

