import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StintFormComponent} from './stint-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {NgCrudClientModule} from '@ic/ng-crud-client';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {RadioButtonModule} from 'primeng/radiobutton';
import {CheckboxModule} from 'primeng/checkbox';
import {ButtonModule} from 'primeng/button';
import {MessageModule} from 'primeng/message';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {TooltipModule} from 'primeng/tooltip';
import {SelectButtonModule} from 'primeng/selectbutton';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MultiSelectModule} from 'primeng/multiselect';
import {ToolbarModule} from 'primeng/toolbar';
import {MenuModule} from 'primeng/menu';
import {PatientModule} from '../../patient/patient.module';
import {AuthCoreModule} from '@ic/auth';
import {TriStateCheckboxModule} from 'primeng/tristatecheckbox';
import {InputNumberModule} from 'primeng/inputnumber';


@NgModule({
  declarations: [
    StintFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    FormsModule,
    InputTextModule,
    InputTextareaModule,
    NgCrudClientModule,
    AutoCompleteModule,
    RadioButtonModule,
    CheckboxModule,
    ButtonModule,
    MessageModule,
    ScrollPanelModule,
    TooltipModule,
    SelectButtonModule,
    ScrollingModule,
    MultiSelectModule,
    ToolbarModule,
    MenuModule,
    TriStateCheckboxModule,
    PatientModule,
    AuthCoreModule,
    InputNumberModule,
  ],
  providers: [
  ],
  exports: [
    StintFormComponent,
  ],
})
export class StintFormModule { }
