import {InjectionToken} from '@angular/core';

export {AuthService} from './auth.service';
export {AuthGuard} from './auth.guard';
export {IsLoggedInDirective} from './is-logged-in/is-logged-in.directive';
export {IfHasAnyRoleDirective} from './if-has-any-role/if-has-any-role.directive';
export let AUTH_CONFIG = new InjectionToken<AuthConfig>('auth.config');
export class AuthConfig {
  passwordRetrieval: boolean;
  labelUsername?: string;
  labelPassword?: string;
}
