import {Injectable} from '@angular/core';
import {from, NEVER, Observable} from 'rxjs';
import {first, map, mergeMap, shareReplay, tap} from 'rxjs/operators';
import * as angular from 'angular';
import {environment} from '../../environments/environment';
import {ErrorHandlerService} from '../error-handling/error-handler.service';
import {MessageService} from 'primeng/api';
import {ApplicationStatusService} from '../application-status/application-status.service';


declare global {
  interface Window {
    contextPath: string;
    language: string;
  }
}

interface AngularJSModule {
  bootstrap(element: HTMLElement): angular.auto.IInjectorService;
}

/**
 * AngularJsAppHelperService helps in bootstrapping an angularJS application on a given HTML element.
 * It ensures the required scripts are loaded. If they are not yet present it loads them lazily.
 */
@Injectable({
  providedIn: 'root',
})
export class AngularJsAppHelperService {

  private _isNGJSRunning = false;
  private _isFirstLoginRequest = false;

  constructor(private errorHandler: ErrorHandlerService, private messageService: MessageService,
              protected statusService: ApplicationStatusService) {
  }

  private angularJS$: Observable<AngularJSModule> = from(import('../../legacy/manifest'))
    .pipe(
      mergeMap((app: AngularJSModule) => new Observable<AngularJSModule>((subscriber) => {
        // I couldn't find another way to make it work with an import statement which also works for IE.,
        // Load angularJS code coming from grails plugins and rendered by the grails asset pipeline.
        const script = document.createElement('script');
        script.src = environment.LEGACY_contextPath + '/assets/manifest.js?v=' + environment.COMMIT_HASH;
        script.addEventListener('load', () => {
          subscriber.next(app);
          subscriber.complete();
        });
        document.head.appendChild(script);
      })),
      first(),
      shareReplay(1),
    );

  /**
   * Bootstrap an angularJS application within the given element.
   * Don't forget to call shutdown with the return value of this method.
   *
   * @param element root element where the angularJS application should be bootstrapped
   * @return The returned Observable resolves to an angularJS injector with which angularJS services can be accessed.
   */
  bootstrap(element: HTMLElement): Observable<angular.auto.IInjectorService> {
    if (this._isNGJSRunning) {
      throw Error('There is currently an other angularJS application running but there should be maximally be one. ' +
        'Dont forget to call shutdown() when an angularJS application is no longer needed.');
    }
    this._isNGJSRunning = true;

    // define global variables which previously have been set using a script block in the index.gsp
    window.contextPath = environment.LEGACY_contextPath;
    window.language = environment.LEGACY_language;

    return this.angularJS$.pipe(
      map((angularJSModule) => angularJSModule.bootstrap(element)),
      tap((injector) => {
        const $rootScope = injector.get('$rootScope');
        $rootScope.$on('authenticationError', (errorEvent, rejection) => {
          this.requestLogin();
        });
        $rootScope.$on('readonlyError', (errorEvent, rejection) => {
          this.statusService.setReadOnly(true);
        })
        $rootScope.$on('exception', (errorEvent, error) => {
          // Error handling of "errorOnUnhandledRejections" by a file upload should not appear!
          // original: this.errorHandler.handleError(error);
        });
      }),
    );
  }

  /**
   * Destroy an angularJS application created with bootstrap(element).
   * @param app the application to shutdown.
   */
  shutdown(app: angular.auto.IInjectorService): void {
    app.get('$rootScope').$destroy();
    this._isNGJSRunning = false;
  }

  /**
   * redirect user to the login form
   * @deprecated once the application is fully built with angular, a login dialog would be more helpful.
   *             This method should then use the auth service provided by the auth plugin.
   */
  private requestLogin() {
    if (this._isFirstLoginRequest) {
      return NEVER;
    }
    this._isFirstLoginRequest = true;
    this.messageService.add({
      severity: 'error',
      summary: 'Authentifizierungsproblem',
      detail: 'Sie wurden auf Grund von Inaktivität ausgeloggt und werden nun zum Loginformular umgeleitet.',
      life: 5000,
    });
    setTimeout(() => {
      window.location.href = window.contextPath + '/';
    }, 5000);
    return NEVER;
  }
}
