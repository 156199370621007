import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {UserNotificationService} from '../user-notification.service';
import {ListPage, Notification} from '../definitions';
import {LazyLoadEvent} from 'primeng/api';
import {debounceTime, finalize, map} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {Table} from 'primeng/table';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Calendar} from 'primeng/calendar';

@Component({
  selector: 'un-user-notification-list',
  templateUrl: './user-notification-list.component.html',
  styleUrls: ['./user-notification-list.component.scss'],
})
export class UserNotificationListComponent implements OnInit {
  notifications: Notification[];
  totalResults: Number;
  loading = false;
  filterForm: UntypedFormGroup

  @ViewChild('table', {static: true})
  table: Table;
  @ViewChild('datePicker', {static: true})
  datePicker: Calendar;

  constructor(private userNotificationService: UserNotificationService,
              private cd: ChangeDetectorRef,
              private route: ActivatedRoute,
              private router: Router,
              fb: UntypedFormBuilder) {
    if (!route.snapshot.queryParamMap.has('sentDateTime')) {
      const twoMonthsAgo = new Date();
      twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
      this.router.navigate([], {queryParams: {sentDateTime: twoMonthsAgo.toISOString()}, replaceUrl: true});
    }
    this.filterForm = fb.group({
      sentDateTime: [],
      subject: [''],
      sender: [''],
      cc: [''],
      to: [''],
      successfulDelivery: [null],
    });
  }

  ngOnInit() {
    this.filterForm.valueChanges.pipe(
      debounceTime(500),
      map(formValue => { // filter all form fields which contain non empty values
        const filtersWithValue = {};
        for (const key of Object.keys(formValue)) {
          const value = formValue[key];
          if (value !== undefined && value !== null && value !== '') {
            if (Array.isArray(value) && value.length > 0 && value[0] instanceof Date) {
              filtersWithValue[key] = [value[0].toISOString()];
              if (value.length === 2 && value[1] instanceof Date) {
                filtersWithValue[key].push(value[1].toISOString());
              }
            } else {
              filtersWithValue[key] = value;
            }
          }
        }
        return filtersWithValue;
      }),
    ).subscribe((filter) => {
      // adjust url in browser menu
      this.router.navigate([], {queryParams: filter, replaceUrl: true});
    });

    this.route.queryParamMap.subscribe((params) => {
      this.table.filters = {}
      const formValues = {
        sentDateTime: null,
        subject: '',
        sender: '',
        cc: '',
        to: '',
        successfulDelivery: null,
      };
      for (const key of params.keys) {
        const value = params.get(key);
        if (key === 'sentDateTime') {
          const dates = params.getAll(key).map(it => it ? new Date(it) : null);
          if (dates.length === 2) { // set end date to end of day
            dates[1].setHours(23, 59, 59);
          }
          this.table.filters[key] = {matchMode: 'contains', value: dates}
          formValues[key] = dates;
        } else {
          this.table.filters[key] = {matchMode: 'contains', value: value}
          formValues[key] = value;
        }
      }
      this.table._filter();
      this.filterForm.setValue(formValues);
    });
  }

  wasNotificationSuccessful(notification: Notification) {
    return notification.to.every(recipient => recipient.status === 'DELIVERED') &&
      notification.cc.every(recipient => recipient.status === 'DELIVERED');
  }

  loadData(event: LazyLoadEvent) {
    setTimeout(() => this.loading = true) // setTimeout required to avoid value changed after it was checked exception

    this.userNotificationService.list(event)
      .pipe(finalize(() => {
        this.loading = false;
      }))
      .subscribe(
        (listPage: ListPage) => {
          this.totalResults = listPage.total;
          this.notifications = listPage.notifications;
        },
      );
  }
}
