<p-table [value]="notifications"
         [paginator]="true"
         [rows]="10"
         [pageLinks]="5"
         [rowsPerPageOptions]="[10,20,50,100]"
         paginatorDropdownAppendTo="body"
         [lazy]="true"
         [totalRecords]="totalResults"
         [loading]="loading"
         [responsive]="true"
         [autoLayout]="true"
         filterDelay="500"
         (onLazyLoad)="loadData($event)"
         #table
>


  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="sentDateTimeUTC">Sendedatum <p-sortIcon [field]="'sentDateTimeUTC'"></p-sortIcon></th>
      <th pSortableColumn="subject">Betreff <p-sortIcon [field]="'subject'"></p-sortIcon></th>
      <th pSortableColumn="originator">Sender <p-sortIcon [field]="'originator'"></p-sortIcon></th>
      <th>Empfänger (To)</th>
      <th>CC</th>
      <th>Zustellung</th>
    </tr>
    <tr [formGroup]="filterForm">
      <th>
        <p-calendar
          selectionMode="range"
          appendTo="body"
          [readonlyInput]="true"
          formControlName="sentDateTime"
          [hideOnDateTimeSelect]="true"
        ></p-calendar>
      </th>
      <th>
        <input pInputText formControlName="subject">
      </th>
      <th>
        <input pInputText formControlName="sender">
      </th>
      <th>
        <input pInputText formControlName="to">
      </th>
      <th>
        <input pInputText formControlName="cc">
      </th>
      <th>
        <p-dropdown
          [options]="[{label: 'erfolgreich', value: true}, {label: 'fehlerhaft', value: false}]"
          placeholder="Zustellung"
          showClear="true"
          formControlName="successfulDelivery"
        ></p-dropdown>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-notification>
    <tr>
      <td><a [routerLink]="['../show', notification.id]">{{notification.sentDateTime | date:"dd.MM.yyyy HH:mm" }}</a></td>
      <td>{{notification.subject}}</td>
      <td>{{notification.sender}}</td>
      <td>
        <span *ngFor="let recipient of notification.to; let last=last">
          {{ notification.to[0].address }}
          <i class="pi"
             [ngClass]="{'pi-times': recipient.status === 'DELIVERY_FAILED', 'pi-check': recipient.status === 'DELIVERED', 'pi-filter': recipient.status === 'DROPPED', 'pi-refresh': recipient.status === 'DELAYED'}"
             [pTooltip]="recipient.status | deliveryStateTranslation"></i>
          {{ !last ? ', ' : ''}}
        </span>
      </td>
      <td>
        <ul>
          <li *ngFor="let recipient of notification.cc">
            {{recipient.address}}
            <i class="pi"
               [ngClass]="{'pi-times': recipient.status === 'DELIVERY_FAILED', 'pi-check': recipient.status === 'DELIVERED', 'pi-filter': recipient.status === 'DROPPED', 'pi-refresh': recipient.status === 'DELAYED'}"
               [pTooltip]="recipient.status | deliveryStateTranslation"></i>
          </li>
        </ul>
      </td>
      <td>
        <i *ngIf="wasNotificationSuccessful(notification)" class="pi pi-check"></i>
        <i *ngIf="!wasNotificationSuccessful(notification)" class="pi pi-times"></i>
      </td>
    </tr>
  </ng-template>
</p-table>
<div class="leer"></div>
