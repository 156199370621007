import {Injectable, Injector} from '@angular/core';
import {CrudService} from '@ic/ng-crud-client';
import {HttpClient} from '@angular/common/http';
import {UserFeedbackPriority, userFeedbackPriorityDefinition} from './user-feedback-priority.definition';
import {Observable} from 'rxjs/internal/Observable';


@Injectable({
  providedIn: 'root',
})
export class UserFeedbackPriorityService extends CrudService<UserFeedbackPriority> {

  constructor(http: HttpClient, injector: Injector) {
    super(userFeedbackPriorityDefinition, http, injector)
  }
  delete(model: any): Observable<unknown> {
     return this.http.delete(this.apiBaseUrl + `userFeedbackPriority/delete/${model.id}`);
  }
}
