import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {Entity} from '../../definitions';


@Component({
  selector: 'ic-table-settings',
  templateUrl: './table-settings.component.html',
  styleUrls: ['./table-settings.component.scss'],
})
export class TableSettingsComponent<E extends Entity> {
  @Input() path: string;
  @Input() options: (keyof E)[];
  @Input() selected: (keyof E)[];

  @Output() configChange = new EventEmitter<(keyof E)[]>();
  display = false;
  Object = Object;

  showDialog() {
    this.display = true;
  }

  onChange(event) {
    this.configChange.emit(this.options.filter((item) => event.checked.indexOf(item) > -1)); // keeps original order
  }

}
