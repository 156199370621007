import {InjectionToken} from '@angular/core';
import {CachedCrudService, DomainDefinition, Entity, FilterType, PropertyDefinition} from '@ic/ng-crud-client';

export interface Education extends Entity {
  education: string;
  poolId: number;
}

export const EDUCATION_SERVICE = new InjectionToken<CachedCrudService<Education>>('EDUCATION_SERVICE');

export const educationDefinition: DomainDefinition<Education> = {
  path: 'education',
  service: EDUCATION_SERVICE,
  properties: new Map<keyof Education, PropertyDefinition>([
    ['education', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
  ]),
  formDefinitions: {
    default: {
      fields: ['education'],
    },
  },
  tableDefinitions: {
    default: {
      columns: ['education'],
      inlineEditable: false,
      immutable: false,
      disableDetail: true,
      sortField: 'education',
      sortOrder: 1,
      filters: {},
      filterStorable: true,
    },
  },
};


