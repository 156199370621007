import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {first, map} from 'rxjs/operators';
import {ProtectedRouteData, SecurityContext} from '../definitions';
import {AuthService} from './auth.service';

/**
 * Guard for protecting routes.
 * A configuration example can be found in the README.md
 *
 * @author Daniel Ritter
 * @author Tim Aebi
 * @since 0.1
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {

  /**
   * Auth guard constructor.
   */
  constructor(private router: Router, private authService: AuthService) {
  }

  /**
   * Method which returns a boolean and checks if a user is logged in and has the required permissions.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const routeData: ProtectedRouteData = <ProtectedRouteData>route.data;

    if (!routeData.hasOwnProperty('permission')
      || (Array.isArray(routeData.permission.any) && Array.isArray(routeData.permission.all))
      || (!Array.isArray(routeData.permission.any) && !Array.isArray(routeData.permission.all))) {

      throw new Error('configure required permissions either with permission.any, permission.all');
    }

    return this.authService.securityContext$.pipe(
      first(),
      map((securityContext: SecurityContext) => {
        // Check if user has all required roles
        let accessDecision;
        if (Array.isArray(routeData.permission.any)) {
          accessDecision = securityContext.hasAnyRole(routeData.permission.any);
        } else {
          accessDecision = securityContext.hasAllRoles(routeData.permission.all);
        }

        if (accessDecision) {
          return true;
        }

        // If user is not logged in, redirect to login page with the return url and return false.
        // If user is already logged in but does not have the required role, redirect to the access denied page
        if (securityContext.isLoggedIn()) {
          this.router.navigate(['/403-error'])
        } else {
          this.authService.requestLogin();
        }
        return false;
      }),
    );
  }
}
