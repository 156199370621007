import {Injectable} from '@angular/core';
import {PlanningFetchCommand} from '../../planning.definitions';
import {LocalDate} from '@js-joda/core';
import {DATE_ISO} from '../../../util';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {INITIAL_FETCH_COMMAND} from '../../planning.constants';


@Injectable()
export class PlanningFetchService {
  constructor(private http: HttpClient) {
  }

  get(): Observable<PlanningFetchCommand> {
    const params = {
      key: 'planning.fetch.0',
    };
    return this.http.get<PlanningFetchCommand>(environment.serverURL + 'clientStore/get', {params}).pipe(
      map((cmd) => cmd ? this.fromJson(cmd) : INITIAL_FETCH_COMMAND),
      catchError(() => {
        return of(INITIAL_FETCH_COMMAND);
      }),
    );
  }

  store(cmd: PlanningFetchCommand): Observable<void> {
    const params = {
      key: 'planning.fetch.0',
    };
    const body = {
      ...cmd,
      closed: Array.from(cmd.closed ? cmd.closed : []),
    };
    return this.http.post<void>(environment.serverURL + 'clientStore/store', body, {params});
  }

  fromJson(data: any): PlanningFetchCommand {
    return {
      ...data,
      start: LocalDate.parse(data.start),
      end: LocalDate.parse(data.end),
      closed: new Set(data.closed),
    };
  }

  toParams(cmd: PlanningFetchCommand): any {
    const params = {
      ...cmd,
      start: cmd.start.format(DATE_ISO),
      end: cmd.end.format(DATE_ISO),
      closed: Array.from(cmd.closed ? cmd.closed : []),
    };
    Object.keys(params)
      .filter((key) => !params[key])
      .forEach((key) => delete params[key]);
    return params;
  }

}
