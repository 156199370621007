<div *ngIf="!isBusy &&(status.environment !== 'PRODUCTION' || status.readOnly || status.bannerMessage)" class="status-banner">
  <div *ngIf="status.environment !== 'PRODUCTION'">
    {{status.app.name}}&#64;{{status.app.version}} ({{status.environmentName | uppercase}})
  </div>
  <div *ngIf="status.readOnly">
    KEINE SCHREIBBERECHTIGUNG – Aufgrund technischer Probleme kann im Moment nur lesend auf hospital-pool zugegriffen werden.
  </div>
  <div *ngIf="status.bannerMessage">
    <span class="pi" [ngClass]="status.bannerMessage.type"></span> {{status.bannerMessage.text}}
  </div>
</div>

<div class="status-banner" *ngIf="errorMessage">{{errorMessage}}</div>
