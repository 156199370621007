import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {PoolConfiguration} from './pool-configuration.definition';
import {environment} from '../../../environments/environment';
import {first, mergeMap, shareReplay, tap} from 'rxjs/operators';
import {AuthService, SecurityContext} from '@ic/auth';
import {Role} from '../../role';

@Injectable({
  providedIn: 'root',
})
export class PoolConfigurationService {
  protected poolConfiguration$: Observable<PoolConfiguration>;
  private _snapshot: PoolConfiguration | null = null;

  get snapshot(): PoolConfiguration | null {
    return this._snapshot;
  }

  constructor(private http: HttpClient, private authService: AuthService) {
    this.poolConfiguration$ = authService.securityContext$.pipe(
      first(),
      mergeMap((securityContext: SecurityContext) => {
        if (securityContext.hasAnyRole([Role.ROLE_READ_POOLCONFIGURATION_POOL])) {
          return http.get<PoolConfiguration>(environment.serverURL + 'poolConfiguration/show')
        } else {
          return of(null);
        }
      }),
      tap(config => this._snapshot = config),
      shareReplay(1),
    );
  }

  get(): Observable<PoolConfiguration> {
    return this.poolConfiguration$;
  }
}
