import {CrudService, DomainDefinition, Entity, FilterType, PropertyDefinition, Relation} from '@ic/ng-crud-client';
import {LocalDate} from '@js-joda/core';
import {InjectionToken, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '../user/user.definition';
import {Employee} from '../employee/employee.definition';

export interface EmployeeComment extends Entity {
  comment: string;
  validAsOf?: LocalDate;
  validUntil?: LocalDate;
  requiredRole?: string;
  employee: Relation<Employee>;

  dateCreated?: LocalDate;
  lastUpdated?: LocalDate;
  createdBy?: Relation<User>;
  lastUpdatedBy?: Relation<User>;
}

export const EMPLOYEE_COMMENT_SERVICE = new InjectionToken<CrudService<EmployeeComment>>('EMPLOYEE_COMMENT_SERVICE');

export const employeeCommentDefinition: DomainDefinition<EmployeeComment> = {
  path: 'employeeComment',
  service: EMPLOYEE_COMMENT_SERVICE,
  properties: new Map<keyof EmployeeComment, PropertyDefinition>([
    ['comment', {
      type: 'text',
      nullable: false,
      filter: FilterType.CONTAINS,
      sortable: true,
    }],
    ['validAsOf', {
      type: 'local-date',
      nullable: true,
      filter: FilterType.GREATER_OR_EQUALS,
    }],
    ['validUntil', {
      type: 'local-date',
      nullable: true,
      filter: FilterType.LOWER_OR_EQUALS,
    }],
  ]),
}

export function employeeCommentServiceFactory(http: HttpClient, injector: Injector) {
  return new CrudService(employeeCommentDefinition, http, injector);
}
