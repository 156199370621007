import {InjectionToken, Injector} from '@angular/core';
import {
  CachedCrudService,
  CrudService,
  DomainDefinition,
  Entity,
  FilterType,
  Flatten,
  PropertyDefinition,
  Relation,
} from '@ic/ng-crud-client';
import {Qualification, QUALIFICATION_SERVICE} from '../qualification/qualification.definition';
import {Sex, SexMin} from '../../definitions';
import {Agency} from '../agency/agency.definition';
import {User} from '../user/user.definition';
import {ORGANISATION_UNIT_SERVICE} from '../organisation-unit/organisation-unit.definition';
import {EmployeeContract} from '../contract/employee-contract.definition';

export interface Employee extends Entity {
  firstName: string;
  lastName: string;
  agency: Relation<Agency>;
  personalData: PersonalData;
  employeeData: EmployeeData;
  user: User;
  profileStatus: ProfileStatus;
  resourceType?: ResourceType;
  canPlanOrganisations?: string;
}

export enum ProfileStatus {
  INVITATION_EXPIRED = 'INVITATION_EXPIRED',
  EDIT = 'EDIT',
  COMPLETED = 'COMPLETED',
  VALIDATED = 'VALIDATED',
  EMPLOYMENT_WITHDRAWN = 'EMPLOYMENT_WITHDRAWN',
  EMPLOYMENT_WITHDRAWN_WORKING = 'EMPLOYMENT_WITHDRAWN_WORKING',
  EMPLOYMENT_WITHDRAWN_WORKED_HOURS_MISSING = 'EMPLOYMENT_WITHDRAWN_WORKED_HOURS_MISSING',
  EMPLOYMENT_FINISHED = 'EMPLOYMENT_FINISHED',
  LOCKED = 'LOCKED',
}

export enum ResourceType {
  POOL = 'POOL',
  SPRINGER = 'SPRINGER',
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export interface EmployeeData extends Entity {
  foreignLanguage: ForeignLanguage[];
  employeeNumber: string;
  contracts: EmployeeContract[];
}

export interface PersonalData extends Entity {
  mobilePhone: string;
  landlinePhone: string;
  dateOfBirth: Date;
  qualification: Relation<Qualification>;
  sex: Sex;
}

export interface EmployeePermission extends Entity {
  employee: Partial<Employee>;
  canCreateAvailability: boolean;
  canCreateAbsenceWish: boolean;
  canFillStint: boolean;
  receivesBookingRequest: boolean;
  receivesPrintedPlan: boolean;
  requiresBookingConfirmation: boolean;
  canReceiveNotifications: boolean;
}

export interface ForeignLanguage extends Entity {
  language: string;
  oral: boolean;
  written: boolean;
}

export type FlatEmployee = Flatten<Employee> & Employee;

export const EMPLOYEE_SERVICE = new InjectionToken<CachedCrudService<FlatEmployee>>('EMPLOYEE_SERVICE');
export const PLANNER_SERVICE = new InjectionToken<CachedCrudService<FlatEmployee>>('PLANNER_SERVICE');
export const RESOURCE_SERVICE = new InjectionToken<CachedCrudService<FlatEmployee>>('RESOURCE_SERVICE');

export interface Assignment extends Entity {
  employee: Entity;
  organisationUnit: Entity;
  canPlan: boolean;
  canWork: boolean;
  isBaseOrganisationUnit: boolean;
}

export const ASSIGNMENT_SERVICE = new InjectionToken<CrudService<Assignment>>('ASSIGNMENT_SERVICE');

export const assignmentDefinition: DomainDefinition<Assignment> = {
  path: 'assignment',
  service: ASSIGNMENT_SERVICE,
  properties: new Map<keyof Assignment, PropertyDefinition>([
    ['employee', {
      type: 'belongs-to',
      service: EMPLOYEE_SERVICE,
    }],
    ['organisationUnit', {
      type: 'belongs-to',
      service: ORGANISATION_UNIT_SERVICE,
    }],
  ]),
};

// @ts-ignore (required to fix excessively deep type error)
export const employeeDefinition: DomainDefinition<FlatEmployee> = {
  path: 'employee',
  service: EMPLOYEE_SERVICE,
  properties: new Map<keyof FlatEmployee, PropertyDefinition>([
    ['lastName', {
      type: 'text',
      nullable: true,
      filter: FilterType.CONTAINS,
      sortable: true,
    }],
    ['firstName', {
      type: 'text',
      nullable: true,
      filter: FilterType.CONTAINS,
      sortable: true,
    }],
    ['sex', {
      type: 'enum',
      cls: SexMin,
      prefix: 'Sex',
      sortable: true,
      filter: FilterType.IN,
    }],
    ['mobilePhone', {
      type: 'text',
      nullable: true,
      filter: FilterType.CONTAINS,
      sortable: true,
    }],
    ['landlinePhone', {
      type: 'text',
      nullable: true,
      filter: FilterType.CONTAINS,
      sortable: true,
    }],
    ['email', {
      type: 'string',
      filter: FilterType.CONTAINS,
      sortable: true,
    }],
    ['username', {
      type: 'string',
      filter: FilterType.CONTAINS,
      sortable: true,
    }],
    ['qualification', {
      type: 'belongs-to',
      service: QUALIFICATION_SERVICE,
      filter: FilterType.IN,
      sortable: true,
    }],
    ['lastLogin', {
      type: 'local-date-time',
      filter: FilterType.GREATER_OR_EQUALS,
      sortable: true,
    }],
    ['enabled', {
      type: 'boolean',
      filter: FilterType.IN,
      sortable: true,
    }],
    ['employeeNumber', {
      type: 'string',
      filter: FilterType.CONTAINS,
      sortable: true,
    }],
    ['profileStatus', {
      type: 'enum',
      cls: ProfileStatus,
      prefix: 'ProfileStatus',
      defaultFilterValue: [
        ProfileStatus.VALIDATED,
        ProfileStatus.EMPLOYMENT_WITHDRAWN,
        ProfileStatus.EMPLOYMENT_WITHDRAWN_WORKING,
        ProfileStatus.EMPLOYMENT_WITHDRAWN_WORKED_HOURS_MISSING,
      ],
      filter: FilterType.IN,
    }],
    ['resourceType', {
      type: 'enum',
      cls: ResourceType,
      prefix: 'ResourceType',
      defaultFilterValue: [ResourceType.POOL, ResourceType.SPRINGER, ResourceType.INTERNAL, ResourceType.EXTERNAL],
      filter: FilterType.IN,
    }],
    ['canPlanOrganisations', {
      type: 'string',
    }],
  ]),
  propertiesMapping: new Map<keyof FlatEmployee, any>([
    ['mobilePhone', ['personalData', 'mobilePhone']],
    ['landlinePhone', ['personalData', 'landlinePhone']],
    ['sex', ['personalData', 'sex']],
    ['username', ['user', 'username']],
    ['email', ['user', 'email']],
    ['qualification', ['personalData', 'qualification']],
    ['lastLogin', ['user', 'lastLogin']],
    ['enabled', ['user', 'enabled']],
    ['employeeNumber', ['employeeData', 'employeeNumber']],
  ]),
  formDefinitions: {
    planning: {
      fields: ['firstName', 'lastName'],
    },
  },
  tableDefinitions: {
    default: {
      columns: ['lastName', 'firstName', 'sex', 'mobilePhone', 'email', 'qualification', 'resourceType', 'lastLogin', 'profileStatus', 'enabled'],
      inlineEditable: false,
      disableDetail: true,
      globalFilter: 'z.B. Müller...',
      // TODO: make link relative without pool3
      immutable: true,
      filterStorable: true,
      columnsConfigurable: true,
      filters: {
        resourceType: {
          matchMode: FilterType.IN,
          value: [ResourceType.POOL, ResourceType.SPRINGER, ResourceType.INTERNAL, ResourceType.EXTERNAL],
        },
      },
    },
    planner: {
      columns: ['lastName', 'firstName', 'landlinePhone', 'mobilePhone', 'email', 'canPlanOrganisations', 'lastLogin', 'enabled'],
      inlineEditable: false,
      disableDetail: true,
      globalFilter: 'z.B. Müller...',
      // TODO: make link relative without pool3
      immutable: true,
      filters: {
        resourceType: {
          matchMode: FilterType.IS_NULL,
          value: null,
        },
      },
      sortField: 'lastName',
      sortOrder: 1,
      tableTitle: 'Manager und Planer',
      tableEntityName: 'Manager und Planer',
    },
  },
};

export function employeeCrudServiceFactory(httpClient, injector: Injector) {
  return new CachedCrudService(employeeDefinition, httpClient, injector);
}

export function plannerCrudServiceFactory(httpClient, injector: Injector) {
  return new CachedCrudService(employeeDefinition, httpClient, injector, '/planners');
}

export function resourceCrudServiceFactory(httpClient, injector: Injector) {
  return new CachedCrudService(employeeDefinition, httpClient, injector, '/resources');
}


