import {CachedCrudService, DomainDefinition, Entity, FilterType, PropertyDefinition} from '@ic/ng-crud-client';
import {LocalDateTime} from '@js-joda/core';
import {InjectionToken} from '@angular/core';
import {Employee, RESOURCE_SERVICE} from '../employee/employee.definition';


export const USER_SERVICE = new InjectionToken<CachedCrudService<User>>('USER_SERVICE');

export interface User extends Entity {
  id: number;
  username: string;
  employee?: Partial<Employee>;
  email: string;
  lastLogin: LocalDateTime;
  enabled: boolean;
  accountExpired: boolean;
  accountLocked: boolean;
  passwordExpired: boolean;
  isAnonymous: boolean;
}

export const userDefinition: DomainDefinition<User> = {
  path: 'user',
  service: USER_SERVICE,
  properties: new Map<keyof User, PropertyDefinition>([
    ['username', {
      type: 'text',
      nullable: false,
      filter: FilterType.CONTAINS,
      sortable: true,
    }],
      ['employee', {
      type: 'belongs-to',
        service: RESOURCE_SERVICE,
        filter: FilterType.CONTAINS,
        sortable: false,
        denyEdit: true,
    }],
    ['email', {
    type: 'text',
      nullable: false,
      filter: FilterType.CONTAINS,
      sortable: true,
    }],
    ['enabled', {
      type: 'boolean',
      nullable: false,
      filter: FilterType.IN,
      sortable: true,
    }],
    ['accountExpired', {
      type: 'boolean',
      nullable: false,
      filter: FilterType.IN,
      sortable: true,
    }],
    ['accountLocked', {
      type: 'boolean',
      nullable: false,
      filter: FilterType.IN,
      sortable: true,
    }],
    ['passwordExpired', {
      type: 'boolean',
      nullable: false,
      filter: FilterType.IN,
      sortable: true,
    }],
  ]),
  tableDefinitions: {
    default: {
      columns: ['username','employee', 'email', 'enabled', 'accountExpired', 'accountLocked', 'passwordExpired'],
      denyEntityCreation: true,
      inlineEditable: true,
      immutable: false,
      disableDetail: false,
      sortField: 'username',
      sortOrder: 1,
      filters: {},
      filterStorable: true,
    },
  },
}

