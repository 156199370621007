import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StatusBannerComponent} from './status-banner/status-banner.component';


@NgModule({
  declarations: [
    StatusBannerComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    StatusBannerComponent,
  ],
})
export class ApplicationStatusModule {
}
