import {InjectionToken} from '@angular/core';
import {DomainDefinition, FilterType, Paths, PropertyDefinition, Relation} from '@ic/ng-crud-client';
import {Qualification, QUALIFICATION_SERVICE} from '../qualification/qualification.definition';
import {Sex, SexMin} from '../../definitions';
import {Employee} from '../employee/employee.definition';
import {Agency, AGENCY_SERVICE} from '../agency/agency.definition';

export interface ExternalResource extends Employee {
  firstName: string;
  lastName: string;
  mobilePhone: string; // optional
  dateOfBirth?: Date; // optional
  username: string,
  email: string;
  sex: SexMin;
  agencyId?: number, // optional
  employeeNumber: string;
  qualificationId: number;
  // workaround to make dropdown work
  qualification: Relation<Qualification>;
  agency: Relation<Agency>;
}
export const EXTERNAL_RESOURCE_SERVICE: InjectionToken<ExternalResource> = new InjectionToken('EXTERNAL_RESOURCE_SERVICE');

export const externalResourceDefinition: DomainDefinition<ExternalResource> = {
  path: 'employee',
  service: EXTERNAL_RESOURCE_SERVICE,
  properties: new Map<keyof ExternalResource, PropertyDefinition>([
    ['lastName', {
      type: 'text',
      nullable: true,
      filter: FilterType.CONTAINS,
      sortable: true,
    }],
    ['firstName', {
      type: 'text',
      nullable: true,
      filter: FilterType.CONTAINS,
      sortable: true,
    }],
    ['sex', {
      type: 'enum',
      cls: SexMin,
      prefix: 'Sex',
      sortable: true,
      filter: FilterType.IN,
    }],
    ['mobilePhone', {
      type: 'text',
      nullable: true,
      filter: FilterType.CONTAINS,
      sortable: true,
    }],
    ['username', {
      type: 'string',
      filter: FilterType.CONTAINS,
      sortable: true,
    }],
    ['email', {
      type: 'string',
      filter: FilterType.CONTAINS,
      sortable: true,
    }],
    ['qualification', {
      type: 'belongs-to',
      service: QUALIFICATION_SERVICE,
      filter: FilterType.IN,
      sortable: true,
    }],
    ['agency', {
      type: 'belongs-to',
      service: AGENCY_SERVICE,
    }],
    ['dateOfBirth', {
      type: 'date',
      nullable: true,
    }],
    ['employeeNumber', {
      type: 'string',
      nullable: true,
    }],
    // TODO: add missing fields
  ]),
  propertiesMapping: new Map<keyof ExternalResource, Paths<ExternalResource>>([
    ['mobilePhone', ['personalData', 'mobilePhone']],
    ['dateOfBirth', ['personalData', 'dateOfBirth']],
    ['sex', ['personalData', 'sex']],
    ['username', ['user', 'username']],
    ['email', ['user', 'email']],
    ['qualification', ['personalData', 'qualification']],
    ['qualificationId', ['personalData', 'qualification', 'id']],
    ['employeeNumber', ['employeeData', 'employeeNumber']],
  ]),
  formDefinitions: {
    default: {
      fields: ['firstName', 'lastName', 'sex', 'qualification', 'agency'],
    },
  },
};

