import {Injectable, Injector} from '@angular/core';
import {CrudService} from '@ic/ng-crud-client';
import {HttpClient} from '@angular/common/http';
import {Meeting, meetingDefinition} from './meeting.definititon';

@Injectable({
  providedIn: 'root',
})
export class MeetingService extends CrudService<Meeting> {

  constructor(http: HttpClient, injector: Injector) {
    super(meetingDefinition, http, injector);
  }
}
