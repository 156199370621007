import {InjectionToken} from '@angular/core';
import {CachedCrudService, DomainDefinition, Entity, FilterType, PropertyDefinition} from '@ic/ng-crud-client';
import {Pool} from '../pool/pool.definition';

export interface UpgradeTrainingCourse extends Entity {
  courseName: string;
  pool:  Pool;
}

export const UPGRADE_TRAINING_COURSE_SERVICE = new InjectionToken<CachedCrudService<UpgradeTrainingCourse>>('UPGRADE_TRAINING_COURSE_SERVICE');

export const upgradeTrainingCourseDefinition: DomainDefinition<UpgradeTrainingCourse> = {
  path: 'upgradeTrainingCourse',
  service: UPGRADE_TRAINING_COURSE_SERVICE,
  properties: new Map<keyof UpgradeTrainingCourse, PropertyDefinition>([
    ['courseName', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
  ]),
  formDefinitions: {
    default: {
      fields: ['courseName'],
    },
  },
  tableDefinitions: {
    default: {
      columns: ['courseName'],
      inlineEditable: false,
      immutable: false,
      disableDetail: true,
      sortField: 'courseName',
      sortOrder: 1,
      filters: {},
      filterStorable: true,
    },
  },
};

