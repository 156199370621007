import {CrudService, ListPage} from '@ic/ng-crud-client';
import {Shift, shiftDefinition} from './shift.definition';
import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {OrganisationUnit} from '../organisation-unit/organisation-unit.definition';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Booking} from '../booking/booking.definition';
import {FilterMatchMode, LazyLoadEvent} from 'primeng/api';
import { Weekdays } from '../../definitions';

@Injectable({
  providedIn: 'root',
})
export class ShiftService extends CrudService<Shift> {

  constructor(http: HttpClient, injector: Injector) {
    super(shiftDefinition, http, injector);
  }

  delete(model: Shift): Observable<unknown> {
    return this.http.delete(this.apiBaseUrl + `shift/delete/${model.id}`);
  }

  restore(shift: Shift): Observable<any> {
    return this.http.put(`/api/v1/shift/update/${shift.id}`, {deleted: false});
  }

  listForOU(organisationUnit: OrganisationUnit, isWeekend: Boolean): Observable<Shift[]> {
    return this.list({
      filters: {
        organisationUnit: {
          matchMode: 'in',
          value: [{id: organisationUnit.id}],
        },
        weekdays: {
          matchMode: 'in',
          value: [Weekdays.WEEK, isWeekend ? Weekdays.WEEKEND : Weekdays.MONDAY_TO_FRIDAY],
        }
      },
      rows: 999,
      sortField: 'poolShift.shiftName',
    }).pipe(
      map((listPage: ListPage<Shift>) => listPage.records),
    );
  }

  findForBooking(booking: Booking): Observable<Shift> {
    const shiftFilter: LazyLoadEvent = {
      filters: {
        poolShift: {
          matchMode: FilterMatchMode.EQUALS,
          value: {id: booking.basedOn.shift.id},
        },
        organisationUnit: {
          matchMode: FilterMatchMode.EQUALS,
          value: {id: booking.organisationUnit.id},
        },
      },
    };
    return (<Observable<ListPage<Shift>>>this.list(shiftFilter)).pipe(
      switchMap((result: ListPage<Shift>) => {
        if (result.records.length === 0) {
          return of(null);
        }
        return of(result.records[0]);
      }),
    );
  }
}
