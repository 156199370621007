import {CommonModule, DatePipe, DecimalPipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EntityFormComponent} from './form/entity-form.component';
import {TranslateModule} from '@ngx-translate/core';
import {CreateComponent} from './create/create.component';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EditComponent} from './edit/edit.component';
import {ListComponent} from './list/list.component';
import {TableComponent} from './table/table.component';
import {TableModule} from 'primeng/table';
import {NotFoundComponent} from './not-found/not-found.component';
import {ShowComponent} from './show/show.component';
import {CardModule} from 'primeng/card';
import {DialogModule} from 'primeng/dialog';
import {StringifyPipe} from './stringify/stringify.pipe';
import {StringifyService} from './stringify/stringify.service';
import {API_CONFIGURATION, APIConfiguration} from './definitions';
import {SharedModule} from 'primeng/api';
import {CalendarModule} from 'primeng/calendar';
import {MultiSelectModule} from 'primeng/multiselect';
import {InputTextModule} from 'primeng/inputtext';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {CheckboxModule} from 'primeng/checkbox';
import {DropdownModule} from 'primeng/dropdown';
import {FileUploadModule} from 'primeng/fileupload';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {MessagesModule} from 'primeng/messages';
import {TooltipModule} from 'primeng/tooltip';
import {ActionCellRendererComponent} from './table/action-cell-renderer/action-cell-renderer.component';
import {MenuModule} from 'primeng/menu';
import {AuthCoreModule} from '@ic/auth';
import {ViewCellDirective} from './table/view-cell.directive';
import {EntityFormDialogAdaptorComponent} from './entity-form-dialog-adaptor/entity-form-dialog-adaptor.component';
import {InputNumberModule} from 'primeng/inputnumber';
import {TableSettingsComponent} from './table/table-settings/table-settings.component';
import {DateTypeDirective} from './calendar/date-type.directive';
import {DownloadListButtonComponent} from './download-list-button/download-list-button.component';

const routes: Routes = [
  {path: 'not-found', component: NotFoundComponent},
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    CalendarModule,
    MultiSelectModule,
    TableModule,
    InputTextModule,
    InputTextareaModule,
    CheckboxModule,
    MessagesModule,
    DropdownModule,
    TooltipModule,
    CardModule,
    DialogModule,
    AutoCompleteModule,
    FileUploadModule,
    RouterModule,
    // I18nModule,
    RouterModule.forChild(routes),
    MenuModule,
    AuthCoreModule,
    InputNumberModule,
    SharedModule,
  ],
  declarations: [
    DateTypeDirective,
    EntityFormComponent,
    TableComponent,
    TableSettingsComponent,
    CreateComponent,
    EditComponent,
    ListComponent,
    StringifyPipe,
    NotFoundComponent,
    ShowComponent,
    ActionCellRendererComponent,
    ViewCellDirective,
    EntityFormDialogAdaptorComponent,
    DownloadListButtonComponent,
  ],
  exports: [
    EntityFormComponent,
    CreateComponent,
    EditComponent,
    ListComponent,
    TableComponent,
    ShowComponent,
    StringifyPipe,
    CommonModule,
    CalendarModule,
    MultiSelectModule,
    FormsModule,
    DropdownModule,
    CheckboxModule,
    TranslateModule,
    ViewCellDirective,
    DateTypeDirective,
    ActionCellRendererComponent,
  ],
})
export class NgCrudClientModule {
  static forRoot(apiConfig: APIConfiguration): ModuleWithProviders<NgCrudClientModule> {
    return {
      ngModule: NgCrudClientModule,
      providers: [
        DatePipe,
        DecimalPipe,
        StringifyService,
        {provide: API_CONFIGURATION, useValue: apiConfig},
      ],
    };
  }
}
