import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainMenuComponent} from './main-menu/main-menu.component';
import {MenubarModule} from 'primeng/menubar';
import {LegacyNavigationComponent} from './legacy-navigation.component';

@NgModule({
  declarations: [MainMenuComponent, LegacyNavigationComponent, LegacyNavigationComponent],
  exports: [
    MainMenuComponent,
    LegacyNavigationComponent,
  ],
  imports: [
    CommonModule,
    MenubarModule,
  ],
})
export class NavigationModule { }
