import {Injectable} from '@angular/core';
import {Change, ChangeCommand} from '../../planning.definitions';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {delay, map, tap} from 'rxjs/operators';

@Injectable()
export abstract class DialogDataService {
  protected readonly delayTime = 0; // avoids flickering
  public readonly loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get loading$(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }
  protected constructor(protected http: HttpClient) {}

  sendChanges(endpoint: string, changeCmd: ChangeCommand): Observable<ChangeCommand> {
    this.loadingSubject.next(true);
    return this.http.post<ChangeCommand>(`${environment.serverURL}planning/${endpoint}`, changeCmd).pipe(
      map(this._commandFromJson, this),
      delay(this.delayTime),
      tap(() => {
        this.loadingSubject.next(false);
      }),
    )
  }


  private _commandFromJson(cmd: ChangeCommand): ChangeCommand {
    return {...cmd, changes: cmd.changes.map(this.changeFromJson, this)};
  }

  protected abstract changeFromJson(change: Change);
}

