import {InjectionToken, Injector} from '@angular/core';
import {CachedCrudService, DomainDefinition, Entity, FilterType, PropertyDefinition} from '@ic/ng-crud-client';

export interface SharedFileTag extends Entity {
  tag: string;
}

export const SHARED_FILE_TAG_SERVICE = new InjectionToken<CachedCrudService<SharedFileTag>>('SHARED_FILE_TAG_SERVICE');

export const sharedFileTagDefinition: DomainDefinition<SharedFileTag> = {
  path: 'sharedFileTag',
  service: SHARED_FILE_TAG_SERVICE,
  properties: new Map<keyof SharedFileTag, PropertyDefinition>([
    ['tag', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
  ]),
  formDefinitions: {
    default: {
      fields: ['tag'],
    },
  },
  tableDefinitions: {
    default: {
      columns: ['tag'],
      inlineEditable: true,
      immutable: false,
      disableDetail: false,
      sortField: 'tag',
      sortOrder: 1,
      filters: {},
      filterStorable: true,
    },
  },
};

