import {InjectionToken} from '@angular/core';

export enum DeliveryState {
  DELIVERED = 'DELIVERED',
  DROPPED = 'DROPPED',
  DELAYED = 'DELAYED',
  DELIVERY_FAILED = 'DELIVERY_FAILED',
}

export interface Recipient {
  status: DeliveryState;
  address: string;  // can be an email or phone number
}

// Note this model differs from the server model.
// This makes sense since we don't want the full complexity on the client
export interface Notification {
  id: Number;
  subject: string;
  sentDateTime: Date;
  sender: string;
  to: Recipient[];
  cc: Recipient[];
}

export interface ListPage {
  total: Number;
  notifications: Notification[];
}

export interface UserNotificationConfiguration {
  backendBaseURL: string;
}

export const USER_NOTIFICATION_CONFIGURATION: InjectionToken<UserNotificationConfiguration> =
  new InjectionToken<UserNotificationConfiguration>('USER_NOTIFICATION_CONFIGURATION');
