import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit
} from '@angular/core';
import {AngularJsAppHelperService} from '../angular-js/angular-js-app-helper.service';
import * as angular from 'angular';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {AuthService} from "@ic/auth";
import {Role} from "../role";

/**
 * LegacyNavigationComponent bootstraps an angularJS application to render the application header.
 * Once the header has been migrated from angularJS to Angular8+, this component should be removed, and its usages replaced
 * with the newly created Angular 8+ component.
 */
@Component({
  selector: 'pool-legacy-navigation',
  template: `
    <div id="top-bar">
        <div ng-include="'${environment.LEGACY_contextPath}/index/headerTemplate'"></div>
    </div>
  `,
  styles: [],
})
export class LegacyNavigationComponent implements OnInit, OnDestroy {

  private _angularJSApp: angular.auto.IInjectorService;

  constructor(private elementRef: ElementRef,
              private angularJSAppHelperService: AngularJsAppHelperService,
              private router: Router,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.angularJSAppHelperService.bootstrap(this.elementRef.nativeElement).subscribe((app) => {
      this._angularJSApp = app;

      // Prevent angularJS ui-router to do any routing since this component is the only part with an angularJS application
      // and it does not have an ui-view element where it could place the view content.
      // Instead use the Angular 8+ router to navigate to a route which bootstraps a complete angularJS application using
      // the AngularJSContentComponent.
      const $rootScope = app.get('$rootScope');
      const $state = app.get('$state');
      $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams, options) => {
        event.preventDefault();
        const url: string = ($state as any).href(toState.name, toParams, options);
        this.router.navigate([url.substr(1)]);
      });

      // TODO: securityContextSnapshot not always defined... why
      if (this.authService.securityContextSnapshot && this.authService.securityContextSnapshot.hasAnyRole([
        // TODO: needs to be a role that only agencies are assigend to?
      ])) {
        this._securityTrimNavigation();
      }
    });
  }

  ngOnDestroy(): void {
    this.angularJSAppHelperService.shutdown(this._angularJSApp);
  }

  private _securityTrimNavigation() {
    const topBar = document.body.querySelector("#top-bar");

    // hide nav until trimmed
    topBar.setAttribute('hidden','hidden');

    // triggers when angularJS component completely ready
    const observer = new MutationObserver(mutations => {
      observer.disconnect();
      this._trimNavigation(topBar);

      // show nav
      topBar.removeAttribute('hidden');
    });

    observer.observe(topBar, {
      attributes: true,
      childList: true,
      characterData: true
    });
  }

  private _trimNavigation(topBar: Element) {
    const header = topBar.querySelector(".header");
    // remove elements from header left
    const navLeft = header.querySelector(".left");
    const iconNode = navLeft.querySelector("#logo");
    LegacyNavigationComponent.removeOthers(navLeft, iconNode);

    // remove link from icon...
    iconNode.removeAttribute('href');

    // remove elements from header left
    const navRight = header.querySelector(".right");
    const userNode = navRight.querySelector("#user");
    LegacyNavigationComponent.removeOthers(navRight, userNode);

    // remove elements from user menu
    const ul = userNode.querySelector(".uib-dropdown-menu");
    const logout = ul.querySelector("#logout");
    LegacyNavigationComponent.removeOthers(ul, logout);
  }

  private static removeOthers(rootElement: Element, remainingElement: Element) {
    Array.from(rootElement.children).forEach(o => {
      if (!(o.contains(remainingElement) || o === remainingElement)) {
        rootElement.removeChild(o);
      }
    });
  }
}
