import {DomainDefinition, Entity, FilterType, Paths, PropertyDefinition, Relation} from '@ic/ng-crud-client';
import {InjectionToken} from '@angular/core';
import {Agency, AGENCY_SERVICE} from '../agency/agency.definition';
import {Stint, STINT_SERVICE} from '../stint/stint.definition';
import {ORGANISATION_UNIT_SERVICE, OrganisationUnit} from '../organisation-unit/organisation-unit.definition';
import {HEALTH_INSTITUTION_SERVICE, HealthInstitution} from '../health-institution/health-institution.definition';
import {Qualification, QUALIFICATION_SERVICE} from '../qualification/qualification.definition';
import {LocalDateTime} from '@js-joda/core';
import {Sex} from '../../definitions';
import {StintOfferService} from './stint-offer.service';

export interface StintOffer extends Entity {
  agency: Relation<Agency>;
  stintID: string;
  stint: Relation<Stint>;
  rejected: boolean;
  offeredSince: LocalDateTime;
  stintUpdated: LocalDateTime;
  organisationUnit?: Relation<OrganisationUnit>;
  department?: string;
  healthInstitution?: Relation<HealthInstitution>;
  qualifications?: Partial<Qualification>[];
  start?: LocalDateTime;
  end?: LocalDateTime;
  sex?: Sex;
  alreadyWorkedInOrganisationUnit?: boolean;
  alreadyWorkedInHealthInstitution?: boolean;
  room?: string;
  comment?: string;
}

export const STINT_OFFER_SERVICE = new InjectionToken<StintOfferService>('STINT_OFFER_SERVICE');

export const stintOfferDefinition: DomainDefinition<StintOffer> = {
  path: 'stintOffer',
  service: STINT_OFFER_SERVICE,
  properties: new Map<keyof StintOffer, PropertyDefinition>([
    ['offeredSince', {
      type: 'local-date-time',
      filter: FilterType.GREATER_OR_EQUALS,
      sortable: true,
    }],
    ['stintUpdated', {
      type: 'local-date-time',
      filter: FilterType.GREATER_OR_EQUALS,
      sortable: true,
    }],
    ['rejected', {
      type: 'boolean',
      filter: FilterType.IN,
      sortable: true,
    }],
    ['room', {
      type: 'string',
    }],
    ['department', {
      type: 'string',
      filter: FilterType.CONTAINS,
      sortable: true,
    }],
    ['comment', {
      type: 'string',
    }],
    ['agency', {
      type: 'belongs-to',
      service: AGENCY_SERVICE,
      filter: FilterType.IN,
      sortable: true,
    }],
    ['stintID', {
      type: 'string',
      filter: FilterType.NONE,
      sortable: true,
    }],
    ['stint', {
      type: 'belongs-to',
      service: STINT_SERVICE,
      filter: FilterType.NONE,
      sortable: true,
    }],
    ['start', {
      type: 'local-date-time',
      filter: FilterType.BETWEEN,
      nullable: true,
      sortable: true,
      defaultFilterValue:  new Date(),
    }],
    ['end', {
      type: 'local-date-time',
      filter: FilterType.NONE,
      nullable: true,
      sortable: true,
    }],
    ['sex', {
      type: 'enum',
      cls: Sex,
      prefix: 'Sex',
      sortable: true,
    }],
    ['alreadyWorkedInOrganisationUnit', {
      type: 'boolean',
      filter: FilterType.IN,
      sortable: true,
    }],
    ['alreadyWorkedInHealthInstitution', {
      type: 'boolean',
      filter: FilterType.IN,
      sortable: true,
    }],
    ['qualifications', {
      type: 'has-many',
      service: QUALIFICATION_SERVICE,
      filter: FilterType.NONE,
      sortable: false,
    }],
    ['organisationUnit', {
      type: 'belongs-to',
      service: ORGANISATION_UNIT_SERVICE,
      filter: FilterType.IN,
      sortable: true,
    }],
    ['healthInstitution', {
      type: 'belongs-to',
      service: HEALTH_INSTITUTION_SERVICE,
      filter: FilterType.IN,
      sortable: false,
    }],
  ]),
  // @ts-ignore
  propertiesMapping: new Map<keyof StintOffer, Paths<StintOffer>>([
    ['organisationUnit', ['stint', 'organisationUnit']],
    ['healthInstitution', ['stint', 'organisationUnit', 'healthInstitution']],
    ['department', ['stint', 'organisationUnit', 'department']],
    ['qualifications', ['stint', 'qualifications']],
    ['start', ['stint', 'start']],
    ['end', ['stint', 'end']],
    ['sex', ['stint', 'sex']],
    ['alreadyWorkedInOrganisationUnit', ['stint', 'alreadyWorkedInOrganisationUnit']],
    ['alreadyWorkedInHealthInstitution', ['stint', 'alreadyWorkedInHealthInstitution']],
    ['room', ['stint', 'room']],
    ['comment', ['stint', 'comment']],
    ['stintID', ['stint', 'id']],
  ]),
  tableDefinitions: {
    default: {
      columns: [
        'offeredSince', 'stintUpdated', 'stintID', 'organisationUnit', 'department', 'healthInstitution', 'qualifications', 'start', 'end', 'sex', 'room',
        'comment', 'alreadyWorkedInOrganisationUnit', 'alreadyWorkedInHealthInstitution',
      ],
      inlineEditable: false,
      // globalFilter: 'z.B. Chirurgie, Müller...',
      immutable: true,
      disableDetail: true,
      sortField: 'stint.start',
      sortOrder: 1,
      columnsConfigurable: true,
      filterStorable: true,
    },
  },
};
