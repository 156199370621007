import {CrudService, DomainDefinition, Entity, FilterType, PropertyDefinition} from '@ic/ng-crud-client';
import {EntryType} from '../../planning/planning.definitions';
import {LocalDateTime} from '@js-joda/core';
import {InjectionToken} from '@angular/core';
import {COURSE_SERVICE} from '../course/course.definition';
import {Booking, BOOKING_SERVICE} from '../booking/booking.definition';

export interface Training extends Entity {
  title: string;
  workType: EntryType;
  participants: Entity[];
  place: string;
  start: LocalDateTime;
  end: LocalDateTime;
  description: string;
  course?: Entity;
  lastUpdated: LocalDateTime
  bookings: ContainerBooking[];
}

export type ContainerBooking = Pick<Booking, 'resource' | 'bookingStatus'>

export const TRAINING_SERVICE = new InjectionToken<CrudService<Training>>('TRAINING_SERVICE');

export const trainingDefinition: DomainDefinition<Training> = {
  path: 'training',
  service: TRAINING_SERVICE,
  properties: new Map<keyof Training, PropertyDefinition>([
    ['course', {
      type: 'belongs-to',
      sortable: true,
      filter: FilterType.IN,
      service: COURSE_SERVICE,
    }],
    ['title', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
    ['place', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
    ['start', {
      type: 'local-date-time',
      nullable: true,
      sortable: true,
      filter: FilterType.GREATER_OR_EQUALS,
    }],
    ['end', {
      type: 'local-date-time',
      nullable: true,
      filter: FilterType.LOWER_OR_EQUALS,
    }],
    ['description', {
      type: 'text',
      sortable: true,
      nullable: true,
      filter: FilterType.CONTAINS,
    }],
    ['bookings', {
      type: 'has-many',
      service: BOOKING_SERVICE,
      filter: FilterType.CONTAINS,
      noOptions: true,
    }],
  ]),
  formDefinitions: {
    training: {
      fields: ['course', 'title', 'place', 'description', 'start', 'end'],
    },
  },
  tableDefinitions: {
    default: {
      columns: ['course', 'title', 'place', 'description', 'workType', 'start', 'end', 'lastUpdated', 'bookings'],
      inlineEditable: false,
      immutable: true,
      disableDetail: true,
      sortField: 'start',
      sortOrder: -1,
      filters: {
        workType: {matchMode: 'IN', value: 'TRAINING'},
      },
      filterStorable: true,
    },
  },
};
