import {InjectionToken} from '@angular/core';
import {CrudService, DomainDefinition, Entity, FilterType, PropertyDefinition} from '@ic/ng-crud-client';
import {POOL_SHIFT_SERVICE, PoolShift} from '../pool-shift/pool-shift.definition';
import {ORGANISATION_UNIT_SERVICE, OrganisationUnit} from '../organisation-unit/organisation-unit.definition';
import {LocalTime} from '@js-joda/core';
import {Weekdays} from '../../definitions';

export interface Shift extends Entity {
  poolShift: PoolShift;
  start: LocalTime;
  end: LocalTime;
  startBreak: LocalTime;
  endBreak: LocalTime;
  paidBreak: Boolean;
  weekdays: Weekdays;
  organisationUnit: OrganisationUnit;
  timeSurchargeFactor: number;
  deleted: boolean;
}

export const SHIFT_SERVICE: InjectionToken<CrudService<Shift>> = new InjectionToken('SHIFT_SERVICE');

export const shiftDefinition: DomainDefinition<Shift> = {
  path: 'shift',
  service: SHIFT_SERVICE,
  properties: new Map<keyof Shift, PropertyDefinition>([
    ['poolShift', {
      type: 'belongs-to',
      service: POOL_SHIFT_SERVICE,
      nullable: false,
      filter: FilterType.IN,
      denyAssociationCreation: true,
    }],
    ['start', {
      type: 'local-time',
      sortable: true,
      nullable: false,
    }],
    ['end', {
      type: 'local-time',
      sortable: true,
      nullable: false,
    }],
    ['startBreak', {
      type: 'local-time',
      sortable: true,
      nullable: true,
    }],
    ['endBreak', {
      type: 'local-time',
      sortable: true,
      nullable: true,
    }],
    ['paidBreak', {
      type: 'boolean',
      nullable: true,
      filter: FilterType.IN,
    }],
    ['weekdays', {
      type: 'enum',
      cls: Weekdays,
      prefix: 'Weekdays',
      nullable: false,
      sortable: true,
      filter: FilterType.IN,
    }],
    ['organisationUnit', {
      type: 'belongs-to',
      service: ORGANISATION_UNIT_SERVICE,
      nullable: false,
      filter: FilterType.IN,
      denyAssociationCreation: true,
    }],
    ['timeSurchargeFactor', {
      type: 'number',
      nullable: false,
      filter: FilterType.NONE,
      minFractionDigits: 2,
      min: 0,
    }],
    ['deleted', {
      type: 'boolean',
      nullable: false,
      filter: FilterType.IN,
      sortable: true,
    }],
  ]),
  formDefinitions: {
    default: {
      fields: ['organisationUnit', 'poolShift', 'start', 'end', 'weekdays', 'startBreak', 'endBreak', 'paidBreak', 'timeSurchargeFactor', 'deleted'],
    },
  },
  tableDefinitions: {
    default: {
      columns: ['organisationUnit', 'poolShift', 'start', 'end', 'weekdays', 'startBreak', 'endBreak', 'paidBreak', 'timeSurchargeFactor', 'deleted'],
      inlineEditable: true,
      immutable: false,
      disableDetail: false,
      sortField: 'organisationUnit',
      sortOrder: 1,
      filters: {},
      filterStorable: true,
    },
  },
};

