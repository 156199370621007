import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {AuthService} from '@ic/auth';

/**
 * This is the base component for all parts of the application which are directly based on Angular 8+.
 * The header is rendered using the PoolLegacyNavigation component which bootstraps an angularJS application living
 * only in the header.
 */
@Component({
  selector: 'pool-angular-app',
  template: `
    <pool-legacy-navigation *ngIf="showNavigation$ | async"></pool-legacy-navigation>

    <router-outlet></router-outlet>
  `,
  styles: [':host { display: flex; flex-direction: column; height: 100%; }'],
})
export class AngularAppComponent {
  /**
   * Determines if full navigation for should be rendered as angularJS app. The navigation can only be shown for users that are logged in
   * since the angularJS app in the header requires access to some protected resources. For public pages as e.g. shift management
   * registration form however there is no full navigation.
   * The behavior of this property can be controlled with the data param showNavigation on the defined route.
   */
  showNavigation$: Observable<boolean>;

  constructor(activatedRoute: ActivatedRoute, authService: AuthService) {
    // ensure security context is loaded
    this.showNavigation$ = combineLatest([activatedRoute.firstChild.url, authService.securityContext$.pipe(first())]).pipe(
      map(([childUrl, securityContext]) => {
        return ['wochenende', 'schichtleitung'].indexOf(childUrl[0].path) === -1;
      }),
    );
  }
}
