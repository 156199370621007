import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {AssignmentContext, AssignmentService} from './assignment.service';
import {Observable} from 'rxjs/internal/Observable';
import {first} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AssignmentCanPlanResolver  {

  constructor(private assignmentService: AssignmentService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AssignmentContext> {
    if (this.assignmentService.context$) {
      return this.assignmentService.context$.pipe(first());
    } else {
      return null;
    }
  }
}
