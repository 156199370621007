import {Routes} from '@angular/router';
import {UserNotificationListComponent} from './user-notification-list/user-notification-list.component';
import {UserNotificationShowComponent} from './user-notification-show/user-notification-show.component';
import {TestMailComponent} from './test-mail/test-mail.component';

export const routes: Routes = [
  {path: 'list', component: UserNotificationListComponent},
  {path: 'show/:id', component: UserNotificationShowComponent},
  {path: 'test-mail', component: TestMailComponent},
];
