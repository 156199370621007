import {InjectionToken} from '@angular/core';
import {CachedCrudService, DomainDefinition, Entity, FilterType, PropertyDefinition} from '@ic/ng-crud-client';

export interface UserFeedbackPriority extends Entity {
  priority: string;
  position: number;
}

export const USER_FEEDBACK_PRIORITY_SERVICE = new InjectionToken<CachedCrudService<UserFeedbackPriority>>('USER_FEEDBACK_PRIORITY_SERVICE');

export const userFeedbackPriorityDefinition: DomainDefinition<UserFeedbackPriority> = {
  path: 'userFeedbackPriority',
  service: USER_FEEDBACK_PRIORITY_SERVICE,
  properties: new Map<keyof UserFeedbackPriority, PropertyDefinition>([
    ['priority', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
    ['position', {
      type: 'int',
      sortable: true,
      filter: FilterType.NONE,
    }],
  ]),
  formDefinitions: {
    default: {
      fields: ['priority', 'position'],
    },
  },
  tableDefinitions: {
    default: {
      columns: ['priority', 'position'],
      inlineEditable: true,
      immutable: false,
      disableDetail: false,
      sortField: 'priority',
      sortOrder: 1,
      filters: {},
      filterStorable: true,
    },
  },
}
