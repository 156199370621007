import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {MessageService} from 'primeng/api';
import {ProblemJSONError} from './definitions';
import {Location} from '@angular/common';
import {environment} from '../../environments/environment';

/**
 * Number of milliseconds where the same error type should not be reported again.
 */
const BLACKLIST_PERIOD = 4000;

/**
 * The ErrorHandlerService is responsible for the following points
 *  1. it shows an error message to the user
 *  2. it persists the error to the server using OccurredExceptions API
 *  3. it registers as the global Angular ErrorHandler which catches unhandled exceptions
 */
@Injectable()
export class ErrorHandlerService extends ErrorHandler {

  /**
   * store error types which should not be reported to neither the server nor the user
   */
  private blacklistedErrorTypes: Set<string> = new Set<string>();

  constructor(private http: HttpClient, private messageService: MessageService, private location: Location, private ngZone: NgZone) {
    super();
  }

  /**
   * Show an error message to the user using PrimeNG's message service
   */
  showErrorMessage(title: string, detail: string) {
    this.messageService.add({
      summary: title,
      detail,
      severity: 'error',
      life: 7500,
    });
  }

  /**
   * This method is called with unhandled exceptions within angular and provides a hook for centralized exception handling.
   * It can also be called from anywhere within the application if
   *  - an error should be logged on the server
   *  - the user should see an error message
   */
  handleError(error: ProblemJSONError | HttpErrorResponse);
  /** @deprecated this methods sole purpose is to correctly override the base class */
  handleError(error: any): void {
    try {
      super.handleError(error);
      const problemJSONError = {
        ...this.collectEnvironmentInformation(),
        ...this.convertToProblemJSON(error),
      };
      if (problemJSONError.detail && problemJSONError.detail.includes('ChunkLoadError')) {
        window.location.reload();  // stops execution and reloads the application
        // Any method call after reload (e.g. displaying a message) needs to be done in a startup callback (like window.onload)
      }
      if (this.blacklistedErrorTypes.has(problemJSONError.type)) {
        console.error('error not reported because it is blacklisted', problemJSONError);
        return
      }
      this.showErrorMessage(problemJSONError.title, problemJSONError.detail);
      // don't log authentication issues to server since there is nothing to be analyzed
      if (problemJSONError.type !== 'https://hospital-pool.ch/problem/auth-error' && problemJSONError.type !== 'https://hospital-pool.ch/problem/read-only') {
        this.logErrorToServer(problemJSONError);
      }
      this.blacklistTemporarily(problemJSONError);
    } catch (error) {
      console.warn('Error during failure handling', error);
    }
  }

  /**
   * Convert any object into a ProblemJSONError
   */
  protected convertToProblemJSON(error: any): ProblemJSONError {
    if (typeof error === 'object' && error.promise instanceof Promise && error.rejection != null) {
      error = error.rejection;
    }
    if (error instanceof HttpErrorResponse) {
      return this.convertHttpResponseError(error);
    }
    if (error instanceof Error) {
      return {
        type: 'https://hospital-pool.ch/problem/js-exception',
        title: 'JavaScript Fehler',
        detail: error.message,
        exception: {
          name: error.name,
          message: error.message,
          stack: error.stack,
        },
      };
    }
    if (error && typeof error.type === 'string' && typeof error.title === 'string') {
      return error;
    }
    const exception = this.isObjectSerializable(error) ? error : null;
    return {
      type: 'about:blank',
      title: 'Unbekannter Fehler',
      exception,
    };
  }

  /**
   * Convert an instance of HttpErrorResponse into a ProblemJSONError
   */
  protected convertHttpResponseError(error: HttpErrorResponse): ProblemJSONError {
    const contentType = error.headers.get('content-type');
    let problemJSON: ProblemJSONError;
    if (contentType && contentType.startsWith('application/problem+json')) {
      problemJSON = {
        status: error.status,
        ...error.error,
      };
    } else {
      problemJSON = {
        type: 'https://hospital-pool.ch/problem/generic-server-error',
        title: 'Unbekannter Serverfehler',
      };
    }
    return problemJSON;
  }

  /**
   * Send a ProblemJSONError to the server. If this fails an error is sent to the console and no further retries are made.
   */
  protected logErrorToServer(error: ProblemJSONError) {
    const url = environment.LEGACY_contextPath + '/occurredException/javascriptError';
    this.http.post(url, error, {responseType: 'text'}).subscribe({
      error: err => console.warn('occurred exception could not be logged', err),
    });
  }

  /**
   * Collect further information about the client state such as current url, ...
   */
  protected collectEnvironmentInformation(): { [key: string]: any } {
    return {
      clientState: {
        activatedPath: this.location.path(),
      },
    };
  }

  /**
   * checks if the object can be serialized as json, e.g. that there are no circular references
   */
  protected isObjectSerializable(obj: any): boolean {
    try {
      JSON.stringify(obj);
    } catch (e) {
      return false;
    }
    return true;
  }

  /**
   * Temporarily add the given error to the blacklist.
   * After BLACKLIST_PERIOD the error is automatically removed and will be reported again.
   */
  private blacklistTemporarily(error: ProblemJSONError): void {
    this.blacklistedErrorTypes.add(error.type);
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => this.blacklistedErrorTypes.delete(error.type), BLACKLIST_PERIOD);
    })
  }
}
