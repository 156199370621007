import {Directive, Input, TemplateRef} from '@angular/core';

/**
 * The ViewCellDirective is used as marker directive to override column templates in {@link TableComponent}.
 */
@Directive({
  selector: '[icViewCell]',
})
export class ViewCellDirective {
  /**
   * The property name of the column to override with the marked template.
   */
  @Input('icViewCell')
  colName: string;

  constructor(public readonly template: TemplateRef<any>) {
  }
}
