import {ErrorHandler, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErrorHandlerService} from './error-handler.service';
import {MessageService} from 'primeng/api';

/**
 * This module provides a global error handler, which handles all unhandled observable errors as well as
 * any exception occurring in the Angular context.
 * Errors shown to the user and sent to the server for logging purposes.
 */
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    {provide: ErrorHandlerService, useClass: ErrorHandlerService},
    {provide: ErrorHandler, useExisting: ErrorHandlerService},
    {provide: MessageService, useClass: MessageService},
  ],
})
export class ErrorHandlingModule {
}
