import {PoolShift} from '../pool-crud/pool-shift/pool-shift.definition';
import {
  EntrySelection,
  EntryType,
  ImmutableAppointmentState,
  ImmutableDialogState,
  ImmutablePlanningEditState,
  ImmutablePlanningState,
  OUFilterType,
  PlanningFetchCommand,
  ResourceGroupKey,
  Tab,
} from './planning.definitions';
import {LocalDate, TemporalAdjusters} from '@js-joda/core';


export const INITIAL_FETCH_COMMAND: PlanningFetchCommand = {
  groupBy: ResourceGroupKey.NONE,
  start: LocalDate.now().withDayOfMonth(1),
  end: LocalDate.now().with(TemporalAdjusters.lastDayOfMonth()),
  closed: new Set(),
  ouFilterType: OUFilterType.CAN_WORK_ON,
  showAbsences: true,
  showBlocked: true,
  showOU: true,
  showEmptyResourceRows: true,
  showEmptyOURows: true,
  showAllStints: true,
  showAllBookings: true,
  showOnlyFutureOpenStints: true,
};

export const emptySelection = (): EntrySelection => {
  const result = {};
  for (const type of Object.keys(EntryType)) {
    result[type] = [];
  }
  return result as EntrySelection;
};

export const INITIAL_DIALOG_STATE: ImmutableDialogState = {
  changeCommand: {changes: []},
  changeSelection: [],
  currentTab: Tab.CREATE,
  currentPage: 0,
  formValid: false,
};

export const INITIAL_EDIT_STATE: ImmutablePlanningEditState = {
  cellSelection: [],
  affectedEntries: [],
  entrySelection: emptySelection(), // todo: what about special bookings like appointments?
  cellOptionSelection: [],
  entryTypeSelection: EntryType.BOOKING,
  availabilityForm: null,
  bookingForm: null,
  cancelForm: null,
};
export const INITIAL_APPOINTMENT_STATE: ImmutableAppointmentState = {
  day: null,
  appointmentsOfDay: [],
  selectedAppointment: null,
  appointmentForm: null,
};
export const INITIAL_STATE: ImmutablePlanningState = {
  maxRows: 0,
  planningRows: [],
  daysWithAppointments: null,
  rowScrolling: {index: 0, diff: 0},
  fetchCommand: null,
  poolShifts: new Map<number, PoolShift>(),
  loading: false,
  dialog: INITIAL_DIALOG_STATE,
  dialogLoading: false,
  edit: INITIAL_EDIT_STATE,
  appointment: INITIAL_APPOINTMENT_STATE,
};
