import {Injectable} from '@angular/core';
import {DialogContext} from '../../../planning/dialog/definitions';
import {PlanningSelectionOutput} from '../../../planning/dialog/services/planning-dialog-state.service';
import {DialogStateService} from '../../../planning/dialog/services/dialog-state.service';

// TODO: limit scope...
@Injectable({
  providedIn: 'root',
})
export class ListDialogStateService extends DialogStateService<DialogContext, PlanningSelectionOutput> {

  constructor() {
    super(null);
  }


  completeSelection(selection: PlanningSelectionOutput): void {
    if (!selection.entryType || (!selection.entries && !selection.cells)) {
      throw Error('invalid cell selection');
    }
    super.completeSelection(selection);
  }


  completeDialog(): void {
    this.context = undefined;
    this.formHelper = null;
  }
}
