import {Injectable} from '@angular/core';
import {
  HttpContext,
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {catchError} from 'rxjs/operators';

/**
 * The ReadOnlyInterceptor intercepts http requests and switches to read-only mode if the server returns the specific read-only error.
 *
 * The interceptor can be bypassed by calling {@link ReadOnlyInterceptor.bypass()} with a {@link HttpContext} that is passed to the http
 * request.
 */
@Injectable()
export class ReadOnlyInterceptor implements HttpInterceptor {
  private static readonly BYPASS_TOKEN = new HttpContextToken(() => false);
  protected readonly _readonlyErrors$: Subject<unknown> = new Subject();

  constructor() {
  }

  get readonlyErrors$(): Observable<unknown> {
    return this._readonlyErrors$;
  }

  static bypass(ctx: HttpContext) {
    ctx.set(ReadOnlyInterceptor.BYPASS_TOKEN, true);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.context.get(ReadOnlyInterceptor.BYPASS_TOKEN)) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      catchError((err, caught) => {
        if (this.isReadOnlyError(err)) {
          this._readonlyErrors$.next(undefined);
        }
        throw err;
      }),
    );
  }

  protected isReadOnlyError(response: HttpErrorResponse): boolean {
    if (response.status === 500 && response.headers.get('content-type')?.startsWith('application/problem+json')) {
      return response.error.type === 'https://hospital-pool.ch/problem/read-only';
    }
    return false;
  }
}
