import {ModuleWithProviders, NgModule} from '@angular/core';
import {UserNotificationListComponent} from './user-notification-list/user-notification-list.component';
import {UserNotificationShowComponent} from './user-notification-show/user-notification-show.component';
import {TestMailComponent} from './test-mail/test-mail.component';
import {RouterModule} from '@angular/router';
import {routes} from './user-notification.routes';
import {TableModule} from 'primeng/table';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {USER_NOTIFICATION_CONFIGURATION, UserNotificationConfiguration} from "./definitions";
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {CalendarModule} from "primeng/calendar";
import {TooltipModule} from "primeng/tooltip";
import {DropdownModule} from "primeng/dropdown";
import { DeliveryStateTranslationPipe } from './delivery-state-translation.pipe';


@NgModule({
  declarations: [
    UserNotificationListComponent,
    UserNotificationShowComponent,
    TestMailComponent,
    DeliveryStateTranslationPipe,
  ],
  imports: [
    RouterModule.forChild(routes),
    TableModule,
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    CalendarModule,
    TooltipModule,
    DropdownModule,
  ],
  exports: [RouterModule, UserNotificationListComponent, UserNotificationShowComponent, TestMailComponent],
})
export class UserNotificationModule {
  static forRoot(config: UserNotificationConfiguration): ModuleWithProviders<UserNotificationModule> {
    return {
      ngModule: UserNotificationModule,
      providers: [
        {provide: USER_NOTIFICATION_CONFIGURATION, useValue: config},
      ]
    };
  }
}
