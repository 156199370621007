<p-dialog header="Spalten anzeigen" [(visible)]="display" position="topright" [closable]="true">
  <div class="grid" style="width:400px;margin-bottom:10px">
    <div *ngFor="let col of options" class="col-12">
      <p-checkbox name="columns" [value]="col"
                  [label]="path + '.' + col | translate"
                  [ngModel]="selected"
                  (onChange)="onChange($event)"
      ></p-checkbox>
    </div>
  </div>
</p-dialog>

<button type="button" (click)="showDialog()" pButton
        icon="pi pi-cog"
        class="p-button-secondary">
</button>




