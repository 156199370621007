import {DomainDefinition, Entity, FilterType, Paths, PropertyDefinition, Relation} from '@ic/ng-crud-client';
import {BookingService} from './booking.service';
import {InjectionToken} from '@angular/core';
import {ORGANISATION_UNIT_SERVICE, OrganisationUnit} from '../organisation-unit/organisation-unit.definition';
import {Employee, PLANNER_SERVICE, RESOURCE_SERVICE, ResourceType} from '../employee/employee.definition';
import {LocalDate, LocalDateTime} from '@js-joda/core';
import {Availability} from '../availability/availability.definition';
import {Shift} from '../shift/shift.definition';
import {Patient, PATIENT_SERVICE} from '../patient/patient.definition';
import {POOL_SHIFT_SERVICE, PoolShift} from '../pool-shift/pool-shift.definition';
import {HealthInstitution} from '../health-institution/health-institution.definition';
import {Qualification, QUALIFICATION_SERVICE} from '../qualification/qualification.definition';
import {WorkType} from './work-type.definition';
import {KeyIssue} from '../key-issue/key-issue.definition';
import {AGENCY_SERVICE} from '../agency/agency.definition';


export enum BookingStatus {
  REQUEST = 'REQUEST',
  ACCEPTED = 'ACCEPTED',
  DENIED = 'DENIED',
  FULFILLED = 'FULFILLED',
  ILL = 'ILL',
  ILL_CHILD = 'ILL_CHILD',
  ACCIDENT = 'ACCIDENT',
  COMPENSATION = 'COMPENSATION',
  CANCELLED_BY_RESOURCE = 'CANCELLED_BY_RESOURCE',
  REQUEST_CANCELLED_BY_PLANNER = 'REQUEST_CANCELLED_BY_PLANNER',
  CANCELLED_BY_PLANNER = 'CANCELLED_BY_PLANNER',
  CANCELLED_BY_MANAGER = 'CANCELLED_BY_MANAGER',
  LAST_MINUTE_CANCELLATION = 'LAST_MINUTE_CANCELLATION',
}

export interface Booking extends Entity {
  bookingStatus?: BookingStatus;
  start: LocalDateTime;
  end: LocalDateTime;
  startBreak: LocalDateTime | null;
  endBreak: LocalDateTime | null;
  noBreak: boolean;
  planner: Relation<Employee>;
  workType?: WorkType;
  resourceType?: ResourceType;
  introductoryTrainingBy: string | null;
  comment: string | null;
  internalNote: string | null;
  resource?: Partial<Employee>;
  organisationUnit: Relation<OrganisationUnit>;
  basedOn?: Partial<Omit<Availability, 'relatedBookings'>>;
  patient?: Patient;
  caseNumber: string | null;
  room?: string;
  processSteps?: Omit<ProcessStep, 'booking'>[];
  isTimeDeviated?: boolean;
  isBreakDeviated?: boolean;
  invoiceNumber?: string;
  poolShift?: Relation<PoolShift>;
  healthInstitution?: Relation<HealthInstitution>;
  qualification?: Relation<Qualification>;
  shouldOpenStintOnCancellation?: boolean;
  email: string;
  mobilePhone: string;
  agency: string;
  keyIssue: Relation<KeyIssue>;
  duration: number;
  paidBreak: boolean;
  isLastMinuteCancellation: boolean
}

export interface ProcessStep extends Entity {
  title: string;
  class: string;
  completed: boolean;
  completedBy: Relation<Employee>; // TODO: its probably better to have a user here
  completedAt: LocalDateTime;
  completionComment: string;
  booking?: Relation<Booking>;
  active: boolean;
  outdated: boolean;
  role?: string;
}

export interface UpdateTimeStep extends ProcessStep {
  originalStart: LocalDateTime;
  originalEnd: LocalDateTime;
  committedStart: LocalDateTime;
  committedEnd: LocalDateTime
  originalBreakStart: LocalDateTime;
  committedBreakStart: LocalDateTime;
  originalBreakEnd: LocalDateTime;
  committedBreakEnd: LocalDateTime;
  originalWorkType: WorkType;
  committedWorkType: WorkType;
  originalOU: Relation<OrganisationUnit>
  committedOU: Relation<OrganisationUnit>
}

// tslint:disable-next-line:no-empty-interface
export interface WorkReceiptStep extends ProcessStep {
}


export interface FeedbackStep extends ProcessStep {
  committedFeedback: string;
  toBeInformed: string;
}

export interface InvoiceNumberStep extends ProcessStep {
  invoiceNumber: string;
  denied: boolean;
}

export interface BookingValue {
  organisationUnit: Relation<OrganisationUnit>;
  shift?: Partial<Shift>;
  startTime?: string;
  endTime?: string;
  comment: string | null;
  workType: WorkType;
  introductoryTrainingBy: string | null;
}

export const BOOKING_SERVICE: InjectionToken<BookingService> = new InjectionToken('BOOKING_SERVICE');

export const bookingDefinition: DomainDefinition<Booking> = {
  path: 'booking',
  service: BOOKING_SERVICE,
  properties: new Map<keyof Booking, PropertyDefinition>([
      ['resource', {
        type: 'belongs-to',
        service: RESOURCE_SERVICE,
        filter: FilterType.IN,
        sortable: true,
      }],
      ['email', {
        type: 'text',
        filter: FilterType.CONTAINS,
        sortable: false,
      }],
      ['mobilePhone', {
        type: 'text',
        filter: FilterType.CONTAINS,
        sortable: false,
      }],
      ['organisationUnit', {
        type: 'belongs-to',
        service: ORGANISATION_UNIT_SERVICE,
        filter: FilterType.IN,
        // sortable: true,
      }],
      ['planner', {
        type: 'belongs-to',
        service: PLANNER_SERVICE,
        filter: FilterType.IN,
        sortable: true,
      }],
      ['workType', {
        type: 'enum',
        cls: WorkType,
        prefix: 'WorkType',
        filter: FilterType.IN,
        // sortable: true,
      }],
      ['introductoryTrainingBy', {
        type: 'text',
        nullable: true,
        filter: FilterType.IN,
        sortable: true,
      }],
      ['start', {
        type: 'local-date-time',
        filter: FilterType.GREATER_OR_EQUALS,
        nullable: true,
        sortable: true,
        defaultFilterValue: LocalDate.now(),
      }],
      ['end', {
        type: 'local-date-time',
        filter: FilterType.LOWER_OR_EQUALS,
        nullable: true,
        sortable: true,
      }],
      ['startBreak', {
        type: 'local-date-time',
        filter: FilterType.GREATER_OR_EQUALS,
        nullable: true,
        sortable: true,
        defaultFilterValue: LocalDate.now(),
      }],
      ['endBreak', {
        type: 'local-date-time',
        filter: FilterType.LOWER_OR_EQUALS,
        nullable: true,
        sortable: true,
      }],
      ['comment', {
        type: 'text',
        nullable: true,
        filter: FilterType.CONTAINS,
        sortable: true,
      }],
      ['internalNote', {
        type: 'text',
        nullable: true,
        filter: FilterType.CONTAINS,
        sortable: true,
      }],
      ['patient', {
        type: 'belongs-to',
        service: PATIENT_SERVICE,
        nullable: true,
        sortable: true,
      }],
      ['bookingStatus', {
        type: 'enum',
        cls: BookingStatus,
        prefix: 'BookingStatus',
        sortable: true,
        filter: FilterType.IN,
        defaultFilterValue: [BookingStatus.ACCEPTED, BookingStatus.REQUEST],
      }],

      ['poolShift', {
        type: 'belongs-to',
        service: POOL_SHIFT_SERVICE,
        filter: FilterType.IN,
      }],
      ['caseNumber', {
        type: 'text',
        nullable: true,
        filter: FilterType.CONTAINS,
        sortable: true,
      }],
      ['room', {
        type: 'text',
      }],
      ['qualification', {
        type: 'belongs-to',
        service: QUALIFICATION_SERVICE,
        filter: FilterType.IN,
      }],
      ['resourceType', {
        type: 'enum',
        cls: ResourceType,
        prefix: 'ResourceType',
        filter: FilterType.IN,
        sortable: true,
      }],
      ['agency', {
        type: 'belongs-to',
        service: AGENCY_SERVICE,
        filter: FilterType.CONTAINS,
        sortable: true,
      }],
    ],
  ),
  formDefinitions: {
    planning: {
      fields: ['resource', 'organisationUnit', 'workType', 'start', 'end', 'comment', 'internalNote'],
    },
  },
  propertiesMapping: new Map<keyof Booking, Paths<Booking>>([
    ['poolShift', ['basedOn', 'shift']],
    ['qualification', ['resource', 'personalData', 'qualification']],
    ['resourceType', ['resource', 'resourceType']],
    ['room', ['patient', 'room']],
    ['caseNumber', ['patient', 'caseNumber']],
    ['email', ['resource', 'user', 'email']],
    ['mobilePhone', ['resource', 'personalData', 'mobilePhone']],
    ['agency', ['resource', 'agency']],
  ]),
  tableDefinitions: {
    default: {
      columns: ['resource', 'email', 'mobilePhone', 'qualification', 'organisationUnit', 'planner', 'workType', 'poolShift', 'caseNumber', 'room', 'start',
        'end', 'bookingStatus', 'comment'],
      inlineEditable: false,
      immutable: true,
      disableDetail: true,
      sortField: 'start',
      sortOrder: 1,
      filterStorable: true,
      downloadable: true,
      columnsConfigurable: true,
    },
    planner: {
      columns: ['resource', 'email', 'mobilePhone', 'qualification', 'organisationUnit', 'planner', 'workType', 'poolShift', 'caseNumber', 'room', 'resourceType', 'agency', 'start',
        'end', 'bookingStatus', 'comment', 'internalNote'],
      inlineEditable: false,
      immutable: true,
      disableDetail: true,
      sortField: 'end',
      sortOrder: 1,
      columnsConfigurable: true,
      filterStorable: true,
    },
    resource: {
      columns: ['resource', 'email', 'mobilePhone', 'qualification', 'organisationUnit', 'planner', 'workType', 'poolShift', 'caseNumber', 'room', 'start',
        'end', 'bookingStatus', 'comment'],
      inlineEditable: false,
      immutable: true,
      disableDetail: true,
      sortField: 'start',
      sortOrder: 1,
      columnsConfigurable: true,
      filterStorable: true,
    },
    agency: {
      columns: ['resource', 'email', 'mobilePhone', 'qualification', 'organisationUnit', 'planner', 'workType', 'poolShift', 'caseNumber', 'room', 'start',
        'end', 'bookingStatus', 'comment'],
      inlineEditable: false,
      immutable: true,
      disableDetail: true,
      sortField: 'start',
      sortOrder: 1,
      columnsConfigurable: true,
      filterStorable: true,
    },
  },
};
