import {InjectionToken, Injector} from '@angular/core';
import {CrudService, DomainDefinition, Entity, FilterType, PropertyDefinition, Relation} from '@ic/ng-crud-client';
import {User} from '../user/user.definition';
import {AgencyOfferRole} from '../../definitions';

export interface AgencyOfficer extends Entity {
  user: Relation<User>;
}

export interface Agency extends Entity {
  agencyName: string;
  landlinePhone: string;
  /** additional landline number as it might be different for weekends */
  weekendPhone: string;
  mobilePhone: string;
  callAsOf: string;
  callUntil: string;
  pool: string;
  /** This is the agencies' primary email address. Whenever this property changes the email of the agency officer
   * is changed to using a GORM event listener. */
  email: string;
  /** belongs to a default group of agencies that receive automatic offers */
  isDefault: boolean;
  stintOfferAtWeekendSms: boolean;
  /** Resource Agency will always receive a sms notification of a stint offer */
  stintOfferSms: boolean;
  officers: AgencyOfficer[];
  offerRole: string;
  enabled: boolean;
  receiveOutstandingWorkReceiptsMail: boolean;
}

export const AGENCY_SERVICE = new InjectionToken<CrudService<Agency>>('AGENCY_SERVICE');

export const agencyDefinition: DomainDefinition<Agency> = {
  path: 'agency',
  service: AGENCY_SERVICE,
  properties: new Map<keyof Agency, PropertyDefinition>([
    ['agencyName', {
      type: 'string',
      filter: FilterType.CONTAINS,
      sortable: true,
    }],
    ['landlinePhone', {
      type: 'string',
      filter: FilterType.CONTAINS,
    }],
    ['mobilePhone', {
      type: 'string',
      filter: FilterType.CONTAINS,
      nullable: true,
    }],
    ['weekendPhone', {
      type: 'string',
      filter: FilterType.CONTAINS,
      nullable: true,
    }],
    ['callAsOf', {
      type: 'string',
      nullable: true,
    }],
    ['callUntil', {
      type: 'string',
      nullable: true,
    }],
    ['email', {
      type: 'string',
      filter: FilterType.CONTAINS,
      sortable: true,
    }],
    ['isDefault', {
      type: 'boolean',
      filter: FilterType.IN,
    }],
    ['stintOfferAtWeekendSms', {
      type: 'boolean',
      filter: FilterType.IN,
    }],
    ['stintOfferSms', {
      type: 'boolean',
      filter: FilterType.IN,
    }],
    ['pool', {
      type: 'string',
    }],
    ['offerRole', {
      type: 'enum',
      cls: AgencyOfferRole,
      prefix: 'AgencyOfferRole',
      filter: FilterType.IN,
    }],
    ['receiveOutstandingWorkReceiptsMail', {
      type: 'boolean',
      filter: FilterType.IN,
    }],
    // TODO: add missing fields
  ]),
  formDefinitions: {
    default: {
      fields: ['agencyName', 'landlinePhone', 'mobilePhone', 'weekendPhone', 'callAsOf', 'callUntil', 'email', 'isDefault', 'stintOfferAtWeekendSms', 'stintOfferSms', 'receiveOutstandingWorkReceiptsMail', 'offerRole'],
    },
  },
  tableDefinitions: {
    default: {
      columns: ['agencyName', 'landlinePhone', 'mobilePhone', 'weekendPhone', 'callAsOf', 'callUntil', 'email', 'isDefault', 'stintOfferAtWeekendSms', 'stintOfferSms', 'receiveOutstandingWorkReceiptsMail', 'offerRole'],
      inlineEditable: false,
      immutable: false,
      disableDetail: false,
      sortField: 'agencyName',
      sortOrder: 1,
      filters: {},
      tableTitle: 'Agenturen',
      filterStorable: true,
    },
  },
};

export function agencyCrudServiceFactory(httpClient, injector: Injector) {
  return new CrudService(agencyDefinition, httpClient, injector);
}

