import {InjectionToken, Injector} from '@angular/core';
import {CachedCrudService, DomainDefinition, Entity, FilterType, PropertyDefinition} from '@ic/ng-crud-client';
import {RenewalInterval} from './renewal-interval.definition';

// tslint:disable-next-line:no-empty-interface
export interface Course extends Entity {
  courseName: string;
  renewalInterval: RenewalInterval;
}

export const COURSE_SERVICE = new InjectionToken<CachedCrudService<Course>>('COURSE_SERVICE');

export const courseDefinition: DomainDefinition<Course> = {
  path: 'course',
  service: COURSE_SERVICE,
  properties: new Map<keyof Course, PropertyDefinition>([
    ['courseName', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
    ['renewalInterval', {
      type: 'enum',
      cls: RenewalInterval,
      prefix: 'RenewalInterval',
      filter: FilterType.IN,
      sortable: true,
    }],
  ]),
  formDefinitions: {
    default: {
      fields: ['courseName', 'renewalInterval'],
    },
  },
  tableDefinitions: {
    default: {
      columns: ['courseName', 'renewalInterval'],
      inlineEditable: true,
      immutable: false,
      disableDetail: false,
      sortField: 'courseName' +
        '',
      sortOrder: 1,
      filters: {},
      filterStorable: true,
    },
  },
};

export function courseCrudServiceFactory(httpClient, injector: Injector) {
  return new CachedCrudService(courseDefinition, httpClient, injector);
}
