<div class="mb-3">
  <p-button type="button" icon="pi pi-chevron-left" (onClick)="prev()" [disabled]="isFirstPage()" styleClass="p-button-text"></p-button>
  <p-button type="button" icon="pi pi-refresh" (onClick)="reset()" styleClass="p-button-text"></p-button>
  <p-button type="button" icon="pi pi-chevron-right" (onClick)="next()" [disabled]="isLastPage()" styleClass="p-button-text"></p-button>
</div>
<div class="table-tools">
  <div *ngIf="tableDef.downloadable">
    <ic-download-list-button [filter]="loadCommand"></ic-download-list-button>
  </div>

  <div *ngIf="tableDef.globalFilter">
    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
    <input type="text" pInputText size="50" placeholder="{{ tableDef.globalFilter }}" [(ngModel)]="globalFilter"
           (input)="table.filterGlobal($event.target.value, 'contains')" style="width:auto">
  </div>
  <button type="button" pButton
          icon="pi pi-filter-slash"
          [class]="this.filterActive? 'p-button-warning' : 'p-button-secondary'"
          (click)="clearFilter()">
  </button>
  <ic-table-settings *ngIf="tableDef.columnsConfigurable"
                     [path]="domainDef.path"
                     [options]="tableDef.columns"
                     [selected]="visibleColumns"
                     (configChange)="onTableSettingsChange($event)"></ic-table-settings>
</div>
<p-table #table
         [columns]=cols [value]="records"
         [paginator]="true"
         [rows]="rows?rows:10"
         [(first)]="first"
         [pageLinks]="5"
         [rowsPerPageOptions]="[10,20,50,100]"
         paginatorDropdownAppendTo="body"
         [lazy]="true"
         [totalRecords]="totalRecords"
         [loading]="loading"
         [responsive]="true"
         [autoLayout]="true"
         [sortField]="tableDef.sortField"
         [sortOrder]="tableDef.sortOrder"
         [filters]="defaultFilter"
         (onLazyLoad)="loadData($event)"
         (onEditInit)="onEditInit($event.data)"
         (onEditComplete)="onEditComplete($event.data)"
         [(selection)]="selectedItems"
         (selectionChange)="onSelectionChange($event)"
         dataKey="id">
  <!--         [(first)]="first"-->

  <!--  <ng-template pTemplate="caption"></ng-template>-->

  <ng-template pTemplate="header" let-columns>
    <!-- Column title -->

    <tr>
      <th style="width: 30px" *ngIf="!tableDef.disableDetail"></th>

      <ng-container *ngFor="let col of columns">
        <th *ngIf="col.propertyDefinition.sortable; else not_sortable" [pSortableColumn]="col.qualifiedName">
          <div class="header sortable-header">
            <span>{{ domainDef.path + '.' + col.header | translate }}</span>
            <p-sortIcon [field]="col.qualifiedName"></p-sortIcon>
          </div>
        </th>
        <ng-template #not_sortable>
          <th *ngIf="col.propertyDefinition.type === 'bulk'; else default_not_sortable">
            <div class="header header-bulk-cell">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              <ng-container *ngIf="col.colConfig && col.colConfig.headerMenu$">
                <button type="button" pButton icon="pi pi-ellipsis-v" (click)="bulkMenu.toggle($event)"
                        class="p-button-text"></button>
                <p-menu #bulkMenu [popup]="true" [model]="col.colConfig.headerMenu$ | async" appendTo="body"></p-menu>
              </ng-container>
            </div>
          </th>
        </ng-template>
        <ng-template #default_not_sortable>
          <th>
            <div class="header not-sortable-header">
              <span>{{ domainDef.path + '.' + col.header | translate }}</span>
            </div>
          </th>
        </ng-template>
      </ng-container>


    </tr>

    <!-- Column filter -->
    <tr>
      <th *ngIf="!tableDef.disableDetail"></th>

      <th *ngFor="let col of columns">

        <div class="p-inputgroup" *ngIf="['contains'].indexOf(col.propertyDefinition.filter) > -1">
          <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
          <input pInputText type="text"
                 [(ngModel)]="col.selected"
                 (input)="filter($event.target.value, col)">
        </div>

        <p-dropdown *ngIf="col.propertyDefinition.filter == 'equals'"
                    placeholder="- Auswählen -"
                    showClear="true"
                    [options]="col.options"
                    [style]="{'width':'100%'}"
                    [(ngModel)]="col.selected"
                    appendTo="body"
                    (onChange)="filter($event.value, col)"></p-dropdown>

        <p-multiSelect
          *ngIf="col.propertyDefinition.filter == 'in'"
          [options]="col.options"
          [(ngModel)]="col.selected"
          [disabled]="!col.options"
          defaultLabel="- Auswählen -"
          [maxSelectedLabels]="maxSelectedLabels"
          selectedItemsLabel="{{ 'crud.domain.selected' | translate }}"
          [filter]="true"
          appendTo="body"
          (onChange)="filter($event.value, col)"></p-multiSelect>

        <div class="p-inputgroup"
             *ngIf="['date', 'local-date', 'local-date-time'].includes(col.propertyDefinition.type) && ['lte', 'gte', 'between'].indexOf(col.propertyDefinition.filter) > -1">
            <span *ngIf="['lte', 'gte'].indexOf(col.propertyDefinition.filter) > -1"
                  class="p-inputgroup-addon">{{ col.propertyDefinition.filter == 'gte' ? '&ge;' : '&lt;' }}</span>
          <span *ngIf="col.propertyDefinition.filter === 'between'" class="p-inputgroup-addon"><i
            class="pi pi-calendar"></i></span>
          <p-calendar [icDateType]="col.propertyDefinition.type"
                      [showIcon]="false"
                      [showTime]="col.propertyDefinition.type === 'local-date-time' && col.propertyDefinition.filter !== 'between'"
                      [selectionMode]="col.propertyDefinition.filter == 'between' ? 'range' : 'single'"
                      dateFormat="dd.mm.yy"
                      appendTo="body"
                      (onInput)="filter(col.selected, col)"
                      (onSelect)="filter(col.selected, col)"
                      [(ngModel)]="col.selected"
                      [readonlyInput]="true"
                      [showButtonBar]="true"></p-calendar>
        </div>
      </th>

    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-columns="columns">
    <tr [class.highlighted]="item.highlighted">
      <td *ngIf="!tableDef.disableDetail" class="action-cell"><a (click)="display(item)"
                                                                 class="pi pi-arrow-circle-right"
                                                                 [class.pi-arrow-circle-right]="tableDef.inlineEditable || tableDef.immutable"
                                                                 [class.pi-pencil]="!tableDef.inlineEditable && !tableDef.immutable">
      </a></td>

      <ng-container *ngFor="let col of columns">

        <!-- Editable Cell -->
        <td *ngIf="!col.propertyDefinition.readonly && tableDef.inlineEditable  && !col.propertyDefinition.denyEdit; else not_editable"
            [pEditableColumn]="item">
          <p-cellEditor>
            <!-- Cell being edited -->
            <ng-template pTemplate="input">
              <ng-container [ngSwitch]="true">
                <p-calendar *ngSwitchCase="['date', 'local-date'].includes(col.propertyDefinition.type)"
                            [showIcon]="false"
                            dataType="string"
                            dateFormat="dd.mm.yy"
                            appendTo="body"
                            [locale]="calendarLocale[translate.currentLang]"
                            (onSelect)="onEditComplete(item)"
                            [(ngModel)]="item[col.field]"
                            [readonlyInput]="true"
                            [showButtonBar]="true"></p-calendar>
                <p-calendar *ngSwitchCase="['local-date-time'].includes(col.propertyDefinition.type)"
                            [showIcon]="false"
                            dataType="string"
                            dateFormat="dd.mm.yy HH:mm"
                            appendTo="body"
                            [locale]="calendarLocale[translate.currentLang]"
                            (onSelect)="onEditComplete(item)"
                            [(ngModel)]="item[col.field]"
                            [readonlyInput]="true"
                            [showButtonBar]="true"></p-calendar>
                <p-checkbox *ngSwitchCase="['boolean'].includes(col.propertyDefinition.type)"
                            (onChange)="onEditComplete(item)"
                            [(ngModel)]="item[col.field]"
                            binary="true"></p-checkbox>
                <p-dropdown
                  *ngSwitchCase="'enum' === col.propertyDefinition.type"
                  [options]="col.options" appendTo="body"
                  (onChange)="onEditComplete(item)"
                  [(ngModel)]="item[col.field]"></p-dropdown>
                <!-- <p-multiSelect *ngSwitchCase="'has-many' === col.propertyDefinition.type"
                               [options]="col.options"
                               [(ngModel)]="item[col.field]"
                               selectedItemsLabel="{{ 'crud.domain.selected' | translate }}"
                               [filter]="false"
                               dataKey="id"
                               (onChange)="onEditComplete(item)"></p-multiSelect>-->
                <p-autoComplete
                  *ngSwitchCase="'in' === col.propertyDefinition.filter && 'belongs-to' === col.propertyDefinition.type"
                  [(ngModel)]="item[col.field]"
                  [suggestions]="col.options"
                  [dropdown]="true"
                  (completeMethod)="filterAssociation(col, $event)"
                  (onSelect)="onEditComplete(item)"
                  styleClass="wid100"
                  appendTo="body"
                  [minLength]="1"
                  placeholder="{{ 'crud.button.search' | translate }}"
                  [multiple]="false"
                  dataKey="id"
                  field="label"
                  [forceSelection]="true">
                </p-autoComplete>

                <p-autoComplete *ngSwitchCase="'has-many' === col.propertyDefinition.type"
                                [(ngModel)]="item[col.field]"
                                [suggestions]="col.options"
                                [dropdown]="true"
                                (completeMethod)="filterAssociation(col, $event)"
                                (onSelect)="onEditComplete(item)"
                                styleClass="wid100"
                                [minLength]="1"
                                placeholder="{{ 'crud.button.search' | translate }}"
                                [multiple]="true"
                                dataKey="id"
                                field="label">
                </p-autoComplete>

                <p-inputNumber *ngSwitchCase="'number' === col.propertyDefinition.type"
                               [(ngModel)]="item[col.field]"
                               [useGrouping]="true"
                               [min]="col.propertyDefinition['min']"
                               [max]="col.propertyDefinition['max']"
                               [minFractionDigits]="col.propertyDefinition['minFractionDigits']"
                               [maxFractionDigits]="col.propertyDefinition['maxFractionDigits']"
                               locale="de-CH"
                ></p-inputNumber>

                <input *ngSwitchDefault
                       pInputText type="text"
                       [style]="{'width':'100%'}"
                       [(ngModel)]="item[col.field]"/>
                <a *ngIf="['has-many', 'belongs-to'].includes(col.propertyDefinition.type)
                      && tableDef.inlineEditable
                      && !col.propertyDefinition.denyAssociationCreation"
                   class="pi pi-plus"
                   (click)="addAssociation(item, col.field)"></a>
              </ng-container>
            </ng-template>
            <!-- Cell not being edited -->
            <ng-template pTemplate="output">
              <!--                            <span [pTooltip]="item[col.field] | stringify:col.propertyDefinition "-->
              <!--                                  tooltipPosition="top">{{ item[col.field] | stringify:col.propertyDefinition }}</span>-->
              <ng-template [ngIfElse]="noSpecificTemplate" [ngIf]="cellTemplateByColName[col.field]">
                <ng-template [ngTemplateOutletContext]="{$implicit: item, colDef: col}"
                             [ngTemplateOutlet]="cellTemplateByColName[col.field].template"></ng-template>
              </ng-template>
              <ng-template #noSpecificTemplate [ngSwitch]="col.propertyDefinition.type">
                <ng-container *ngSwitchCase="'bulk'">
                  <p-tableCheckbox [value]="item"></p-tableCheckbox>
                </ng-container>
                <ng-container *ngSwitchCase="'action'">
                  <div class="action-container">
                    <ng-container *ngFor="let colAction of getActions(col.field)"><!-- TODO: check performance -->
                      <ic-action-cell-renderer *icAuthIfHasAnyRole="colAction.roles?.join(',')" [colAction]="colAction"
                                               [item]="item"></ic-action-cell-renderer>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                <span [pTooltip]="item[col.field] | stringify:col.propertyDefinition "
                      tooltipPosition="top">{{ item[col.field] | stringify:col.propertyDefinition }}</span>
                </ng-container>
              </ng-template>
            </ng-template>
          </p-cellEditor>
        </td>

        <!-- Not editable cell -->
        <ng-template #not_editable>
          <td>
            <ng-template [ngIf]="cellTemplateByColName[col.field]" [ngIfElse]="noSpecificTemplate">
              <ng-template [ngTemplateOutlet]="cellTemplateByColName[col.field].template"
                           [ngTemplateOutletContext]="{$implicit: item, colDef: col}"></ng-template>
            </ng-template>
            <ng-template #noSpecificTemplate [ngSwitch]="col.propertyDefinition.type">
              <ng-container *ngSwitchCase="'bulk'">
                <p-tableCheckbox [value]="item"></p-tableCheckbox>
              </ng-container>
              <ng-container *ngSwitchCase="'action'">
                <div class="action-container">
                  <ng-container *ngFor="let colAction of getActions(col.field)"><!-- TODO: check performance -->
                    <ic-action-cell-renderer *icAuthIfHasAnyRole="colAction.roles?.join(',')" [item]="item"
                                             [colAction]="colAction"></ic-action-cell-renderer>
                  </ng-container>
                </div>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <span [pTooltip]="item[col.field] | stringify:col.propertyDefinition "
                      tooltipPosition="top">{{ item[col.field] | stringify:col.propertyDefinition }}</span>
              </ng-container>
            </ng-template>
          </td>
        </ng-template>
      </ng-container>

    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft">
    <p-button *ngIf="!tableDef.immutable && !tableDef.denyEntityCreation" icon="pi pi-plus" styleClass="p-button-text"
              label="{{ 'crud.domain.add' | translate:addingItemTitleParams }}"
              (click)="append()"></p-button>
  </ng-template>
  <ng-template pTemplate="paginatorright"
               let-state>{{ state.totalRecords }} {{  domainDef.tableDefinitions[this.useTableDef]?.tableEntityName ?
    domainDef.tableDefinitions[this.useTableDef].tableEntityName :
    domainDef.path + '.domain.plural' | translate }} total&nbsp;&nbsp;&nbsp;
  </ng-template>
</p-table>

<!-- add dialog -->
<p-dialog
  header="{{ (addingForm?.row ? 'crud.domain.edit' : 'crud.domain.add') | translate:addingForm?.dialogParams }}"
  [(visible)]="addingForm"
  [responsive]="true"
  [maximizable]="true"
  [modal]="true">
  <ic-entity-form *ngIf="addingForm"
                  [domainDef]="addingForm?.domainDef"
                  [entity]="addingForm?.row"
                  [formDefinitionName]="addingForm?.row ? 'edit' : 'create'"
                  (save)="onEditComplete($event)">
  </ic-entity-form>
</p-dialog>


<!-- show dialog -->
<p-dialog header="{{ domainDef.path + '.domain.name' | translate }}"
          [(visible)]="displayedItem"
          [responsive]="true"
          [modal]="true"
          [resizable]="true"
          [style]="{minWidth: '40em'}"
          appendTo="body"
          maximizable="true"
>
  <div class="grid" *ngFor="let key of propertyKeys">
    <label class="col-6 lg:col-4"><strong>{{ domainDef.path + '.' + key | translate }}</strong></label>


    <div *ngIf="displayedItem" class="col-6 lg:col-8 p-fluid">{{ displayedItem[key] |
      stringify:domainDef.properties.get(key) }}
    </div>
  </div>
</p-dialog>
