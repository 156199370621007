import {Injectable} from '@angular/core';
import {Availability} from '../../../pool-crud/availability/availability.definition';
import {Booking} from '../../../pool-crud/booking/booking.definition';
import {Change, EntryType} from '../../planning.definitions';
import {PoolShift} from '../../../pool-crud/pool-shift/pool-shift.definition';
import {TIME_DE} from '../../../util';
import {Stint} from '../../../pool-crud/stint/stint.definition';
import {TranslateService} from '@ngx-translate/core';
import {StintService} from '../../../pool-crud/stint/stint.service';
import {Appointment} from '../../../pool-crud/appointment/appointment.definition';
import {AppointmentService} from '../../../pool-crud/appointment/appointment.service';

/**
 * Add details to changes
 */
@Injectable()
export class ChangeDetailsService {
  constructor(private translate: TranslateService) {
  }

  /**
   * Compares a change with the original entry and adds changeDetails to the change
   */
  addChangeDetails(origin: Change, target: Change, poolShifts: Map<number, PoolShift>): void {
    target.details = [];
    if ([EntryType.BOOKING, EntryType.STINT].indexOf(origin.entryType) > -1) {
      const oldBooking = origin.entry as Booking;
      const newBooking = target.entry as Booking;
      if (oldBooking.organisationUnit.id !== newBooking.organisationUnit.id) {
        target.details.push({
          property: 'Organisationseinheit',
          oldValue: oldBooking.organisationUnit.label,
          newValue: newBooking.organisationUnit.label,
        });
      }
      if (!oldBooking.start.equals(newBooking.start)) {
        target.details.push({
          property: 'Beginn',
          oldValue: oldBooking.start.format(TIME_DE),
          newValue: newBooking.start.format(TIME_DE),
        });
      }
      if (!oldBooking.end.equals(newBooking.end)) {
        target.details.push({
          property: 'Ende',
          oldValue: oldBooking.end.format(TIME_DE),
          newValue: newBooking.end.format(TIME_DE),
        });
      }
      if (oldBooking.comment !== newBooking.comment) {
        target.details.push({
          property: 'Kommentar',
          oldValue: oldBooking.comment,
          newValue: newBooking.comment,
        });
      }
    }
    if (origin.entryType === EntryType.BOOKING) {
      const oldBooking = origin.entry as Booking;
      const newBooking = target.entry as Booking;
      if (oldBooking.basedOn.shift.id !== newBooking.basedOn.shift.id) {
        target.details.push({
          property: 'Schicht',
          oldValue: '' + poolShifts.get(oldBooking.basedOn.shift.id).shiftNameShort,
          newValue: '' + poolShifts.get(newBooking.basedOn.shift.id).shiftNameShort,
        });
      }
    }
    if (origin.entryType === EntryType.STINT) {
      const oldStint = origin.entry as Stint;
      const newStint = target.entry as Stint;
      if (!!oldStint.shift !== !!newStint.shift ||
        oldStint.shift && newStint.shift && oldStint.shift.poolShift.id !== newStint.shift.poolShift.id) {
        target.details.push({
          property: 'Schicht',
          oldValue: '' + (oldStint.shift ? poolShifts.get(oldStint.shift.poolShift.id).shiftNameShort : ''),
          newValue: '' + (newStint.shift ? poolShifts.get(newStint.shift.poolShift.id).shiftNameShort : ''),
        });
      }
      if (oldStint.sex !== newStint.sex) {
        target.details.push({
          property: 'Geschlecht',
          oldValue: '' + this.translate.instant(`Sex.${(oldStint.sex || 'NONE')}`),
          newValue: '' + this.translate.instant(`Sex.${(newStint.sex || 'NONE')}`),
        });
      }
      if (oldStint.alreadyWorkedInOrganisationUnit !== newStint.alreadyWorkedInOrganisationUnit) {
        target.details.push({
          property: 'hat in Organisationseinheit gearbeitet',
          oldValue: '' + (oldStint.alreadyWorkedInOrganisationUnit ? 'ja' : 'nein'),
          newValue: '' + (newStint.alreadyWorkedInOrganisationUnit ? 'ja' : 'nein'),
        });
      }
      if (oldStint.alreadyWorkedInHealthInstitution !== newStint.alreadyWorkedInHealthInstitution) {
        target.details.push({
          property: 'hat in Gesundheitseinrichtung gearbeitet',
          oldValue: '' + (oldStint.alreadyWorkedInHealthInstitution ? 'ja' : 'nein'),
          newValue: '' + (newStint.alreadyWorkedInHealthInstitution ? 'ja' : 'nein'),
        });
      }
      if (!StintService.equalQualifications(oldStint, newStint)) {
        target.details.push({
          property: 'Qualifikationen',
          oldValue: '' + (oldStint.qualifications.length ? oldStint.qualifications.map((q) => q.label).join(', ') : 'keine'),
          newValue: '' + (newStint.qualifications.length ? newStint.qualifications.map((q) => q.label).join(', ') : 'keine'),
        });
      }
    }
    if (origin.entryType === EntryType.AVAILABILITY) {
      const oldAvailability = origin.entry as Availability;
      const newAvailability = target.entry as Availability;
      if (oldAvailability.shift.id !== newAvailability.shift.id) {
        target.details.push({
          property: 'Schicht',
          oldValue: '' + poolShifts.get(oldAvailability.shift.id).shiftNameShort,
          newValue: '' + poolShifts.get(newAvailability.shift.id).shiftNameShort,
        });
      }
    }

  }


  /**
   * Compares a change with the original appointment and adds changeDetails to the change
   */
  addAppointmentDetails(oldAppointment: Appointment, target: Change): void {
    const newAppointment = target.entry as Appointment;
    target.details = [];
    if (oldAppointment.title !== newAppointment.title) {
      target.details.push({
        property: 'Titel',
        oldValue: oldAppointment.title,
        newValue: newAppointment.title,
      });
    }
    if ((!!oldAppointment.start !== !!newAppointment.start) ||
      oldAppointment.start && newAppointment.start && !oldAppointment.start.equals(newAppointment.start)) {
      target.details.push({
        property: 'Beginn',
        oldValue: oldAppointment.start && oldAppointment.start.format(TIME_DE),
        newValue: newAppointment.start && newAppointment.start.format(TIME_DE),
      });
    }
    if ((!!oldAppointment.end !== !!newAppointment.end) ||
      oldAppointment.end && newAppointment.end && !oldAppointment.end.equals(newAppointment.end)) {
      target.details.push({
        property: 'Ende',
        oldValue: oldAppointment.end && oldAppointment.end.format(TIME_DE),
        newValue: newAppointment.end && newAppointment.end.format(TIME_DE),
      });
    }
    if ((!!oldAppointment.description || !!newAppointment.description) && oldAppointment.description !== newAppointment.description) {
      target.details.push({
        property: 'Beschreibung',
        oldValue: oldAppointment.description,
        newValue: newAppointment.description,
      });
    }
    if (!AppointmentService.equalParticipants(oldAppointment, newAppointment)) {
      const hasOldParticipants = oldAppointment.participants && oldAppointment.participants.length;
      const hasNewParticipants = newAppointment.participants && newAppointment.participants.length;
      target.details.push({
        property: 'Teilnehmer',
        oldValue: '' + (oldAppointment.participants && oldAppointment.participants.length ?
          oldAppointment.participants.map((q) => q.label).join(', ') : ''),
        newValue: '' + (newAppointment.participants && newAppointment.participants.length ?
          newAppointment.participants.map((q) => q.label).join(', ') : 'keine'),
      });
    }
    if (oldAppointment.place !== newAppointment.place) {
      target.details.push({
        property: 'Ort',
        oldValue: oldAppointment.place,
        newValue: newAppointment.place,
      });
    }
    if (!!oldAppointment.course !== !!newAppointment.course ||
      oldAppointment.course && newAppointment.course && oldAppointment.course.id !== newAppointment.course.id) {
      target.details.push({
        property: 'Kurs',
        oldValue: oldAppointment.course && oldAppointment.course.label,
        newValue: newAppointment.course && newAppointment.course.label,
      });
    }
    if (!!oldAppointment.responsible !== !!newAppointment.responsible ||
      oldAppointment.responsible && newAppointment.responsible && oldAppointment.responsible.id !== newAppointment.responsible.id) {
      target.details.push({
        property: 'Verantwortlich',
        oldValue: oldAppointment.responsible && oldAppointment.responsible.label,
        newValue: newAppointment.responsible && newAppointment.responsible.label,
      });
    }
  }

}
