import {Injectable, Injector} from '@angular/core';
import {CrudService} from '@ic/ng-crud-client';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ExternalResource, externalResourceDefinition} from './external-resource.definition';
import {Observable, of} from "rxjs";
import {ResourceType} from '../employee/employee.definition';


@Injectable({
  providedIn: 'root',
})
export class ExternalResourceService extends CrudService<ExternalResource> {

  constructor(http: HttpClient, injector: Injector) {
    super(externalResourceDefinition, http, injector);
  }

  /* Workaround to make entity-form.component work */
  getNewInstance(): Observable<ExternalResource> {
    return of({
      id: null,
      label: '',
      firstName: '',
      lastName: '',
      mobilePhone: '',
      dateOfBirth: null,
      resourceType: ResourceType.EXTERNAL,
      username: '',
      email: '',
      sex: null,
      agencyId: null, // optional
      employeeNumber: '',
      qualificationId: null,
      qualification: null,
      agency: null,
      personalData: null,
      employeeData: null,
      user: null,
      profileStatus: null,
    })
  }

  save(entity: ExternalResource, format: string = 'json'): Observable<ExternalResource> {
      this.convertDateToStrings(entity);

    if (entity.id) {
      return this.http.put<ExternalResource>(`${this.apiBaseUrl}${this.domainDefinition.path}/updateExternal/${entity.id}.${format}`,
        entity, {
          headers: new HttpHeaders({'Content-Type': 'application/json'}),
          withCredentials: true
        });
    } else {
      return this.http.post<ExternalResource>(`${this.apiBaseUrl}${this.domainDefinition.path}/saveExternal`, entity, {
        headers: new HttpHeaders({'Content-Type': 'application/json'}),
        withCredentials: true
      });
    }


  }
}
