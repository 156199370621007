import {Pipe, PipeTransform} from '@angular/core';
import {DeliveryState} from "./definitions";


const STATE_TRANSLATION: Map<DeliveryState, string> = new Map<DeliveryState, string>([
  [DeliveryState.DELIVERED, 'zugestellt'],
  [DeliveryState.DROPPED, 'herausgefiltert'],
  [DeliveryState.DELAYED, 'in Warteschlaufe'],
  [DeliveryState.DELIVERY_FAILED, 'fehlgeschlagen'],
])


// Note: This module has no i18n setup and this is the only place where translation is required. So this is is a simple translation pipe
// for the DeliveryState enum.
// once translation is required for other parts this pipe can be removed and its usages refactored in a way that bases on translation
@Pipe({
  name: 'deliveryStateTranslation',
})
export class DeliveryStateTranslationPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return STATE_TRANSLATION.get(value);
  }

}
