import {Component} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'pool-send-token-link-dialog',
  templateUrl: './send-token-link-dialog.component.html',
  styleUrls: ['./send-token-link-dialog.component.scss'],
})
export class SendTokenLinkDialogComponent {
  form: UntypedFormGroup;

  constructor(private dialogRef: DynamicDialogRef, private config: DynamicDialogConfig, fb: UntypedFormBuilder) {
    this.form = fb.group({
      message: [],
    });
  }

  complete() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.dialogRef.close(this.form.value);
  }
}
