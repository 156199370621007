import {Component, Inject, OnInit} from '@angular/core';
import {CRUD_SERVICE, CrudService} from '../crud.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {Entity} from '../definitions';

@Component({
  templateUrl: './create.component.html',
})
export class CreateComponent<T extends Entity> implements OnInit {
  title: string;
  protected submission: Observable<T>;

  constructor(@Inject(CRUD_SERVICE) protected crudService: CrudService<T>,
              protected router: Router, protected activatedRoute: ActivatedRoute, protected translate: TranslateService) {
  }

  ngOnInit() {
    this.translate.get(this.crudService.getDomainDefinition().path + '.domain.name').subscribe(domainName => {
      // translations should be ready by now -> use of instant should be possible
      this.title = this.translate.instant('crud.domain.add', {item: domainName});
    });
  }

  /**
   * Creates a new instance whenever the user submits the form.
   * Double submission is prevented.
   * @param instance
   */
  onCreate(instance: T) {
    if (this.submission) {
      return;
    }
    this.submission = this.crudService.save(instance);
    this.submission.subscribe(this.afterSave, () => {
      this.submission = null;
    });
  }

  afterSave = (instance: T) => {
    this.router.navigate(['../list'], {relativeTo: this.activatedRoute});
  }

}
