import {BrowserModule} from '@angular/platform-browser';
import {Injector, LOCALE_ID, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {NavigationModule} from './navigation/navigation.module';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {NgCrudClientModule} from '@ic/ng-crud-client';
import {environment} from '../environments/environment';
import {POOL_SHIFT_SERVICE, poolShiftCrudServiceFactory} from './pool-crud/pool-shift/pool-shift.definition';
import {AVAILABILITY_SERVICE, availabilityCrudServiceFactory} from './pool-crud/availability/availability.definition';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {QUALIFICATION_SERVICE,} from './pool-crud/qualification/qualification.definition';
import {BOOKING_SERVICE} from './pool-crud/booking/booking.definition';
import {BookingService} from './pool-crud/booking/booking.service';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SHIFT_SERVICE} from './pool-crud/shift/shift.definition';
import {ORGANISATION_UNIT_SERVICE} from './pool-crud/organisation-unit/organisation-unit.definition';
import {ShiftService} from './pool-crud/shift/shift.service';
import {AppointmentService} from './pool-crud/appointment/appointment.service';
import {OrganisationUnitService} from './pool-crud/organisation-unit/organisation-unit.service';
import {
  ASSIGNMENT_SERVICE,
  EMPLOYEE_SERVICE,
  PLANNER_SERVICE,
  plannerCrudServiceFactory,
  RESOURCE_SERVICE,
  resourceCrudServiceFactory,
} from './pool-crud/employee/employee.definition';
import {APPOINTMENT_SERVICE} from './pool-crud/appointment/appointment.definition';
import {COURSE_SERVICE, courseCrudServiceFactory} from './pool-crud/course/course.definition';
import {AppRoutingModule} from './app-routing.module';
import {AngularJSModule} from './angular-js/angular-js.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {UserNotificationModule} from '@ic/user-notification';
import {ErrorHandlingModule} from './error-handling/error-handling.module';
import {ToastModule} from 'primeng/toast';
import {AssignmentService} from './pool-crud/assignment/assignment.service';
import {LandingPageModule} from './landing-page/landing-page.module';
import {CookieAuthModule} from '@ic/auth';
import {PATIENT_SERVICE, patientCrudServiceFactory} from './pool-crud/patient/patient.definition';
import {STINT_SERVICE} from './pool-crud/stint/stint.definition';
import {StintService} from './pool-crud/stint/stint.service';
import {AGENCY_SERVICE, agencyCrudServiceFactory} from './pool-crud/agency/agency.definition';
import {TableModule} from 'primeng/table';
import {ExternalResourceService} from './pool-crud/external-resource/external-resource.service';
import {EXTERNAL_RESOURCE_SERVICE} from './pool-crud/external-resource/external-resource.definition';
import localeDE from '@angular/common/locales/de-CH'
import {registerLocaleData} from '@angular/common';
import {
  EMPLOYEE_COMMENT_SERVICE,
  employeeCommentServiceFactory
} from './pool-crud/employee-comment/employee-comment.definition';
import {DialogService} from 'primeng/dynamicdialog';
import {
  HEALTH_INSTITUTION_SERVICE,
  healthInstitutionCrudServiceFactory,
} from './pool-crud/health-institution/health-institution.definition';
import {EMPLOYEE_CONTRACT_SERVICE} from './pool-crud/contract/employee-contract.definition';
import {EmployeeContractService} from './pool-crud/contract/employee-contract.service';
import {STINT_OFFER_SERVICE} from './pool-crud/stint-offer/stint-offer.definition';
import {StintOfferService} from './pool-crud/stint-offer/stint-offer.service';
import {PUBLIC_HOLIDAY_SERVICE} from './pool-crud/public-holiday/public-holiday.definition';
import {PublicHolidayService} from './pool-crud/public-holiday/public-holiday.service';
import {PrimeNGConfig} from 'primeng/api';
import {SYNC_EXTERNAL_SYSTEM_SERVICE} from './pool-crud/sync-external-system/sync-external-system.definition';
import {SyncExternalSystemService} from './pool-crud/sync-external-system/sync-external-system.service';
import {USER_SERVICE} from './pool-crud/user/user.definition';
import {UserService} from './pool-crud/user/user.service';
import {QualificationService} from './pool-crud/qualification/qualification.service';
import {ApplicationStatusModule} from './application-status/application-status.module';
import {ReadOnlyInterceptor} from './application-status/read-only.interceptor';
import {
  ACTIVITY_RULE_CONFIGURATION_SERVICE,
} from './pool-crud/activity-rule-configuration/activity-rule-configuration.definition';
import {
  ActivityRuleConfigurationService,
} from './pool-crud/activity-rule-configuration/activity-rule-configuration.service';
import {EmployeeService} from './pool-crud/employee/employee.service';
import {TrainingService} from './pool-crud/training/training.service';
import {TRAINING_SERVICE} from './pool-crud/training/training.definititon';
import {AgencyService} from './pool-crud/agency/agency.service';
import {COUNTRY_SERVICE, countryCrudServiceFactory} from './pool-crud/country/country.definition';
import {MEETING_SERVICE} from './pool-crud/meeting/meeting.definititon';
import {MeetingService} from './pool-crud/meeting/meeting.service';
import {EducationService} from './pool-crud/education/education.service';
import {EDUCATION_SERVICE} from './pool-crud/education/education.definition';
import {UpgradeTrainingCourseService} from './pool-crud/upgrade-training-course/upgrade-training-course.service';
import {UPGRADE_TRAINING_COURSE_SERVICE} from './pool-crud/upgrade-training-course/upgrade-training-course.definition';
import {FEEDBACK_TYPE_SERVICE} from './pool-crud/feedback-type/feedback-type.definition';
import {FeedbackTypeService} from './pool-crud/feedback-type/feedback-type.service';
import {SHARED_FILE_TAG_SERVICE} from './pool-crud/shared-file-tag/shared-file-tag.definition';
import {SharedFileTagService} from './pool-crud/shared-file-tag/shared-file-tag.service';
import {CourseService} from './pool-crud/course/course.service';
import {AbsenceService} from './pool-crud/absence/absence.service';
import {ABSENCE_SERVICE} from './pool-crud/absence/absence.definition';
import {FunctionService} from './pool-crud/function/function.service';
import {FUNCTION_SERVICE} from './pool-crud/function/function.definition';
import {SoftwareService} from './pool-crud/software/software.service';
import {SOFTWARE_SERVICE} from './pool-crud/software/software.definition';
import {LogService} from './pool-crud/log/log.service';
import {LOG_SERVICE} from './pool-crud/log/log.definition';
import {PoolService} from './pool-crud/pool/pool.service';
import {POOL_SERVICE} from './pool-crud/pool/pool.definition';
import {SpecialtyService} from './pool-crud/specialty/specialty.service';
import {SPECIALTY_SERVICE} from './pool-crud/specialty/specialty.definition';
import {UserFeedbackPriorityService} from './pool-crud/user-feedback-priority/user-feedback-priority.service';
import {USER_FEEDBACK_PRIORITY_SERVICE} from './pool-crud/user-feedback-priority/user-feedback-priority.definition';
import {ConfirmDenyDialogModule} from './confirm-deny-dialog/confirm-deny-dialog.module';


registerLocaleData(localeDE)

export function translateLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, environment.translationsURL, '.json?v=' + environment.COMMIT_HASH);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    NgCrudClientModule.forRoot({serverURL: environment.serverURL}),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NavigationModule,
    TableModule,
    AppRoutingModule,
    AngularJSModule,
    ErrorHandlingModule,
    LandingPageModule,
    ConfirmDenyDialogModule,
    UserNotificationModule.forRoot({
      backendBaseURL: environment.serverURL,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
      useDefaultLang: true,
    }),
    ToastModule,
    CookieAuthModule.forRoot({
      authenticationEndpoint: '/userinfo',
      logoutEndpoint: '/logout/index',
      loginEndpoint: '/',
    }),
    ApplicationStatusModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: ReadOnlyInterceptor, multi: true},
    {provide: POOL_SHIFT_SERVICE, useFactory: poolShiftCrudServiceFactory, deps: [HttpClient, Injector]},
    {provide: AGENCY_SERVICE, useFactory: agencyCrudServiceFactory, deps: [HttpClient, Injector]},
    {provide: AVAILABILITY_SERVICE, useFactory: availabilityCrudServiceFactory, deps: [HttpClient, Injector]},
    {
      provide: HEALTH_INSTITUTION_SERVICE,
      useFactory: healthInstitutionCrudServiceFactory,
      deps: [HttpClient, Injector],
    },
    {provide: FUNCTION_SERVICE, useExisting: FunctionService},
    {provide: SOFTWARE_SERVICE, useExisting: SoftwareService},
    {provide: LOG_SERVICE, useExisting: LogService},
    {provide: POOL_SERVICE, useExisting: PoolService},
    {provide: SPECIALTY_SERVICE, useExisting: SpecialtyService},
    {provide: USER_FEEDBACK_PRIORITY_SERVICE, useExisting: UserFeedbackPriorityService},
    {provide: COURSE_SERVICE, useExisting: CourseService},
    {provide: EDUCATION_SERVICE, useExisting: EducationService},
    {provide: FEEDBACK_TYPE_SERVICE, useExisting: FeedbackTypeService},
    {provide: SHARED_FILE_TAG_SERVICE, useExisting: SharedFileTagService},
    {provide: UPGRADE_TRAINING_COURSE_SERVICE, useExisting: UpgradeTrainingCourseService},
    {provide: QUALIFICATION_SERVICE, useExisting: QualificationService},
    {provide: ABSENCE_SERVICE, useExisting: AbsenceService},
    {provide: EMPLOYEE_SERVICE, useExisting: EmployeeService},
    {provide: PLANNER_SERVICE, useFactory: plannerCrudServiceFactory, deps: [HttpClient, Injector]},
    {provide: RESOURCE_SERVICE, useFactory: resourceCrudServiceFactory, deps: [HttpClient, Injector]},
    {provide: COURSE_SERVICE, useFactory: courseCrudServiceFactory, deps: [HttpClient, Injector]},
    {provide: PATIENT_SERVICE, useFactory: patientCrudServiceFactory, deps: [HttpClient, Injector]},
    {provide: EMPLOYEE_COMMENT_SERVICE, useFactory: employeeCommentServiceFactory, deps: [HttpClient, Injector]},
    {provide: COUNTRY_SERVICE, useFactory: countryCrudServiceFactory, deps: [HttpClient, Injector]},
    {provide: ORGANISATION_UNIT_SERVICE, useExisting: OrganisationUnitService},
    {provide: SHIFT_SERVICE, useExisting: ShiftService},
    {provide: ORGANISATION_UNIT_SERVICE, useExisting: OrganisationUnitService},
    {provide: BOOKING_SERVICE, useExisting: BookingService},
    {provide: STINT_SERVICE, useExisting: StintService},
    {provide: STINT_OFFER_SERVICE, useExisting: StintOfferService},
    {provide: EXTERNAL_RESOURCE_SERVICE, useExisting: ExternalResourceService},
    {provide: APPOINTMENT_SERVICE, useExisting: AppointmentService},
    {provide: TRAINING_SERVICE, useExisting: TrainingService},
    {provide: MEETING_SERVICE, useExisting: MeetingService},
    {provide: ASSIGNMENT_SERVICE, useExisting: AssignmentService},
    {provide: EMPLOYEE_CONTRACT_SERVICE, useExisting: EmployeeContractService},
    {provide: PUBLIC_HOLIDAY_SERVICE, useExisting: PublicHolidayService},
    {provide: SYNC_EXTERNAL_SYSTEM_SERVICE, useExisting: SyncExternalSystemService},
    {provide: USER_SERVICE, useExisting: UserService},
    {provide: LOCALE_ID, useValue: 'de-CH'},
    {provide: ACTIVITY_RULE_CONFIGURATION_SERVICE, useExisting: ActivityRuleConfigurationService},
    {provide: AGENCY_SERVICE, useExisting: AgencyService},
    DialogService,
    QualificationService,
    PublicHolidayService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // inject tracking service to ensure that it is initialized
  constructor(translate: TranslateService, config: PrimeNGConfig) {
    translate.setDefaultLang('de');
    translate.use('de');
    translate.get('primeng').subscribe(res => config.setTranslation(res));
  }
}
