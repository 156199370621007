import {InjectionToken} from '@angular/core';
import {CachedCrudService, DomainDefinition, Entity, FilterType, PropertyDefinition} from '@ic/ng-crud-client';
import {LocalDateTime} from '@js-joda/core';

export interface Log extends Entity {
  logLevel: string;
  message: string;
  dateCreated: LocalDateTime;
  userName?: string;
  details: string;
}

export const LOG_SERVICE = new InjectionToken<CachedCrudService<Log>>('LOG_SERVICE');

export const logDefinition: DomainDefinition<Log> = {
  path: 'log',
  service: LOG_SERVICE,
  properties: new Map<keyof Log, PropertyDefinition>([
    ['logLevel', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
    ['message', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
    ['dateCreated', {
      type: 'local-date-time',
      sortable: true,
      filter: FilterType.BETWEEN,
    }],
    ['userName', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
    ['details', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
  ]),
  formDefinitions: {
    default: {
      fields: ['logLevel', 'message', 'dateCreated', 'userName', 'details'],
    },
  },
  tableDefinitions: {
    default: {
      columns: ['logLevel', 'message', 'dateCreated', 'userName', 'details'],
      inlineEditable: false,
      immutable: true,
      disableDetail: true,
      sortField: 'dateCreated',
      sortOrder: 1,
      filters: {},
      filterStorable: true,
    },
  },
}
