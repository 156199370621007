import {Injectable} from '@angular/core';
import {AppointmentDialogDataService} from '../../../planning/dialog/services/appointment-dialog-data.service';
import {AppointmentDialogStateService} from '../../../planning/dialog/services/appointment-dialog-state.service';

@Injectable({providedIn: 'root'})
export class ListAppointmentDialogStateService extends AppointmentDialogStateService {

  constructor(dataService: AppointmentDialogDataService) {
    super(dataService, null);
  }

  completeDialog(): void {
    this.context = undefined;
    this.formHelper = null;
  }
}


