import {Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {DomainDefinition, Entity, MinimalList} from './definitions';
import {CrudService} from './crud.service';

/**
 * CachedCrudService extends the basic CrudService with caching functionality.
 * It should be a drop in replacement for CrudService.
 *
 * Currently calls to minimalList and get are cached.
 *
 * TODO: evict cache when new data is created
 */
export class CachedCrudService<E extends Entity> extends CrudService<E> {
    private _byIDCache: { [key: number]: Observable<E> };
    private _minimalListCache: Map<any, Observable<MinimalList<E>>> = new Map();

    constructor(domainDefinition: DomainDefinition<E>,
                http: HttpClient,
                injector: Injector, listPath?: string) {
        super(domainDefinition, http, injector, listPath);
    }

    get(id: number): Observable<E> {
        if (id in this._byIDCache) {
            return this._byIDCache[id]
        }
        const entity$ = super.get(id).pipe(
            shareReplay(1)
        );
        this._byIDCache[id] = entity$;
        return entity$;
    }


    minimalList(params?: any): Observable<MinimalList<E>> {
        if (this._minimalListCache.has(params)) {
            return this._minimalListCache.get(params);
        }
        const minimalList$ = super.minimalList(params).pipe(
            shareReplay(1),
        );
        this._minimalListCache.set(params, minimalList$);
        return minimalList$;
    }

    flushCache() {
        this._byIDCache = {};
        this._minimalListCache = new Map();
    }
}
