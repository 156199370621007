import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {NgCrudClientModule} from '@ic/ng-crud-client';
import {CheckboxModule} from 'primeng/checkbox';
import {MessageModule} from 'primeng/message';
import {TooltipModule} from 'primeng/tooltip';
import {StintDeleteFormComponent} from './stint-delete-form.component';


@NgModule({
  declarations: [
    StintDeleteFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    FormsModule,
    InputTextModule,
    NgCrudClientModule,
    CheckboxModule,
    MessageModule,
    TooltipModule,
  ],
  exports: [
    StintDeleteFormComponent,
  ],
})
export class StintDeleteFormModule { }
