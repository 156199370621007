import {Component, ComponentFactoryResolver, OnInit, Type, ViewContainerRef} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {first} from 'rxjs/operators';
import {EntityFormComponent} from '../form/entity-form.component';
import {DomainDefinition, Entity} from '../definitions';

export interface EntityFormConfiguration<E extends Entity> {
  domainDefinition: DomainDefinition<E>;
  formDefinitionName?: string;
  entity?: Partial<E>;
  componentOverride?: Type<any>;
}

function isEntityFormConfiguration(value: any): boolean {
  return typeof value.domainDefinition === 'object';
}

@Component({
  selector: 'ic-entity-form-dialog-adaptor',
  templateUrl: './entity-form-dialog-adaptor.component.html',
  styleUrls: ['./entity-form-dialog-adaptor.component.scss'],
})
export class EntityFormDialogAdaptorComponent<E extends Entity> implements OnInit {
  private readonly formDefinition: EntityFormConfiguration<E>;

  constructor(private readonly dialogRef: DynamicDialogRef, dialogConfig: DynamicDialogConfig, private viewContainerRef: ViewContainerRef,
              private componentFactoryResolver: ComponentFactoryResolver) {
    if (isEntityFormConfiguration(dialogConfig.data)) {
      this.formDefinition = dialogConfig.data as EntityFormConfiguration<E>;
    } else {
      throw new Error('data passed to dialog needs to be of type EntityFormConfiguration');
    }
  }

  ngOnInit(): void {
    this.renderForm();
  }

  private renderForm(): void {
    const componentType = this.formDefinition.componentOverride ? this.formDefinition.componentOverride : EntityFormComponent;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent<EntityFormComponent<E>>(componentFactory);
    const component = componentRef.instance;
    component.entity = this.formDefinition.entity as E;
    component.formDefinitionName = this.formDefinition.formDefinitionName;
    component.domainDef = this.formDefinition.domainDefinition;
    component.save.pipe(first()).subscribe({
      // merge with original entity to keep relations that might be lost if no form field exists for them.
      next: (formValue: E) => this.dialogRef.close({...this.formDefinition.entity, ...formValue}),
      error: () => this.dialogRef.close(null),
    });
  }
}
