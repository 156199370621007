export enum StintAction {
  EXPORT_STINT = 'EXPORT',
  COMMENT = 'COMMENT',
  LOCK_STINT = 'BLOCK',
  UNLOCK_STINT = 'UNBLOCK',
  BROADCAST_SMS = 'BROADCAST_SMS',
  CANCEL_SMS = 'CANCELSMS',
  ACCEPT_STINT = 'ACCEPT',
  PLAN_RESOURCE = 'PLAN_RESOURCE',
  EDIT_STINT = 'EDIT',
  DELETE_STINT = 'DELETE',
  TOGGLE_LOCK_STATE = 'TOGGLE_LOCK_STATE',
  AGENCY_INFOS = 'AGENCY_INFOS',
}
