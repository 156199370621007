export enum WorkType {
  POOL = 'POOL',
  INDUCTION = 'INDUCTION',
  TRAINING = 'TRAINING',
  STANDBY = 'STANDBY',
  RECURRING = 'RECURRING',
  MEETING = 'MEETING',
  INTERVIEW = 'INTERVIEW',
  SITTINGGUARD = 'SITTINGGUARD',  // , OTHER
  WARDHELP = 'WARDHELP',  // , OTHER
}

export const NONE_MEETING_WORK_TYPES = [
  WorkType.POOL,
  WorkType.INDUCTION,
  WorkType.STANDBY,
  WorkType.RECURRING,
  WorkType.SITTINGGUARD,
  WorkType.WARDHELP,
];
