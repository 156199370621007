/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !! Contents of this file should be in sync with ch.hospital.pool.UserRoles
 * !! This is also where you can find details about every permissions
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */
export enum Role {
  ANONYMOUS = 'permitAll',
  ANY_USER = 'IS_AUTHENTICATED_REMEMBERED',
  ADMIN = 'ROLE_ADMIN',
  SYS_ADMIN = 'ROLE_SYS_ADMIN',
  PROFILE_ACCESS = 'ROLE_PROFILE_ACCESS',
  ROLE_RESOURCE = 'ROLE_RESOURCE',
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_PLANNER = 'ROLE_PLANNER',
  ROLE_HR = 'ROLE_HR',
  ROLE_PLANNER_READ_ONLY = 'ROLE_PLANNER_READ_ONLY',
  ROLE_RESOURCE_SEARCH = 'ROLE_RESOURCE_SEARCH',
  ROLE_CRUD_CONFIGURATOR_POOL = 'ROLE_CRUD_CONFIGURATOR_POOL',
  ROLE_READ_CONFIGURATOR_POOL = 'ROLE_READ_CONFIGURATOR_POOL',
  ROLE_READ_AGENCY_POOL = 'ROLE_READ_AGENCY_POOL',
  ROLE_READ_AVAILABILITY_POOL = 'ROLE_READ_AVAILABILITY_POOL',
  ROLE_READ_AVAILABILITY_OU = 'ROLE_READ_AVAILABILITY_OU',
  ROLE_READ_AVAILABILITY_SELF = 'ROLE_READ_AVAILABILITY_SELF',
  ROLE_CREATE_AVAILABILITY_POOL = 'ROLE_CREATE_AVAILABILITY_POOL',
  ROLE_CREATE_AVAILABILITY_OU = 'ROLE_CREATE_AVAILABILITY_OU',
  ROLE_CREATE_AVAILABILITY_SELF = 'ROLE_CREATE_AVAILABILITY_SELF',
  ROLE_DELETE_AVAILABILITY_POOL = 'ROLE_DELETE_AVAILABILITY_POOL',
  ROLE_DELETE_AVAILABILITY_OU = 'ROLE_DELETE_AVAILABILITY_OU',
  ROLE_DELETE_AVAILABILITY_SELF = 'ROLE_DELETE_AVAILABILITY_SELF',
  ROLE_READ_ACTIVEBOOKING_POOL = 'ROLE_READ_ACTIVEBOOKING_POOL',
  ROLE_READ_INACTIVEBOOKING_POOL = 'ROLE_READ_INACTIVEBOOKING_POOL',
  ROLE_READ_ACTIVEBOOKING_OU = 'ROLE_READ_ACTIVEBOOKING_OU',
  ROLE_READ_INACTIVEBOOKING_OU = 'ROLE_READ_INACTIVEBOOKING_OU',
  ROLE_READ_BOOKING_SELF = 'ROLE_READ_BOOKING_SELF',
  ROLE_READ_BOOKING_AGENCY = 'ROLE_READ_BOOKING_AGENCY',
  ROLE_CREATE_UNCONFIRMEDBOOKING_POOL = 'ROLE_CREATE_UNCONFIRMEDBOOKING_POOL',
  ROLE_CREATE_UNCONFIRMEDBOOKING_OU = 'ROLE_CREATE_UNCONFIRMEDBOOKING_OU',
  ROLE_CREATE_UNCONFIRMEDBOOKING_SELF = 'ROLE_CREATE_UNCONFIRMEDBOOKING_SELF',
  ROLE_CREATE_LOWBOOKING_OU = 'ROLE_CREATE_LOWBOOKING_OU',
  ROLE_CREATE_NIGHTBOOKING_OU = 'ROLE_CREATE_NIGHTBOOKING_OU',
  ROLE_CREATE_NIGHTLOWBOOKING_OU = 'ROLE_CREATE_NIGHTLOWBOOKING_OU',
  ROLE_CONFIRM_BOOKING_POOL = 'ROLE_CONFIRM_BOOKING_POOL',
  ROLE_CONFIRM_BOOKING_OU = 'ROLE_CONFIRM_BOOKING_OU',
  ROLE_CONFIRM_BOOKING_SELF = 'ROLE_CONFIRM_BOOKING_SELF',
  ROLE_DELETE_BOOKING_POOL = 'ROLE_DELETE_BOOKING_POOL',
  ROLE_DELETE_BOOKING_OU = 'ROLE_DELETE_BOOKING_OU',
  ROLE_CANCEL_BOOKING_SELF = 'ROLE_CANCEL_BOOKING_SELF',
  ROLE_CANCEL_NIGHTBOOKING_OU = 'ROLE_CANCEL_NIGHTBOOKING_OU',
  ROLE_READ_STINT_POOL = 'ROLE_READ_STINT_POOL',
  ROLE_READ_STINT_OU = 'ROLE_READ_STINT_OU',
  ROLE_READ_STINT_SELF = 'ROLE_READ_STINT_SELF',
  ROLE_READ_STINT_AGENCY = 'ROLE_READ_STINT_AGENCY',
  ROLE_READ_BLOCKEDSTINT_POOL = 'ROLE_READ_BLOCKEDSTINT_POOL',
  ROLE_READ_BLOCKEDSTINT_OU = 'ROLE_READ_BLOCKEDSTINT_OU',
  ROLE_CREATE_STINT_OPEN_POOL = 'ROLE_CREATE_STINT_OPEN_POOL',
  ROLE_CREATE_STINT_OU = 'ROLE_CREATE_STINT_OU',
  ROLE_CREATE_LOWSTINT_OU = 'ROLE_CREATE_LOWSTINT_OU',
  ROLE_CREATE_STINT_SELF = 'ROLE_CREATE_STINT_SELF',
  ROLE_DELETE_STINT_POOL = 'ROLE_DELETE_STINT_POOL',
  ROLE_DELETE_STINT_OU = 'ROLE_DELETE_STINT_OU',
  /** allows to set a stint's status to OPEN or BROADCASTED */
  ROLE_UNLOCK_STINT_POOL = 'ROLE_UNLOCK_STINT_POOL',
  ROLE_UNLOCK_STINT_OU = 'ROLE_UNLOCK_STINT_OU',
  ROLE_UNLOCK_LOWSTINT_OU = 'ROLE_UNLOCK_LOWSTINT_OU',
  ROLE_UNLOCK_NIGHTSTINT_OU = 'ROLE_UNLOCK_NIGHTSTINT_OU',
  ROLE_UNLOCK_NIGHTLOWSTINT_OU = 'ROLE_UNLOCK_NIGHTLOWSTINT_OU',
  ROLE_OFFER_STINT_POOL = 'ROLE_OFFER_STINT_POOL',
  ROLE_OFFER_STINT_OU = 'ROLE_OFFER_STINT_OU',
  ROLE_OFFER_LOWSTINT_OU = 'ROLE_OFFER_LOWSTINT_OU',
  ROLE_OFFER_NIGHTSTINT_OU = 'ROLE_OFFER_NIGHTSTINT_OU',
  ROLE_OFFER_NIGHTLOWSTINT_OU = 'ROLE_OFFER_NIGHTLOWSTINT_OU',
  ROLE_READ_MEETING_POOL = 'ROLE_READ_MEETING_POOL',
  ROLE_CREATE_MEETING_POOL = 'ROLE_CREATE_MEETING_POOL',
  ROLE_DELETE_MEETING_POOL = 'ROLE_DELETE_MEETING_POOL',
  ROLE_READ_TRAINING_POOL = 'ROLE_READ_TRAINING_POOL',
  ROLE_CREATE_TRAINING_POOL = 'ROLE_CREATE_TRAINING_POOL',
  ROLE_DELETE_TRAINING_POOL = 'ROLE_DELETE_TRAINING_POOL',
  ROLE_READ_EMPLOYEE_POOL = 'ROLE_READ_EMPLOYEE_POOL',
  ROLE_READ_EMPLOYEE_OU = 'ROLE_READ_EMPLOYEE_OU',
  ROLE_READ_LOWEMPLOYEE_OU = 'ROLE_READ_LOWEMPLOYEE_OU',
  ROLE_READ_EMPLOYEE_SELF = 'ROLE_READ_EMPLOYEE_SELF',
  ROLE_CREATE_EMPLOYEE_POOL = 'ROLE_CREATE_EMPLOYEE_POOL',
  ROLE_DELETE_EMPLOYEE_POOL = 'ROLE_DELETE_EMPLOYEE_POOL',
  ROLE_READ_EMPLOYEEDATA_POOL = 'ROLE_READ_EMPLOYEEDATA_POOL',
  ROLE_READ_EMPLOYEEDATA_OU = 'ROLE_READ_EMPLOYEEDATA_OU',
  ROLE_READ_EMPLOYEEDATA_SELF = 'ROLE_READ_EMPLOYEEDATA_SELF',
  ROLE_CREATE_EMPLOYEEDATA_POOL = 'ROLE_CREATE_EMPLOYEEDATA_POOL',
  ROLE_CREATE_EMPLOYEEDATA_OU = 'ROLE_CREATE_EMPLOYEEDATA_OU',
  ROLE_CREATE_EMPLOYEEDATA_SELF = 'ROLE_CREATE_EMPLOYEEDATA_SELF',
  ROLE_DELETE_EMPLOYEEDATA_POOL = 'ROLE_DELETE_EMPLOYEEDATA_POOL',
  ROLE_READ_PATIENT_SELF = 'ROLE_READ_PATIENT_SELF',
  ROLE_READ_ORGANISATIONUNIT_POOL = 'ROLE_READ_ORGANISATIONUNIT_POOL',
  ROLE_CREATE_ORGANISATIONUNIT_POOL = 'ROLE_CREATE_ORGANISATIONUNIT_POOL',
  ROLE_READ_HEALTHINSTITUTION_POOL = 'ROLE_READ_HEALTHINSTITUTION_POOL',
  ROLE_CREATE_HEALTHINSTITUTION_POOL = 'ROLE_CREATE_HEALTHINSTITUTION_POOL',
  ROLE_READ_QUALIFICATION_POOL = 'ROLE_READ_QUALIFICATION_POOL',
  ROLE_CREATE_QUALIFICATION_POOL = 'ROLE_CREATE_QUALIFICATION_POOL',
  ROLE_READ_POOLSHIFT_POOL = 'ROLE_READ_POOLSHIFT_POOL',
  ROLE_READ_SHIFT_POOL = 'ROLE_READ_SHIFT_POOL',
  ROLE_READ_POOLCONFIGURATION_POOL = 'ROLE_READ_POOLCONFIGURATION_POOL',
  ROLE_READ_USERFEEDBACK_POOL = 'ROLE_READ_USERFEEDBACK_POOL',
  ROLE_CREATE_USERFEEDBACK_POOL = 'ROLE_CREATE_USERFEEDBACK_POOL',
  ROLE_CREATE_USERFEEDBACK_SELF = 'ROLE_CREATE_USERFEEDBACK_SELF',
  ROLE_READ_ABSENCE_POOL = 'ROLE_READ_ABSENCE_POOL',
  ROLE_READ_ABSENCE_OU = 'ROLE_READ_ABSENCE_OU',
  ROLE_READ_ABSENCE_SELF = 'ROLE_READ_ABSENCE_SELF',
  ROLE_CREATE_ABSENCE_POOL = 'ROLE_CREATE_ABSENCE_POOL',
  ROLE_CREATE_ABSENCE_OU = 'ROLE_CREATE_ABSENCE_OU',
  ROLE_CREATE_ABSENCE_SELF = 'ROLE_CREATE_ABSENCE_SELF',
  ROLE_DELETE_ABSENCE_POOL = 'ROLE_DELETE_ABSENCE_POOL',
  ROLE_DELETE_ABSENCE_OU = 'ROLE_DELETE_ABSENCE_OU',
  ROLE_DELETE_ABSENCE_SELF = 'ROLE_DELETE_ABSENCE_SELF',
  ROLE_CREATE_EMPLOYEE_AGENCY = 'ROLE_CREATE_EMPLOYEE_AGENCY',
  ROLE_ACCEPT_STINT_AGENCY = 'ROLE_ACCEPT_STINT_AGENCY',
  ROLE_ACCEPT_STINT_SELF = 'ROLE_ACCEPT_STINT_SELF',
  ROLE_ADMIN_EMPLOYEECOMMENT_POOL = 'ROLE_ADMIN_EMPLOYEECOMMENT_POOL',
  ROLE_ADMIN_EMPLOYEECOMMENT_OU = 'ROLE_ADMIN_EMPLOYEECOMMENT_OU',
  ROLE_ADMIN_EMPLOYEECOMMENT_SELF = 'ROLE_ADMIN_EMPLOYEECOMMENT_SELF',
  ROLE_CREATE_BOOKINGSTEP_POOL = 'ROLE_CREATE_BOOKINGSTEP_POOL',
  ROLE_CONFIRM_BOOKINGSTEP_POOL = 'ROLE_CONFIRM_BOOKINGSTEP_POOL',
  ROLE_CONFIRM_BOOKINGSTEP_OU = 'ROLE_CONFIRM_BOOKINGSTEP_OU',
  ROLE_CONFIRM_BOOKINGSTEP_SELF = 'ROLE_CONFIRM_BOOKINGSTEP_SELF',
  ROLE_CONFIRM_BOOKINGSTEP_AGENCY = 'ROLE_CONFIRM_BOOKINGSTEP_AGENCY',
  ROLE_CONFIRM_SYNCEXTERNALSYSTEM_POOL = 'ROLE_CONFIRM_SYNCEXTERNALSYSTEM_POOL',
  ROLE_CONFIRM_SYNCEXTERNALSYSTEM_OU = 'ROLE_CONFIRM_SYNCEXTERNALSYSTEM_OU',
  ROLE_READ_AVAILABILITYFREEZE_POOL = 'ROLE_READ_AVAILABILITYFREEZE_POOL',
  ROLE_ADMIN_AVAILABILITYFREEZE_POOL = 'ROLE_ADMIN_AVAILABILITYFREEZE_POOL',
  ROLE_CREATE_FROZENAVAILABILITY_POOL = 'ROLE_CREATE_FROZENAVAILABILITY_POOL',
  ROLE_CREATE_STINTBROADCAST_POOL = 'ROLE_CREATE_STINTBROADCAST_POOL',
  ROLE_CREATE_STINTBROADCAST_OU = 'ROLE_CREATE_STINTBROADCAST_OU',
  ROLE_CREATE_NIGHTSTINTBROADCAST_OU = 'ROLE_CREATE_NIGHTSTINTBROADCAST_OU',
  ROLE_CREATE_NIGHTSTINT_OU = 'ROLE_CREATE_NIGHTSTINT_OU',
  ROLE_READ_SYNCEXTERNALSYSTEM_POOL = 'ROLE_READ_SYNCEXTERNALSYSTEM_POOL',
  ROLE_READ_SYNCEXTERNALSYSTEM_OU = 'ROLE_READ_SYNCEXTERNALSYSTEM_OU',
  ROLE_CREATE_LONGTERMSTINT_POOL = 'ROLE_CREATE_LONGTERMSTINT_POOL',
  ROLE_CREATE_AGENCY_POOL = 'ROLE_CREATE_AGENCY_POOL',
  ROLE_SEND_TOKENLINK_POOL = 'ROLE_SEND_TOKENLINK_POOL',
  ROLE_SEND_TOKENLINK_AGENCY = 'ROLE_SEND_TOKENLINK_AGENCY',
  ROLE_OFFERTO_RESTRICTEDAGENCY_POOL = 'ROLE_OFFERTO_RESTRICTEDAGENCY_POOL',
  ROLE_UPDATE_USER_POOL = 'ROLE_UPDATE_USER_POOL',
  ROLE_RESOURCE_SAME_DAY_WORKING = 'ROLE_RESOURCE_SAME_DAY_WORKING ',
  ROLE_PLANNER_DASHBOARD = 'ROLE_PLANNER_DASHBOARD',
  ROLE_ISSUE_KEY_POOL = 'ROLE_ISSUE_KEY_POOL',
}
