import {Observable} from 'rxjs/internal/Observable';
import {merge, Subject, timer} from 'rxjs';
import {SecurityContext} from '../definitions';
import {shareReplay, switchMap, tap} from 'rxjs/operators';

export abstract class AuthService {
  readonly securityContext$: Observable<SecurityContext>;
  private _securityContextSnapshot: SecurityContext | null;
  private loginLogout$: Subject<SecurityContext> = new Subject<SecurityContext>();

  protected constructor() {
    // The call to validateAuthentication needs to be delayed until the child class is fully initialized, e.g.
    // its constructor has been called. Otherwise validateAuthentication can not access the fields
    const initialAuth$: Observable<SecurityContext> = timer(0).pipe(switchMap(it => this.validateAuthentication()));
    this.securityContext$ = merge(
      this.loginLogout$,
      initialAuth$,
    ).pipe(
      tap(securityContext => this._securityContextSnapshot = securityContext),
      shareReplay(1),
    );
  }


  get securityContextSnapshot(): SecurityContext | null {
    return this._securityContextSnapshot;
  }

  /**
   * Call this method when the user has successfully proved his identity.
   */
  setAuthentication(securityContext: SecurityContext) {
    this.loginLogout$.next(securityContext);
  }

  /**
   * logout destroys the current session.
   * implementors of this class should override this method and revoke the corresponding sessions and/or tokens
   */
  clearAuthentication() {
    this.loginLogout$.next(new SecurityContext(null, null));
  }

  /**
   * The user wanted to take an action that he did not have the permission to do, either because he was not logged in
   * or because he does not have the required roles.
   */
  abstract requestLogin(): void;

  /**
   * Check the authentication status with the server. Does the server consider the current session/token valid?
   */
  abstract validateAuthentication(): Observable<SecurityContext>;

  abstract logout(): void;
}
