import {InjectionToken} from '@angular/core';
import {DomainDefinition, Entity, PropertyDefinition} from '@ic/ng-crud-client';
import {LocalDate} from '@js-joda/core';
import {PublicHolidayService} from './public-holiday.service';

export interface PublicHoliday extends Entity {
  holidayLabel: string;
  date: LocalDate;
  hours: number;
}

export const PUBLIC_HOLIDAY_SERVICE: InjectionToken<PublicHolidayService> = new InjectionToken('PUBLIC_HOLIDAY_SERVICE');

export const publicHolidayDefinition: DomainDefinition<PublicHoliday> = {
  path: 'publicHoliday',
  service: PUBLIC_HOLIDAY_SERVICE,
  properties: new Map<keyof PublicHoliday, PropertyDefinition>([
    ['holidayLabel', {
      type: 'string',
      nullable: false,
    }],
    ['date', {
      type: 'local-date',
      nullable: false,
    }],
    ['hours', {
      type: 'string',
      nullable: false,
    }],
  ]),
};

