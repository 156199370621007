import {Injectable, Injector} from '@angular/core';
import {CrudService} from '@ic/ng-crud-client';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {SharedFileTag, sharedFileTagDefinition} from './shared-file-tag.definition';


@Injectable({
  providedIn: 'root',
})
export class SharedFileTagService extends CrudService<SharedFileTag> {

  constructor(http: HttpClient, injector: Injector) {
    super(sharedFileTagDefinition, http, injector)
  }
  delete(model: any): Observable<unknown> {
    return this.http.delete(this.apiBaseUrl + `sharedFileTag/delete/${model.id}`);
  }
}
