<div>
  <h1>Test Mail</h1>
  <p>
    With this tool you can test the Mail configuration. It tries to send an email to the entered email address.
    For successful delivery correct smtp settings as well as correct mail filter settings are required.
  </p>

  <form [formGroup]="form" (ngSubmit)="sendTestMail()" novalidate class="ui-g">
    <div class="ui-g-11 ui-lg-7 p-fluid">
      <label for="testMailAddress">Email: </label>
      <input id="testMailAddress" type="email" formControlName="testMailAddress" pInputText>
    </div>
    <div class="ui-g-12">
      <p-button type="submit" [disabled]="!form.valid" label="Send"></p-button>
    </div>
  </form>

  <div [hidden]="message===undefined">
    <h2>Server Mail Session Output</h2>
    <pre style="background-color: #d2d2d2; padding: 1rem;">{{message}}</pre>
  </div>
</div>
