import {InjectionToken, Injector} from '@angular/core';
import {
  CrudService,
  DomainDefinition,
  Entity,
  FilterType,
  Paths,
  PropertyDefinition,
  Relation
} from '@ic/ng-crud-client';
import {AvailabilityType} from '../../definitions';
import {HttpClient} from '@angular/common/http';
import {POOL_SHIFT_SERVICE, PoolShift} from '../pool-shift/pool-shift.definition';
import {LocalDate, LocalDateTime} from '@js-joda/core';
import {Booking} from '../booking/booking.definition';
import {Employee, EMPLOYEE_SERVICE} from '../employee/employee.definition';
import {Qualification, QUALIFICATION_SERVICE} from '../qualification/qualification.definition';

export const AVAILABILITY_SERVICE = new InjectionToken<CrudService<Availability>>('AVAILABILITY_SERVICE');

export interface Availability extends Entity {
  id: number;
  version: number;
  availabilityType: AvailabilityType;
  // todo: this value is never set and therefore null, not sure if this is rather a screen than an availability property
  //       as planners won't see resources they cannot plan at all. However a booking on another organisation unit
  //       should be disabled
  editable: boolean;
  resourceName: string;
  resourceFirstName: string;
  resourceLastName: string;
  resourcePhone: string;
  qualification?: Relation<Qualification>;
  start: LocalDateTime;
  end: LocalDateTime;
  shiftDate: LocalDate;
  shiftName: string;
  resource?: Partial<Employee>;
  relatedBookings?: Partial<Booking>[];
  shift?: Partial<PoolShift>;
  deleted: boolean;
  comment?: string;
}

export let availabilityDefinition: DomainDefinition<Availability>;
availabilityDefinition = {
  path: 'availability',
  service: AVAILABILITY_SERVICE,
  properties: new Map<keyof Availability, PropertyDefinition>([
    ['resourceName', {
      type: 'text',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
    ['resourcePhone', {
      type: 'text',
      sortable: false,
    }],
    ['qualification', {
      type: 'belongs-to',
      service: QUALIFICATION_SERVICE,
      filter: FilterType.IN,
    }],
    ['resource', {
      type: 'belongs-to',
      service: EMPLOYEE_SERVICE,
      noOptions: true,
    }],
    ['shiftDate', {
      type: 'local-date',
      filter: FilterType.GREATER_OR_EQUALS,
      nullable: true,
      sortable: true,
      defaultFilterValue: LocalDate.now(),
    }],
    ['shift', {
      type: 'belongs-to',
      service: POOL_SHIFT_SERVICE,
      filter: FilterType.IN,
      nullable: false,
      sortable: true,
    }],
    ['start', {
      type: 'local-date-time',
      nullable: true,
      filter: FilterType.BETWEEN,
      sortable: true,
    }],
    ['end', {
      type: 'local-date-time',
      nullable: true,
      filter: FilterType.BETWEEN,
      sortable: true,
    }],
    ['availabilityType', {
      type: 'enum',
      cls: AvailabilityType,
      prefix: 'AvailabilityType',
      sortable: true,
      defaultFilterValue: [AvailabilityType.AVAILABLE],
      filter: FilterType.IN,
    }],
  ]),
  // @ts-ignore
  propertiesMapping: new Map<keyof Availability, Paths<Availability>>([
    ['resourcePhone', ['resource', 'phoneNumber']],
    ['qualification', ['resource', 'personalData', 'qualification']],
    ['shiftName', ['shift', 'label']],

  ]),
  tableDefinitions: {
    default: {
      columns: [
        'resourceName', 'qualification', 'resourcePhone', 'shiftDate', 'shift', 'availabilityType',
      ],
      inlineEditable: false,
      immutable: true,
      disableDetail: true,
      sortField: 'shiftDate',
      sortOrder: 1,
      filterStorable: true,
      columnsConfigurable: true,
    },
  },
};

export function availabilityCrudServiceFactory(httpClient: HttpClient, injector: Injector) {
  return new CrudService(availabilityDefinition, httpClient, injector);
}
