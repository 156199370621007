import {InjectionToken} from '@angular/core';
import {CrudService, DomainDefinition, Entity, FilterType, PropertyDefinition, Relation,} from '@ic/ng-crud-client';
import {HEALTH_INSTITUTION_SERVICE, HealthInstitution} from '../health-institution/health-institution.definition';

export interface OrganisationUnit extends Entity {
  unitName?: string;
  unitNameShort?: string;
  department?: string;
  phone?: string;
  emailAddress?: string;
  defaultQuota?: number;
  healthInstitution?: Relation<HealthInstitution>;
  customSortIndex?: string;
}

export const ORGANISATION_UNIT_SERVICE: InjectionToken<CrudService<OrganisationUnit>> = new InjectionToken('ORGANISATION_UNIT_SERVICE');

export const organisationUnitDefinition: DomainDefinition<OrganisationUnit> = {
  path: 'organisationUnit',
  service: ORGANISATION_UNIT_SERVICE,
  properties: new Map<keyof OrganisationUnit, PropertyDefinition>([
    ['unitName', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
    ['unitNameShort', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
    ['department', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
      nullable: true,
    }],
    ['customSortIndex', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
      nullable: true,
    }],
    ['phone', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
      nullable: true,
    }],
    ['emailAddress', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
      nullable: true,
    }],
    ['defaultQuota', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
      nullable: true,
    }],
    ['healthInstitution', {
      type: 'belongs-to',
      service: HEALTH_INSTITUTION_SERVICE,
      sortable: true,
      filter: FilterType.IN,
      denyAssociationCreation: true,
    }],
  ]),
  formDefinitions: {
    default: {
      fields: ['healthInstitution', 'unitName', 'unitNameShort', 'department', 'customSortIndex', 'phone', 'emailAddress', 'defaultQuota'],
    },
  },
  tableDefinitions: {
    default: {
      columns: ['healthInstitution', 'unitName', 'unitNameShort', 'department', 'customSortIndex', 'phone', 'emailAddress', 'defaultQuota'],
      inlineEditable: true,
      immutable: false,
      disableDetail: false,
      sortField: 'customSortIndex',
      sortOrder: 1,
      filters: {},
      filterStorable: true,
    },
  },
};
