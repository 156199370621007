import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StintBroadcastFormComponent} from './stint-broadcast-form.component';
import {SharedModule} from '../../../../shared/shared.module';
import {MenuModule} from 'primeng/menu';


@NgModule({
  declarations: [
    StintBroadcastFormComponent,
  ],
  exports: [
    StintBroadcastFormComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MenuModule,
  ],
})
export class StintBroadcastFormModule {
}
