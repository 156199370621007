import {InjectionToken, Injector} from '@angular/core';
import {CachedCrudService, DomainDefinition, Entity, PropertyDefinition} from '@ic/ng-crud-client';
import {Sex} from '../../definitions';

export interface Patient extends Entity {
    firstName: string;
    lastName: string;
    room: string;
    caseNumber: string;
    year: string;
    sex: Sex;
}

export const PATIENT_SERVICE = new InjectionToken<CachedCrudService<Patient>>('PATIENT_SERVICE');

export const patientDefinition: DomainDefinition<Patient> = {
  path: 'patient',
  service: PATIENT_SERVICE,
  properties: new Map<keyof Patient, PropertyDefinition>([
      ['lastName', {
        type: 'text',
        nullable: true,
      }],
      ['firstName', {
        type: 'text',
        nullable: true,
      }],
      ['room', {
        type: 'text',
        nullable: true,
      }],
      ['caseNumber', {
          type: 'text',
          nullable: true,
      }],
      ['sex', {
          type: 'enum',
          cls: Sex,
          prefix: 'Sex',
      }],
      ['year', {
          type: 'text',
          nullable: true,
      }],
      ],
  ),
};

export function patientCrudServiceFactory(httpClient, injector: Injector) {
  return new CachedCrudService(patientDefinition, httpClient, injector);
}
