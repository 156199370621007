import {Injectable, Injector} from '@angular/core';
import {CrudService, ListPage} from '@ic/ng-crud-client';
import {Absence, absenceDefinition} from './absence.definition';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn:'root',
})
export class AbsenceService extends CrudService<Absence>{

  constructor(http: HttpClient, injector: Injector) {
    super(absenceDefinition, http, injector);
  }

  list(body?: object, params?: any): Observable<ListPage<Absence> | Absence[]> {
    return super.list(body, params).pipe(
      map((it) => {
        return it as ListPage<Absence>;
      }),
    );
  }
  delete(absence: Absence): Observable<unknown> {
    return this.http.delete(this.apiBaseUrl + `absence/delete/${absence.id}`);
  }
}
