import {LocalDateTime} from '@js-joda/core';

export enum AvailabilityType {
  BOOKED = 'BOOKED',
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  VACATION = 'VACATION',
  VACATION_UNPAID = 'VACATION_UNPAID',
  ACCIDENT = 'ACCIDENT',
  ILLNESS = 'ILLNESS',
  ILLNESS_CHILD = 'ILLNESS_CHILD',
  ARMY = 'ARMY',
  CIVIL_PROTECTION = 'CIVIL_PROTECTION',
  CIVIL_SERVICE = 'CIVIL_SERVICE',
  PREGNANCY = 'PREGNANCY',
  PATERNITY_LEAVE = 'PATERNITY_LEAVE',
  CONTINUOUS_EDUCATION = 'CONTINUOUS_EDUCATION',
  CANCELLED_AVAILABILITY = 'CANCELLED_AVAILABILITY',
  REQUEST_FREE = 'REQUEST_FREE',
  WEDDING = 'WEDDING',
  MOVING = 'MOVING',
  DEATH = 'DEATH',
  EXAM_PHASE = 'EXAM_PHASE',
  NOT_REACHABLE = 'NOT_REACHABLE',
  SENIORITY_GIFT = 'SENIORITY_GIFT',
  REST_DAY = 'REST_DAY',
  OTHER_WORK = 'OTHER_WORK',
  DRESSING_TIME = 'DRESSING_TIME',
}

export enum ActivityType {
  PROFILE_COMPLETED = 'PROFILE_COMPLETED',
  PROFILE_INCOMPLETE = 'PROFILE_INCOMPLETE',
  PROFILE_VALIDATED = 'PROFILE_VALIDATED',
  PROFILE_MODIFIED = 'PROFILE_MODIFIED',
  ABSENCE_NEW = 'ABSENCE_NEW',
  ABSENCE_NEW_WITH_CERTIFICATE = 'ABSENCE_NEW_WITH_CERTIFICATE',
  ABSENCE_UPDATED = 'ABSENCE_UPDATED',
  AVAILABILITY_NEW = 'AVAILABILITY_NEW',
  AVAILABILITY_DELETED = 'AVAILABILITY_DELETED',
  AVAILABILITY_UPDATED = 'AVAILABILITY_UPDATED',
  AVAILABILITY_ABSENCE_DELETED = 'AVAILABILITY_ABSENCE_DELETED',
  ABSENCE_MEDICAL_CERTIFICATE_CONFIRMED = 'ABSENCE_MEDICAL_CERTIFICATE_CONFIRMED',
  BOOKING_DELETED = 'BOOKING_DELETED',
  BOOKING_CONFIRMED = 'BOOKING_CONFIRMED',
  BOOKING_DENIED = 'BOOKING_DENIED',
  BOOKING_CANCELLED_BY_RESOURCE = 'BOOKING_CANCELLED_BY_RESOURCE',
  BOOKING_CANCELLED_BY_PLANNER = 'BOOKING_CANCELLED_BY_PLANNER',
  BOOKING_FULFILLED = 'BOOKING_FULFILLED',
  BOOKING_REQUEST_CANCELLED_BY_PLANNER = 'BOOKING_REQUEST_CANCELLED_BY_PLANNER',
  BOOKING_CANCELLED_BY_MANAGER = 'BOOKING_CANCELLED_BY_MANAGER',
  BOOKING_CANCELLED_LAST_MINUTE = 'BOOKING_CANCELLED_LAST_MINUTE',
  BOOKING_REQUEST = 'BOOKING_REQUEST',
  BOOKING_REACTIVATE = 'BOOKING_REACTIVATE',
  BOOKING_REQUEST_DIRECT = 'BOOKING_REQUEST_DIRECT',
  ORGANISATION_UNIT_QUOTA_INCREASE_CREATE = 'ORGANISATION_UNIT_QUOTA_INCREASE_CREATE',
  ORGANISATION_UNIT_QUOTA_INCREASE_COMMIT = 'ORGANISATION_UNIT_QUOTA_INCREASE_COMMIT',
  ORGANISATION_UNIT_QUOTA_INCREASE_REJECT = 'ORGANISATION_UNIT_QUOTA_INCREASE_REJECT',
  BOOKING_ORGANISATION_UNIT_MODIFIED = 'BOOKING_ORGANISATION_UNIT_MODIFIED',
  OPEN_STINT_CLOSED = 'OPEN_STINT_CLOSED',
  OPEN_STINT_CREATED = 'OPEN_STINT_CREATED',
  OPEN_STINT_MODIFIED = 'OPEN_STINT_MODIFIED',
  OPEN_STINT_ACCEPTED = 'OPEN_STINT_ACCEPTED',
  BOOKING_MODIFIED = 'BOOKING_MODIFIED',
  USER_ENABLED = 'USER_ENABLED',
  USER_DISABLED = 'USER_DISABLED',
  USER_INVITE = 'USER_INVITE',
  PAST_BOOKING = 'PAST_BOOKING',
  BOOKING_POOL_RESOURCE = 'BOOKING_POOL_RESOURCE',
  BOOKING_INTERNAL_RESOURCE = 'BOOKING_INTERNAL_RESOURCE',
  BOOKING_EXTERNAL_RESOURCE = 'BOOKING_EXTERNAL_RESOURCE',
  FILE_UPLOAD = 'FILE_UPLOAD',
  FILE_DOWNLOAD = 'FILE_DOWNLOAD',
  FILE_DELETED = 'FILE_DELETED',
  ORGANISATION_UNIT_DELETED = 'ORGANISATION_UNIT_DELETED',
  STINT_OFFER_CREATED = 'STINT_OFFER_CREATED',
  STINT_OFFER_DELETED = 'STINT_OFFER_DELETED',
  ACCOUNT_LOCKED = 'ACCOUNT_LOCKED',
  ACCOUNT_UNLOCKED = 'ACCOUNT_UNLOCKED',
  UNDELIVERED_NOTIFICATION = 'UNDELIVERED_NOTIFICATION',
  BOOKING_OF_EXTERNAL_RESOURCE_MODIFIED = 'BOOKING_OF_EXTERNAL_RESOURCE_MODIFIED',
  LABOR_LAW_WARNING = 'Arbeitsgesetz verletzt',
  LABOR_LAW_ERROR = 'Arbeitsgesetz schwerwiegend verletzt',
  KEY_ISSUE = 'Schlüssel Ausgabe/Rücknahme',
  ORGANISATION_UNIT_ACCESS_MODIFICATION = 'Anpassung Zuordnung Organisationseinheit',
  AGENCY_CREATED = 'AGENCY_CREATED',
  AGENCY_ACTIVATED = 'AGENCY_ACTIVATED',
  AGENCY_DEACTIVATED = 'AGENCY_DEACTIVATED',
  SUBSCRIPTION_MODIFIED = 'SUBSCRIPTION_MODIFIED',
  INACTIVE_USER = 'INACTIVE_USER  ',
}

export enum SyncDoneByRole {
  ROLE_AUTOCOMPLETED_SYNCEXTERNALSYSTEM_POOL = 'ROLE_AUTOCOMPLETED_SYNCEXTERNALSYSTEM_POOL',
  NONE = 'NONE',
}

export enum SyncForRole {
  ROLE_READ_SYNCEXTERNALSYSTEM_OU = 'ROLE_READ_SYNCEXTERNALSYSTEM_OU',
  ROLE_READ_SYNCEXTERNALSYSTEM_POOL = 'ROLE_READ_SYNCEXTERNALSYSTEM_POOL',
}

export enum AgencyOfferRole {
  ROLE_OFFERTO_RESTRICTEDAGENCY_POOL = 'ROLE_OFFERTO_RESTRICTEDAGENCY_POOL',
  NONE = 'NONE',
}

export const ABSENCE_TYPES = [
  AvailabilityType.UNAVAILABLE, AvailabilityType.VACATION, AvailabilityType.VACATION_UNPAID, AvailabilityType.ACCIDENT,
  AvailabilityType.ILLNESS, AvailabilityType.ILLNESS_CHILD, AvailabilityType.ARMY, AvailabilityType.CIVIL_PROTECTION,
  AvailabilityType.CIVIL_SERVICE, AvailabilityType.PREGNANCY, AvailabilityType.CONTINUOUS_EDUCATION,
  AvailabilityType.REQUEST_FREE, AvailabilityType.EXAM_PHASE, AvailabilityType.WEDDING, AvailabilityType.MOVING,
  AvailabilityType.DEATH, AvailabilityType.PATERNITY_LEAVE, AvailabilityType.NOT_REACHABLE,
]

export enum Weekdays {
  MONDAY_TO_FRIDAY = 'MONDAY_TO_FRIDAY',
  WEEK = 'WEEK',
  WEEKEND = 'WEEKEND',
}

export enum AvailableTypeAvailableType {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NONE = 'NONE',
}

export enum Sex {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NONE = 'NONE',
}

export enum SexMin {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum MessageType {
  SUCCESS = 'SUCCESS', INFO = 'INFO', WARNING = 'WARNING', DANGER = 'DANGER', ERROR = 'ERROR',
}

export interface Option {
  label: string;
  value: any;
  remark?: string;
  severity?: string;
}

export interface TimeSpan {
  start: LocalDateTime,
  end: LocalDateTime
}

export enum Term {
  SHORT_TERM_AVAILABILITY = 'SHORT_TERM_AVAILABILITY',
  MEDIUM_TERM_AVAILABILITY = 'MEDIUM_TERM_AVAILABILITY',
  LONG_TERM_BOOKING = 'LONG_TERM_BOOKING',
  SHORT_TERM_BOOKING = 'SHORT_TERM_BOOKING',
}

