import {LocalDate} from '@js-joda/core';
import {EMPLOYEE_SERVICE, EmployeeData} from '../employee/employee.definition';
import {DomainDefinition, Entity, PropertyDefinition, Relation} from '@ic/ng-crud-client';
import {EmployeeContractService} from './employee-contract.service';
import {InjectionToken} from '@angular/core';

export interface EmployeeContract extends Entity {
  start: LocalDate;
  end?: LocalDate;
  employmentLevel: number;
  vacationDays: number;
  resourceData: Relation<EmployeeData>;
}

export const EMPLOYEE_CONTRACT_SERVICE = new InjectionToken<EmployeeContractService>('EMPLOYEE_CONTRACT_SERVICE');

export const contractDomainDefinition: DomainDefinition<EmployeeContract> = {
  path: 'employeeContract',
  service: EMPLOYEE_CONTRACT_SERVICE,
  properties: new Map<keyof EmployeeContract, PropertyDefinition>([
    ['start', {
      type: 'local-date',
    }],
    ['end', {
      type: 'local-date',
      nullable: true,
    }],
    ['employmentLevel', {
      type: 'int',
    }],
    ['vacationDays', {
      type: 'int',
    }],
  ]),
}
