import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

export function RegexValidator(regex: RegExp, translate: TranslateService): ValidatorFn {
    return (ctrl: AbstractControl): ValidationErrors | null => {
        const allowed = regex.test(ctrl.value);
        const message = translate.instant('error.validation.regex', { value: ctrl.value, regex: regex.toString() });
        return allowed ?  null : {'regex': message};
    };
}
