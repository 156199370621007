import {CrudService, ListPage} from '@ic/ng-crud-client';
import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {PoolShift, poolShiftDefinition} from './pool-shift.definition';

@Injectable({
  providedIn: 'root',
})
export class PoolShiftService extends CrudService<PoolShift> {

  constructor(http: HttpClient, injector: Injector) {
    super(poolShiftDefinition, http, injector);
  }

  listForResources(resourceIds: number[]) {
    return this.list({
      filters: {
        forResource: {value: resourceIds},
      },
      rows: 999,
    }).pipe(
      map((listPage: ListPage<PoolShift>) => listPage.records),
    );
  }

}
