import {Observable} from 'rxjs';
import {bufferCount, first, flatMap, map, mergeMap, shareReplay, tap} from 'rxjs/operators';
import {InjectionToken, Injector} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {DateTimeFormatter, LocalDate, LocalDateTime, LocalTime} from '@js-joda/core';
import {
  API_CONFIGURATION,
  BelongsToPropertyDefinition,
  DomainDefinition,
  Entity,
  HasManyPropertyDefinition,
  Metadata,
  MinimalList,
  Paths,
  PropertyDefinition,
} from './definitions';
import {
  EntityFormConfiguration,
  EntityFormDialogAdaptorComponent
} from './entity-form-dialog-adaptor/entity-form-dialog-adaptor.component';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {LazyLoadEvent} from 'primeng/api';
import {DOCUMENT} from '@angular/common';


export const LOCAL_DATE_FORMATTER = DateTimeFormatter.ofPattern('dd.MM.yyyy');

/**
 * Token to use to get the current `CrudService` handling the class of this component
 */
export const CRUD_SERVICE = new InjectionToken<CrudService<Entity>>('CRUD_SERVICE');

export interface ListPage<E> {
  records: E[];
  total: number;
}

/**
 * CrudService is responsible to exchange object entities with the backend.
 * It provides methods to create read and update entities.
 * There should be an instance of CrudService for each object type.
 */
export class CrudService<E extends Entity> {
  protected apiBaseUrl: string;

  protected metadataObservable: Observable<Metadata<E>>;
  protected dialogService: DialogService;
  protected translate: TranslateService;

  constructor(protected domainDefinition: DomainDefinition<E>,
              protected http: HttpClient,
              protected injector: Injector,
              private listPath?: string) {
    this.apiBaseUrl = this.injector.get(API_CONFIGURATION).serverURL;
    this.translate = injector.get(TranslateService);
    this.dialogService = injector.get(DialogService);
  }

  /**
   * Download the list in the specified format.
   *
   * @param format Check the server side controller for supported formats (examples are csv, xlsx)
   * @param filter Filter to apply to data when rendering the file. Usually, this is set to the list filter without pagination params.
   */
  download(format: string, filter?: LazyLoadEvent): Promise<unknown> {
    return this.http.post(`${this.apiBaseUrl}${this.domainDefinition.path}/list.${format}`, filter, {responseType: 'blob'}).pipe(
      tap((result) => {
        // download the file content using a xhr request, create a link tag <a download="entity-plural.csv" href="blob:0011aa..."></a> and
        // click it using javascript
        const document = this.injector.get(DOCUMENT);
        const a = document.createElement('a');
        const data = URL.createObjectURL(result);
        a.href = data;
        if (typeof a.download !== 'undefined') {
          a.download = this.translate.instant(this.domainDefinition.path + '.domain.plural') + '.' + format;
        }
        a.dispatchEvent(new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        }));
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          a.remove();
        }, 1000);
      }),
    ).toPromise();
  }

  /**
   * Fetch a list of instances from the server
   * TODO params should have some structure
   */
  list(body?: object, params?: any, customList?: string): Observable<ListPage<E> | E[]> {
    let httpParams: HttpParams | undefined;
    if (params) {
      httpParams = new HttpParams();
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          httpParams = httpParams.append(key, params[key]);
        }
      }
    }
    if (body) {
      return this.http.post<ListPage<E>>(`${this.apiBaseUrl}${this.domainDefinition.path}/${customList || 'list'}.json`, body, {
        params: httpParams,
        withCredentials: true,
      }).pipe(tap((result: ListPage<E>) => {
        /*                    this.growl.add({
                                severity: GrowlService.SEV_SUCCESS,
                                summary: 'Success',
                                detail: `${result.records.length} records loaded.`});*/
        this.mapDeepValues(result.records)
        this.convertStringsToDate(result.records);
      }));
    } else {
      return this.http.get<E[]>(this.apiBaseUrl + this.domainDefinition.path + (this.listPath || '') + '.json', {
        params: httpParams,
        withCredentials: true,
      }).pipe(
        tap((records: E[]) => {
          this.mapDeepValues(records)
          this.convertStringsToDate(records);
        }));
    }
  }

  /**
   * Fetch a `MinimalList` from the server.
   * This is useful for dropdowns, multiselects and other widgets, where only a string representation and an ID is required.
   *
   * TODO params should be structured. See also list
   * @returns
   */
  minimalList(params?: any): Observable<MinimalList<E>> {
    if (!params) {
      params = {};
    }
    params.format = 'minimal';
    return this.list(undefined, params).pipe(map((records: E[]) => {
      return records.map(item => {
        return item as { id: number, label: string };
      });
    }));
  }

  /**
   * Fetch the instance with the given `id` from the server
   */
  get(id: number, forEditing?: boolean): Observable<E> {
    return this.http.get<E>(`${this.apiBaseUrl}${this.domainDefinition.path}/${forEditing ? 'edit' : 'show'}/${id}.json`,
      {withCredentials: true})
      .pipe(
        bufferCount(1),
        map(this.mapDeepValues.bind(this)),
        flatMap(this.convertStringsToDate.bind(this)),
      );
  }

  /**
   * Save the `entity`. If it is a new object without an `id` create a new instance, otherwise update it.
   */
  save(entity: E, format: string = 'json'): Observable<E> {
    entity = this.convertDateToStrings(entity);
    if (entity.id) {
      return this.http.put<E>(`${this.apiBaseUrl}${this.domainDefinition.path}/update/${entity.id}.${format}`,
        entity, this.getSaveOptions());
    } else {
      return this.http.post<E>(`${this.apiBaseUrl}${this.domainDefinition.path}/save.${format}`, entity, this.getSaveOptions());
    }
  }

  /**
   * Bulk save/update a list of resources
   */
  bulk(entities: E[], format: string = 'json'): Observable<E[]> {
    return this.http.put<E[]>(`${this.apiBaseUrl}${this.domainDefinition.path}/bulk.${format}`,
      entities, this.getSaveOptions()).pipe(
      map((saved: E[]) => this.convertStringsToDate(saved)),
    );
  }

  /**
   * Delete the entity from the server.
   * @param entity
   * @returns
   */
  destroy(entity: E): Observable<any> {
    return this.http.delete(this.apiBaseUrl + this.domainDefinition.path + '/' + entity.id + '.json', {withCredentials: true});
  }

  /**
   * Fetch a minimal list of all objects from the server
   *
   * TODO params should be structured. See also list
   * @param propertyName
   * @param params
   * @returns
   */
  listAssociation<R extends Entity>(propertyName: keyof E, params?: any): Observable<MinimalList<R>> {
    const associationService = this.serviceForAssociation(propertyName);
    return associationService.minimalList(params);
  }

  /**
   * Get form definition fot
   * @returns
   */
  getDomainDefinition(): DomainDefinition<E> {
    return this.domainDefinition;
  }

  /**
   * Domain definition for the current association dialog
   * TODO maybe instead of modifying domain definitions just pass a FormDefinition parameter
   * @returns
   */
  domainDefinitionOfAssociation<A extends Entity>(propertyName: keyof E): DomainDefinition<A> {
    const domainDef: DomainDefinition<A> = this.serviceForAssociation<A>(propertyName).copyDomainDefinition();
    return this.removeAssociationsFromDomainDefinition<A>(domainDef);
  }

  /**
   * Returns an Observable which resolves to true if the current user is allowed to edit the given property.
   * @param property
   * @returns
   */
  isFieldEditable(property: keyof E): Observable<boolean> {
    return this.getMetadata().pipe(map<Metadata<E>, boolean>((metadata) => {
      return metadata.propertyPermissions.editable[property];
    }));
  }

  /**
   * Returns an Observable for new instance with default values from server
   * @returns
   */
  getNewInstance(): Observable<E> {
    const path = this.apiBaseUrl + this.domainDefinition.path + '/create.json';
    return this.http.get<E>(path, {withCredentials: true});
  }

  /**
   * Find service which handles type of association `propertyName`.
   * @param propertyName
   * @returns
   */
  serviceForAssociation<A extends Entity>(propertyName: keyof E): CrudService<A> {
    const pd = <HasManyPropertyDefinition>this.domainDefinition.properties.get(propertyName);
    return this.injector.get<CrudService<A>>(pd.service);
  }

  /**
   * Open a entity create dialog using the {@link EntityFormDialogAdaptorComponent}.
   *
   * @param defaultValue pre populate form fields with this value. Primarily useful to set parent object associations.
   */
  createInDialog(defaultValue: Partial<E>): Observable<E> {
    const formConfig: EntityFormConfiguration<E> = {
      domainDefinition: this.domainDefinition,
      entity: defaultValue,
    };
    const domainName = this.translate.instant(this.domainDefinition.path + '.domain.name');
    const ref: DynamicDialogRef = this.dialogService.open(EntityFormDialogAdaptorComponent, {
      header: this.translate.instant('crud.domain.add', {item: domainName}),
      data: formConfig,
      dismissableMask: false,
      width: '500px',
    });
    return ref.onClose.pipe(
      first(), // unfortunately onClose is not completed when the dialog closes, so this ensures we unsubscribe correctly
      mergeMap((formValue: E) => {
        if (formValue == null) {
          throw Error('dialog cancelled');
        }
        return this.save(formValue);
      }),
    );
  }

  /**
   * convertStringsToDate changes all fields which are of type date to real JS Dates
   * JSON does not have a native Date type and therefore dates are serialized as ISO date strings
   * @param entities
   * @returns
   */
  public convertStringsToDate(entities: E[]): E[] {
    // TODO: convert StringsToDate for deep properties
    this.getDomainDefinition().properties.forEach((pd: PropertyDefinition, key) => {
      const name = key.toString();
      if (pd.type === 'date') {
        entities.forEach((entity: E) => entity[name] = !isNaN(new Date(entity[name]).getTime()) ? new Date(entity[name]) : null);
      } else if (pd.type === 'local-date') {
        entities.forEach((entity: E) => {
          try {
            entity[name] = entity[name] && entity[name].indexOf('-') > -1 ? LocalDate.parse(entity[name]) : entity[name];
          } catch (e) {
            console.warn(`could not parse ${entity[name]} as LocalDate`, e);
          }
        });
      } else if (pd.type === 'local-date-time') {
        entities.forEach((entity: E) => {
          try {
            entity[name] = entity[name] ? LocalDateTime.parse(entity[name]) : entity[name];
          } catch (e) {
            console.warn(`could not parse ${entity[name]} as LocalDateTime`, e);
          }
        });
      } else if (pd.type === 'local-time') {
        entities.forEach((entity: E) => {
          try {
            entity[name] = entity[name] ? LocalTime.parse(entity[name]) : entity[name];
          } catch (e) {
            console.warn(`could not parse ${entity[name]} as LocalTime`, e);
          }
        });
      }
    });
    return entities;
  }

  /**
   * convertDateToStrings serialized all date fields as local date strings
   * @param entities
   * @returns
   */
  public convertDateToStrings(entity: E): E {
    // TODO: convert convertDateToStrings for deep properties
    // TODO: make complete version
    // TODO: check DateInterceptor implementation
    this.getDomainDefinition().properties.forEach((pd: PropertyDefinition, key) => {
      const name = key.toString();
      if (pd.type === 'date') {
        try {
          if (entity[name] != null) {
            entity[name] = (entity[name] as Date).toISOString();
          }

        } catch (e) {
          console.warn(`could not parse ${entity[name]} as LocalDate`, e);
        }
      } else if (pd.type === 'local-date') {
        try {
          if (entity[name] != null) {
            entity[name] = LocalDate.from(LOCAL_DATE_FORMATTER.parse(entity[name].toString()));
          }
        } catch (e) {
          console.warn(`could not parse ${entity[name]} as LocalDate`, e);
        }
      }
    });
    return entity;
  }

  mapDeepValues(items: E[]): E[] {
    if (this.getDomainDefinition().propertiesMapping) {
      items.forEach(item => {
        for (const [field, prop] of this.domainDefinition.propertiesMapping) {
          item[field as string] = this.getMappedPropertyValue<E>(item, prop as Paths<E>);
        }
      })
    }

    return items;
  }

  getMappedPropertyValue<T>(obj: E, path: Paths<E>): any {
    return obj && (path as string[]).reduce(
      (result, prop) => result == null ? undefined : result[prop as string],
      obj,
    );
  }

  fetchLoadCommand(): Observable<LazyLoadEvent> {
    const params = {
      key: `${this.domainDefinition.path}.table.filter.0`,
    };
    return this.http.get<LazyLoadEvent>(this.apiBaseUrl + 'clientStore/get', {params});
  }

  storeLoadCommand(cmd: LazyLoadEvent): Observable<void> {
    const params = {
      key: `${this.domainDefinition.path}.table.filter.0`,
    };
    return this.http.post<void>(this.apiBaseUrl + 'clientStore/store', cmd,
      {params});
  }

  clearLoadCommand(): Observable<void> {
    const params = {
      key: `${this.domainDefinition.path}.table.filter.0`,
    };
    return this.http.delete<void>(this.apiBaseUrl + 'clientStore/clear',
      {params});
  }

  fetchTableSettings(): Observable<(keyof E)[]> {
    const params = {
      key: `${this.domainDefinition.path}.table.settings.0`,
    };
    return this.http.get<(keyof E)[]>(this.apiBaseUrl + 'clientStore/get', {params});
  }

  storeTableSettings(cmd: (keyof E)[]): Observable<void> {
    const params = {
      key: `${this.domainDefinition.path}.table.settings.0`,
    };
    return this.http.post<void>(this.apiBaseUrl + 'clientStore/store', cmd,
      {params});
  }

  clearTableSettings(cmd: (keyof E)[]): Observable<void> {
    const params = {
      key: `${this.domainDefinition.path}.table.settings.0`,
    };
    return this.http.delete<void>(this.apiBaseUrl + 'clientStore/clear',
      {params});
  }

  /**
   * Load metadata from server and cache response
   * Metadata contains information about editable properties and in the future probably more
   */
  protected getMetadata(): Observable<Metadata<E>> {
    if (!this.metadataObservable) {
      const path = this.apiBaseUrl + this.domainDefinition.path + '/metadata.json';
      this.metadataObservable = this.http.get<Metadata<E>>(path, {withCredentials: true})
        .pipe(shareReplay());
    }
    return this.metadataObservable;
  }

  private getSaveOptions() {
    return {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      withCredentials: true,
    };
  }

  private copyDomainDefinition(): DomainDefinition<E> {
    const newDef: DomainDefinition<E> = {...this.domainDefinition};
    newDef.properties = new Map<keyof E, PropertyDefinition>();
    this.domainDefinition.properties.forEach((value: PropertyDefinition, key: keyof E) => {
      newDef.properties.set(key, value);
    });
    return newDef;
  }

  private removeAssociationsFromDomainDefinition<A extends Entity>(domainDef: DomainDefinition<A>): DomainDefinition<A> {
    Array.from(domainDef.properties.keys())
      .filter((key: keyof A) => {
        const prop = domainDef.properties.get(key) as BelongsToPropertyDefinition;
        return ['has-many', 'belongs-to'].indexOf(prop.type) > -1 &&
          (!prop.inverse || prop.inverse !== key.toString());
      })
      .forEach((prop: keyof A) => domainDef.properties.delete(prop));
    return domainDef;
  }
}
