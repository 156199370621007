import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {PublicHolidayService} from './public-holiday.service';

@Injectable({
  providedIn: 'root',
})
export class PublicHolidayResolver  {

  constructor(private publicHolidayService: PublicHolidayService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Map<number, string>> {
    return this.publicHolidayService.load();
  }
}
