import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {SecurityContext} from '../definitions';
import {catchError, map} from 'rxjs/operators';
import {UsernamePasswordAuthService} from '../username-password-auth';
import {COOKIE_AUTH_CONFIG, CookieAuthConfig} from './config';

interface LoginResult {
  sub: string;
  roles: string[];
  custom_claims: any;
}

@Injectable()
export class CookieAuthService extends UsernamePasswordAuthService {

  constructor(router: Router, @Inject(COOKIE_AUTH_CONFIG) private config: CookieAuthConfig, private http: HttpClient) {
    super(router);
  }

  validateAuthentication(): Observable<SecurityContext> {
    return this.http.get<LoginResult>(this.config.authenticationEndpoint)
      .pipe(
        map((response) => {
          // consider the anonymous user as an unauthenticated user
          if (response.sub === '__grails.anonymous.user__') {
            return new SecurityContext(null, null);
          }
          return new SecurityContext(response.sub, response.roles, response.custom_claims || null)
        }),
        catchError((response, caught) => {
          console.warn(response);
          return of(new SecurityContext(null, null));
        }));
  }

  protected doLogin(username: string, password: string): Observable<SecurityContext> {
    const body = new HttpParams()
      .set('username', username)
      .set('password', password);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest',
    })
    return this.http.post<LoginResult>(this.config.loginEndpoint, body.toString(), {headers}).pipe(
      map(data => new SecurityContext(data.sub, data.roles, data.custom_claims || null)),
    );
  }


  logout() {
    if (this.config.logoutEndpoint) {
      this.http.get(this.config.logoutEndpoint).subscribe({
        next: () => super.logout(),
        error: err => console.error('Logout Error', err),
      });
    } else {
      super.logout();
    }
  }
}
