<ng-container *ngIf="notification">
  <div class="un-property-container">
      <div class="un-property-header">Betreff</div>
      <div>{{notification.subject}}</div>

      <div class="un-property-header">Absender</div>
      <div>{{notification.sender}}</div>

      <div class="un-property-header">Sende Datum</div>
      <div>{{notification.sentDateTime | date:"dd.MM.yyyy HH:mm"}}</div>

      <div class="un-property-header">An</div>
      <ul class="mail-list">
        <li *ngFor="let recipient of notification.to">{{recipient.address}} ({{recipient.status | deliveryStateTranslation}})</li>
      </ul>

      <div class="un-property-header">CC</div>
      <ul class="mail-list">
        <li *ngFor="let recipient of notification.cc">{{recipient.address}} ({{recipient.status | deliveryStateTranslation}})</li>
      </ul>

    <div class="controls">
      <a [routerLink]="['../../list']">Zurück</a>
    </div>
  </div>

  <iframe [src]="notification.mailContentURL" frameborder="0" class="un-notification-window"></iframe>
</ng-container>
