import {InjectionToken, Injector} from '@angular/core';
import {CachedCrudService, DomainDefinition, Entity, FilterType, PropertyDefinition} from '@ic/ng-crud-client';

export interface FeedbackType extends Entity {
  name: string;
  isDefault: boolean;
}

export const FEEDBACK_TYPE_SERVICE = new InjectionToken<CachedCrudService<FeedbackType>>('FEEDBACK_TYPE_SERVICE');

export const feedbackTypeDefinition: DomainDefinition<FeedbackType> = {
  path: 'feedbackType',
  service: FEEDBACK_TYPE_SERVICE,
  properties: new Map<keyof FeedbackType, PropertyDefinition>([
    ['name', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
  ]),
  formDefinitions: {
    default: {
      fields: ['name'],
    },
  },
  tableDefinitions: {
    default: {
      columns: ['name'],
      inlineEditable: true,
      immutable: false,
      disableDetail: false,
      sortField: 'name',
      sortOrder: 1,
      filters: {},
      filterStorable: true,
    },
  },
};


