import {Injectable} from '@angular/core';
import {from, Observable, of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe, DecimalPipe} from '@angular/common';
import {
  BelongsToPropertyDefinition,
  DomainDefinition,
  Entity,
  EnumPropertyDefinition,
  HasManyPropertyDefinition,
  MultiSelectPropertyDefinition,
  PropertyDefinition,
} from '../definitions';
import {map, mergeMap, tap, toArray} from 'rxjs/operators';
import {DateTimeFormatter, LocalDate, LocalDateTime, LocalTime} from '@js-joda/core';

export const DATE_DE = DateTimeFormatter.ofPattern('dd.MM.yyyy');
export const DATE_TIME_DE = DateTimeFormatter.ofPattern('dd.MM.yyyy HH:mm');
export const TIME_DE = DateTimeFormatter.ofPattern('HH:mm');

/**
 * StringifyService returns a string representations for many kinds of objects
 */
@Injectable({
  providedIn: 'root',
})
export class StringifyService {

  constructor(private translate: TranslateService, private datePipe: DatePipe, private decimalPipe: DecimalPipe) {
  }

  /**
   * Return the translated property name belonging to domainDefinition
   */
  getPropertyName<T extends Entity>(domainDefiniton: DomainDefinition<T>, propertyName: keyof T): Observable<string> {
    return null;
  }

  getPropertyValue(propertyDefinition: PropertyDefinition, value: any): Observable<string> {
    // TODO called many many times in a grid, we may want to do some performance tuning here
    switch (propertyDefinition.type) {
      case 'string':
      case 'text':
        return of(value);
      case 'local-date':
        return of(value instanceof LocalDate ? value.format(DATE_DE) : null);
      case 'local-time':
        return of(value instanceof LocalTime ? value?.format(TIME_DE) : null);
      case 'local-date-time':
        return of(value instanceof LocalDateTime ? value?.format(DATE_TIME_DE) : null);
      case 'boolean':
        return this.translate.get('crud.button.' + (value ? 'yes' : 'no'));
      case 'date':
        return of(this.datePipe.transform(value) || '');
      case 'enum':
        return this.transformEnum(value, propertyDefinition);
      case 'has-many':
        return this.transformOneToMany(value, propertyDefinition);
      case 'belongs-to':
        return this.transformBelongsTo(value, propertyDefinition);
      case 'action':
        return of('TODO: action template');
      case 'number':
        const digitsInfo = '1.' +
          (propertyDefinition.minFractionDigits != null ? propertyDefinition.minFractionDigits : 0) +
          '-' +
          (propertyDefinition.maxFractionDigits != null ? propertyDefinition.maxFractionDigits : 3);
        return of(this.decimalPipe.transform(value, digitsInfo));
      case 'int':
        return of (value);
      case 'multi-select':
        return this.transformMultiSelect(value, propertyDefinition);
    }
  }


  // property names
  // property values (enum, date, has-many, ...
  // Domain Definitions


  private transformEnum(value: any, pd: EnumPropertyDefinition): any {
    if (!value) {
      return of('');
    }
    const key = pd.prefix + '.' + value;
    return this.translate.get(key);
  }

  private transformOneToMany(items: Entity[], pd: HasManyPropertyDefinition): Observable<string> {
    return this.translate.get('crud.domain.selected').pipe(
      map((text: string) => {
        if (items.length <= 3) {
          return items.map((item: Entity) => item.label).join(', ');
        } else {
          return text.replace('{0}', '' + items.length);
        }
      }));
  }

  private transformBelongsTo(item: Entity, pd: BelongsToPropertyDefinition): Observable<string> {
    return of(item ? item.label : '');
  }

  private transformMultiSelect(items: any | null, pd: MultiSelectPropertyDefinition): Observable<string> {
    if (items == null) {
      return of('')
    }
    if (typeof items === 'string') {
      items = [items];
    }
    if (Array.isArray(items)){
      return from(items).pipe(
        mergeMap((item)=>{
          return this.translate.get(pd.prefix + '.'+ item);
        }),
        toArray(),
        map(labels => {
          return labels.join(', ')
        }),
      );
    }
    return of(items?.toString());
  }
}
