import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import {CRUD_SERVICE, CrudService} from '../crud.service';
import {TranslateService} from '@ngx-translate/core';
import {LazyLoadEvent, MenuItem, MessageService} from 'primeng/api';
import {
  ActionsPropertyDefinition,
  BulkPropertyDefinition,
  DomainDefinition,
  Entity,
  EnumPropertyDefinition,
  Filters,
  FilterType,
  MinimalList,
  PropertyDefinition,
  TableDefinition,
} from '../definitions';
import {calendarLocale} from '../i18n';
import {catchError, finalize, switchMap, take, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {Table} from 'primeng/table';
import {ViewCellDirective} from './view-cell.directive';
import {cloneDeep, isEqual} from 'lodash-es';
import {of, Subscription} from 'rxjs';
import {FilterMetadata} from 'primeng/api/filtermetadata';

export interface ColDef<E extends Entity> {
  field: keyof E;
  header: string;
  propertyDefinition: PropertyDefinition;
  qualifiedName: string;
  options?: any[];
  selected?: any;
  colConfig?: ColConfig
}

export interface ColConfig {
  field: string;
  after?: string;
  before?: string;
  actions?: ColAction[];
  bulk?: boolean;
  headerMenu$?: Observable<MenuItem[]>
}

export interface ColAction {
  id: string;
  actionDelegate: (model: any) => Observable<any> | void;
  isDisabled: (model: any) => boolean;
  isVisible?: (model: any) => boolean;
  icon?: (model: any) => string;
  toolTip?: (model: any) => string;
  label?: (model: any) => string;
  cssClass?: (model: any) => string;
  roles: string[];
}

/**
 * This TableComponent renders a table with filters given a {@link DomainDefinition} and {@link ColConfig}.
 *
 * The table contains the following features:
 * - sorting and filtering and pagination using CrudControllers on the server
 * - column template overriding
 * - inline editing
 * - action buttons
 *
 *
 * Override the template for the column `email`
 * @example
 * ```
 * <ic-table [colConfigs]="colConfigs">
 *   <a *icViewCell="'email' let row; let colDef = colDef" [href]="'mailto:' + row.email">{{row.email}}</a>
 * </ic-table>
 * ```
 */
@Component({
  selector: 'ic-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<E extends Entity> implements OnInit, AfterViewInit {
  @Input() colConfigs: ColConfig[] = [];
  @Input() useTableDef: string;
  @Input() maxSelectedLabels = 3;
  @Input() quickFilters: Filters; //allows definition for default quickFilters in specific lists
  hasUrlParams: boolean;
  private _queryParamsSubscription: Subscription;
  records: E[] = [];
  totalRecords: number;
  loading: boolean;
  domainDef: DomainDefinition<E>;
  tableDef: TableDefinition<E>;
  propertyKeys: (keyof E)[];
  addingForm: { propertyName: keyof E | null, domainDef: DomainDefinition<E>, dialogParams: object, row?: E };
  addingItemTitleParams: object;
  displayedItem: E;
  originalItem: E;
  cols: ColDef<E>[];
  rows = 10;
  visibleColumns: (keyof E)[];
  defaultFilterOriginal: Filters //reference for highlighting of reset filter button highlighting
  defaultFilter: Filters;
  globalFilter: '';
  calendarLocale = calendarLocale;
  selectedItems: E[];
  @ViewChild('table')
  table: Table;
  cellTemplateByColName: { [k in keyof E]?: any } = {};
  @Output() selectionChanged = new EventEmitter<E[]>();
  @Output() storedLoadCommandFetched = new EventEmitter<LazyLoadEvent>();
  private _loadCommand: any;
  first = 0;

  constructor(@Inject(CRUD_SERVICE) private crudService: CrudService<E>, private injector: Injector,
              public translate: TranslateService, private cd: ChangeDetectorRef, private router: Router,
              private messageService: MessageService, private route: ActivatedRoute) {
    this.domainDef = this.crudService.getDomainDefinition();
  }

  ngOnInit(): void {
    this.tableDef = this.domainDef.tableDefinitions &&
      (this.useTableDef ? this.domainDef.tableDefinitions[this.useTableDef] : this.domainDef.tableDefinitions.default);
  }

  @ContentChildren(ViewCellDirective)
  set viewCellTemplates(cellTemplates: QueryList<ViewCellDirective>) {
    for (const tpl of cellTemplates) {
      this.cellTemplateByColName[tpl.colName] = tpl;
    }
  }


  get filterActive(): boolean {
    const loadCommandFilters = this._loadCommand?.filters;
    if (loadCommandFilters && Object.keys(loadCommandFilters).length === 0) {
      return false;
    } else {
      return !isEqual(loadCommandFilters, {...this.defaultFilterOriginal, ...this.quickFilters})
    }
  }

  ngAfterViewInit() {
    this.propertyKeys = Array.from(this.domainDef.properties.keys());
    this.translate.get(`${this.domainDef.path}.domain.name`).subscribe((text: string) => this.addingItemTitleParams = {item: text});
    if (!this.hasUrlParams) {
      this.defaultFilter = this._defaultFilters();
      this.fetchVisibleColumns$().pipe(
        tap((names) => {
          this.setColumns(names);
        }),
        switchMap((names) => this.fetchLoadCommand$()),
        tap((cmd: LazyLoadEvent) => {
          // tslint:disable-next-line:no-unused-expression
          this.patchFilter(cmd) || this.loadData(cmd);
        }),
      ).subscribe((cmd) => {
        this.storedLoadCommandFetched.emit(cmd);
      });
    } else {
      this.fetchVisibleColumns$().pipe(
        take(1),
      ).subscribe((names) => {
        this.setColumns(names);
      });
      // this.fetchVisibleColumns$().pipe(
      //   tap((names) => {
      //     this.setColumns(names)
      //   }),
      //   take(1),
      // ).subscribe();
    }
    this.cd.detectChanges();
  }

  public get loadCommand() {
    return {...this._loadCommand};
  }

  onSelectionChange($event) {
    this.selectionChanged.emit(this.selectedItems);
  }

  refreshData() {
    this.loadData(this._loadCommand);
  }

  patchFilter(partialEvent: Partial<LazyLoadEvent>): boolean {
    let patched = false;
    this.storedLoadCommandFetched.emit(partialEvent);
    if (partialEvent.sortField) {
      this.table.sortField = partialEvent.sortField;
    }
    if (partialEvent.sortOrder) {
      this.table.sortOrder = partialEvent.sortOrder
    }
    if (partialEvent.sortField || partialEvent.sortOrder) {
      this.table.tableService.onSort({field: this.table.sortField, order: this.table.sortOrder});
    }
    if (partialEvent.filters) {
      for (const [propertyName, filter] of Object.entries(partialEvent.filters)) {
        patched = true;
        if (!this.table.filters) this.table.filters = {};
        if (filter) {
          this.table.filter(filter.value, propertyName, filter.matchMode);
          const colDef = this.cols?.find(it => it.qualifiedName === propertyName);
          if (colDef) {
            if (
              ['enum', 'has-many', 'belongs-to'].includes(colDef.propertyDefinition.type) &&
              !((colDef.propertyDefinition.type === 'has-many' || colDef.propertyDefinition.type === 'belongs-to') && colDef.propertyDefinition.noOptions)
            ) {
              this.waitForOptions(colDef, () => {
                if (colDef.options) {
                  if (Array.isArray(filter.value)) {
                    colDef.selected = filter.value.filter(val => {
                      const valueToCompare = val.id !== undefined ? val.id : val;
                      return colDef.options.some(option => {
                        const optionValue = option.value.id !== undefined ? option.value.id : option.value;
                        return optionValue == valueToCompare;
                      });
                    });
                  } else {
                    colDef.selected = colDef.options.find(option => {
                      const optionValue = option.value.id !== undefined ? option.value.id : option.value;
                      return optionValue == filter.value;
                    });
                  }
                }
              });
            } else if (filter.matchMode == "isNull" || filter.matchMode == "isNotNull") {
              colDef.selected = null;
            } else {
              colDef.selected = filter.value;
            }
          }
        }
      }
    }
    if (partialEvent.rows) {
      this.table.rows = partialEvent.rows;
    }
    return patched;
  }

  waitForOptions(colDef: any, callback: () => void, interval: number = 100, timeout: number = 10000) {
    const startTime = Date.now();

    const checkOptions = () => {
      if (colDef && colDef.options && colDef.options.length > 0 || colDef.propertyDefinition.noOptions) {
        callback();
      } else if (Date.now() - startTime < timeout) {
        setTimeout(checkOptions, interval);
      } else {
        console.error('Timeout: colDef.options did not load in time:', colDef.qualifiedName);
      }
    };

    checkOptions();
  }

  loadData(event: LazyLoadEvent): void {
    // todo: debounce when typing? (probably already in primeng)
    if (this._loadCommand === undefined) {
      this._loadCommand = cloneDeep(event);
      return;
    }

    function getRows() {
      return event.rows ? event.rows : this.table.rowsPerPageOptions.include(event.rows) ? event.rows : 10;
    }

    setTimeout(() => { // prevent ExpressionChangedAfterItHasBeenCheckedError
      this.loading = true;
      event = {...this._loadCommand, ...event, filters: {...cloneDeep(this._defaultFilters()), ...event.filters, ...this.quickFilters}};
      const colDef = this.cols?.find(it => it.qualifiedName === event.sortField);
      if ((colDef?.propertyDefinition.type == 'has-many' || colDef?.propertyDefinition.type == 'belongs-to') && colDef?.propertyDefinition.sortProperty) {
        event.sortField = colDef.propertyDefinition.sortProperty;
      }
      this.crudService.list(this.cleanFilters(event))  // todo: clean filter from hidden columns
        .pipe(finalize(() => this.loading = false))
        .subscribe((page: any) => {
          for (let i = 0; i < this.records.length && i < page.records.length; i++) {
            this.records[i] = page.records[i];
          }
          if (this.records.length < page.records.length) {
            page.records.splice(0, this.records.length);
            this.records.splice(this.records.length, 0, ...page.records);
          } else if (this.records.length > page.records.length) {
            this.records.splice(page.records.length, this.records.length - page.records.length);
          }
          this.totalRecords = page.total;
          if (this.tableDef.filterStorable) {
            this.rows = getRows();
          }
          if (!isEqual(this._loadCommand, event)) {
            this._loadCommand = cloneDeep(event);
            if (this.tableDef.filterStorable && !this.hasUrlParams) {
              this.crudService.storeLoadCommand(event).pipe(take(1)).subscribe();
            }
          }
        });
    });
  }

  onEditInit(record: object) {
    this.originalItem = {...record} as E;
  }

  equals(a: object, b: object) {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  onEditComplete(record: Entity): Entity {
    if (this.equals(record, this.originalItem)) {
      return;
    }  // nothing changed
    const crudService = (!this.addingForm || !this.addingForm.propertyName) ? this.crudService :
      this.crudService.serviceForAssociation(this.addingForm.propertyName);
    const reset = () => {
      this.loading = false;
      this.addingForm = null;
    };
    const reload = () => {
      this.loadData(this._loadCommand);
      this.loadOptions();
    };

    setTimeout(() => { // prevent ExpressionChangedAfterItHasBeenCheckedError
      this.loading = true;
      if (this.addingForm && this.addingForm.row &&
        this.addingForm.propertyName && this.domainDef.path !== this.addingForm.propertyName) {
        record = this.updatedRowOfOrigin(record);
      }
      record = this.formatTimeField(record);
      crudService.save(record as E).pipe(finalize(reset))
        .subscribe({
          next: reload,
          error: error => {
            if (error.error && error.error.type === 'https://hospital-pool.ch/problems/validation-exception') {
              const details = error.error.errors.map((err: { title: string; }) => err.title).join('\n');
              this.messageService.add({
                  severity: 'error',
                  summary: error.error.title,
                  detail: details,
                  sticky: true,
                })
            }
          },
        });
    });
  }

  /**
   * Opens the dialog for adding an item
   */
  append() {
    if (this.tableDef && this.tableDef.appendLink) {
      this.router.navigate([`${this.tableDef.appendLink}`]);
    } else {
      this.addingForm = {
        propertyName: null, // just a hack to generate a property name
        domainDef: this.domainDef,
        dialogParams: this.addingItemTitleParams,
      };
    }
  }

  /**
   * Opens the dialog for adding an association
   */
  addAssociation(item: E, propertyName: keyof E) {
    const domainDef = this.crudService.domainDefinitionOfAssociation<E>(propertyName);
    this.translate.get(`${domainDef.path}.domain.name`).subscribe((text: string) => {
        this.addingForm = {
          propertyName: propertyName,
          domainDef: domainDef,
          dialogParams: {item: text},
          row: item,
        };
      },
    );
  }

  /**
   * Filters an association for autocomplete
   * @param col the column definition
   * @param event containing the query
   */
  filterAssociation(col: ColDef<E>, event: { query: string }) {
    const query = event.query;
    this.crudService.listAssociation(col.field, {filter: query}).subscribe((items: MinimalList<Entity>) => {
      col.options = items;
      this.cd.markForCheck();
    });
  }

  /**
   * Opens the dialog for displaying an item
   */
  display(row: E): void {
    if (this.tableDef && this.tableDef.displayLink) {
      this.router.navigate([`${this.tableDef.displayLink}/${row.id}`]);
    } else if (this.tableDef && this.tableDef.inlineEditable) {
      this.displayedItem = row;
    } else {
      this.crudService.get(row.id).subscribe((item: E) => {
        this.addingForm = {
          propertyName: null,
          domainDef: this.domainDef,
          dialogParams: this.addingItemTitleParams,
          row: item,
        };
      });
    }
  }

  getActions(field: string): ColAction[] {
    return this.colConfigs.find(o => o.field === field && o.actions)?.actions;
  }

  filter(value: any, col: ColDef<E>) {
    this.table.filter(col.selected, col.qualifiedName, col.propertyDefinition.filter);
  }

  clearFilter(): void {
    this.defaultFilter = {};
    this.globalFilter = '';
    this._loadCommand.filters = {};
    this.setColumns(this.visibleColumns);
    const cmd = {...this._loadCommand, filters: {...this._defaultFilters(), ...this.quickFilters}}
    cmd.rows = this.tableDef.tableDefaultRowsOption ? this.tableDef.tableDefaultRowsOption : 10;
    this.patchFilter(cmd);
    if (Object.keys(cmd.filters).length === 0) { // No filters? -> we still need to reload and nullify client store
      this.crudService.storeLoadCommand(cmd).pipe(take(1)).subscribe();
      this.refreshData();
    }
  }

  onTableSettingsChange(event: (keyof E)[]) {
    this.setColumns(event);
    this.refreshData();
    this.crudService.storeTableSettings(event).pipe(take(1),).subscribe();
  }

  private setColumns(names: (keyof E)[]) {
    this.visibleColumns = names;
    this.cols = [];
    names.forEach(name => {
      this.addColumn(name);
    });
    this.initColConfigs();
    this.loadOptions();
    if (!this.defaultFilterOriginal) {
      this.defaultFilterOriginal = {...this.defaultFilter};
    }
  }

  private fetchVisibleColumns$(): Observable<(keyof E)[]> {
    if (this.tableDef.columnsConfigurable) {
      return this.crudService.fetchTableSettings().pipe(
        catchError((err) => this._defaultVisibleColumns()))
    } else {
      return this._defaultVisibleColumns();
    }
  }

  private _defaultVisibleColumns(): Observable<(keyof E)[]> {
    return of(this.tableDef.columns || this.propertyKeys as Array<keyof E>);
  }

  private fetchLoadCommand$(): Observable<LazyLoadEvent> {
    if (this.tableDef.filterStorable && !this.hasUrlParams) {
      const cmd = this.crudService.fetchLoadCommand().pipe(
        catchError((err) => of({...this._loadCommand, filters: this._defaultFilters()})),
      )
      return cmd;
    } else {
      return of({filters: this._defaultFilters()});
    }
  }

  private _defaultFilters(): Filters {
    let filters = {...this.defaultFilter};
    if (this.tableDef.filters) {
      filters = {
        ...filters,
        ...this.tableDef.filters,
      }
    }
    return filters;
  }

  private updatedRowOfOrigin(associatedValue: any) {
    const pd = this.domainDef.properties.get(this.addingForm.propertyName as keyof E);
    if (pd.type === 'belongs-to') {
      this.addingForm.row[this.addingForm.propertyName] = associatedValue;
    } else {
      if (!this.addingForm.row[this.addingForm.propertyName]) {
        this.addingForm.row[this.addingForm.propertyName] = <any>[];
      }
      (this.addingForm.row[this.addingForm.propertyName] as unknown as any[]).push(associatedValue);
    }
    return this.addingForm.row;
  }

  private addColumn(name: keyof E) {
    const pd = this.domainDef.properties.get(name);
    if (pd) {
      const col: ColDef<E> = {
        field: name,
        header: name.toString(),
        propertyDefinition: pd,
        selected: pd.defaultFilterValue,
        qualifiedName: name as string,
      };
      const pm = this.domainDef.propertiesMapping?.get(name);
      if (pm) {
        col.qualifiedName = pm.join('.');
      }
      this.cols.push(col);
      if (pd.defaultFilterValue) {
        if (!this.defaultFilter) {
          this.defaultFilter = {};
        }
        this.defaultFilter[col.qualifiedName] = {value: pd.defaultFilterValue, matchMode: pd.filter};
      }
    }
  }


  private initColConfigs() {
    this.colConfigs.forEach(colConfig => {
      let index = this.cols.length;
      // TODO: check if multiple id-fields work...
      let col: ColDef<E>;
      if (colConfig.actions) {
        col = this.createActionCol(colConfig);
      } else if (colConfig.bulk) {
        col = this.createBulkCol(colConfig);
      } else {
        throw Error(`colConfig not supported: ${JSON.stringify(colConfig)}`);
      }
      col.colConfig = colConfig;

      if (colConfig.after) {
        index = this.cols.findIndex(o => o.field === colConfig.after) + 1;
      } else if (colConfig.before) {
        index = Math.max(0, this.cols.findIndex(o => o.field === colConfig.before) - 1);
      }
      this.cols.splice(index, 0, col);
    });
  }

  private createActionCol(colConfig: ColConfig): ColDef<E> {
    return {
      field: colConfig.field,
      header: 'actions',
      propertyDefinition: {type: 'action', readonly: true} as ActionsPropertyDefinition,
    } as ColDef<E>;
  }

  private createBulkCol(colConfig): ColDef<E> {
    return {
      field: colConfig.field,
      header: 'bulk',
      propertyDefinition: {type: 'bulk'} as BulkPropertyDefinition,
    } as ColDef<E>;
  }

  private loadOptions() {
    this.cols.forEach((col: ColDef<E>) => {
      col.options = col.propertyDefinition.filter !== FilterType.NONE &&
      (
        col.propertyDefinition.type === 'enum' ||
        col.propertyDefinition.type === 'has-many' ||
        col.propertyDefinition.filter && col.propertyDefinition.filter === FilterType.IN
      ) ? this.getOptions(col.propertyDefinition, col.field) : undefined;
    });
  }

  private getOptions(pd: PropertyDefinition, name: keyof E): any[] {
    switch (pd.type) {
      case 'boolean':
        return [
          {label: this.translate.instant('crud.button.yes'), value: true},
          {label: this.translate.instant('crud.button.no'), value: false},
        ];
      case 'enum':
        return this.getEnumOptions(pd);
      case 'has-many':
      case 'belongs-to':
        if (!pd.noOptions) {
          return this.getAssociationOptions(name, pd);
        }
      default:
        return [];
    }
  }

  private getEnumOptions(pd: EnumPropertyDefinition): any[] {
    const options: object[] = [];
    for (const entry of Object.keys(pd.cls)) {
      this.translate.get(pd.prefix + '.' + entry).subscribe((text: string) => {
        options.push({label: text, value: entry});
      });
    }
    options.sort((a: any, b: any) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
    return options;
  }

  private getAssociationOptions(propertyName: keyof E, pd: PropertyDefinition) {
    const options: object[] = [];
    this.crudService.listAssociation(propertyName).subscribe((items: MinimalList<Entity>) => {
      items.forEach(item => {
        options.push({label: item.label, value: {id: item.id}});
      });
      this.cd.detectChanges();
    });
    return options;
  }

  /** Removes filters of hidden columns from the event/command */
  private cleanFilters(event: LazyLoadEvent): LazyLoadEvent {
    if (event.filters) {
      const qualifiedFilterKeys = Object.keys(event.filters) as (keyof E & string)[];
      const filters: { [k: string]: FilterMetadata } = {};

      const propertiesByQualifiedKey = new Map();
      for (const col of this.tableDef.columns) {
        propertiesByQualifiedKey.set(col, col);
      }
      if (this.domainDef.propertiesMapping instanceof Map) {
        for (const [propertyName, path] of this.domainDef.propertiesMapping.entries()) {
          propertiesByQualifiedKey.set(path.join('.'), propertyName);
        }
      }

      for (const qualifiedKey of qualifiedFilterKeys) {
        if (!propertiesByQualifiedKey.has(qualifiedKey) || this.visibleColumns?.includes(propertiesByQualifiedKey.get(qualifiedKey))) {
          filters[qualifiedKey] = event.filters[qualifiedKey];
        }
      }
      return {...event, filters: filters}
    }
    return event;
  }

  next() {
    this.first = this.first + this.rows;
    this.loadData({
      ...this.loadCommand,
      first: this.first,
    });
  }

  prev() {
    this.first = this.first - this.rows;
    this.loadData({
      ...this.loadCommand,
      first: this.first,
    });
  }

  reset() {
    this.refreshData();
  }

  isLastPage(): boolean {
    return this.records ? this.first + this.rows >= this.totalRecords : true;
  }

  isFirstPage(): boolean {
    return this.records ? this.first === 0 : true;
  }

  private formatTimeField(record: Entity) {
    const regex = new RegExp('^[0-9]:[0-5][0-9]$')
    Object.keys(record).forEach((key) => {
      if (this.domainDef.properties.has(key as keyof E) &&
        this.domainDef.properties.get(key as keyof E).type === 'local-time' &&
        typeof record[key] === 'string' &&
        regex.test(record[key])) {
        record[key] = 0 + record[key];
      }
    });
    return record;
  }
}
