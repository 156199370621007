<div class="login-form">
  <form id="form-login" [formGroup]="loginForm" (ngSubmit)="onSubmit()"
        [class.error]="!loginForm.valid && loginForm.touched" novalidate>
    <h2>Bitte melden Sie sich an</h2>
    <div *ngIf="message" class="error-message">{{message}}</div>
    <div *ngIf="loginForm.errors && loginForm.errors['cookiesDisabled']"
         class="error-message">Im Browser müssen Cookies aktiviert sein.
    </div>
    <div class="p-fluid">
      <div class="field" style="padding: 0">
        <label for="input-username">{{ config.labelUsername || 'Benutzername' }}</label>
        <input pInputText id="input-username" formControlName="username">
      </div>
      <div class="field" style="padding: 0">
        <label for="input-password">{{ config.labelPassword || 'Passwort' }}</label>
        <input pInputText id="input-password" formControlName="password" type="password">
      </div>
    </div>
    <div class="field">
      <button pButton type="submit" [disabled]="!loginForm.valid" label="Anmelden"></button>
    </div>
  </form>
  <a *ngIf="config.passwordRetrieval" class="forgot-password" routerLink="/forgot-password" title="Passwort vergessen?">Passwort vergessen?</a>
</div>
