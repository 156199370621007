import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {CRUD_SERVICE, CrudService} from '../crud.service';
import {TranslateService} from '@ngx-translate/core';
import {AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {
  DomainDefinition,
  Entity,
  EnumPropertyDefinition,
  ImagePropertyDefinition,
  MinimalList,
  PropertyDefinition
} from '../definitions';
import {calendarLocale} from '../i18n';
import {StringifyService} from '../stringify/stringify.service';
import {ImageSizeValidator} from '../validators/image-size.validator';
import {FileUpload} from 'primeng/fileupload';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {RegexValidator} from '../validators/regex.validator';


/**
 * EntityFormComponent creates a form from a `domainDefinition` and emit an event `save` when the user saves the form.
 * If no form definition is given the definition from the `CrudService` for this component is taken.
 *
 * Example:
 *
 * ```html
 * <!-- Take the given domainDefinition -->
 * <ic-entity-form [domainDefinition]="domainDefinition" (save)="onCreate($event)"></ic-entity-form>
 *
 * <!-- Use form definition from the `CrudService` -->
 * <ic-entity-form (save)="onCreate($event)"></ic-entity-form>
 * ```
 */
@Component({
  selector: 'ic-entity-form',
  templateUrl: './entity-form.component.html',
  styleUrls: ['./entity-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityFormComponent<E extends Entity> implements OnInit, OnChanges, OnDestroy {

  @Input()
  domainDef: DomainDefinition<E>;

  // apply a specific key of the formDefinitions, value is set to create or edit by the default crud html files
  @Input()
  formDefinitionName: string;

  @Input()
  entity: E;

  @Input()
  title: string;

  @Output()
  save: EventEmitter<E> = new EventEmitter();

  optionLists: Map<keyof E, object[]> = new Map();
  properties: Map<keyof E, PropertyDefinition>;
  entityForm: UntypedFormGroup;
  autoCompleteQueries = new Map<keyof E, string>();
  filteredOptionLists: Map<keyof E, object[]> = new Map();
  calendarLocale = calendarLocale;

  propertyKeys: (keyof E)[];
  fields: Map<keyof E, PropertyDefinition>;
  destroy = new Subject<boolean>();

  addingAssociation: { propertyName: keyof E, domainDef: DomainDefinition<Entity>, dialogParams: object };
  protected crudService: CrudService<E>;

  constructor(protected injector: Injector,
              public translate: TranslateService,
              protected cd: ChangeDetectorRef,
              protected stringify: StringifyService) {
  }

  private _tenant: number | undefined;

  get tenant() {
    return this._tenant;
  }

  set tenant(tenant: number | undefined) {
    this._tenant = tenant;
  }

  ngOnInit(): void {
    if (!this.domainDef) {
      this.crudService = this.injector.get<CrudService<E>>(CRUD_SERVICE);
      this.domainDef = this.crudService.getDomainDefinition();
    } else {
      this.crudService = this.injector.get<CrudService<E>>(this.domainDef.service);
    }
    this.propertyKeys = Array.from(this.domainDef.properties.keys());
    const formProperties = this.initFields();
    this.populate(this.domainDef, formProperties);
    if (this.entity) {
      this.entityForm.patchValue(this.entity);
    } else {
      this.crudService.getNewInstance().subscribe((instance: E) => {
        this.entityForm.patchValue(instance);
        this.cd.markForCheck();
      });
    }
    this.entityForm.statusChanges
      .pipe(
        takeUntil(this.destroy),
        distinctUntilChanged(),
      )
      .subscribe(() => this.cd.markForCheck());
  }


  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entity && !changes.entity.firstChange) {
      this.entityForm.reset();
      this.entityForm.patchValue(this.entity);
    }
  }

  /**
   * populate fills dropdowns and multiselects with content
   * @param fd
   */
  populate(fd: DomainDefinition<E>, formProperties: string[]): void {
    if (!fd) {
      throw new Error('Property domainDefinition of EntityFormComponent must be set');
    }
    this.createForm(fd, formProperties);
    this.properties = fd.properties;

    // Go through each
    fd.properties.forEach((pd: PropertyDefinition, name: keyof E) => {
      switch (pd.type) {
        case 'enum':
          this.populateEnumOptions(name, pd);
          break;
        case 'has-many':
        case 'belongs-to':
          if (!pd.lazy) {
            this.populateAssociation(name, pd);
          }
          break;
        case 'multi-select':
          this.optionLists.set(name, pd.options.concat());
          this.filteredOptionLists.set(name, pd.options.concat());
          this.cd.markForCheck();
          break;
      }
    });
  }

  /**
   * onSubmit is called, when the user tries to submit the form
   * The form is checked for validity and if it is valid a save event is emitted with
   * the form value as content.
   */
  onSubmit(): void {
    if (this.entityForm.status !== 'VALID') {
      for (const fieldName of Object.keys(this.entityForm.controls)) {
        const control = this.entityForm.get(fieldName);
        control.markAsDirty();
      }
      return;
    }
    this.domainDef.properties.forEach((pd: PropertyDefinition, name: keyof E) => {
      if (pd.type === 'has-many') {
        this.entityForm.value[name] = ((this.entityForm.value[name] || []) as Entity[]).map((item: Entity) => {
          return {id: item.id, label: item.label};  // remove any extra data
        });
      }
    });
    this.save.next(Object.assign(this.entity || {}, this.entityForm.value) as E);
  }

  /**
   * Filters an association for autocomplete
   * @param propertyName
   * @param event containing the query
   */
  filterAssociation(propertyName: keyof E, event): void {
    this.autoCompleteQueries.set(propertyName, event.query);
    const pd = this.domainDef.properties.get(propertyName);
    if (pd['lazy']) {
      this.remoteFilterAssociation(propertyName);
    } else {
      this.localFilterAssociation(propertyName);
    }
  }

  /**
   * Adds the selected file to the form
   */
  onImageSelect(fu: FileUpload, key: string, event: { files: File[] }) {
    if (event.files.length) {
      // if (this.entityForm.controls['name']) {
      //     this.entityForm.controls['name'].setValue(event.files[0].name);
      // }
      const reader = new FileReader();
      reader.readAsDataURL(event.files[0]);
      fu.clear();
      reader.onload = () => {
        this.entityForm.controls[key].setValue(reader.result);
        this.cd.markForCheck();
      };
    }
  }

  /**
   * Displays the errors of a control as a list of strings.
   * Assumption: The error values are translated text.
   * @param fieldName
   */
  errors(fieldName: string): string[] {
    const errors = this.entityForm.controls[fieldName].errors;
    return errors ? Object.keys(errors).map(key => errors[key]).filter(value => typeof value === 'string') : [];
  }

  /**
   * Can be used as a hook for modifying option lists
   * @param propertyName
   * @param items
   */
  updateOptionLists(propertyName: keyof E, items: MinimalList<Entity>): void {
    this.filteredOptionLists.set(propertyName, items);
    this.cd.markForCheck();
  }

  /**
   * Opens the dialog for adding an association
   * @param propertyName
   */
  addAssociation(propertyName: keyof E): void {
    const domainDef = this.crudService.domainDefinitionOfAssociation(propertyName);
    this.translate.get(`${domainDef.path}.domain.name`).subscribe((text: string) =>
      this.addingAssociation = {
        propertyName: propertyName,
        domainDef: domainDef,
        dialogParams: {item: text}
      }
    );
  }

  /**
   * Updates the form with the new association value
   * @param record
   */
  onAssociationComplete(record: object): void {
    setTimeout(() => { // prevent ExpressionChangedAfterItHasBeenCheckedError
      // this.loading = true;
      this.crudService.serviceForAssociation(this.addingAssociation.propertyName).save(record as E, 'minimal')
        .subscribe(
          (result: E) => this.updateAssociation(result));
    });
  }

  /**
   * Underline matching part of query result
   * @param propertyName
   * @param text
   */
  formatQueryResult(propertyName: keyof E, text: string): string {
    let result = text;
    const queryString = this.autoCompleteQueries.get(propertyName);
    if (queryString) {
      const query = new RegExp(queryString, 'gi');
      let re: RegExpExecArray;
      while ((re = query.exec(text)) !== null) {
        result = text.slice(0, re.index) + '<u>' + re[0] + '</u>' + text.slice(re.index + re[0].length);
      }
    }
    return result;
  }

  /**
   * Create an Angular ReactiveForm which will be referenced from the html template
   * This also adds the validation rules to the form.
   * @param domainDefinition
   */
  protected createForm(domainDefinition: DomainDefinition<E>, formProperties: string[]): void {
    this.entityForm = new UntypedFormGroup({});
    for (const propertyName of formProperties as Array<keyof E>) {
      const pd = domainDefinition.properties.get(propertyName);

      const validators: ValidatorFn[] = [];
      const asyncValidators: AsyncValidatorFn[] = [];
      if (!pd['nullable']) {
        validators.push(Validators.required);
      }
      if (pd.hasOwnProperty('minSize')) {
        validators.push(Validators.minLength(pd['minSize']));
      }
      if (pd.hasOwnProperty('maxSize')) {
        validators.push(Validators.maxLength(pd['maxSize']));
      }
      if (pd.hasOwnProperty('width') || pd.hasOwnProperty('height')) {
        asyncValidators.push(ImageSizeValidator(pd as ImagePropertyDefinition, this.translate));
      }
      if (pd.hasOwnProperty('regex')) {
        validators.push(RegexValidator(pd['regex'] as RegExp, this.translate));
      }

      const control = new UntypedFormControl(null, validators, asyncValidators);
      this.entityForm.addControl(propertyName.toString(), control);
      if (this.entity && pd.readonly) {
        control.disable();
      }
    }
  }

  protected populateAssociation(propertyName: keyof E, pd: PropertyDefinition) {
    const params = this.tenant ? {tenant: this.tenant} : undefined;
    this.crudService.listAssociation(propertyName, params).subscribe((items: MinimalList<Entity>) => {
      this.optionLists.set(propertyName, items);
      this.cd.markForCheck(); // run change detection
      if (this.addingAssociation) {
        this.entityForm.controls[this.addingAssociation.propertyName.toString()].updateValueAndValidity();
      }
    });
  }

  // if no formDefinitions is configured all fields defined in propertyKeys are initialized
  private initFields(): string[] {
    this.fields = new Map();
    const formDefinitions = this.domainDef.formDefinitions;
    let names;
    let appliedFormDefinitionName = this.formDefinitionName;
    if (!appliedFormDefinitionName || !formDefinitions[appliedFormDefinitionName]) {
      appliedFormDefinitionName = 'default';
    }
    if (formDefinitions && formDefinitions[appliedFormDefinitionName]) {
      names = formDefinitions[appliedFormDefinitionName].fields;
    } else {
      names = this.propertyKeys as Array<keyof E>;
    }
    names.forEach(name => {
      const propertyDefinition = this.domainDef.properties.get(name);
      if (propertyDefinition) {
        this.fields.set(name, propertyDefinition);
      }
    });

    return names
  }

  /**
   * Filters an association for autocomplete remotely
   * @param propertyName
   */
  private remoteFilterAssociation(propertyName: keyof E): void {
    const params = {filter: this.autoCompleteQueries.get(propertyName)};
    if (this.tenant) {
      params['tenant'] = this.tenant;
    }
    this.crudService.listAssociation(propertyName, params).subscribe((items: MinimalList<Entity>) => {
      this.updateOptionLists(propertyName, items);
    });
  }

  /**
   * Filters an association for autocomplete locally
   * @param propertyName
   */
  private localFilterAssociation(propertyName: keyof E): void {
    const items = this.optionLists.get(propertyName).filter(item => {
      const queryString = this.autoCompleteQueries.get(propertyName);
      if (queryString) {
        const query = new RegExp(queryString, 'gi');
        return query.test(item['label'] + (item['group'] || ''));
      }
      return true;
    });
    this.updateOptionLists(propertyName, items as MinimalList<Entity>);
  }

  private updateAssociation(record: object): void {
    const property = this.addingAssociation.propertyName;
    this.addingAssociation = null;
    const pd = this.domainDef.properties.get(property);
    if (pd.type === 'has-many') {
      this.entityForm.controls[property.toString()].value.push(record);
    } else {
      this.entityForm.controls[property.toString()].setValue(record);
      this.optionLists.set(property, [record]);
      this.entityForm.controls[property.toString()].updateValueAndValidity();
    }
    this.cd.markForCheck();
  }

  private populateEnumOptions(propertyName: keyof E, pd: EnumPropertyDefinition): void {
    const options: { label: string, value: string }[] = [];
    this.optionLists.set(propertyName, options);

    for (const entry of Object.keys(pd.cls)) {
      this.stringify.getPropertyValue(pd, entry).subscribe(it => {
        options.push({label: it, value: entry});
        this.cd.markForCheck();
      });
    }
    options.sort((a, b) => a.label.localeCompare(b.label))
  }
}
