import {Inject, Injectable, OnDestroy} from '@angular/core';
import {EMPTY, merge, NEVER, Observable, of, OperatorFunction, pipe, Subject, Subscription} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  scan,
  shareReplay,
  skipUntil,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import {get} from 'lodash-es';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Availability, AVAILABILITY_SERVICE} from '../../../pool-crud/availability/availability.definition';
import {CrudService, ListPage} from '@ic/ng-crud-client';
import {
  CellSelection,
  Change,
  ChangeCommand,
  EntryType,
  ImmutablePlanningState,
  PlanningFetchCommand,
  PlanningRow,
  PlanningRowBatch,
  PlanningRowType,
  ResourceGroupKey,
} from '../../planning.definitions';
import {LocalDate} from '@js-joda/core';
import {environment} from '../../../../environments/environment';
import {StintService} from '../../../pool-crud/stint/stint.service';
import {BookingService} from '../../../pool-crud/booking/booking.service';
import {ChangeDetailsService} from './change-details.service';
import {PlanningFetchService} from './planning-fetch.service';
import {Action} from './action';
import {
  emptySelection,
  INITIAL_APPOINTMENT_STATE,
  INITIAL_DIALOG_STATE,
  INITIAL_EDIT_STATE,
  INITIAL_STATE,
} from '../../planning.constants';
import {AppointmentService} from '../../../pool-crud/appointment/appointment.service';
import {ErrorHandlerService} from '../../../error-handling/error-handler.service';
import {AuthService} from '@ic/auth';
import {Role} from '../../../role';
import {PoolShiftService} from '../../../pool-crud/pool-shift/pool-shift.service';
import {AssignmentService} from '../../../pool-crud/assignment/assignment.service';
import {Assignment} from '../../../pool-crud/employee/employee.definition';

/* Connect to Chrome plugin Redux DevTools */
const win = window as any;
win.devTools = win.__REDUX_DEVTOOLS_EXTENSION__ && win.__REDUX_DEVTOOLS_EXTENSION__.connect();


/**
 * Checks if a selection has entries of multiple types
 */
const isDiverse = (entries: Change[]): boolean => {
  return entries.length > 1 && entries.slice(1, entries.length).some((entry: Change) =>
    entries[0].entryType !== entry.entryType);
};

/**
 * Operator to get a slice of the state
 */
const slice = (path: string) =>
  pipe(
    map(state => get(state, path)),
    distinctUntilChanged(),
    // filter(obj => obj != null)
  );

const defaultEntrySelection = (cells: CellSelection[], changes: Change[]) => {
  const selection = emptySelection();
  if (!isDiverse(changes) && changes.length > 0) {
    const key: string = changes[0].entryType;
    selection[key] = changes.map((change: Change, pos: number) => pos);
  }
  return selection;
};

const defaultCellOptionSelection = (cells: CellSelection[]) => {
  return [...cells.keys()];
};


/**
 * A simple redux store
 * (cf. https://angularfirebase.com/lessons/redux-from-scratch-angular-rxjs/)
 */
@Injectable()
export class PlanningStore implements OnDestroy {
  private _action$: Subject<Action> = new Subject();
  private _state$: Observable<ImmutablePlanningState>;
  private _epic: Subscription;

  private _selectionObservables = new Map<string, Observable<any>>();

  constructor(private http: HttpClient,
              @Inject(AVAILABILITY_SERVICE) private availabilityService: CrudService<Availability>,
              private bookingService: BookingService,
              private poolShiftService: PoolShiftService,
              private stintService: StintService,
              private changeDetailsService: ChangeDetailsService,
              private planningFetchService: PlanningFetchService,
              private appointmentService: AppointmentService,
              private assignmentService: AssignmentService,
              private errorHandlerService: ErrorHandlerService,
              private authService: AuthService,
  ) {
    /* State is defined as result of the actions */
    this._state$ = this._action$.pipe(
      this._reducer(),
      shareReplay(1),
    );

    /* Combine the epics and put their actions on the action stream */
    /* An error in an epic completes that observable. */
    /* To continue the observable after an error see https://iamturns.com/continue-rxjs-streams-when-errors-occur/ */
    this._epic = merge(
      this._epicLoadConfig(),
      this._epicLoadQuickFilter(),
      this._epicLoadPlanningRows(),
      this._epicStoreFetchCommand(),
      this._epicLoadDaysWithAppointments(),
    ).subscribe((action: Action) => this.dispatch(action));

  }

  private static _addPlanningRows(rows: PlanningRow[], batch: PlanningRowBatch) {
    rows.splice(batch.first, batch.rows.length, ...batch.rows);
    return rows;
  }

  /**
   * Cleans up subscriptions
   */
  ngOnDestroy(): void {
    this._epic.unsubscribe();
  }

  /**
   * Puts an action on the action stream
   */
  dispatch(action: Action) {
    this._action$.next(action);
  }

  /**
   * Selects config from the state stream
   */
  selectConfig(): Observable<PlanningFetchCommand> {
    return this._state$.pipe(
      map((state: ImmutablePlanningState) => state.fetchCommand),
      distinctUntilChanged(),
      filter((config: PlanningFetchCommand) => config !== null),
      shareReplay(1),
    );
  }

  /**
   * Selects planningConfiguration.groupBy from the state stream
   */
  selectGroupBy(): Observable<ResourceGroupKey> {
    return this._state$.pipe(
      map((state: ImmutablePlanningState) => state.fetchCommand && state.fetchCommand.groupBy),
      distinctUntilChanged(),
      shareReplay(1),
    );
  }

  /**
   * @deprecated
   */
  selectChangeCommand(): Observable<ChangeCommand> {
    return this.select('dialog.changeCommand');
  }

  selectFetchCommand(): Observable<PlanningFetchCommand> {
    return this.select('fetchCommand');
  }

  /**
   * General select method
   */
  select<T>(path: string): Observable<T> {
    if (!this._selectionObservables[path]) {
      this._selectionObservables[path] = this._state$.pipe(
        slice(path),
        filter((it) => it !== undefined),

        shareReplay(1),
      );
    }
    return this._selectionObservables[path];
  }

  /**
   * Loads the planning configuration
   * input action: LOAD_CONFIG
   * output action: SET_CONFIG or LOADING_FAILURE
   */
  private _epicLoadConfig(): Observable<Action> {
    return this._action$.pipe(
      filter((action: Action) => action.type === Action.LOAD_CONFIG),
      switchMap(() => {
        return this.planningFetchService.get();
      }),
      map((config: PlanningFetchCommand) => new Action(Action.SET_CONFIG, config)),
      catchError(error => of(new Action(Action.LOADING_FAILURE, error))),
      shareReplay(1),
    );
  }

  /**
   * Loads the planning configuration
   * input action: LOAD_CONFIG
   * output action: SET_CONFIG or LOADING_FAILURE
   */
  private _epicLoadQuickFilter(): Observable<Action> {
    return this._action$.pipe(
      filter((action: Action) => action.type === Action.LOAD_QUICK_FILTER),
      switchMap((action: Action) => {
        return this.assignmentService.list(action.payload)
      }),
      map((assignments: ListPage<Assignment>) => {
        const config = {
          ou: assignments.records.map((assignment) => assignment.organisationUnit.id),
          showEmptyOURows: true,
          showAllStints: false,
          showAllBookings: false,
        }
        return new Action(Action.UPDATE_CONFIG, config);
      }),
      catchError(error => of(new Action(Action.LOADING_FAILURE, error))),
      shareReplay(1),
    );
  }

  /**
   * Loads the planning rows
   * input action: LOAD_PLANNING_ROWS, SET_CONFIG, UPDATE_CONFIG, UPDATE_PLANNING_ROWS, SET_PLANNING_START, TOGGLE_GROUP
   * output action: SET_PLANNING_ROWS or LOADING_FAILURE
   */
  private _epicLoadPlanningRows(): Observable<Action> {
    return this._action$.pipe(
      // It is important to wait for the first SET_CONFIG event before doing anything else.
      // In POOL-1686 and POOL-1674 we had the problem that for some reason UPDATE_CONFIG was dispatched before the SET_CONFIG event, which
      // lead to the problem that state.fetchCommand was incomplete and therefore the planning rows could not be loaded which resulted in
      // an empty planning board.
      skipUntil(this._action$.pipe(filter(action => action.type === Action.SET_CONFIG))),

      filter((action: Action) => [Action.LOAD_PLANNING_ROWS, Action.SET_CONFIG, Action.UPDATE_CONFIG,
        Action.TOGGLE_GROUP, Action.UPDATE_PLANNING_ROWS,
      ].indexOf(action.type) > -1),
      withLatestFrom(this._state$),
      switchMap(([action, state]: [Action, ImmutablePlanningState]) => {
        if (!state.fetchCommand) {
          return NEVER;
        }
        const params = this.planningFetchService.toParams(state.fetchCommand);
        params.first = action.type === Action.UPDATE_PLANNING_ROWS ? action.payload.first : state.rowScrolling.index;
        params.max = action.type === Action.UPDATE_PLANNING_ROWS ? action.payload.max : state.maxRows;
        return this.http.get<PlanningRowBatch>(environment.serverURL + 'planning/rows', {params}).pipe(  // move to service
          map((data) => {
            data.first = params.first;
            data.isUpdate = action.type === Action.UPDATE_PLANNING_ROWS;
            return data;
          }),
        );
      }),
      map((data) => new Action(Action.SET_PLANNING_ROWS, data)),
      catchError(error => of(new Action(Action.LOADING_FAILURE, error))),
      shareReplay(1),
    );
  }

  private _epicStoreFetchCommand(): Observable<Action> {
    return this._action$.pipe(
      // It is important to wait for the first SET_CONFIG event before doing anything else.
      // In POOL-1686 and POOL-1674 we had the problem that for some reason UPDATE_CONFIG was dispatched before the SET_CONFIG event, which
      // lead to the problem that state.fetchCommand was incomplete and therefore the planning rows could not be loaded which resulted in
      // an empty planning board.
      skipUntil(this._action$.pipe(filter(action => action.type === Action.SET_CONFIG))),

      filter((action: Action) => [Action.SET_CONFIG, Action.UPDATE_CONFIG,
        Action.TOGGLE_GROUP].indexOf(action.type) > -1),
      withLatestFrom(this._state$),
      switchMap(([action, state]: [Action, ImmutablePlanningState]) => {
        if (!state.fetchCommand) {
          return NEVER;
        }
        return this.planningFetchService.store(state.fetchCommand);
      }),
      map(() => new Action(Action.NONE)),
      catchError(error => of(new Action(Action.LOADING_FAILURE, error))),
      shareReplay(1),
    );
  }


  /**
   * Loads the entries affected by the provided selection
   * input action: LOAD_AFFECTED_ENTRIES
   * output action: SET_SHIFTS or LOADING_FAILURE
   */
  private _epicLoadDaysWithAppointments(): Observable<Action> {
    if (!(this.authService.securityContextSnapshot &&
      this.authService.securityContextSnapshot.hasAnyRole([Role.ROLE_READ_TRAINING_POOL, Role.ROLE_READ_MEETING_POOL]))) {
      return EMPTY;
    }
    return this._action$.pipe(
      // It is important to wait for the first SET_CONFIG event before doing anything else.
      // In POOL-1686 and POOL-1674 we had the problem that for some reason UPDATE_CONFIG was dispatched before the SET_CONFIG event, which
      // lead to the problem that state.fetchCommand was incomplete and therefore the planning rows could not be loaded which resulted in
      // an empty planning board.
      skipUntil(this._action$.pipe(filter(action => action.type === Action.SET_CONFIG))),

      filter((action: Action) => [Action.LOAD_PLANNING_ROWS, Action.SET_CONFIG, Action.UPDATE_CONFIG,
        Action.TOGGLE_GROUP].indexOf(action.type) > -1),
      withLatestFrom(this._state$),
      switchMap(([action, state]: [Action, ImmutablePlanningState]) => {
        if (!state.fetchCommand) {
          return NEVER;
        }
        const params = {start: state.fetchCommand.start.toString(), end: state.fetchCommand.end.toString()};
        return this.http.get<HttpResponse<object>>(environment.serverURL + 'planning/daysWithAppointments', {
          params,
          observe: 'response',
        });
      }),
      map((response: HttpResponse<object>) => {
        return response.status !== 204 ?
          new Action(Action.SET, {daysWithAppointments: new Map(Object.entries(response.body))}) :
          new Action(Action.NONE);
      }),
      catchError(error => of(new Action(Action.LOADING_FAILURE, error))),
      shareReplay(1),
    );
  }

  /**
   * Operator that keeps track of the state and reduces it according to the action
   */
  private _reducer(): OperatorFunction<Action, ImmutablePlanningState> {
    return scan((state: ImmutablePlanningState, action: Action) => {
      let next: ImmutablePlanningState;
      switch (action.type) {

        case Action.SET:
          next = {...state, ...action.payload};
          break;

        case Action.SET_ROW_SCROLL_INDEX:
          if (action.payload !== state.rowScrolling.index) {
            next = {
              ...state,
              rowScrolling: {index: action.payload, diff: action.payload - state.rowScrolling.index},
            };
          } else {
            next = state;
          }
          break;

        case Action.TOGGLE_GROUP:
          const nextKeys = new Set(state.fetchCommand.closed);
          if (!nextKeys.delete(action.payload)) {
            nextKeys.add(action.payload);
          }
          next = {
            ...state, fetchCommand: {
              ...state.fetchCommand,
              closed: nextKeys,
            },
          };
          break;

        case Action.SET_PLANNING_ROWS:
          next = {
            ...state,
            planningRows: PlanningStore._addPlanningRows(action.payload.isUpdate ?
              [...state.planningRows] : new Array(action.payload.total), action.payload as PlanningRowBatch),
          };
          break;

        case Action.LOAD_CONFIG:
          next = {...state, loading: true};
          break;

        case Action.SET_CONFIG:
          next = {
            ...state,
            fetchCommand: {
              start: LocalDate.now().withDayOfMonth(1), ...action.payload,
            },
            loading: true,
          };
          break;

        case Action.UPDATE_CONFIG:
          if (state.fetchCommand) {
            next = {
              ...state, fetchCommand: {...state.fetchCommand, ...action.payload},
            };
          } else {
            // Don't modify state if the fetchCommand has not been set by SET_CONFIG before.
            // The problem is that if we apply a patch to the fetchCommand here, most likely this will not result in a complete fetchCommand
            // which then causes further errors.
            next = state;
          }
          break;

        case Action.SET_AFFECTED_ENTRIES:
          next = {
            ...state, edit: {
              ...state.edit,
              affectedEntries: action.payload,
              entrySelection: defaultEntrySelection(state.edit.cellSelection, action.payload),
            },
          };
          break;

        case Action.SET_LOADING:
          next = {...state, loading: action.payload};
          break;

        case Action.VALIDATE_CHANGES:
        case Action.SAVE_CHANGES:
          next = {...state, dialogLoading: action.payload};
          break;

        case Action.LOADING_FAILURE:
          this.errorHandlerService.handleError(action.payload);
          next = {...state, error: action.payload, loading: false, dialogLoading: false};
          break;

        case Action.SET_BOOKINGS:
          break;

        case Action.SET_CELL_SELECTION:
          next = {
            ...state,
            edit: {
              ...INITIAL_EDIT_STATE,
              cellSelection: action.payload,
              cellOptionSelection: defaultCellOptionSelection(action.payload),
            },
          };
          if (action.payload.length && action.payload[0].row.type === PlanningRowType.ORGANISATION_UNIT) {
            next.edit.entryTypeSelection = EntryType.STINT;
          }
          if (next.edit.cellSelection.length && state.appointment.day) {
            next.appointment = INITIAL_APPOINTMENT_STATE;  // close appointment dialog
            next.dialog = INITIAL_DIALOG_STATE;  // reset dialog
          }
          break;

        case Action.ADD_CELL_SELECTION:
          const newSelection = [...state.edit.cellSelection];
          // ensure that each cell can only be selected once
          for (const cell of action.payload as CellSelection[]) {
            if (!newSelection.find(it => it.day.isEqual(cell.day) && it.row === cell.row)) {
              newSelection.push(cell);
            }
          }
          next = {
            ...state,
            edit: {
              ...state.edit,
              cellSelection: newSelection,
              // todo: keep existing selection, add new items
              entrySelection: defaultEntrySelection(newSelection, state.edit.affectedEntries),
              // todo: keep existing selection, add new items
              cellOptionSelection: defaultCellOptionSelection(newSelection),
            },
          };
          if (action.payload.length && action.payload[0].row.type === PlanningRowType.ORGANISATION_UNIT) {
            next.edit.entryTypeSelection = EntryType.STINT;
          }
          if (next.edit.cellSelection.length && state.appointment.day) {
            next.appointment = INITIAL_APPOINTMENT_STATE;  // close appointment dialog
            next.dialog = INITIAL_DIALOG_STATE;  // reset dialog
          }
          break;

        case Action.SET_CHANGES:
          const cmd: ChangeCommand = action.payload;
          next = {...state, dialogLoading: false, dialog: {...state.dialog, changeCommand: cmd}};
          cmd.changes.forEach((target) => {
            if ([EntryType.STINT, EntryType.BOOKING, EntryType.AVAILABILITY].indexOf(target.entryType) > -1) {
              const origin = state.edit.affectedEntries.find((item) =>
                item.entryType === target.entryType && item.entry.id === target.entry.id);
              if (origin) {
                this.changeDetailsService.addChangeDetails(origin, target, state.poolShifts);
              }
            } else if ([EntryType.INTERVIEW, EntryType.MEETING, EntryType.TRAINING].indexOf(target.entryType) > -1) {
              const origin = state.appointment.selectedAppointment;
              if (origin) {
                this.changeDetailsService.addAppointmentDetails(origin, target);
              }
            }
          });
          break;

        case Action.PATCH_DIALOG_STATE:
          next = {...state, dialog: {...state.dialog, ...action.payload}};
          break;

        case Action.INCR_CURRENT_PAGE:
          next = {...state, dialog: {...state.dialog, currentPage: state.dialog.currentPage + 1}};
          break;

        case Action.DECR_CURRENT_PAGE:
          next = {...state, dialog: {...state.dialog, currentPage: state.dialog.currentPage - 1}};
          break;

        case Action.PATCH_EDIT_STATE:
          next = {...state, edit: {...state.edit, ...action.payload}};
          break;

        case Action.SET_SELECTED_DAY:
          next = {...state, appointment: {...state.appointment, day: action.payload}};
          break;

        case Action.PATCH_APPOINTMENT_STATE:
          next = {...state, appointment: {...state.appointment, ...action.payload}};
          if (next.appointment.day && state.edit.cellSelection.length) {
            next.edit = INITIAL_EDIT_STATE;  // close edit dialog
            next.dialog = INITIAL_DIALOG_STATE;  // reset dialog
          }
          break;

        default:
          next = state;
      }
      if (win.devTools) {
        win.devTools.send(action.type, next);
      }
      return next;
    }, INITIAL_STATE);
  }


}
