import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpContext, HttpInterceptor} from '@angular/common/http';
import {merge, Observable, Subject, timer} from 'rxjs';
import {BannerMessage, ServerEnv, ServerStatus} from './definitions';
import {distinctUntilChanged, filter, first, map, shareReplay, switchMap, switchMapTo, tap,} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {ReadOnlyInterceptor} from './read-only.interceptor';
import {AuthService} from '@ic/auth';
import {Role} from '../role';

export interface ApplicationStatusCommand {
  message?: BannerMessage;
  readOnly?: boolean;
}

/**
 * ApplicationStatusService is responsible to provide information about the current server state, which includes
 * - the current environment
 * - read-only mode
 * - server version
 * - maintenance messages (not yet implemented)
 */
@Injectable({
  providedIn: 'root',
})
export class ApplicationStatusService implements OnDestroy {
  private readonly _statusLoader$ = new Subject<Observable<ServerStatus>>();
  private readonly _status$: Observable<ServerStatus>;
  private _currentStatus: ServerStatus;
  private _addedCSS: string

  constructor(protected http: HttpClient, @Inject(DOCUMENT) protected document: Document,
              @Inject(HTTP_INTERCEPTORS) interceptors: HttpInterceptor[], authService: AuthService) {
    const readOnlyInterceptor: ReadOnlyInterceptor = <ReadOnlyInterceptor>interceptors.find(it => it instanceof ReadOnlyInterceptor);
    readOnlyInterceptor.readonlyErrors$.subscribe(() => this.setReadOnly(true));

    authService.securityContext$.pipe(
      filter(ctx => ctx.hasAnyRole([Role.ROLE_RESOURCE])),
      first(),
    ).subscribe(ctx => {
      const meta = document.createElement('meta');
      meta.setAttribute('name', 'apple-itunes-app');
      meta.setAttribute('content', 'app-id=1611361158');
      document.head.prepend(meta);
    })

    // client to reload server status every 5'
    const scheduledLoader: Observable<Observable<ServerStatus>> = timer(0, 300000).pipe(
      map(() => this.http.get<ServerStatus>('/api/info')),
    );
    this._status$ = merge(this._statusLoader$, scheduledLoader).pipe(
      switchMap((it) => it),
      tap((status) => {
        this._currentStatus = status;
        if (this._addedCSS != null) {
          document.body.classList.remove(this._addedCSS)
        }
        this._addedCSS = this.cssClassForEnv(status);
        document.body.classList.add(this._addedCSS);
      }),
      shareReplay(1),
    )
  }

  setReadOnly(readOnly: boolean): void {
    const context = new HttpContext();
    ReadOnlyInterceptor.bypass(context);
    this.persistServerStatus({readOnly}, context)
  }

  setBannerMessage(message: BannerMessage): void {
    this.persistServerStatus({message}, null)
  }

  get status$(): Observable<ServerStatus> {
    return this._status$;
  }

  get readOnly$(): Observable<boolean> {
    return this._status$.pipe(
      map(status => status.readOnly),
      distinctUntilChanged(),
    )
  }

  get bannerMessage$(): Observable<BannerMessage> {
    return this._status$.pipe(
      map(status => status.bannerMessage),
      distinctUntilChanged(),
    )
  }

  get snapshot(): ServerStatus {
    return {...this._currentStatus};
  }

  ngOnDestroy(): void {
    this._statusLoader$.complete();
  }

  protected persistServerStatus(body: ApplicationStatusCommand, context?: HttpContext): void {
    this._statusLoader$.next(
      this.http.post('/api/v1/serverStatus', body, {context}).pipe(
        switchMapTo(this.http.get<ServerStatus>('/api/info')),
      ),
    )
  }

  protected cssClassForEnv(status: ServerStatus): string {
    switch (status.environment) {
      case ServerEnv.APPLICATION:
        return 'env-app'
      case ServerEnv.DEVELOPMENT:
        return 'env-dev'
      case ServerEnv.CUSTOM:
      default:
        return 'env-' + status.environmentName.toUpperCase();
    }
  }
}
