import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AngularJSContentComponent} from './angular-js/angular-js-content.component';
import {AngularAppComponent} from './angular-js/angular-app.component';
import {NotFoundComponent} from '@ic/ng-crud-client';
import {LandingPageComponent} from './landing-page/landing-page/landing-page.component';
import {AuthGuard, ProtectedRoutes} from '@ic/auth';
import {Role} from './role';
import {PoolConfigurationResolverService} from './pool-crud/pool-configuration/pool-configuration-resolver.service';
import {ApplicationStatusResolver} from './application-status/application-status-resolver.service';
import {PublicHolidayResolver} from './pool-crud/public-holiday/public-holiday.resolver';
import {AssignmentCanPlanResolver} from './pool-crud/assignment/assignment-can-plan-resolver.service';


const appRoutes: ProtectedRoutes = [
  {
    path: '', redirectTo: 'pool3/dashboard', pathMatch: 'full',
  },
  {
    path: 'start', component: LandingPageComponent,
  },
  {
    path: 'pool3',
    component: AngularAppComponent,
    resolve: {
      poolConfiguration: PoolConfigurationResolverService,
      serverStatus: ApplicationStatusResolver,
    },
    children: [
      {
        path: 'testing-demand',
        loadChildren: () => import('./demand/demand.module')
          .then(m => m.DemandModule),
      },
      {
        path: 'notification',
        loadChildren: () => import('./user-notification-wrapper/user-notification-wrapper.module')
          .then(m => m.UserNotificationWrapperModule),
      },
      {
        path: 'planning',
        loadChildren: () => import('./planning/planning.module').then(m => m.PlanningModule),
        canActivate: [AuthGuard],
        data: {
          permission: {
            any: [
              Role.ROLE_READ_AVAILABILITY_POOL,
              Role.ROLE_READ_AVAILABILITY_OU,
              Role.ROLE_READ_ACTIVEBOOKING_POOL,
              Role.ROLE_READ_ACTIVEBOOKING_OU,
              Role.ROLE_READ_STINT_POOL,
              Role.ROLE_READ_STINT_OU,
              Role.ROLE_READ_BLOCKEDSTINT_POOL,
              Role.ROLE_READ_BLOCKEDSTINT_OU,
            ],
          },
        },
      },
      {
        path: 'assignment',
        loadChildren: () => import('./assignment/assignment.module').then(m => m.AssignmentModule),
      },
      {
        path: 'training',
        loadChildren: () => import('./lists/training/training.module').then(m => m.TrainingModule),
      },
      {
        path: 'meeting',
        loadChildren: () => import('./lists/meeting/meeting.module').then(m => m.MeetingModule),
      },
      {
        path: 'wochenende',
        loadChildren: () => import('./shift-management/shift-management.module').then(m => m.ShiftManagementModule),
      },
      {
        path: 'schichtleitung',
        loadChildren: () => import('./shift-management/shift-management.module').then(m => m.ShiftManagementModule),
      },
      {
        path: 'booking',
        loadChildren: () => import('./lists/booking/booking.module').then(m => m.BookingModule),
        resolve: {publicHolidayResolver: PublicHolidayResolver},
      },
      {
        path: 'resource',
        loadChildren: () => import('./lists/resource/resource.module').then(m => m.ResourceModule),
      },
      {
        path: 'unused-resource',
        loadChildren: () => import('./lists/resource/unused-resource/unused-resource.module').then(m => m.UnusedResourceModule),
      },
      {
        path: 'planner-management',
        loadChildren: () => import('./lists/planner-management/planner-management.module').then(m => m.PlannerManagementModule),
      },
      {
        path: 'qualification',
        loadChildren: () => import('./lists/qualification/qualification.module').then(m => m.QualificationModule),
      },
      {
        path: 'health-institution',
        loadChildren: () => import('./lists/health-institution/health-institution.module').then(m => m.HealthInstitutionModule),
      },
      {
        path: 'user',
        loadChildren: () => import('./lists/user/user.module').then(m => m.UserModule),
      },
      {
        path: 'absence',
        loadChildren: () => import('./lists/absence/absence.module').then(m => m.AbsenceModule),
      },
      {
        path: 'agency',
        loadChildren: () => import('./lists/agency/agency.module').then(m => m.AgencyModule),
      },
      {
        path: 'reporting',
        loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule),
      },
      {
        path: 'course',
        loadChildren: () => import('./lists/course/course.module').then(m => m.CourseModule),
      },
      {
        path: 'education',
        loadChildren: () => import('./lists/education/education.module').then(m => m.EducationModule),
      },
      {
        path: 'feedbackType',
        loadChildren: () => import('./lists/feedback-type/feedback-type.module').then(m => m.FeedbackTypeModule),
      },
      {
        path: 'sharedFileTag',
        loadChildren: () => import('./lists/shared-file-tag/shared-file-tag.module').then(m => m.SharedFileTagModule),
      },
      {
        path: 'upgradeTrainingCourse',
        loadChildren: () => import('./lists/upgrade-training-course/upgrade-training-course.module').then(m => m.UpgradeTrainingCourseModule),
      },
      {
        path: 'options',
        loadChildren: () => import('./options/options.module').then(m => m.OptionsModule),
      },
      {
        path: 'organisationUnit',
        loadChildren: () => import('./lists/organisation-unit/organisation-unit.module').then(m => m.OrganisationUnitModule),
      },
      {
        path: 'shift',
        loadChildren: () => import('./lists/shift/shift.module').then(m => m.ShiftModule),
      },
      {
        path: 'stint',
        loadChildren: () => import('./lists/stint/stint.module').then(m => m.StintModule),
        resolve: {assignmentContext: AssignmentCanPlanResolver},
      },
      {
        path: 'stint-offer',
        loadChildren: () => import('./lists/stint-offer/stint-offer.module').then(m => m.StintOfferModule),
      },
      {
        path: 'arztzeugnis',
        loadChildren: () => import('./medical-certificate/medical-certificate.module').then(m => m.MedicalCertificateModule),
      },
      {
        path: 'synchronisation',
        loadChildren: () => import('./lists/sync-external-system/sync-external-system.module').then(m => m.SyncExternalSystemModule),
      },
      {
        path: 'server-config',
        loadChildren: () => import('./application-status/server-config/server-config.module').then(m => m.ServerConfigModule),
      },
      {
        path: 'function',
        loadChildren: () => import('./lists/function/function.module').then(m => m.FunctionModule),
      },
      {
        path: 'user-feedback-priority',
        loadChildren: () => import('./lists/user-feedback-priority/user-feedback-priority.module').then(m => m.UserFeedbackPriorityModule),
      },
      {
        path: 'software',
        loadChildren: () => import('./lists/software/software.module').then(m => m.SoftwareModule),
      },
      {
        path: 'log',
        loadChildren: () => import('./lists/log/log.module').then(m => m.LogModule),
      },
      {
        path: 'pool',
        loadChildren: () => import('./lists/pool/pool.module').then(m => m.PoolModule),
      },
      {
        path: 'specialty',
        loadChildren: () => import('./lists/specialty/specialty.module').then(m => m.SpecialtyModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        resolve: {assignmentContext: AssignmentCanPlanResolver},
      },
      {
        path: 'booking-request/confirm-deny',
        loadChildren: () => import('./confirm-deny-dialog/confirm-deny-dialog.module').then(m => m.ConfirmDenyDialogModule),
      },
      {
        path:'update-password',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
      },       // Add other Angular 8+ routes here.
      {path: '**', component: NotFoundComponent},
    ],
  },

  // Catch everything that does not start with pool3 and handle it as angularJS ui-router routes.
  // For all matched paths a full AngularJS App is loaded.
  {path: '**', component: AngularJSContentComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {
    useHash: true,
    initialNavigation: 'enabledNonBlocking'
})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
