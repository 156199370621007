import {CrudService} from '@ic/ng-crud-client';
import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Qualification, qualificationDefinition} from './qualification.definition';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class QualificationService extends CrudService<Qualification> {

  constructor(http: HttpClient, injector: Injector) {
    super(qualificationDefinition, http, injector);
  }

  moveUp(id: number): Observable<unknown> {
    return this.http.get(this.apiBaseUrl + 'qualification/moveUp/' + id);
  }

  moveDown(id: number): Observable<unknown> {
    return this.http.get(this.apiBaseUrl + 'qualification/moveDown/' + id);
  }

  delete(model: any): Observable<unknown> {
    return this.http.delete(this.apiBaseUrl + `qualification/delete/${model.id}`);
  }

  getBookable(): Observable<any> {
    return this.http.get(this.apiBaseUrl + 'qualification/getBookable');
  }

  toggleBookableState(model: any): any {
    return this.http.post(this.apiBaseUrl + 'qualification/toggleBookableState/', model,)
  }
}
