import {Injectable, Injector} from '@angular/core';
import {CrudService} from '@ic/ng-crud-client';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {UpgradeTrainingCourse, upgradeTrainingCourseDefinition} from './upgrade-training-course.definition';


@Injectable({
  providedIn: 'root',
})
export class UpgradeTrainingCourseService extends CrudService<UpgradeTrainingCourse> {

  constructor(http: HttpClient, injector: Injector) {
    super(upgradeTrainingCourseDefinition, http, injector)
  }
  delete(model: any): Observable<unknown> {
    return this.http.delete(this.apiBaseUrl + `upgradeTrainingCourse/delete/${model.id}`);
  }
}
