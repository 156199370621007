import {CrudService} from '@ic/ng-crud-client';
import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PublicHoliday, publicHolidayDefinition} from './public-holiday.definition';
import {LocalDate} from '@js-joda/core';
import {map, shareReplay, tap} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class PublicHolidayService extends CrudService<PublicHoliday> {
  private _map: Map<number, string>;
  private _allHolidays$ = this.list().pipe(
    map((result: PublicHoliday[]) => new Map(result.map(holiday => [holiday.date.toEpochDay(), holiday.holidayLabel]))),
    tap((holidays) => this._map = holidays),
    shareReplay(1),
  )

  constructor(http: HttpClient, injector: Injector) {
    super(publicHolidayDefinition, http, injector);
  }

  holidaysOfDates$(dates: LocalDate[]): Observable<(LocalDate[] | string[])[]> {
    const result = this._allHolidays$.pipe(
      map((holidays) => [
        dates,
        dates.map((date) => holidays.get(date.toEpochDay())),
      ]),
    )
    return result;
  }

  load(): Observable<Map<number, string>> {
    return this._allHolidays$;
  }

  label(date: LocalDate): string {
    return this._map?.get(date.toEpochDay()) || '';
  }

}
