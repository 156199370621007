import {Injectable, Injector} from '@angular/core';
import {CrudService, ListPage} from '@ic/ng-crud-client';
import {HttpClient} from '@angular/common/http';
import {OrganisationUnit, organisationUnitDefinition} from './organisation-unit.definition';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {SelectItem} from 'primeng/api';
import {Observable, Subject} from 'rxjs';
import {Action} from '../../planning/services/store/action';
import {AuthService, SecurityContext} from '@ic/auth';
import {AssignmentService} from '../assignment/assignment.service';
import {Assignment} from '../employee/employee.definition';


@Injectable({
  providedIn: 'root',
})
export class OrganisationUnitService extends CrudService<OrganisationUnit> {
  private _action$: Subject<Action> = new Subject();


  constructor(private authService: AuthService,
              private assignmentService: AssignmentService,
              http: HttpClient,
              injector: Injector) {
    super(organisationUnitDefinition, http, injector);
  }

  delete(model: any): Observable<unknown> {
    return this.http.delete(this.apiBaseUrl + `organisationUnit/delete/${model.id}`);
  }

  unitOptionsForResources(employees: number[], includeVirtualUnit = false): Observable<SelectItem[]> {
    const params: any = {employees, includeVirtualUnit};
    return this.http.get<OrganisationUnit[]>(
      environment.serverURL + 'organisationUnit/forResources.json', {params},
    ).pipe(
      map((units: OrganisationUnit[]) => units.map((unit: OrganisationUnit) => ({
          value: unit,
          label: unit.label,
          healthInstitution: unit.healthInstitution,
        }),
      )));
  }

  canPlan(): Observable<ListPage<Assignment> | Assignment[]> {
    const action = new Action(Action.LOAD_QUICK_FILTER, {
      filters: {
        employee: {value: [{id: this.employeeId(this.authService.securityContextSnapshot)}], matchMode: 'in'},
        canPlan: {value: [true], matchMode: 'in'},
      },
    });
    return this.assignmentService.list(action.payload);
  }

  private employeeId(securityContext: SecurityContext) {
    return securityContext.customClaims.get('employee_id');
  }

}


