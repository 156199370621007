<ng-container *ngIf="actionState$ | async as actionState">
  <div class="tooltip-wrapper"
       [pTooltip]="actionState.toolTip | translate"
       tooltipPosition="left">
  <p-button *ngIf="actionState.isVisible"
            [icon]="actionState.icon"
            [styleClass]="actionState.cssClass"
            [disabled]="actionState.isDisabled"
            label="{{ actionState.label }}"
            (onClick)="onClickAction()"></p-button>
  </div>
</ng-container>
