import {Component} from '@angular/core';
import {ApplicationStatusService} from '../application-status.service';
import {ServerStatus} from '../definitions';

/**
 * StatusBannerComponent is responsible to show the following information at the very top of the page
 * - environment (if not prod)
 * - maintenance messages (not yet implemented)
 * - read-only hint
 */
@Component({
  selector: 'pool-status-banner',
  templateUrl: './status-banner.component.html',
  styleUrls: ['./status-banner.component.scss'],
})
export class StatusBannerComponent {
  status: ServerStatus;
  readOnly = false
  isBusy = true;
  errorMessage: string | null = null;

  constructor(protected statusService: ApplicationStatusService) {
    statusService.status$.subscribe({
      next: (status: ServerStatus) => {
        this.status = status;
        this.readOnly = status.readOnly;
        this.isBusy = false;
      },
      error: () => {
        this.errorMessage = 'Applikationsinformationen konnten nicht geladen werden!';
      },
    });
  }
}
