import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {SearchSingleResourceComponent} from './components/search-single-resource/search-single-resource.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {FormsModule} from '@angular/forms';
import {SearchMultipleResourcesComponent} from './components/search-multiple-resources/search-multiple-resources.component';
import {OrderListModule} from 'primeng/orderlist';
import {SearchResourceItemComponent} from './components/search-resource-item/search-resource-item.component';
import {TooltipModule} from 'primeng/tooltip';
import {PlanningFormHostDirective} from './directives/planning-form-host.directive';


@NgModule({
  declarations: [
    SpinnerComponent,
    SearchSingleResourceComponent,
    SearchMultipleResourcesComponent,
    SearchResourceItemComponent,
    PlanningFormHostDirective,
  ],
  exports: [
    SpinnerComponent,
    SearchSingleResourceComponent,
    SearchMultipleResourcesComponent,
    PlanningFormHostDirective,
  ],
    imports: [
        CommonModule,
        AutoCompleteModule,
        FormsModule,
        OrderListModule,
        TooltipModule,
    ],
})
export class SharedModule { }
