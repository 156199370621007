<div role="main">
    <form class="grid" [formGroup]="entityForm" name="saveEntity" (ngSubmit)="onSubmit()">
        <div class="col-12 lg:col-12"><h1>{{ title }}</h1></div>
        <ng-container *ngFor="let key of fields.keys()" [ngSwitch]="fields.get(key).type">

            <label for="{{ key }}" class="col-12 lg:col-4">{{ domainDef.path + '.' + key | translate }}</label>
            <div class="col-11 lg:col-7 p-fluid">
                <input *ngSwitchCase="'string'" pInputText
                       formControlName="{{ key }}"
                       name="{{ key }}" id="{{ key }}"
                       type="text"
                       [attr.maxlength]="properties.get(key)['maxSize'] || undefined"/>

                <p-inputNumber *ngSwitchCase="'number'"
                               formControlName="{{ key }}" name="{{key}}" id="{{ key }}"
                               [useGrouping]="true"
                               [min]="properties.get(key)['min']"
                               [max]="properties.get(key)['max']"
                               [minFractionDigits]="properties.get(key)['minFractionDigits']"
                               [maxFractionDigits]="properties.get(key)['maxFractionDigits']"
                ></p-inputNumber>

                <textarea *ngSwitchCase="'text'" pInputTextarea
                          formControlName="{{ key }}"
                          name="{{ key }}" id="{{ key }}"></textarea>

                <p-dropdown *ngSwitchCase="'enum'"
                            formControlName="{{ key }}"
                            [options]="optionLists.get(key)"
                            appendTo="body"
                            placeholder="{{ 'crud.button.select' | translate }}"></p-dropdown>

                <p-calendar *ngSwitchCase="'local-date'"
                            formControlName="{{ key }}"
                            dataType="string"
                            dateFormat="dd.mm.yy"
                            appendTo="body"
                            [locale]="calendarLocale[translate.currentLang]"></p-calendar>

                <p-calendar *ngSwitchCase="'local-date-time'"
                            formControlName="{{ key }}"
                            dataType="string"
                            dateFormat="dd.mm.yy"
                            showTime="true" hourFormat="24"
                            [locale]="calendarLocale[translate.currentLang]"></p-calendar>

                <p-calendar *ngSwitchCase="'local-time'"
                            formControlName="{{ key }}"
                            dataType="string"
                            [timeOnly]="true"></p-calendar>

                <p-checkbox *ngSwitchCase="'boolean'"
                            binary="true"
                            formControlName="{{ key }}"
                            name="{{ key }}" id="{{ key }}"></p-checkbox>

                <p-autoComplete *ngSwitchCase="'belongs-to'"
                                formControlName="{{ key }}"
                                [suggestions]="filteredOptionLists.get(key)"
                                [dropdown]="true"
                                (completeMethod)="filterAssociation(key, $event)"
                                styleClass="wid100"
                                [minLength]="1"
                                placeholder="{{ 'crud.button.search' | translate }}"
                                [multiple]="false"
                                dataKey="id"
                                [forceSelection]="true"
                                field="label">
                    <ng-template let-item pTemplate="item">
                        <div class="autocomplete-item">
                            <img *ngIf="!!item.icon" [src]="item.icon"/>
                            <small *ngIf="!!item.group" [innerHTML]="formatQueryResult(key, item.group)"></small>
                            <div [class.right]="!!item.icon" [innerHTML]="formatQueryResult(key, item.label)"></div>
                        </div>
                    </ng-template>
                </p-autoComplete>

                <p-autoComplete *ngSwitchCase="'has-many'"
                                formControlName="{{ key }}"
                                [suggestions]="filteredOptionLists.get(key)"
                                [dropdown]="true"
                                (completeMethod)="filterAssociation(key, $event)"
                                styleClass="wid100"
                                [minLength]="1"
                                placeholder="{{ 'crud.button.search' | translate }}"
                                [multiple]="true"
                                dataKey="id"
                                field="label">
                </p-autoComplete>

              <p-multiSelect *ngSwitchCase="'multi-select'"
                             formControlName="{{ key }}"
                             [options]="filteredOptionLists.get(key)"
                             defaultLabel="Auswählen"
                             appendTo="body">

              </p-multiSelect>

                <ng-container *ngSwitchCase="'image'">
                    <p-fileUpload #fu name="{{ key }}"
                                  customUpload="true"
                                  mode="basic"
                                  accept="image/png"
                                  chooseLabel="{{ 'crud.button.image' | translate }}"
                                  [maxFileSize]="properties[key]?.fileSize || 100 * 1024"
                                  [auto]="true"
                                  (uploadHandler)="onImageSelect(fu, key, $event)">
                      <!--bug: properties for fileSize not working-->
                    </p-fileUpload>
                  <img *ngIf="entityForm.controls[key].value" alt="{{ 'crud.type.image' | translate }}"
                       [src]="entityForm.controls[key].value"/>
                </ng-container>

              <p-inputNumber *ngSwitchCase="'int'" [formControlName]="key"></p-inputNumber>

              <ng-container *ngIf="entityForm.controls[key].value && entityForm.controls[key].errors">
                <div class="col-12 lg:col-12 validation-error" *ngFor="let error of errors(key)">{{ error }}</div>
              </ng-container>

            </div>
            <div class="col-1 lg:col-1">
                <a *ngIf="['has-many', 'belongs-to'].includes(fields.get(key).type)
                      && !entityForm.controls[key].disabled
                      && !properties.get(key)['denyAssociationCreation']"
                   class="pi pi-plus"
                   (click)="addAssociation(key)"></a>
            </div>

        </ng-container>
        <div class="col-12">
            <button pButton type="submit" label="{{ 'crud.button.save' | translate }}" icon="pi pi-check"></button>
        </div>
    </form>
    <br>
<!--<div class="alert">
        <pre style="max-width: 800px; overflow: hidden" [style.color]="entityForm?.invalid ? 'red' : 'green'">{{entityForm?.value | json}}</pre>
    </div>-->

</div>


<p-dialog header="{{ 'crud.domain.add' | translate:addingAssociation?.dialogParams }}"
          [(visible)]="addingAssociation"
          [responsive]="true"
          [modal]="false">
    <ic-entity-form *ngIf="addingAssociation"
                    [domainDef]="addingAssociation.domainDef"
                    (save)="onAssociationComplete($event)"></ic-entity-form>
</p-dialog>
