import {Injectable, Injector} from '@angular/core';
import {CrudService} from '@ic/ng-crud-client';
import {contractDomainDefinition, EmployeeContract} from './employee-contract.definition';
import {HttpClient} from '@angular/common/http';


export interface ContractCreateResult {
  successful: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class EmployeeContractService extends CrudService<EmployeeContract> {

  constructor(http: HttpClient, injector: Injector) {
    super(contractDomainDefinition, http, injector);
  }
}
