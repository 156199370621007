import {Component, Inject, OnInit} from '@angular/core';
import {CRUD_SERVICE, CrudService} from '../crud.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Entity} from '../definitions';
import {mergeMap} from 'rxjs/operators';
import {throwError} from 'rxjs';

/**
 * EditComponent renders a form filled with the current entity
 */
@Component({
  templateUrl: './edit.component.html',
})
export class EditComponent<E extends Entity> implements OnInit {
  entity: E;

  title: string;

  constructor(@Inject(CRUD_SERVICE) protected crudService: CrudService<E>,
              protected router: Router, protected activatedRoute: ActivatedRoute, protected translate: TranslateService) {
  }

  ngOnInit() {
    this.translate.get(this.crudService.getDomainDefinition().path + '.domain.name').subscribe(domainName => {
      this.title = this.translate.instant('crud.domain.edit', {item: domainName});
    });

    this.activatedRoute.params.pipe(mergeMap(
      (params) => {
        if (!params.id) {
          return throwError('edit needs an id');
        }
        return this.crudService.get(parseInt(params.id, 10), true);
      }))
      .subscribe((params) => {
        this.entity = params;
      }, () => {
        this.router.navigate(['/not-found']);
      });
  }

  onEdit(instance: E) {
    this.crudService.save(instance).subscribe(this.afterSave);
  }

  afterSave = (instance: E) => {
    this.router.navigate(['../list'], {relativeTo: this.activatedRoute});
  }

}
