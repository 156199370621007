import {InjectionToken, Injector} from '@angular/core';
import {CachedCrudService, DomainDefinition, Entity, PropertyDefinition} from '@ic/ng-crud-client';

export interface PoolShift extends Entity {
  sortIndex: number;
  shiftName: string;
  shiftNameShort: string;
  iconText: string;
  iconCss?: string;
}

export const POOL_SHIFT_SERVICE = new InjectionToken<CachedCrudService<PoolShift>>('POOL_SHIFT_SERVICE');

export const poolShiftDefinition: DomainDefinition<PoolShift> = {
  path: 'poolShift',
  service: POOL_SHIFT_SERVICE,
  properties: new Map<keyof PoolShift, PropertyDefinition>([
    // TODO: add missing fields
  ]),
};

export function poolShiftCrudServiceFactory(httpClient, injector: Injector) {
  return new CachedCrudService(poolShiftDefinition, httpClient, injector);
}
