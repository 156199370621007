import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClientModule} from '@angular/common/http';
import {IfHasAnyRoleDirective} from './if-has-any-role/if-has-any-role.directive';
import {IsLoggedInDirective} from './is-logged-in/is-logged-in.directive';
import {NgModule} from '@angular/core';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    TranslateModule,
  ],
  declarations: [
    IfHasAnyRoleDirective,
    IsLoggedInDirective,
  ],
  exports: [
    IfHasAnyRoleDirective,
    IsLoggedInDirective,
  ],
})
export class AuthCoreModule {
}
