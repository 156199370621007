import {InjectionToken} from '@angular/core';
import {CrudService, DomainDefinition, Entity, PropertyDefinition} from '@ic/ng-crud-client';
import {EMPLOYEE_SERVICE} from '../employee/employee.definition';
import {EntryType} from '../../planning/planning.definitions';
import {LocalDateTime} from '@js-joda/core';
import {WorkType} from '../booking/work-type.definition';


export interface Appointment extends Entity {
  title: string;
  workType: EntryType;
  participants: Entity[];
  place: string;
  start: LocalDateTime;
  end: LocalDateTime;
  description: string;
  course?: Entity;
  responsible?: Entity;
}

export interface AppointmentValue {
  title: string;
  participants: Entity[];
  place: string;
  startDate: string
  startTime: string;
  endTime: string;
  description: string;
  course?: Entity;
  responsible?: Entity;
}

export const APPOINTMENT_SERVICE = new InjectionToken<CrudService<Appointment>>('APPOINTMENT_SERVICE');

export const appointmentDefinition: DomainDefinition<Appointment> = {
  path: 'bookingContainer',
  service: APPOINTMENT_SERVICE,
  properties: new Map<keyof Appointment, PropertyDefinition>([
    ['title', {
      type: 'string',
    }],
    ['workType', {
      type: 'enum',
      cls: WorkType,
      prefix: 'WorkType',
    }],
    ['participants', {
      type: 'has-many',
      service: EMPLOYEE_SERVICE,
    }],
    ['place', {
      type: 'string',
    }],
    ['start', {
      type: 'local-date-time',
      nullable: true,
    }],
    ['end', {
      type: 'local-date-time',
      nullable: true,
    }],
    ['description', {
      type: 'text',
      nullable: true,
    }],
  ]),
};

