import {InjectionToken, Injector} from '@angular/core';
import {CachedCrudService, DomainDefinition, Entity, FilterType, PropertyDefinition} from '@ic/ng-crud-client';

export interface Software extends Entity {
  software: Software;
}
export const SOFTWARE_SERVICE = new InjectionToken<CachedCrudService<Software>>('SOFTWARE_SERVICE');

export const softwareDefinition: DomainDefinition<Software> = {
  path: 'software',
  service: SOFTWARE_SERVICE,
  properties: new Map<keyof Software, PropertyDefinition>([
    ['software', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
  ]),
  formDefinitions: {
    default: {
      fields: ['software'],
    },
  },
  tableDefinitions: {
    default: {
      columns: ['software'],
      inlineEditable: true,
      immutable: false,
      disableDetail: false,
      sortField: 'software',
      sortOrder: 1,
      filters: {},
      filterStorable: true,
    },
  },
}

