import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmDenyDialogComponent} from './confirm-deny-dialog/confirm-deny-dialog.component';
import {CardModule} from 'primeng/card';
import {ButtonModule} from 'primeng/button';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ListDialogModule} from '../lists/list-dialog/list-dialog.module';


@NgModule({
  declarations: [
    ConfirmDenyDialogComponent,
  ],
  imports: [
    CommonModule,
    CardModule,
    ButtonModule,
    ProgressSpinnerModule,
    ListDialogModule,

  ],
})
export class ConfirmDenyDialogModule {
}
