import {Injectable} from '@angular/core';
import {CellSelection} from '../../planning.definitions';
import {PlanningDialogContext} from '../definitions';
import {CellSelectionService} from '../../services/cell-selection.service';
import {PlanningStore} from '../../services/store/planning-store.service';
import {DialogStateService, SelectionOutput} from './dialog-state.service';

export interface PlanningSelectionOutput extends SelectionOutput {
  cells: CellSelection[]
  //todo: rowIds: number[]
}

export function isPlanningSelectionOutput(selectionOutput: SelectionOutput): selectionOutput is PlanningSelectionOutput {
  return typeof selectionOutput === 'object' && selectionOutput && 'cells' in selectionOutput;
}

@Injectable()
export class PlanningDialogStateService extends DialogStateService<PlanningDialogContext, PlanningSelectionOutput> {

  constructor(private cellSelectionService: CellSelectionService, store: PlanningStore) {
    super(store);
  }

  completeSelection(selection: PlanningSelectionOutput): void {
    if (!selection.entryType || (!selection.entries && !selection.cells)) {
      throw Error('invalid cell selection');
    }
    super.completeSelection(selection);
  }

  completeDialog(): void {
    this.cellSelectionService.deselectCells();
    super.completeDialog();
  }
}


