import {MessageFormComponent} from './message-form.component';
import {NgModule} from '@angular/core';
import {AuthCoreModule} from "@ic/auth";
import {CalendarModule} from "primeng/calendar";
import {CheckboxModule} from "primeng/checkbox";
import {CommonModule} from "@angular/common";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InputNumberModule} from "primeng/inputnumber";
import {InputTextareaModule} from "primeng/inputtextarea";
import {MultiSelectModule} from "primeng/multiselect";
import {PatientModule} from "../../patient/patient.module";
import {TooltipModule} from "primeng/tooltip";
import {TriStateCheckboxModule} from "primeng/tristatecheckbox";
import {InputTextModule} from "primeng/inputtext";


@NgModule({
  declarations: [
    MessageFormComponent,
  ],
  imports: [
    AuthCoreModule,
    CalendarModule,
    CheckboxModule,
    CommonModule,
    DropdownModule,
    FormsModule,
    InputNumberModule,
    InputTextareaModule,
    MultiSelectModule,
    PatientModule,
    ReactiveFormsModule,
    TooltipModule,
    TriStateCheckboxModule,
    InputTextModule
  ],
  providers: [
  ],
  exports: [
    MessageFormComponent,
  ],
})
export class MessageFormModule { }
