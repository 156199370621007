/**
 * Standardizes the way changes to the state occur
 */
export class Action {
  static readonly NONE = 'NONE';
  static readonly SET = 'SET';
  static readonly LOAD_PLANNING_ROWS = 'LOAD_PLANNING_ROWS';
  static readonly SET_PLANNING_ROWS = 'SET_PLANNING_ROWS';
  static readonly UPDATE_PLANNING_ROWS = 'UPDATE_PLANNING_ROWS';
  static readonly SET_ROW_SCROLL_INDEX = 'SET_ROW_SCROLL_INDEX';
  static readonly TOGGLE_GROUP = 'TOGGLE_GROUP';
  static readonly LOAD_CONFIG = 'LOAD_CONFIG';
  static readonly SET_CONFIG = 'SET_CONFIG';
  static readonly UPDATE_CONFIG = 'UPDATE_CONFIG';
  static readonly SET_LOADING = 'SET_LOADING';
  static readonly LOADING_FAILURE = 'LOADING_FAILURE';
  static readonly DELETE = 'DELETE';
  static readonly SET_AFFECTED_ENTRIES = 'SET_AFFECTED_ENTRIES';
  static readonly SAVE_AVAILABILITIES = 'SAVE_AVAILABILITIES';
  static readonly SAVE_BOOKINGS = 'SAVE_BOOKINGS';
  static readonly SET_BOOKINGS = 'SET_BOOKINGS';
  static readonly SET_CELL_SELECTION = 'SET_CELL_SELECTION';
  static readonly ADD_CELL_SELECTION = 'ADD_CELL_SELECTION';
  static readonly SET_SELECTED_DAY = 'SET_SELECTED_DAY';
  static readonly INCR_CURRENT_PAGE = 'INCR_CURRENT_PAGE';
  static readonly DECR_CURRENT_PAGE = 'DECR_CURRENT_PAGE';
  static readonly PATCH_APPOINTMENT_STATE = 'PATCH_APPOINTMENT_STATE';
  static readonly LOAD_QUICK_FILTER = 'LOAD_QUICK_FILTER';

  /**
   * @deprecated
   */
  static readonly SAVE_CHANGES = 'SAVE_CHANGES';
  /**
   * @deprecated
   */
  static readonly VALIDATE_CHANGES = 'VALIDATE_CHANGES';
  /**
   * @deprecated
   */
  static readonly SET_CHANGES = 'SET_CHANGES';
  /**
   * @deprecated
   */
  static readonly PATCH_DIALOG_STATE = 'PATCH_DIALOG_STATE';
  /**
   * @deprecated
   */
  static readonly PATCH_EDIT_STATE = 'PATCH_EDIT_STATE';

  constructor(public type: string, public payload?: any) {
  }
}
