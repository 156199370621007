import {Injectable, Injector} from '@angular/core';
import {CrudService, MinimalList} from '@ic/ng-crud-client';
import {HttpClient} from '@angular/common/http';
import {Employee, employeeDefinition, EmployeePermission} from './employee.definition';
import {environment} from '../../../environments/environment';
import {AbstractControl, AsyncValidatorFn} from '@angular/forms';
import {catchError, debounceTime, first, mapTo, switchMap} from 'rxjs/operators';
import {of, ReplaySubject} from 'rxjs';
import {InviteEmployee} from './inviteEmployee.definitions';
import {Observable} from 'rxjs/internal/Observable';
import {toHttpParams} from '../../util';
import {ChangeBookingEmployeeCommand} from '../booking/booking.service';


@Injectable({
  providedIn: 'root',
})
export class EmployeeService extends CrudService<Employee> {

  constructor(http: HttpClient, injector: Injector) {
    super(employeeDefinition, http, injector);
  }

  /**
   * @deprecated use methods in SearchResourceService instead
   */
  resources() {
    return this.http.get<MinimalList<Employee>>(`${environment.serverURL}employee/resources.json?format=minimal`);
  }

  /**
   * @deprecated use methods in SearchResourceService instead
   */
  resourcesExcludeExternal() {
    return this.http.get<MinimalList<Employee>>(`${environment.serverURL}employee/resources.json?format=minimal&excludeAgencyResources=true`);
  }

  /**
   * @deprecated use methods in SearchResourceService instead
   */
  resourcesForStint(stintId: number) {
    return this.http.get<MinimalList<Employee>>(`${environment.serverURL}employee/resources.json?format=minimal&stintId=${stintId}`);
  }

  /**
   * @deprecated use methods in SearchResourceService instead
   */
  resourcesForBooking(bookingId: number) {
    return this.http.get<MinimalList<Employee>>(`${environment.serverURL}employee/resources.json?format=minimal&bookingId=${bookingId}`);
  }


  planners(enabledOnly: boolean) {
    return this.http.get<MinimalList<Employee>>(environment.serverURL + 'employee/planners.json?format=minimal&enabledOnly=' + enabledOnly);
  }

  managers() {
    return this.http.get<MinimalList<Employee>>(environment.serverURL + 'employee/managers.json?format=minimal');
  }

  managerPrincipal() {
    return this.http.get<any>(environment.serverURL + 'employee/managerPrincipal');
  }

  managersAndPlanners() {
    return this.http.get<MinimalList<Employee>>(environment.serverURL + 'employee/managersAndPlanners.json?format=minimal');
  }

  deactivatedEmployees(email: string): Observable<Employee[]> {
    const url = environment.serverURL + 'employee/deactivatedUsers'
    return this.http.get<Employee[]>(url, {params: {email}});
  }

  usernameUniqueValidator(originalUsername: string): AsyncValidatorFn {
    const requests$ = new ReplaySubject<string>();
    const results$ = requests$.pipe(
      debounceTime(800),
      switchMap(username => {
        if (originalUsername && originalUsername === username) {
          return of(null);
        }
        return this.http.get('/api/v1/employee/validateUsername', {params: {username}}).pipe(
          mapTo(null),
          catchError(err => {
            if (err.status === 400) {
              return of({usernameUnique: true});
            }
            throw new Error('unknown error');
          }),
        );
      }),
    );
    return (control: AbstractControl) => {
      requests$.next(control.value);
      return results$.pipe(first());
    }
  }

  inviteEmployee(cmd: InviteEmployee): Observable<Employee> {
    return this.http.post<Employee>(
      environment.serverURL + 'employee/invite', cmd,
    )
  }

  resendInvitation(newUser:Employee) {
    return this.http.post<Employee>('/overview/resendInvitation/' , {params: newUser.user});
  }

  changeEmployee(cmd: ChangeBookingEmployeeCommand): Observable<void> {
    return this.http.get<void>(
      environment.serverURL + 'booking/changeEmployee.json?format=minimal', {params: toHttpParams(cmd)},
    )
  }

  getEmployeeProfileStatus(): Observable<Partial<Employee>> {
    return this.http.get<Partial<Employee>>('/employee/profileStatus')

  }

  getApproximateEmploymentLevels(): Observable<any> {
    return this.http.get<any>(
      environment.serverURL + 'employee/getApproximateEmploymentLevels',
    )
  }

  getEmployeePermissions(): Observable<EmployeePermission> {
    return this.http.get<any>(
      environment.serverURL + 'employee/employeePermission',
    )
  }
}
