import {Injectable, Injector} from '@angular/core';
import {CrudService} from '@ic/ng-crud-client';
import {HttpClient} from '@angular/common/http';
import {Booking, bookingDefinition} from './booking.definition';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';
import {toHttpParams} from '../../util';


export interface ChangeBookingEmployeeCommand {
  bookingId: number;
  employeeId: number;
}

@Injectable({
  providedIn: 'root',
})
export class BookingService extends CrudService<Booking> {

  constructor(http: HttpClient, injector: Injector) {
    super(bookingDefinition, http, injector);
  }

  changeEmployee(cmd: ChangeBookingEmployeeCommand): Observable<void> {
    return this.http.get<void>(
      environment.serverURL + 'booking/changeEmployee.json?format=minimal', {params: toHttpParams(cmd)},
    )
  }
  acceptBooking(cmd: ChangeBookingEmployeeCommand): Observable<void>{
    return this.http.get<void>(
        environment.serverURL + 'booking/acceptBooking.json?format=minimal', {params: toHttpParams(cmd)},
    )
  }

  getWorkReceiptPdfUrl(id: number): string {
    return environment.serverURL + `booking/workReceipt/${id}`;
  }

  getWorkReceiptZipUrl(ids: number[]): string {
    return environment.serverURL + `booking/workReceiptZip?ids=${ids.join(',')}`
  }

  updateComment(commentBookingCmd: any){
    return this.http.post(environment.serverURL + 'booking/updatePastComment', commentBookingCmd)
  }

}
