<header class="grid">
  <div class="col-12"></div>
  <div class="col-6 p-offset-1">
    <a href="#">
      <svg class="w-64" height="80px" id="hospital-pool">
        <use xlink:href="assets/images/images.svg#hospital-pool"></use>
      </svg>
    </a>
  </div>
  <div class="col-5">Login</div>
</header>

<section class="banner banner-image-1 grid">
  <div class="col-1 banner-layer"></div>
  <div class="col-6 banner-layer">
    <h2>hospital-pool.ch:
      Personaleinsatzplanung einfach
      und flexibel organisiert!</h2>
    <p>Bei hospital-pool.ch ist nachhaltige Praxiserfahrung
      im Gesundheitswesen spürbar.</p>
    <p>Die individuell anpassbare PersonaleinsatzplanungWebapplikation ist in enger Zusammenarbeit
      mit dem Hirslanden Aarau und dem LUKS agil
      entwickelt worden.
      Zahlreiche Vorteile machen komplexe Planung mit
      hospital-pool.ch für alle Gesundheitseinrichtungen
      schnell, einfach und übersichtlich.</p>
  </div>
</section>

<section class="videos grid p-justify-between">
  <div class="col-11 p-offset-1">
    <h2>Lorem ipsum dolor sit amet, consetetur sadipscing elitr:</h2>
  </div>
  <div class="col-11 p-offset-1 grid p-justify-between">
    <img class="col-3 p-offset-1" src="assets/images/youtube-1.png">
    <img class="col-3" src="assets/images/youtube-2.png">
    <img class="col-3" src="assets/images/youtube-3.png">
  </div>
</section>

<section class="stories grid">
  <div class="col-5 p-offset-1">
    <h1>Vorteil Nr. 1: <br>
      Einfache Planung</h1>
    <h3>Personaleinsatz ganz einfach planbar</h3>
    <ul>
      <li>Eine zuverlässige Haupt-Infostelle</li>
      <li>Jeder sieht nur, was er braucht</li>
      <li>Bedarf und Verfügbarkeit in Echtzeit</li>
      <li>Zugriff von überall und zu jeder Zeit</li>
      <li>Automatisierte Benachrichtungen bei Änderungen</li>
      <li>Aufwändiges Telefonieren oder Schreiben entfällt</li>
    </ul>
  </div>
  <div class="col-5">
    <img height="270" src="assets/images/planung.jpg" width="480">
  </div>

  <div class="p-offset-5">
    <svg class="relative arrow" height="100px" viewBox="0 0 773.8 624.42" xmlns="http://www.w3.org/2000/svg">
      <path d="M746.7 617.7C615.35 259.33 239.51 68.28 2.7 2.7" fill="none" stroke="#2c6f8d" stroke-linecap="round"
            stroke-miterlimit="10" stroke-width="5.4"/>
      <path d="M771.11 487.04l-21.95 134.68-120.47-71.01" fill="none" stroke="#2c6f8d" stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="5.4"/>
    </svg>
  </div>

  <div class="col-5 p-offset-1">
    <img height="270" src="assets/images/verstaendlich.jpg" width="480">
  </div>
  <div class="col-5">
    <h1>Vorteil Nr. 2: <br>
      Verständlich</h1>
    <h3>Alles klar. Alles laufend.</h3>
    <ul>
      <li>Eine zuverlässige Haupt-Infostelle</li>
      <li>Jeder sieht nur, was er braucht</li>
      <li>Bedarf und Verfügbarkeit in Echtzeit</li>
      <li>Zugriff von überall und zu jeder Zeit</li>
    </ul>
  </div>

  <div class="p-offset-5">
    <svg height="100px" style="transform: rotate(90deg)" viewBox="0 0 773.8 624.42" xmlns="http://www.w3.org/2000/svg">
      <path d="M746.7 617.7C615.35 259.33 239.51 68.28 2.7 2.7" fill="none" stroke="#2c6f8d" stroke-linecap="round"
            stroke-miterlimit="10" stroke-width="5.4"/>
      <path d="M771.11 487.04l-21.95 134.68-120.47-71.01" fill="none" stroke="#2c6f8d" stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="5.4"/>
    </svg>
  </div>

  <div class="col-5 p-offset-1">
    <h1>Vorteil Nr. 3: <br>
      Umfassend</h1>
    <h3>Viele Möglichkeiten, ein System.</h3>
    <ul>
      <li>Eine zuverlässige Haupt-Infostelle</li>
      <li>Jeder sieht nur, was er braucht</li>
      <li>Bedarf und Verfügbarkeit in Echtzeit</li>
      <li>Zugriff von überall und zu jeder Zeit</li>
      <li>Automatisierte Benachrichtungen bei Änderungen</li>
      <li>Aufwändiges Telefonieren oder Schreiben entfällt</li>
    </ul>
  </div>
  <div class="col-5 p-offset-1">
    <img height="270" src="assets/images/umfassend.jpg" width="480">
  </div>

  <div class="p-offset-5">
    <svg height="100px" viewBox="0 0 775.61 646.1" xmlns="http://www.w3.org/2000/svg">
      <path d="M768.44 590.41C389.75 542.48 119.55 218.86 2.75 2.73" fill="none" stroke="#2c6f8d" stroke-linecap="round"
            stroke-miterlimit="10" stroke-width="5.4"/>
      <path d="M646.54 643.4l126.37-51.49-96.14-101.55" fill="none" stroke="#2c6f8d" stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="5.4"/>
    </svg>
  </div>


  <div class="col-5 p-offset-1">
    <img height="270" src="assets/images/sicherheit.jpg" width="480">
  </div>
  <div class="col-5 p-offset-1">
    <h1>Vorteil Nr. 4: <br>
      Hohe Sicherheit</h1>
    <h3>Vorbeugen verhindert und schützt.</h3>
    <ul>
      <li>Eine zuverlässige Haupt-Infostelle</li>
      <li>Jeder sieht nur, was er braucht</li>
      <li>Bedarf und Verfügbarkeit in Echtzeit</li>
      <li>Zugriff von überall und zu jeder Zeit</li>
      <li>Automatisierte Benachrichtungen bei Änderungen</li>
    </ul>
  </div>

</section>

<section class="banner banner-image-2 grid">
  <div class="col-1"></div>
  <div class="col-5">
    <br>
    <h1>Zusatznutzen Nr. 1: <br>
      Bedürfnisorientiert!</h1>
    <h3>Flexibilität steigert Motivation.</h3>
    <ul>
      <li>Eine zuverlässige Haupt-Infostelle</li>
      <li>Jeder sieht nur, was er braucht</li>
      <li>Bedarf und Verfügbarkeit in Echtzeit</li>
    </ul>
  </div>
  <div class="col-6">
    <br><br><br><br><br>
    <h1>Zusatznutzen Nr. 2: <br>
      Reduzierte Personalfluktuation</h1>
    <h3>Mitarbeitende halten, neue gewinnen.</h3>
    <ul>
      <li>Eine zuverlässige Haupt-Infostelle</li>
      <li>Jeder sieht nur, was er braucht</li>
      <li>Bedarf und Verfügbarkeit in Echtzeit</li>
    </ul>
  </div>
</section>

<section class="examples grid">
  <div class="col-11 p-offset-1">
    <h1>Fallbeispiele</h1>
    <h3>Welia voluptaturem idelita tiissin et eum eum, ipsumquis
      quatio Tem ex et et illabor.</h3>
  </div>
  <div class="col-11 p-offset-1 grid p-justify-between">
    <a class="col-3 p-offset-1"
       href="#" title="CESTI RAE CUM QUE ODIPSUM">
      <h4>CESTI RAE CUM QUE ODIPSUM</h4>
      <p>Aut volupta venet veriberum atus
        doloris ea niscipsus, consequi
        sit eos nullectu Ehenducs tiuntiam
        vel int, consequo.</p>
      <span class="arrow">&rarr;</span>
    </a>
    <a class="col-3"
       href="#" title="CESTI RAE CUM QUE ODIPSUM">
      <h4>CESTI RAE CUM QUE ODIPSUM</h4>
      <p>Aut volupta venet veriberum atus
        doloris ea niscipsus, consequi
        sit eos nullectu Ehenducs tiuntiam
        vel int, consequo.</p>
      <span class="arrow">&rarr;</span>
    </a>
    <a class="col-3"
       href="#" title="CESTI RAE CUM QUE ODIPSUM">
      <h4>CESTI RAE CUM QUE ODIPSUM</h4>
      <p>Aut volupta venet veriberum atus
        doloris ea niscipsus, consequi
        sit eos nullectu Ehenducs tiuntiam
        vel int, consequo.</p>
      <span class="arrow">&rarr;</span>
    </a>
  </div>
</section>

<footer>
  <svg height="350px" id="magnifier" width="350px">
    <use xlink:href="assets/images/images.svg#magnifier"></use>
  </svg>
  <div class="grid">
    <div class="col-2 p-offset-1">
      Ceatiorendit rempos
      Eos et quam Eum Con ra pren
      Lesequae mil min<br><br> preptas erup
      Temque aut fugitio
      Us res volupta titem
    </div>
    <div class="col-2">
      Ceatiorendit rempos
      Eos et quam Eum Con ra pren
      Lesequae mil min<br><br> preptas erup
      Temque aut fugitio
      Us res volupta titem
    </div>
    <div class="col-2">
      Ceatiorendit rempos
      Eos et quam Eum Con ra pren
      Lesequae mil min<br><br> preptas erup
      Temque aut fugitio
      Us res volupta titem
    </div>
    <div class="col-2">
      Ceatiorendit rempos
      Eos et quam Eum Con ra pren
      Lesequae mil min<br><br> preptas erup
      Temque aut fugitio
      Us res volupta titem
    </div>
    <div class="col-2">
      <a href="#">
        <svg class="w-56" height="50px" id="hospital-jobs" width="300px">
          <use xlink:href="assets/images/images.svg#hospital-jobs"></use>
        </svg>
      </a>
    </div>
  </div>
</footer>


