import {Directive, Input, Self} from '@angular/core';
import {Calendar, LocaleSettings} from 'primeng/calendar';
import {
  ChronoUnit,
  convert,
  DateTimeFormatter,
  LocalDate,
  LocalDateTime,
  LocalTime,
  nativeJs,
  ZonedDateTime
} from '@js-joda/core';

export const LOCAL_DATE_FORMATTER = DateTimeFormatter.ofPattern('dd.MM.yyyy');

const LOCALE_SETTINGS: LocaleSettings = {
  firstDayOfWeek: 1,
  dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
  dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
  dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
  monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
  today: 'Heute',
  clear: 'Zurücksetzen',
  dateFormat: 'dd.mm.yy',
};

/**
 * Sets the PrimeNg Calendar's data type to LocalDate or LocalDateTime
 */
@Directive({
  selector: '[icDateType]',
})
export class DateTypeDirective {
  _cal: Calendar;
  @Input() icDateType: 'local-date' | 'local-date-time' = 'local-date-time'

  constructor(@Self() calendar: Calendar) {
    this._cal = calendar;
    this._cal.locale = LOCALE_SETTINGS;
    const calWriteValue = this._cal.writeValue;
    const calUpdateModel = this._cal.updateModel;
    this._cal.writeValue = (value: any) => this._writeValue(value, calWriteValue.bind(this._cal));  // passes the original method
    this._cal.updateModel = (value: any) => this._updateModel(value, calUpdateModel.bind(this._cal));
  }

  /**
   * Writes data to the component's inner value (Date) via form field or model
   * @param value: usually a local date or a string
   * @param calFn: the corresponding method of the calender component
   */
  private _writeValue(value: any, calFn: Function): void {
    if ((value instanceof LocalDate || value instanceof LocalDateTime) && this._cal.isSingleSelection()) {
      value = this._dateFromLocalDate(value);
    } else if (Array.isArray(value) && value.length && (value[0] instanceof LocalDate || value[0] instanceof LocalDateTime)) {
      value = this._datesFromLocalDateArray(value);
    } else if (typeof value === 'string') {
      if (this._cal.isSingleSelection()) {
        value = this._dateFromString(value);
      } else {
        value = [this._dateFromString(value), null];
      }
    } else if (Array.isArray(value) && value.length && typeof value[0] === 'string') {
      value = this._datesFromStringArray(value);
    }
    calFn(value);
  }

  /**
   * Updates the local date form field or model
   * @param value: Date
   * @param calFn: the corresponding method of the calender component
   */
  private _updateModel(value: any, calFn: Function) {
    this._cal.value = value;
    if (this.icDateType) {
      let localDate = null;
      if (value && this._cal.isSingleSelection()) {
        localDate = this._localDateFromDate(value);
      } else if (Array.isArray(value) && value.length) {
        localDate = this._localDateFromDateArray(value);
      }
      this._cal.onModelChange(localDate);
    } else {
      calFn(value);
    }
  }

  private _datesFromLocalDateArray(dates: (LocalDate | LocalDateTime)[]) {
    return dates.map((item) => this._dateFromLocalDate(item));
  }

  private _dateFromLocalDate(localDate: LocalDate | LocalDateTime) {
    return localDate ? convert(localDate as (LocalDate | LocalDateTime)).toDate() : null;
  }

  private _datesFromStringArray(data: string[]): Date[] {
    return data.map((text) => this._dateFromString(text));
  }

  private _dateFromString(text: string): Date {
    if (!text) return null;
    if (text.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return convert(LocalDate.parse(text)).toDate();
    } else if (text.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2}(\.\d+)?)?$/)) {
      return convert(LocalDateTime.parse(text)).toDate();
    } else if (text.match(/^\d{2}\.\d{2}\.\d{4}$/)) {
      return convert(LocalDate.parse(text, (LOCAL_DATE_FORMATTER))).toDate();
    } else {
      return convert(ZonedDateTime.parse(text)).toDate();
    }
  }

  private _localDateFromDateArray(value: Date[]): (LocalDate | LocalDateTime)[] {
    const dataType = this.icDateType === 'local-date' ? LocalDate : LocalDateTime
    if (!value?.length) return null;
    const result = value.map((item) => this._localDateFromDate(item));
    if (this._cal.isRangeSelection() && this.icDateType === 'local-date-time') {
      result[1] = result[1] || result[0]
      result[0] = (result[0] as LocalDateTime).toLocalDate().atStartOfDay()
      result[1] = (result[1] as LocalDateTime).toLocalDate().atTime(LocalTime.MAX.truncatedTo(ChronoUnit.SECONDS));
    }
    return result;
  }

  private _localDateFromDate(value: Date): LocalDate | LocalDateTime {
    if (!value) return null;
    const dataType = this.icDateType === 'local-date' ? LocalDate : LocalDateTime
    return dataType.from(nativeJs(value));
  }

}
