import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from '../auth.service';
import {map} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {SecurityContext} from '../../definitions';

/**
 * Show content only if the current user is logged in and has any role, given as attribute, assigned.
 * The condition can also be negated by prefixing the roles with an exclamation mark (`!`). Negating all roles won't work.
 *
 * Example:
 * ```html
 *  <div *icAuthIfHasAnyRole="'ROLE_STAFF,ROLE_EMPLOYER'">
 *    Admin
 *  </div>
 * ```
 */
@Directive({
  selector: '[icAuthIfHasAnyRole]',
})
export class IfHasAnyRoleDirective implements OnInit, OnDestroy {
  @Input('icAuthIfHasAnyRole') hasRoleString: string;

  protected subscription: Subscription;
  private _hasView = false;

  constructor(private viewContainer: ViewContainerRef,
              private templateRef: TemplateRef<any>,
              private authService: AuthService) {
  }

  ngOnInit(): void {
    this.subscription = this.authService.securityContext$.pipe(
      map(this.shouldDisplayContent.bind(this)),
    ).subscribe({
      next: (shouldDisplay: boolean) => {
        if (shouldDisplay && !this._hasView) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this._hasView = true;
        } else if (!shouldDisplay && this._hasView) {
          this.viewContainer.clear();
          this._hasView = false;
        }
      },
      error: err => console.error('Error when evaluating shouldDisplay:', err),
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  protected shouldDisplayContent(securityContext: SecurityContext): boolean {
    if (!this.hasRoleString) {
      return false;
    }

    const roles = this.hasRoleString.split(',');

    for (const roleItem of roles) {
      const negate = roleItem.startsWith('!');

      const role = negate ? roleItem.substr(1) : roleItem;
      const hasRole = securityContext.hasAllRoles([role]);

      if (!negate && hasRole || negate && !hasRole) {
        return true;
      }
    }
    return false;
  }
}
