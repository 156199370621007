import {CrudService, ListPage} from '@ic/ng-crud-client';
import {Stint, stintDefinition, StintStatus} from './stint.definition';
import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {Sex} from '../../definitions';
import {LazyLoadEvent} from 'primeng/api';
import {StintAction} from './stint-action';
import {AssignmentContext} from '../assignment/assignment.service';

@Injectable({
  providedIn: 'root',
})
export class StintService extends CrudService<Stint> {

  constructor(http: HttpClient, injector: Injector) {
    super(stintDefinition, http, injector);
  }

  static equalQualifications(a: Stint, b: Stint): boolean {
    return a.qualifications.length === b.qualifications.length && a.qualifications.every((qual1) =>
      b.qualifications.some((qual2) =>
        qual2.id === qual1.id));
  }

  storeLoadCommand(cmd: LazyLoadEvent): Observable<void> {
    const cmdNew = {
      ...cmd,
    }
    delete cmdNew.filters.end;
    return super.storeLoadCommand(cmdNew);
  }

  list(body?: object, params?: any, customList?: string): Observable<ListPage<Stint> | Stint[]> {
    return super.list(body, params, customList).pipe(
      map((it) => {
        const listPage = it as ListPage<Stint>;
        if (listPage) {
          listPage.records = this.mapStints(listPage.records);
          return listPage;
        } else {
          const arr = it as Stint[];
          return this.mapStints(arr);
        }
      }),
    );
  }

  mapStints(stints: Stint[]): Stint[] {
    return stints.map(stint => {
      return {
        ...stint,
        sex: stint.sex || Sex.NONE,
        offersCount: stint.stintOffers?.length,
      }
    });
  }

  isDisabled = (stint: Stint, action: StintAction,
                assignmentContext: AssignmentContext, permissions: Map<StintAction, boolean>) => {
    const permissionOnCurAction = permissions.get(action)
    const canPlanOnCurStint = assignmentContext?.canPlan(stint.organisationUnit)
    switch (action) {
      case StintAction.LOCK_STINT || StintAction.UNLOCK_STINT || StintAction.TOGGLE_LOCK_STATE:
        return stint.status === StintStatus.BROADCASTED || !(permissionOnCurAction && canPlanOnCurStint)
      case StintAction.BROADCAST_SMS:
        return stint.status === StintStatus.LOCKED || !(permissionOnCurAction && canPlanOnCurStint)
    }
    return !(permissionOnCurAction && canPlanOnCurStint)
  };
}
