import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReassignBookingComponent} from './reassign-booking.component';
import {SharedModule} from '../../../../shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {CheckboxModule} from 'primeng/checkbox';


@NgModule({
  declarations: [
    ReassignBookingComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    CheckboxModule,
  ],
})
export class ReassignBookingFormModule {
}
