import {InjectionToken} from '@angular/core';
import {CachedCrudService, DomainDefinition, Entity, FilterType, PropertyDefinition} from '@ic/ng-crud-client';

export interface Function extends Entity {
  function: string;
  position: number;
}

export const FUNCTION_SERVICE = new InjectionToken<CachedCrudService<Function>>('FUNCTION_SERVICE');

export const functionDefinition: DomainDefinition<Function> = {
  path: 'function',
  service: FUNCTION_SERVICE,
  properties: new Map<keyof Function, PropertyDefinition>([
    ['function', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
    ['position', {
      type: 'int',
      sortable: true,
      filter: FilterType.NONE,
    }],
  ]),
  formDefinitions: {
    default: {
      fields: ['function', 'position'],
    },
  },
  tableDefinitions: {
    default: {
      columns: ['function', 'position'],
      inlineEditable: true,
      immutable: false,
      disableDetail: false,
      sortField: 'function',
      sortOrder: 1,
      filters: {},
      filterStorable: true,
    },
  },
}


