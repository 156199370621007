/** This constant is set by the webpack.DefinePlugin */
declare const COMMIT_HASH: string;

export const environment = {
  production: true,
  serverURL: '/api/v1/',
  translationsURL: '/app/assets/i18n/',
  LEGACY_contextPath: '', // These variables are meant to replace the corresponding variables originally introduced in
  LEGACY_language: 'de',  // the index.gsp and have been moved here. Other usages should consider using serverURL.
  COMMIT_HASH: COMMIT_HASH,
  matomoTracking: true,
};
