import {InjectionToken} from '@angular/core';
import {CachedCrudService, DomainDefinition, Entity, FilterType, PropertyDefinition} from '@ic/ng-crud-client';
import {HEALTH_INSTITUTION_SERVICE, HealthInstitution} from '../health-institution/health-institution.definition';

export enum Canton {
  AG = 'AG',
  AI = 'AI',
  AR = 'AR',
  BE = 'BE',
  BL = 'BL',
  BS = 'BS',
  FR = 'FR',
  GE = 'GE',
  GL = 'GL',
  GR = 'GR',
  JU = 'JU',
  LU = 'LU',
  NE = 'NE',
  NW = 'NW',
  OW = 'OW',
  SG = 'SG',
  SH = 'SH',
  SO = 'SO',
  SZ = 'SZ',
  TG = 'TG',
  TI = 'TI',
  UR = 'UR',
  VD = 'VD',
  VS = 'VS',
  ZG = 'ZG',
  ZH = 'ZH',
}

export interface Pool extends Entity {
  poolName: string;
  canton: Canton;
  institutions: HealthInstitution[];
  licences: string;
  // TODO add remaining fields
}

export const POOL_SERVICE = new InjectionToken<CachedCrudService<Pool>>('POOL_SERVICE');

export const poolDefinition: DomainDefinition<Pool> = {
  path: 'pool',
  service: POOL_SERVICE,
  properties: new Map<keyof Pool, PropertyDefinition>([
    ['poolName', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
    ['institutions', {
      type: 'has-many',
      service: HEALTH_INSTITUTION_SERVICE,
      denyEdit: true,
    }],
    ['canton', {
      type: 'enum',
      cls: Canton,
      prefix: 'Canton',
      sortable: false,
      filter: FilterType.IN,
    }],
    ['licences', {
      type: 'string',
      sortable: false,
      denyEdit: true,
    }],
  ]),
  formDefinitions: {
    default: {
      fields: ['poolName', 'canton', 'institutions', 'licences'],
    },
  },
  tableDefinitions: {
    default: {
      columns: ['poolName', 'canton', 'institutions', 'licences'],
      inlineEditable: true,
      immutable: false,
      disableDetail: false,
      sortField: 'poolName',
      sortOrder: 1,
      filters: {},
      filterStorable: true,
      denyEntityCreation: true,
    },
  },
}
