import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AngularJSContentComponent} from './angular-js-content.component';
import {AngularAppComponent} from './angular-app.component';
import {NavigationModule} from '../navigation/navigation.module';
import {RouterModule} from '@angular/router';

/**
 * This module contains components used to integrate angularJS applications.
 * Once there is no more angularJS code it can be removed.
 */
@NgModule({
  declarations: [AngularJSContentComponent, AngularAppComponent],
  imports: [
    CommonModule,
    NavigationModule,
    RouterModule,
  ],
})
export class AngularJSModule {
}
