import {Component, HostListener, Inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AUTH_CONFIG, AuthConfig, AuthService} from '../../core';
import {UsernamePasswordAuthService} from '../username-password-auth.service';

/**
 * Component for the login form and page.
 *
 * @author Daniel Ritter
 * @since 0.1
 */
@Component({
  selector: 'ic-auth-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {

  loading = false;
  loginForm: UntypedFormGroup;
  message: string;

  private readonly authService: UsernamePasswordAuthService;

  /**
   * Constructor of the login component.
   */
  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              @Inject(AUTH_CONFIG) public config: AuthConfig,
              authService: AuthService,
  ) {
    if (authService instanceof UsernamePasswordAuthService) {
      this.authService = authService;
    } else {
      throw new Error('the login component requires an AuthService which inherits from UsernamePasswordAuthService');
    }
    this.createForm();
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if(event.key === 'Enter') {
      this.onSubmit();
    }
  }

  /**
   * Log in the user in the application on submit.
   */
  onSubmit(): void {
    if (this.loginForm.valid) {
      this.loading = true;
      const {username, password} = this.loginForm.value;
      this.authService.login(username, password).subscribe({
        next: () => {
        },
        error: err => this.message = err.error && err.error[0] && err.error[0].message || 'Anmeldung fehlgeschlagen',
      });
    }
  }

  /**
   * Creates the login form.
   */
  private createForm(): void {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    }, {validator: this.cookieEnabledValidator});
  }

  private cookieEnabledValidator = (control: UntypedFormGroup): {[key: string]: any} | null => {
      return this.isCookieEnabled() ? null : {cookiesDisabled: {value: true}};
  };


  private isCookieEnabled(): boolean {
    if (navigator.cookieEnabled === undefined) {
      document.cookie = 'testcookie';
      return document.cookie.indexOf('testcookie') !== -1;
    } else {
      return navigator.cookieEnabled
    }
  }
}
