import {CrudService} from '@ic/ng-crud-client';
import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Appointment, appointmentDefinition} from './appointment.definition';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService extends CrudService<Appointment> {

  constructor(http: HttpClient, injector: Injector) {
    super(appointmentDefinition, http, injector);
  }

  static equalParticipants(a: Appointment, b: Appointment): boolean {
    return !a.participants && !b.participants || a.participants && b.participants &&
      a.participants.length === b.participants.length && a.participants.every((part1) =>
      b.participants.some((part2) =>
        part2.id === part1.id));
  }
}
