import {CrudService, DomainDefinition, Entity, FilterType, PropertyDefinition} from '@ic/ng-crud-client';
import {InjectionToken, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivityType, SyncDoneByRole, SyncForRole} from '../../definitions';
//todo: create a activity-rule-configuration-form.component, in order that dropdown of form can be modified

export const ACTIVITY_RULE_CONFIGURATION_SERVICE = new InjectionToken<CrudService<ActivityRuleConfiguration>>('ACTIVITY_RULE_CONFIGURATION_SERVICE');

export interface ActivityRuleConfiguration extends Entity {
  id: number;
  activityType: ActivityType;
  gather: boolean;
  end: number;
  maxAge: number;
  syncDoneByRole: string;
  syncForRole: string;
}

export const activityRuleConfigurationDefinition: DomainDefinition<ActivityRuleConfiguration> = {
  path: 'activityRuleConfiguration',
  service: ACTIVITY_RULE_CONFIGURATION_SERVICE,
  properties: new Map<keyof ActivityRuleConfiguration, PropertyDefinition>([
    ['activityType', {
      type: 'enum',
      cls: ActivityType,
      prefix: 'ActivityType',
      filter: FilterType.IN,
    }],
    ['gather', {
      type: 'boolean',
    }],
    ['end', {
      type: 'string',
      nullable: true,
    }],
    ['maxAge', {
      type: 'string',
      nullable: true,
    }],
    ['syncDoneByRole', {
      type: 'enum',
      cls: SyncDoneByRole,
      prefix: 'SyncDoneByRole',
      filter: FilterType.IN,
      nullable: true,
    }],
    ['syncForRole', {
      type: 'multi-select',
      prefix: 'SyncForRole',
      options: [
        {label: 'Planer', value: 'ROLE_READ_SYNCEXTERNALSYSTEM_OU'},
        {label: 'Manager', value: 'ROLE_READ_SYNCEXTERNALSYSTEM_POOL'},
      ],
    }],
  ]),
  formDefinitions: {
    activityRuleConfiguration: {
      fields: ['activityType', 'gather', 'end', 'maxAge', 'syncDoneByRole', 'syncForRole'],
    },
  },
  tableDefinitions: {
    default: {
      columns: ['activityType', 'gather', 'end', 'maxAge', 'syncDoneByRole', 'syncForRole'],
      inlineEditable: false,
      immutable: false,
      disableDetail: true,
      sortField: 'activityType',
      sortOrder: 1,
      filters: {},
      filterStorable: true,
    },
  },
};

export function activityRuleConfigurationCrudServiceFactory(httpClient: HttpClient, injector: Injector) {
  return new CrudService(activityRuleConfigurationDefinition, httpClient, injector);
}
