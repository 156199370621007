import {Component, OnInit} from '@angular/core';
import {UserNotificationService} from '../user-notification.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {map} from 'rxjs/operators';

@Component({
  selector: 'un-test-mail',
  templateUrl: './test-mail.component.html',
})
export class TestMailComponent implements OnInit {
  form: UntypedFormGroup;
  message: string;

  constructor(private userNotificationService: UserNotificationService, private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      testMailAddress: ['', Validators.email],
    });
  }

  sendTestMail() {
    this.message = undefined;
    this.userNotificationService.sendTestMail(this.form.controls['testMailAddress'].value)
      .pipe(map((value) => {
        if (value.mailSessionOutput === '') {
          value.mailSessionOutput = '(No output, message might have been filtered out)';
        }
        return value;
      }))
      .subscribe(
        (data) => this.message = data.mailSessionOutput,
        (err) => this.message = `(${err.message})`
      );
  }

}
