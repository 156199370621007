import {CrudService} from '@ic/ng-crud-client';
import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Training, trainingDefinition} from './training.definititon';

@Injectable({
  providedIn: 'root',
})
export class TrainingService extends CrudService<Training> {

  constructor(http: HttpClient, injector: Injector) {
    super(trainingDefinition, http, injector);
  }
}
