import {CrudService, DomainDefinition, Entity, FilterType, PropertyDefinition, Relation} from '@ic/ng-crud-client';
import {InjectionToken} from '@angular/core';
import {Employee, EMPLOYEE_SERVICE} from '../employee/employee.definition';
import {BookingContainer} from '../booking-container/booking-container.definition';
import {WorkType} from '../booking/work-type.definition';
import {BOOKING_SERVICE} from '../booking/booking.definition';

export interface Meeting extends BookingContainer {
  participants: Entity[];

  responsible: Relation<Employee>;
}

export const MEETING_SERVICE = new InjectionToken<CrudService<Meeting>>('MEETING_SERVICE');

export const meetingDefinition: DomainDefinition<Meeting> = {
  path: 'meeting',
  service: MEETING_SERVICE,
  properties: new Map<keyof Meeting, PropertyDefinition>([
    ['title', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
    ['place', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
    ['start', {
      type: 'local-date-time',
      nullable: true,
      sortable: true,
      filter: FilterType.GREATER_OR_EQUALS,
    }],
    ['end', {
      type: 'local-date-time',
      nullable: true,
      filter: FilterType.LOWER_OR_EQUALS,
    }],
    ['description', {
      type: 'text',
      sortable: true,
      nullable: true,
      filter: FilterType.CONTAINS,
    }],
    ['workType', {
      type: 'enum',
      cls: WorkType,
      prefix: 'WorkType',
      filter: FilterType.IN,
      sortable: true,
    }],
    ['bookings', {
      type: 'has-many',
      service: BOOKING_SERVICE,
      filter: FilterType.CONTAINS,
      noOptions: true,
    }],
    ['responsible', {
      type: 'belongs-to',
      sortable: true,
      service: EMPLOYEE_SERVICE,
      filter: FilterType.CONTAINS,
      noOptions: true,
    }],
    ['lastUpdated', {
      type: 'local-date-time',
      nullable: true,
      filter: FilterType.LOWER_OR_EQUALS,
    }],
  ]),
  tableDefinitions: {
    default: {
      columns: ['title', 'start', 'end', 'place', 'description', 'workType', 'responsible', 'bookings', 'lastUpdated'],
      inlineEditable: false,
      immutable: true,
      disableDetail: true,
      sortField: 'start',
      sortOrder: -1,
      filters: {
        start: {value: new Date().toISOString(), matchMode: 'gte'},
      },
      tableTitle: 'Besprechungen',
      tableEntityName: 'Besprechung',
      filterStorable: true,
    },
  },
};

