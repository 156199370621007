import {Injectable, Injector} from '@angular/core';
import {CrudService} from '@ic/ng-crud-client';
import {HttpClient} from '@angular/common/http';
import {Log, logDefinition} from './log.definition';


@Injectable({
  providedIn: 'root',
})
export class LogService extends CrudService<Log> {

  constructor(http: HttpClient, injector: Injector) {
    super(logDefinition, http, injector)
  }
}
