import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {LoginFormComponent} from './login-form/login-form.component';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {CardModule} from 'primeng/card';
import {AuthCoreModule} from '../core/auth-core.module';


const routes: Routes = [
  {path: 'login', component: LoginFormComponent},
];

@NgModule({
  declarations: [LoginFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AuthCoreModule,
    ButtonModule,
    InputTextModule,
    CardModule,
    RouterModule.forChild(routes),
  ],
  exports: [AuthCoreModule, LoginFormComponent],
})
export class UsernamePasswordAuthModule {
}
