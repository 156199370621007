import {InjectionToken} from '@angular/core';
import {
  CrudService,
  DomainDefinition,
  Entity,
  FilterType,
  Paths,
  PropertyDefinition,
  Relation,
} from '@ic/ng-crud-client';
import {LocalDate, LocalDateTime} from '@js-joda/core';
import {Employee, EMPLOYEE_SERVICE, ResourceType} from '../employee/employee.definition';
import {ORGANISATION_UNIT_SERVICE, OrganisationUnit} from '../organisation-unit/organisation-unit.definition';
import {HEALTH_INSTITUTION_SERVICE, HealthInstitution} from '../health-institution/health-institution.definition';
import {Qualification, QUALIFICATION_SERVICE} from '../qualification/qualification.definition';
import {Activity} from '../activity/activity.definition';
import {ActivityType} from '../../definitions';
import {User, USER_SERVICE} from '../user/user.definition';
import {POOL_SHIFT_SERVICE, PoolShift} from '../pool-shift/pool-shift.definition';
import {WorkType} from '../booking/work-type.definition';
import {Patient} from '../patient/patient.definition';
import {FlatSyncExternalSystemState} from './sync-external-system-state.definition';

export interface FlatSyncExternalSystem extends Entity {
  dateCreated: LocalDateTime
  workType: WorkType;
  referenceId: number;
  referenceDate: LocalDate;
  syncExternalSystemStates: Relation<FlatSyncExternalSystemState>[];
  resourceType: ResourceType;
  description: string;
  syncBy: Relation<User>;
  //todo: managers and planers should also be found
  syncDone: LocalDateTime;
  activity: Relation<Activity>;
  organisationUnit: Relation<OrganisationUnit>;
  patient: Relation<Patient>;
  caseNumber: string;
  qualification: Relation<Qualification>;
  poolShift?: Relation<PoolShift>;

  affected: Relation<Employee>;
  actor: Relation<User>;
  activityType: ActivityType;
  healthInstitution: Relation<HealthInstitution>;
  marked?: boolean;
}

export const SYNC_EXTERNAL_SYSTEM_SERVICE: InjectionToken<CrudService<FlatSyncExternalSystem>> = new InjectionToken('SYNC_EXTERNAL_SYSTEM');

export const syncExternalSystemDefinition: DomainDefinition<FlatSyncExternalSystem> = {
  path: 'syncExternalSystem',
  service: SYNC_EXTERNAL_SYSTEM_SERVICE,
  properties: new Map<keyof FlatSyncExternalSystem, PropertyDefinition>([
    ['dateCreated', {
      filter: FilterType.BETWEEN,
      type: 'local-date-time',
      sortable: true,
    }],
    ['activityType', {
      type: 'enum',
      cls: ActivityType,
      prefix: 'ActivityType',
      filter: FilterType.IN,
    }],
    ['description', {
      filter: FilterType.CONTAINS,
      type: 'string',
    }],
    ['healthInstitution', {
      type: 'belongs-to',
      service: HEALTH_INSTITUTION_SERVICE,
      filter: FilterType.IN,
    }],
    ['organisationUnit', {
      type: 'belongs-to',
      service: ORGANISATION_UNIT_SERVICE,
      filter: FilterType.IN,
    }],
    ['caseNumber', {
      filter: FilterType.CONTAINS,
      type: 'string',
    }],
    ['referenceDate', {
      type: 'local-date-time',
      filter: FilterType.BETWEEN,
      sortable: true,
    }],
    ['workType', {
      type: 'enum',
      filter: FilterType.IN,
      cls: WorkType,
      prefix: 'WorkType',
    }],
    ['resourceType', {
      type: 'enum',
      filter: FilterType.IN,
      cls: ResourceType,
      prefix: 'ResourceType',
    }],
    ['qualification', {
      type: 'belongs-to',
      filter: FilterType.IN,
      service: QUALIFICATION_SERVICE,
    }],
    ['affected', {
      type: 'belongs-to',
      service: EMPLOYEE_SERVICE,
      filter: FilterType.IN,
    }],
    ['actor', {
      type: 'belongs-to',
      service: USER_SERVICE,
      filter: FilterType.IN,
    }],
    ['poolShift', {
      type: 'belongs-to',
      service: POOL_SHIFT_SERVICE,
      filter: FilterType.IN,
    }],
    ['syncBy', {
      type: 'belongs-to',
      service: USER_SERVICE,
      filter: FilterType.IN,
    }],
    ['syncDone', {
      type: 'local-date-time',
      filter: FilterType.BETWEEN,
      sortable: true,
    }],
  ]),
  // @ts-ignore -> prevent Type instantiation is excessively deep and possibly infinite.
  propertiesMapping: new Map<keyof FlatSyncExternalSystem, Paths<FlatSyncExternalSystem>>([
    ['dateCreated', ['activity', 'dateCreated']],
    ['affected', ['activity', 'affected']],
    ['actor', ['activity', 'actor']],
    ['activityType', ['activity', 'activityType']],
    ['healthInstitution', ['organisationUnit', 'healthInstitution']],
    ['qualification', ['activity', 'affected', 'personalData', 'qualification']],
    ['caseNumber', ['patient', 'caseNumber']],
  ]),
  tableDefinitions: {
    default: {
      columns: ['dateCreated', 'activityType', 'description', 'referenceDate', 'poolShift', 'healthInstitution',
        'organisationUnit', 'caseNumber', 'workType', 'affected', 'qualification', 'resourceType', 'actor', 'syncBy', 'syncDone'],
      immutable: true,
      filters: {},
      disableDetail: true,
      filterStorable: true,
      columnsConfigurable: true,
    },
    cantReadEmployee: {
      columns: ['dateCreated', 'activityType', 'description', 'referenceDate', 'poolShift', 'healthInstitution',
        'organisationUnit', 'caseNumber', 'workType', 'affected', 'qualification', 'resourceType', 'syncDone'],
      immutable: true,
      filters: {},
      disableDetail: true,
      filterStorable: true,
      columnsConfigurable: true,
    },
  },
};

