import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {AngularJsAppHelperService} from './angular-js-app-helper.service';
import * as angular from 'angular';
import {environment} from '../../environments/environment';

/**
 * AngularJSContentComponent bootstraps an angularJS application.
 *
 * It's basically a replacement for the index.gsp.
 */
@Component({
  selector: 'pool-angular-jscontent',
  template: `
    <div id=top-bar ng-include="'${environment.LEGACY_contextPath}/index/headerTemplate'"></div>

    <div loading-layer></div>

    <div id="wrapper">
        <div id="main">
            <div ui-view></div>
        </div>
    </div>
  `,
  styles: [':host { display: flex; flex-direction: column; height: 100%; }'],
})
export class AngularJSContentComponent implements OnInit, OnDestroy {
  private _angularJSApp: angular.auto.IInjectorService;

  constructor(private elementRef: ElementRef, private angularJSAppHelperService: AngularJsAppHelperService) {
  }

  ngOnInit() {
    this.angularJSAppHelperService.bootstrap(this.elementRef.nativeElement).subscribe((app) => {
      this._angularJSApp = app;
    });
  }

  ngOnDestroy(): void {
    this.angularJSAppHelperService.shutdown(this._angularJSApp);
  }
}
