import {Component, Inject, Input} from '@angular/core';
import {LazyLoadEvent} from 'primeng/api';
import {CrudService, CRUD_SERVICE} from '../crud.service';
import {Entity} from '../definitions';

@Component({
  selector: 'ic-download-list-button',
  templateUrl: './download-list-button.component.html',
  styleUrls: ['./download-list-button.component.scss'],
})
export class DownloadListButtonComponent<E extends Entity> {
  @Input()
  filter: LazyLoadEvent
  loading = false;

  constructor(@Inject(CRUD_SERVICE) private crudService: CrudService<E>) {
  }

  async download() {
    if (this.loading) {
      return;
    }
    this.loading = true
    try {
      const filter = {...this.filter};
      filter.rows = null;
      filter.first = null;
      await this.crudService.download('csv', this.filter);
    } finally {
      this.loading = false;
    }
  }
}
