import {Data, Route} from '@angular/router';

/**
 * Required permissions to access a route.
 * Either set all or any but not both.
 * If `all` is set the user needs to have all roles assigned to access the route.
 * If `any` is set the user can access the route if he has at least one role in the array assigned.
 */
export interface Permission {
  all?: string[];
  any?: string[];
}

export interface ProtectedRouteData extends Data {
  permission: Permission;
}

export interface ProtectedRoute extends Route {
  data?: ProtectedRouteData;
}

/**
 * Use this type for Routes which can only be accessed by authenticated users with defined permissions.
 *
 * Example:
 *
 * ```
 *
 * const routes: ProtectedRoutes = [
 *   {
 *     path: 'person',
 *     loadChildren: () => PersonModule,
 *     canActivate: [AuthGuard],
 *     data: {
 *       permission: {
 *         all: [ROLE_STAFF]
 *       }
 *     }
 *   },
 *   ...
 * ]
 * ```
 */
export type ProtectedRoutes = ProtectedRoute[];

/**
 * Store information about the currently logged in user.
 * This class should be immutable.
 */
export class SecurityContext {
  public customClaims: Map<string, any> = new Map();

  constructor(public readonly username: string | null,
              private readonly roles: string[] | null,
              customClaims?: any) {
    if (customClaims) {
      this.customClaims = new Map(Object.entries(customClaims));
    }
  }

  hasAllRoles(roles: ReadonlyArray<string>): boolean {
    return this.isLoggedIn() && roles.every(role => this.roles.indexOf(role) !== -1);
  }

  hasAnyRole(roles: ReadonlyArray<string>): boolean {
    return this.isLoggedIn() && roles.some(role => this.roles.indexOf(role) !== -1);
  }

  isLoggedIn(): boolean {
    return this.username !== null && Array.isArray(this.roles);
  }
}
