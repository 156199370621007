import {InjectionToken} from '@angular/core';
import {CrudService} from './crud.service';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {SelectItem} from 'primeng/api';

/**
 * A DomainDefinition<T> specifies how a particular domain class of type T should be scaffold
 */
export interface DomainDefinition<T extends Entity> {
  // backend path
  path: string;
  service: InjectionToken<CrudService<T>>;
  // metadata of domain class properties.
  properties: Map<keyof T, PropertyDefinition>;
  propertiesMapping?: Map<keyof T, Paths<T>>;
  // show definitions for this domain class. The keys of this object are the names of the ShowDefinitions
  // TODO: this restriction is not yet applied
  showDefinitions?: { [key: string]: ShowDefinition<T> };
  // table definitions for this domain class. The keys of this object are the names of the TableDefinitions
  tableDefinitions?: { [key: string]: TableDefinition<T> };
  // form definitions for this domain class. The keys of this object are the names of the FormDefinitions
  // TODO: this restriction is not yet applied
  formDefinitions?: { [key: string]: FormDefinition<T> };
  stringify?: Function;
}

export interface Filters {
  [s: string]: FilterMetadata;
}

export enum FilterType {
  NONE = 'none',
  // filter types provided by primeng table
  CONTAINS = 'contains',
  STARTS_WITH = 'startsWith',
  ENDS_WITH = 'endsWith',
  EQUALS = 'equals',
  IN = 'in',
  GREATER_OR_EQUALS = 'gte',
  LOWER_OR_EQUALS = 'lte',
  BETWEEN = 'between',
  IS_NULL = 'isNull',
  IS_NOT_NULL = 'isNotNull',
}

export interface ListCommand {
  sortOrder?: number;
  sortField?: string;
  filters?: Filters;
  globalFilter?: string;
  rows?: number;
  first?: number;
  multiSortMeta?: object[];
}

export interface BasePropertyDefinition {
  nullable?: boolean;
  sortable?: boolean;
  filter?: FilterType;
  defaultFilterValue?: any;
  readonly?: boolean;
  denyEdit?: boolean;
}

export interface EnumPropertyDefinition extends BasePropertyDefinition {
  type: 'enum';
  // TS enum object
  cls: object;
  // java package used for translation e.g. com.ic.prototype
  prefix: string;
}

export interface TextPropertyDefinition extends BasePropertyDefinition {
  type: 'text';
  minSize?: number;
  maxSize?: number;
}

export interface StringPropertyDefinition extends BasePropertyDefinition {
  type: 'string';
  minSize?: number;
  maxSize?: number;
  regex?: RegExp;
}

export interface DatePropertyDefinition extends BasePropertyDefinition {
  type: 'date';
  min?: Date;
  max?: Date;
}

// TODO: do we really need this? Isn't this the same as date?
export interface LocalDatePropertyDefinition extends BasePropertyDefinition {
  type: 'local-date';
}

export interface LocalDateTimePropertyDefinition extends BasePropertyDefinition {
  type: 'local-date-time';
}


export interface LocalTimePropertyDefinition extends BasePropertyDefinition {
  type: 'local-time';
}

export interface BooleanPropertyDefinition extends BasePropertyDefinition {
  type: 'boolean';
}

export interface ImagePropertyDefinition extends BasePropertyDefinition {
  type: 'image';
  fileSize?: number;
  width?: number;
  height?: number;
}

export interface HasManyPropertyDefinition extends BasePropertyDefinition {
  type: 'has-many';
  // angular service token used to inject the service of the other end of the association
  service: InjectionToken<Entity>;
  inverse?: string;
  lazy?: boolean;
  noOptions?: boolean;
  denyAssociationCreation?: boolean;
  sortProperty?: string;
}

export interface BelongsToPropertyDefinition extends BasePropertyDefinition {
  type: 'belongs-to';
  // angular service token used to inject the service of the other end of the association
  service: InjectionToken<Entity>;
  inverse?: string;
  lazy?: boolean;
  noOptions?: boolean;
  denyAssociationCreation?: boolean;
  sortProperty?: string;
}

export interface IntegerPropertyDefinition extends BasePropertyDefinition {
  type: 'int';
}

export interface NumberPropertyDefinition extends BasePropertyDefinition {
  type: 'number';
  max?: number;
  min?: number;
  minFractionDigits?: number;
  maxFractionDigits?: number;
}

export interface MultiSelectPropertyDefinition extends BasePropertyDefinition {
  type: 'multi-select';
  options: SelectItem[];
  prefix: string;
}

export interface ActionsPropertyDefinition extends BasePropertyDefinition {
  type: 'action';
}

export interface BulkPropertyDefinition extends BasePropertyDefinition {
  type: 'bulk';
}

/**
 * A PropertyDefinition contains metadata for a domainclass property. Similar to PersistentProperty in the grails world
 */
export type PropertyDefinition =
  EnumPropertyDefinition
  | TextPropertyDefinition
  | StringPropertyDefinition
  | HasManyPropertyDefinition
  | BelongsToPropertyDefinition
  | DatePropertyDefinition
  | BooleanPropertyDefinition
  | LocalDatePropertyDefinition
  | LocalDateTimePropertyDefinition
  | LocalTimePropertyDefinition
  | ImagePropertyDefinition
  | ActionsPropertyDefinition
  | BulkPropertyDefinition
  | IntegerPropertyDefinition
  | NumberPropertyDefinition
  | MultiSelectPropertyDefinition;

/**
 * ShowDefinition<T> contains all information needed to render a view for a single domain instance of type T.
 */
export interface ShowDefinition<T extends Entity> {
  // property names in the order they should be rendered
  fields: Array<keyof T>;
}

// Flatten types
// https://flut1.medium.com/deep-flatten-typescript-types-with-finite-recursion-cb79233d93ca
export type NonObjectKeysOf<T> = {
  [K in keyof T]: T[K] extends Array<any> ? K : T[K] extends object ? never : K
}[keyof T];

export type ValuesOf<T> = T[keyof T];

export type ObjectValuesOf<T extends Object> = Exclude<Exclude<Extract<ValuesOf<T>, object>, never>,
  Array<any>>;

export type UnionToIntersection<U> = (U extends any
  ? (k: U) => void
  : never) extends ((k: infer I) => void)
  ? I
  : never;

export type Flatten<T> = Pick<T, NonObjectKeysOf<T>> &
  Partial<UnionToIntersection<ObjectValuesOf<T>>>;

// https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object/58436959#58436959
type Cons<H, T> = T extends readonly any[] ?
  ((h: H, ...t: T) => void) extends ((...r: infer R) => void) ? R : never
  : never;

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]]

export type Paths<T, D extends number = 10> = [D] extends [never] ? never : T extends object ?
  {
    [K in keyof T]-?: [K] | (Paths<T[K], Prev[D]> extends infer P ?
    P extends [] ? never : Cons<K, P> : never
    )
  }[keyof T]
  : [];


/**
 * TableDefinition<T> contains all information needed to render a table grid for a list of domain instances of type T
 */
export interface TableDefinition<T extends Entity> {
  denyEntityCreation?: boolean;
  // property names in the order they should be rendered
  columns: Array<keyof T>;
  inlineEditable?: boolean;
  immutable?: boolean;  // no insert buttons, no edit links
  sortField?: string;
  sortOrder?: 1 | -1;  // 1: ascending | -1: descending
  displayLink?: string;
  appendLink?: string;
  globalFilter?: string;
  disableDetail?: boolean; // no detail link
  filters?: Filters; // default filter applied to all queries
  filterStorable?: boolean;
  columnsConfigurable?: boolean;
  tableTitle?: string;       // set a specific Title
  tableEntityName?: string; // set a specific Entity name e.g "Planer and Manager": "Resource total or 119 Planer and Manager total"
  tableDefaultRowsOption?: number; // set the right default rows number of [rowsPerPageOptions] e.g. [10,20,50,100] otherwise it will be 10 as standard.
  /**
   * Show a download button which lets a user download the list content as CSV file.
   */
  downloadable?: boolean;
}

/**
 * FormDefinition<T> contains all information needed to render a form for a domain instance of type T
 * This does not contain the instance its self but rather the structure of the form.
 */
export interface FormDefinition<T extends Entity> {
  // property names in the order they should be rendered
  fields: Array<keyof T>;
  denyAssociationCreation?: boolean,
}

export interface MinimalListItem<T extends Entity> {
  label: string,
  id: number,
  group?: string,
  icon?: string
}

/**
 * MinimalList for a list of components. Use this if only the id and a string representation is needed.
 * e.g. for dropdowns and multiselects
 */
export type MinimalList<T extends Entity> = MinimalListItem<T>[];

/**
 * Base interface for every DomainClass interface
 */
export interface Entity {
  id: number;
  label?: string;
  highlighted?: boolean;
}

/**
 * Metadata contains enhances the DomainDefinition with additional dynamic properties.
 * Data is fetched from the metadata endpoint which has to be provided by each
 */
export interface Metadata<T extends Entity> {
  propertyPermissions: PropertyPermission<T>;
}

export interface PropertyPermission<T extends Entity> {
  editable: { [property in keyof T]: boolean };
}

/**
 * Use this for associations which might not be fully loaded from the server.
 */
export type Relation<T extends Entity> = T | Entity

/**
 * Backend API configuration
 */
export interface APIConfiguration {
  serverURL: string;  // something like https://example.com/api
}

export const API_CONFIGURATION: InjectionToken<APIConfiguration> = new InjectionToken('API_CONFIGURATION');
