import {InjectionToken, Injector} from '@angular/core';
import {CachedCrudService, DomainDefinition, Entity, FilterType, PropertyDefinition} from '@ic/ng-crud-client';
import {Country, COUNTRY_SERVICE} from '../country/country.definition';

export interface HealthInstitution extends Entity {
  institutionName?: string;
  address?: string;
  zipCode?: string;
  location?: string;
  shortName?: string;
  country?: Country;
}

export const HEALTH_INSTITUTION_SERVICE = new InjectionToken<CachedCrudService<HealthInstitution>>('HEALTH_INSTITUTION_SERVICE');

export const healthInstitutionDefinition: DomainDefinition<HealthInstitution> = {
  path: 'healthInstitution',
  service: HEALTH_INSTITUTION_SERVICE,
  properties: new Map<keyof HealthInstitution, PropertyDefinition>([
    ['institutionName', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
    ['shortName', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
      nullable: true,
    }],
    ['address', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
    ['zipCode', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
    ['location', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
    ['country', {
      type: 'belongs-to',
      filter: FilterType.IN,
      service: COUNTRY_SERVICE,
      denyAssociationCreation: true,
    }],

  ]),
  formDefinitions: {
    default: {
      fields: ['institutionName', 'shortName', 'address', 'zipCode', 'location', 'country'],
    },
  },
  tableDefinitions: {
    default: {
      columns: ['institutionName', 'shortName', 'address', 'zipCode', 'location', 'country'],
      inlineEditable: true,
      immutable: false,
      disableDetail: false,
      sortField: 'institutionName',
      sortOrder: 1,
      filters: {},
      filterStorable: true,
    },
  },
};

export function healthInstitutionCrudServiceFactory(httpClient, injector: Injector) {
  return new CachedCrudService(healthInstitutionDefinition, httpClient, injector);
}

