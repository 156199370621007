export interface ServerVersion {
  grailsVersion: string
  version: string
  name: string
}

export interface ServerStatus {
  app: ServerVersion
  readOnly: boolean
  environmentName: string
  environment: ServerEnv
  bannerMessage?: BannerMessage
}

export interface BannerMessage {
  text: string
  type: string
}

export enum ServerEnv {
  CUSTOM = 'CUSTOM',
  APPLICATION = 'APPLICATION',
  TEST = 'TEST',
  PRODUCTION = 'PRODUCTION',
  DEVELOPMENT = 'DEVELOPMENT',
}
