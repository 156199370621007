import {Injectable, Injector} from '@angular/core';
import {CrudService} from '@ic/ng-crud-client';
import {ActivityRuleConfiguration, activityRuleConfigurationDefinition} from './activity-rule-configuration.definition';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SyncDoneByRole} from '../../definitions';

@Injectable({providedIn: 'root'})
export class ActivityRuleConfigurationService extends CrudService<ActivityRuleConfiguration> {

  constructor(http: HttpClient, injector: Injector) {
    super(activityRuleConfigurationDefinition, http, injector);
  }

  save(entity: ActivityRuleConfiguration, format?: string): Observable<ActivityRuleConfiguration> {
    if (entity.activityType)
      if (entity.syncDoneByRole === SyncDoneByRole.NONE) {
        entity.syncDoneByRole = '';
      }
      return super.save(entity);
  }


  delete(model: any) {
    return super.destroy(model);
  }
}
