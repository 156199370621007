import {Observable} from 'rxjs';
import {SecurityContext} from '../definitions';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {AuthService} from '../core';

export abstract class UsernamePasswordAuthService extends AuthService {
  /** Redirection target after the user has successfully logged in */
  protected redirectionTarget: string;
  protected readonly loginRoute: string = 'login'

  constructor(protected router: Router) {
    super();
  }

  requestLogin() {
    // TODO: Think about a possibility to login with a form in a dialog
    this.requestLoginByRedirect();
  }

  login(username: string, password: string): Observable<unknown> {
    // do internal login by validating username and password on the server
    return this.doLogin(username, password).pipe(
      tap((authentication: SecurityContext) => {
        // inform auth service about new authentication
        this.setAuthentication(authentication);
        // redirect user to originally requested page or close dialog
        this.router.navigateByUrl(this.redirectionTarget || '/');
        this.redirectionTarget = null;
      }),
    );
  };

  logout() {
    this.router.navigateByUrl(this.loginRoute);
  }

  /**
   * Validate credentials with server and return a SecurityContext.
   * The implementation is responsible to store a potential token or session id
   */
  protected abstract doLogin(username: string, password: string): Observable<SecurityContext>;

  protected requestLoginByRedirect() {
    // store current target route
    const navigation = this.router.getCurrentNavigation();
    this.redirectionTarget = navigation.finalUrl.toString()

    // redirect to login form or show login form in context menu
    this.router.navigateByUrl(this.loginRoute);
  }
}

