import {Component, OnInit} from '@angular/core';
import {UserNotificationService} from '../user-notification.service';
import {Notification} from '../definitions';
import {throwError} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {mergeMap} from 'rxjs/operators';
import {SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'un-user-notification-show',
  templateUrl: './user-notification-show.component.html',
  styleUrls: ['./user-notification-show.component.scss'],
})
export class UserNotificationShowComponent implements OnInit {
  notification: Notification & {mailContentURL: SafeResourceUrl};

  constructor(private userNotificationService: UserNotificationService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.activatedRoute.params.pipe(mergeMap(
      (params) => {
        if (!params.id) {
          return throwError('show needs an id');
        }
        return this.userNotificationService.get(parseInt(params.id, 10));
      }))
      .subscribe((notification) => {
        this.notification = notification;
      }, () => {
        return this.router.navigate(['/not-found']);
      });
  }

}
