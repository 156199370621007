import { API_CONFIGURATION, APIConfiguration, CrudService, ListPage, Relation } from '@ic/ng-crud-client';
import { Inject, Injectable, Injector } from '@angular/core';
import { Agency, agencyDefinition } from './agency.definition';
import { HttpClient } from '@angular/common/http';
import { ConnectableObservable, EMPTY, Observable, throwError } from 'rxjs';
import { AgencyOfferRole } from '../../definitions';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { SendTokenLinkDialogComponent } from '../user/send-token-link-dialog/send-token-link-dialog.component';
import { catchError, filter, first, map, publish, share, switchMap, tap } from 'rxjs/operators';

interface SendAgencyTokenCommand {
  agency: Relation<Agency>;
  message?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AgencyService extends CrudService<Agency> {

  constructor(http: HttpClient, injector: Injector, @Inject(API_CONFIGURATION) private apiConfig: APIConfiguration,
              private messageService: MessageService) {
    super(agencyDefinition, http, injector)
  }

  activate(agency: Agency): Observable<unknown> {
    const activationRequest$ = this.http.post<unknown>(
      this.apiConfig.serverURL + 'agency/changeAgencyEnabled',
      {agencyId: agency.id, enabled: true},
    );
    const observable = activationRequest$
      .pipe(
        catchError((error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Unbekannter Fehler',
            detail: 'Agentur konnte nicht reaktiviert werden.',
          });
          return throwError(error)
        }),
        map((response) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Agentur reaktiviert',
            detail: 'Die Agentur wurde erfolgreich reaktiviert',
          });
        }),
        share(),
        publish(),
      ) as ConnectableObservable<unknown>;
    observable.connect();
    return observable;
  }
  /** Disable all users of an Agency.
   */
  deactivate(agency: Agency): Observable<unknown> {
    const observable: ConnectableObservable<unknown> = this.http.post(
      this.apiConfig.serverURL + 'agency/changeAgencyEnabled',
      {agencyId: agency.id, enabled: false},
    )
      .pipe(
        catchError((err => {
          this.messageService.add({
            severity: 'error',
            summary: err.error.title,
            detail: err.error.detail,
            sticky: true,
          })
          return throwError(err);
        })),
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Agentur deaktiviert',
            detail: 'Die Agentur wurde erfolgreich deaktiviert.',
          })
        }),
          share(),
    publish(),
      ) as ConnectableObservable<unknown>;
    observable.connect();
    return observable;
  }
  save(entity: Agency, format?: string): Observable<Agency> {
    if (entity.offerRole)
      if (entity.offerRole === AgencyOfferRole.NONE) {
        entity.offerRole = '';
      }
    return super.save(entity);
  }

  offerableAgencies(stintIDs?: number[]): Observable<ListPage<Agency>> {
    const payload: LazyLoadEvent = {
      filters: {
        offerable: {value: true, matchMode: 'equals'},
        enabled: {value: true, matchMode: 'equals'},
      },
    };
    if (Array.isArray(stintIDs)) {
      payload.filters['forStints'] = {
        matchMode: 'in',
        value: stintIDs.map(stintId=> ({id: stintId})),
      };
    }
    return this.list(payload) as Observable<ListPage<Agency>>;
  }

  sendTokenLink(agency: Agency): Observable<unknown> {
    const config: DynamicDialogConfig = {
      header: 'Anmeldelink verschicken',
      data: {agency: agency},
      closeOnEscape: true,
    };
    return this.dialogService.open(SendTokenLinkDialogComponent, config).onClose.pipe(
      first(),
      filter((cmd) => cmd),
      switchMap((cmd: SendAgencyTokenCommand) => this.http.post('/api/v1/agency/sendToken', {agency: {id: agency.id}, ...cmd})),
      tap(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Anmeldelink verschickt',
          detail: `${agency.label} wurde ein Link für eine einmalige Anmeldung verschickt.`,
        });
      }),
      catchError((err, caught) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Fehler',
          detail: 'Der Anmeldelink konnte leider nicht verschickt werden.',
        });
        return EMPTY;
      }),
    );
  }
}
