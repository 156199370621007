import {Injectable} from '@angular/core';
import {AppointmentDialogContext} from '../definitions';
import {PlanningStore} from '../../services/store/planning-store.service';
import {DialogStateService, SelectionOutput} from './dialog-state.service';
import {AppointmentDialogDataService} from './appointment-dialog-data.service';

@Injectable()
export class AppointmentDialogStateService extends DialogStateService<AppointmentDialogContext, SelectionOutput> {

  constructor(protected dataService: AppointmentDialogDataService, store: PlanningStore) {
    super(store);
  }


  completeDialog(): void {
    this.dataService.loadAppointments(null);
    this.context = undefined;
    super.completeDialog();
  }
}


