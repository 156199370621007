import {InjectionToken} from '@angular/core';
import {CachedCrudService, DomainDefinition, Entity, FilterType, PropertyDefinition} from '@ic/ng-crud-client';

export interface Specialty extends Entity {
  specialty: string;
}

export const SPECIALTY_SERVICE = new InjectionToken<CachedCrudService<Specialty>>('SPECIALTY_SERVICE');

export const specialtyDefinition: DomainDefinition<Specialty> = {
  path: 'specialty',
  service: SPECIALTY_SERVICE,
  properties: new Map<keyof Specialty, PropertyDefinition>([
    ['specialty', {
      type: 'string',
      sortable: true,
      filter: FilterType.CONTAINS,
    }],
  ]),
  formDefinitions: {
    default: {
      fields: ['specialty'],
    },
  },
  tableDefinitions: {
    default: {
      columns: ['specialty'],
      inlineEditable: true,
      immutable: false,
      disableDetail: false,
      sortField: 'specialty',
      sortOrder: 1,
      filters: {},
      filterStorable: true,
    },
  },
}
