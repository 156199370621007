import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UsernamePasswordAuthModule} from '../username-password-auth';
import {CookieAuthService} from './cookie-auth.service';
import {COOKIE_AUTH_CONFIG, CookieAuthConfig} from './config';
import {AuthCoreModule} from '../core/auth-core.module';
import {AuthService} from '../core';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    UsernamePasswordAuthModule,
  ],
  exports: [
    AuthCoreModule, UsernamePasswordAuthModule,
  ],
})
export class CookieAuthModule {
  static forRoot(config: CookieAuthConfig): ModuleWithProviders<CookieAuthModule> {
    return {
      ngModule: CookieAuthModule,
      providers: [
        {provide: AuthService, useClass: CookieAuthService},
        {provide: COOKIE_AUTH_CONFIG, useValue: config},
      ],
    }
  }
}
