import {Component} from '@angular/core';


@Component({
  selector: 'pool-root',
  templateUrl: './app.component.html',
  styles: [],
})
export class AppComponent {
}
