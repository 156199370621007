import {Inject, Injectable, Injector} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {API_CONFIGURATION, APIConfiguration, CrudService, Entity, MinimalList, Relation} from '@ic/ng-crud-client';
import {catchError, filter, first, map, publish, share, switchMap, tap} from 'rxjs/operators';
import {ConnectableObservable, EMPTY, throwError} from 'rxjs';
import {MessageService} from 'primeng/api';
import {EmployeeContractService} from '../contract/employee-contract.service';
import {EmployeeContract} from '../contract/employee-contract.definition';
import {Employee} from '../employee/employee.definition';
import {User, userDefinition} from './user.definition';
import {SendTokenLinkDialogComponent} from './send-token-link-dialog/send-token-link-dialog.component';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {environment} from '../../../environments/environment';

interface SendTokenCommand {
  user: Relation<User>;
  message?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserService extends CrudService<User> {

  constructor(http: HttpClient, injector: Injector, @Inject(API_CONFIGURATION) private apiConfig: APIConfiguration,
              private messageService: MessageService, private contractService: EmployeeContractService) {
    super(userDefinition, http, injector);
  }


  override minimalList(params?: any): Observable<MinimalList<User>> {
    return super.minimalList(params).pipe(
      map((items) => items.sort((a, b) => a.label.localeCompare(b.label))),
    );
  }

  /**
   * Enables the user of an employee
   * If the employee is a resource it ensures that it has a valid contract.
   * If there is no valid contract the user is asked to create one.
   */
  activate(employee: Employee): Observable<unknown> {
    const activationRequest$ = this.http.post<unknown>(
      this.apiConfig.serverURL + 'employee/changeUserEnabled',
      {employeeId: employee.id, enabled: true},
    );
    const observable = activationRequest$
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          if (errorResponse.status === 400 && errorResponse.error.type === 'https://hospital-pool.ch/problems/invalid-interaction/js.error.employeeService.noValidContract') {
            this.messageService.add({
              severity: 'warn',
              summary: 'Kein gültiger Arbeitsvertrag',
              detail: 'Bitte erfassen Sie einen neuen Arbeitsvertrag, damit die Ressource reaktiviert werden kann.',
              life: 5000,
            });

            return this.contractService.createInDialog({resourceData: {id: employee.employeeData.id}}).pipe(
              switchMap((contract: EmployeeContract) => activationRequest$),
            );
          } else if (errorResponse.status === 400 && errorResponse.error.type === 'https://hospital-pool.ch/problems/invalid-interaction/js.license.noRemainingUsers.error') {
            this.messageService.add({
              severity: 'error',
              summary: 'Lizenz ist ausgeschöpft',
              detail: 'Um weitere Arbeitskräfte zu aktivieren, müssen Sie die Lizenz erhöhen. Dies können Sie unter Konfiguration → Lizenzverwaltung tun.',
              life: 5000,
            })
            return EMPTY;
          }
          return throwError(errorResponse);
        }),
        catchError((error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Unbekannter Fehler',
            detail: 'Person konnte nicht reaktiviert werden.',
          });
          return throwError(error);
        }),
        map((response) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Person reaktiviert',
            detail: 'Die Ressource wurde erfolgreich reaktiviert.',
          });
        }),
        share(),
        publish(),
      ) as ConnectableObservable<unknown>;
    observable.connect();
    return observable;
  }


  /**
   * Disable the user of an employee.
   * The contract is terminated, all future bookings are cancelled and future availabilities are deleted.
   */
  deactivate(employee: Employee): Observable<unknown> {
    const observable: ConnectableObservable<unknown> = this.http.post(
      this.apiConfig.serverURL + 'employee/changeUserEnabled',
      {employeeId: employee.id, enabled: false},
    )
      .pipe(
        catchError((err => {
          this.messageService.add({
            severity: 'error',
            summary: err.error.title,
            detail: err.error.detail,
            sticky: true,
          })
          return throwError(err);
        })),
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Person deaktiviert',
            detail: 'Die Person wurde erfolgreich deaktiviert.',
          })
        }),
        share(),
        publish(),
      ) as ConnectableObservable<unknown>;
    observable.connect(); // make the observable hot independent on whether the caller subscribs to the returned observable or not.
    return observable;

  }

  sendPasswordResetMail(user: User): Observable<unknown> {
    return this.http.post('/registration/requestNewPassword', {user: user.username}, {withCredentials: false}).pipe(
      tap(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Einladung versendet',
          detail: `${user.label} wurde eingeladen, ein neues Passwort zu setzen.`,
        });
      }),
      catchError((err, caught) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Fehler',
          detail: 'Die Einladung konnte leider nicht gesendet werden.',
        });
        return EMPTY;
      }));
  }

  sendTokenLink(user: Entity): Observable<unknown> {
    const config: DynamicDialogConfig = {
      header: 'Anmeldelink verschicken',
      data: {user: user},
      closeOnEscape: true,
    };
    return this.dialogService.open(SendTokenLinkDialogComponent, config).onClose.pipe(
      first(),
      filter((cmd) => cmd),
      switchMap((cmd: SendTokenCommand) => this.http.post('/api/v1/user/sendToken', {user: {id: user.id}, ...cmd})),
      tap(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Anmeldelink verschickt',
          detail: `${user.label} wurde ein Link für eine einmalige Anmeldung verschickt.`,
        });
      }),
      catchError((err, caught) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Fehler',
          detail: 'Der Anmeldelink konnte leider nicht verschickt werden.',
        });
        return EMPTY;
      }),
    );
  }

  updatePassword( passwordForm: any) {
    return this.http.post('/registration/doUpdatePassword', passwordForm)
  }
}
