import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonModule} from 'primeng/button';
import {RouterModule, Routes} from '@angular/router';
import {LandingPageComponent} from './landing-page/landing-page.component';

const routes: Routes = [
  {path: '', component: LandingPageComponent},
];

/**
 * Landing page integrated into the Angular system
 * Currently not in use. A static html page is preferred due to SEO discoverability
 */
@NgModule({
    declarations: [
        LandingPageComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        ButtonModule,
        RouterModule.forChild(routes),
    ],
    providers: []
})
export class LandingPageModule {
}
