import {DomainDefinition, FilterType, Paths, PropertyDefinition, Relation} from '@ic/ng-crud-client';
import {InjectionToken} from '@angular/core';
import {ORGANISATION_UNIT_SERVICE} from '../organisation-unit/organisation-unit.definition';
import {Sex} from '../../definitions';
import {Qualification, QUALIFICATION_SERVICE} from '../qualification/qualification.definition';
import {Booking, BookingValue} from '../booking/booking.definition';
import {Shift} from '../shift/shift.definition';
import {StintService} from './stint.service';
import {StintOffer} from '../stint-offer/stint-offer.definition';
import {PATIENT_SERVICE} from '../patient/patient.definition';
import {HEALTH_INSTITUTION_SERVICE} from '../health-institution/health-institution.definition';
import {LocalDateTime} from '@js-joda/core';
import {WorkType} from '../booking/work-type.definition';


export enum StintStatus {
  OPEN = 'OPEN',
  LOCKED = 'LOCKED',
  BROADCASTED = 'BROADCASTED',
  CANCELLED = 'CANCELLED',
  BOOKED = 'BOOKED',
}

export enum CancellationReason {
  INTERNAL_SOLUTION_FOUND = 'INTERNAL_SOLUTION_FOUND',
  AGENCY_SOLUTION = 'AGENCY_SOLUTION',
  NOT_FOUND_ANYONE = 'NOT_FOUND_ANYONE',
  WRONGLY_RECORDED = 'WRONGLY_RECORDED',
  OTHERS = 'OTHERS',
}

interface StintExtension {
  shift?: Partial<Shift>;
  status: StintStatus;
  sex?: Sex;
  dateCreated: LocalDateTime;
  alreadyWorkedInOrganisationUnit?: boolean;
  alreadyWorkedInHealthInstitution?: boolean;
  qualifications?: Relation<Qualification>[];
  stintOffers?: Partial<StintOffer>[];
  firstOffer?: LocalDateTime;
  firstBroadcast?: LocalDateTime;
  offersCount?: number;
  plannedLongTerm?: boolean;
}

export interface Stint extends Booking, StintExtension {
}

export interface StintValue extends BookingValue, StintExtension {
}

export const STINT_SERVICE: InjectionToken<StintService> = new InjectionToken('STINT_SERVICE');


export const stintDefinition: DomainDefinition<Stint> = {
  path: 'stint',
  service: STINT_SERVICE,
  properties: new Map<keyof Stint, PropertyDefinition>([
      ['start', {
        type: 'local-date-time',
        filter: FilterType.BETWEEN,
        nullable: true,
        sortable: true,
        defaultFilterValue: [LocalDateTime.now(), LocalDateTime.now().plusYears(1),],
      }],
      ['end', {
        type: 'local-date-time',
        filter: FilterType.BETWEEN,
        nullable: true,
        sortable: true,
      }],
      ['sex', {
        type: 'enum',
        cls: Sex,
        prefix: 'Sex',
        sortable: true,
      }],
      ['alreadyWorkedInOrganisationUnit', {
        type: 'boolean',
        filter: FilterType.IN,
        sortable: true,
      }],
      ['alreadyWorkedInHealthInstitution', {
        type: 'boolean',
        filter: FilterType.IN,
        sortable: true,
      }],
      ['plannedLongTerm', {
        type: 'boolean',
      }],
      ['comment', {
        type: 'text',
        nullable: true,
      }],
      ['internalNote', {
        type: 'text',
        nullable: true,
      }],
      ['qualifications', {
        type: 'has-many',
        service: QUALIFICATION_SERVICE,
        filter: FilterType.IN,
        sortable: false,
      }],
      ['workType', {
        type: 'enum',
        cls: WorkType,
        prefix: 'WorkType',
        filter: FilterType.IN,
        sortable: true,
      }],
      ['organisationUnit', {
        type: 'belongs-to',
        service: ORGANISATION_UNIT_SERVICE,
        filter: FilterType.IN,
        sortable: true,
        sortProperty: 'organisationUnitName',
      }],
      ['healthInstitution', {
        type: 'belongs-to',
        service: HEALTH_INSTITUTION_SERVICE,
        filter: FilterType.IN,
        sortable: true,
      }],
      ['patient', {
        type: 'belongs-to',
        service: PATIENT_SERVICE,
        filter: FilterType.IN,
        sortable: true,
      }],
      ['caseNumber', {
        type: 'text',
        filter: FilterType.CONTAINS,
        sortable: true,
      }],
      ['dateCreated', {
        type: 'local-date-time',
        filter: FilterType.BETWEEN,
        sortable: true,
        nullable: false,
      }],
      ['firstOffer', {
        type: 'local-date-time',
        filter: FilterType.BETWEEN,
        sortable: true,
        nullable: true,
      }],
      ['firstBroadcast', {
        type: 'local-date-time',
        filter: FilterType.BETWEEN,
        sortable: true,
        nullable: true,
      }],
    ],
  ),
  formDefinitions: {
    planning: {
      fields: ['organisationUnit', 'qualifications', 'start', 'end', 'sex', 'alreadyWorkedInOrganisationUnit', 'alreadyWorkedInHealthInstitution'],
    },
  },
  // @ts-ignore
  propertiesMapping: new Map<keyof Stint, Paths<Stint>>([
    ['healthInstitution', ['organisationUnit', 'healthInstitution']],
    ['caseNumber', ['patient', 'caseNumber']],
  ]),
  tableDefinitions: {
    default: {
      columns: [
        'organisationUnit', 'healthInstitution', 'qualifications', 'workType', 'patient', 'caseNumber', 'start', 'end', 'sex',
        'alreadyWorkedInOrganisationUnit', 'alreadyWorkedInHealthInstitution'],
      inlineEditable: false,
      // globalFilter: 'z.B. Chirurgie, Müller...',
      immutable: true,
      disableDetail: true,
      sortField: 'end',
      sortOrder: 1,
      filterStorable: true,
      columnsConfigurable: true,
    },
    planner: {
      columns: [
        'organisationUnit', 'patient', 'caseNumber', 'qualifications', 'workType', 'start', 'end', 'sex', 'alreadyWorkedInOrganisationUnit', 'alreadyWorkedInHealthInstitution', 'comment', 'internalNote', 'dateCreated', 'firstOffer', 'firstBroadcast'],
      inlineEditable: false,
      // globalFilter: 'z.B. Chirurgie, Müller...',
      immutable: true,
      disableDetail: true,
      sortField: 'end',
      sortOrder: 1,
      columnsConfigurable: true,
      filterStorable: true,
    },
    resource: {
      columns: [
        'organisationUnit', 'qualifications', 'start', 'end', 'sex', 'comment',
      ],
      inlineEditable: false,
      // globalFilter: 'z.B. Chirurgie, Müller...',
      immutable: true,
      disableDetail: true,
      sortField: 'start',
      sortOrder: 1,
      columnsConfigurable: true,
      filterStorable: true,
    },

  },
  //    todo add properties
};
