import {Injectable} from '@angular/core';
import {Entity} from '@ic/ng-crud-client';
import {ChangeCommand, EntryType, Tab} from '../../planning.definitions';
import {DialogContext} from '../definitions';
import {PlanningStore} from '../../services/store/planning-store.service';
import {Action} from '../../services/store/action';
import {FormHelper} from './form-helper';
import {Subject} from 'rxjs';

export interface SelectionOutput {
  entryType: EntryType
  entries: Entity[]
}

@Injectable()
export abstract class DialogStateService<C extends DialogContext, S extends SelectionOutput> {
  private _context: C;
  private _selectionOutput: S;
  private _selectedTab: Tab;
  private _formOutput: ChangeCommand;
  private _formHelper: FormHelper;
  proceedToNextPage$ = new Subject<void>();

  constructor(private store: PlanningStore) {
  }

  /*** Initialization ***/
  set context(context: C) {
    if (this._context && context !== undefined) {
     throw new Error('There is not support for cell context updates. Please recreate the dialog.');
    }
    this._context = context
  }

  public setTabIndex(tab: Tab) {
    this._selectedTab = tab
  }

  public getTabIndex(): Tab {
    return this._selectedTab;
  }


  get context(): C {
    if (!this._context) {
      throw new Error('The cellSelection has not been set yet. Ensure context has been set before.')
    }
    return this._context;
  }

  set formHelper(helper: FormHelper) {
    this._formHelper = helper;
  }

  get formHelper(): FormHelper {
    return this._formHelper;
  }

  /*** PAGE 0: SELECTION ***/
  completeSelection(selection: S): void {
    this._selectionOutput = selection;
  }

  clearSelection(): void {
    this._selectionOutput = undefined;
  }

  get selectionOutput(): S {
    if (this._selectionOutput) {
      return this._selectionOutput
    }
    console.warn('selectionOutput accessed even though it was not set')
    return null;
  }

  /*** PAGE 1: FORM ***/
  completeForm(formOutput: ChangeCommand): void {
    if(this.formHelper) {
      this._formOutput = this.formHelper.withChangeDetails(formOutput);
    } else {
      this._formOutput = formOutput;
    }
  }

  clearForm(): void {
    this._formOutput = undefined;
  }

  /*** PAGE 2: VALIDATION ***/
  get formOutput(): ChangeCommand {
    return this._formOutput;
  }

  completeDialog(): void {
    this.store.dispatch(new Action(Action.LOAD_PLANNING_ROWS))
  }
}


