import {CrudService, ListCommand} from '@ic/ng-crud-client';
import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FlatSyncExternalSystem, syncExternalSystemDefinition} from './sync-external-system.definition';
import {Observable} from 'rxjs/internal/Observable';
import {tap} from 'rxjs/operators';
import {OrganisationUnit} from '../organisation-unit/organisation-unit.definition';

@Injectable({
  providedIn: 'root',
})
export class SyncExternalSystemService extends CrudService<FlatSyncExternalSystem> {

  constructor(http: HttpClient, injector: Injector) {
    super(syncExternalSystemDefinition, http, injector);
  }

  complete(id: number): Observable<void> {
    return this.http.post<void>(`/api/v1/syncExternalSystem/complete?id=${id}`, {});
  }

  incomplete(id: number): Observable<void> {
    return this.http.post<void>(`/api/v1/syncExternalSystem/incomplete?id=${id}`, {});
  }

  bulkComplete(cmd: ListCommand): Observable<void> {
    return this.http.post<void>('/api/v1/syncExternalSystem/bulkComplete', cmd);
  }

  toggleMark(model: FlatSyncExternalSystem): Observable<void> {
    return this.http.post<void>(`/api/v1/syncExternalSystem/toggleMark?id=${model.id}`, {}).pipe(
      tap(() => {
        model.marked = !model.marked
        model.highlighted = !model.highlighted
      }),
    );
  }

  getBaseOrganisations() {
    return this.http.get<OrganisationUnit[]>('/api/v1/syncExternalSystem/getBaseOrganisationUnits');
  }
}
