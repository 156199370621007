import {Injectable, Injector} from '@angular/core';
import {CrudService} from '@ic/ng-crud-client';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {Education, educationDefinition} from './education.definition';


@Injectable({
  providedIn: 'root',
})
export class EducationService extends CrudService<Education> {

  constructor(http: HttpClient, injector: Injector) {
    super(educationDefinition, http, injector)
  }
  delete(model: any): Observable<unknown> {
    return this.http.delete(this.apiBaseUrl + `education/delete/${model.id}`);
  }
}
