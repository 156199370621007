import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, isObservable} from 'rxjs';
import {ColAction} from '../table.component';
import {Entity} from '../../definitions';
import {finalize} from 'rxjs/operators';

export interface ActionState {
  isDisabled: boolean;
  icon?: string;
  toolTip?: string;
  label?: string;
  cssClass?: string;
  isVisible?: boolean;
}

@Component({
  selector: 'ic-action-cell-renderer',
  templateUrl: './action-cell-renderer.component.html',
  styleUrls: ['./action-cell-renderer.component.scss'],
})
export class ActionCellRendererComponent<E extends Entity> implements OnInit, OnDestroy {
  @Input() colAction: ColAction;
  @Input() item: E;

  readonly actionState$: BehaviorSubject<ActionState> = new BehaviorSubject<ActionState>(null);

  constructor() {
    this._setActionState(this.item, this.colAction);
  }

  ngOnInit() {
    this._setActionState(this.item, this.colAction);
  }

  ngOnDestroy(): void {
    this.actionState$.complete();
  }

  public onClickAction(): void {
    const acObservable = this.colAction.actionDelegate(this.item)

    if (isObservable(acObservable)) {
      this.actionState$.next({
        ...this.actionState$.value,
        isDisabled: true,
        icon: 'pi pi-spin pi-spinner',
      })
      acObservable.pipe(
        finalize(() => {
          this._setActionState(this.item, this.colAction)
        }),
      ).subscribe({
        error: (error) => {
          console.error('Error by requesting current data from backend server', error);
        },
      });
    }
  }

  private _setActionState(item: E, colAction: ColAction): void {
    this.actionState$.next(this._getActionState(item, colAction));
  }

  private _getActionState(item: E, colAction: ColAction): ActionState {
    // default
    if (!item) {
      return {
        isDisabled: false,
        toolTip: '',
        icon: '',
      };
    }
    return {
      isDisabled: colAction.isDisabled(item),
      icon: colAction.icon ? colAction.icon(item) : '',
      toolTip: colAction.toolTip ? colAction.toolTip(item) : '',
      label: colAction.label ? colAction.label(item) : '',
      cssClass: colAction.cssClass ? colAction.cssClass(item) : 'p-button-text',
      isVisible: colAction.isVisible ? colAction.isVisible(item) : true,
    };
  }
}
