import {Pipe, PipeTransform} from '@angular/core';
import {StringifyService} from './stringify.service';
import {PropertyDefinition} from '../definitions';

/**
 * StringifyPipe turns a value in a human readable translated string. The way the value is transformed
 * depends on the PropertyDefinition given as argument.
 * e.g Dates are nicely formatted, strings are displayed as is, enums are translated, associations become a stringRepresentation
 *
 * Example
 *    {{ value | stringify:pd }}        // pd needs to be the property definition of the value property
 *
 */
@Pipe({
  name: 'stringify',
  pure: false // used for translation in EnumPipe
})
export class StringifyPipe implements PipeTransform {
  private cachedResult = '-';

  constructor(private stringify: StringifyService) {
  }

  transform(value: any, ...args: any[]): any {
    if (args.length === 1 && args[0]) {
      const pd: PropertyDefinition = args[0];
      this.stringify.getPropertyValue(pd, value).subscribe((text: string) => this.cachedResult = text);
    }
    return this.cachedResult;
  }

}
